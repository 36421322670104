import React, { FC, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ToolbarProps, View } from 'react-big-calendar';
import { CalendarToolbarBuilder } from './CalendarToolbarBuilder';
import { calendarToolbarStyles } from './calendarToolbarStyles';

const CalendarToolbarWithViews: FC<ToolbarProps> = ({ view, views, label, localizer, onNavigate, onView, }) => {
  const classes = calendarToolbarStyles();
  const [viewSelected, setviewSelected] = useState<View>('week');

  const handleChange = (event: React.MouseEvent<HTMLElement>, newView: View) => {
    if (newView) {
      setviewSelected(newView);
      onView(newView);
    }
  };

  useEffect(() => {
    onView(viewSelected);
  }, [viewSelected, onView, view]);

  const componentViewManager = () => {
    return (
      <ToggleButtonGroup
        value={viewSelected}
        exclusive
        onChange={handleChange}
        className={classes.views}
      >
        {((views as Array<View>).map(
          (value, index) => <ToggleButton key={`view-btn-group-${index}`} value={value}>{localizer.messages[value]}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }

  return (
    <CalendarToolbarBuilder
      label={label}
      onToday={() => onNavigate('TODAY')}
      onPrev={() => onNavigate('PREV')}
      onNext={() => onNavigate('NEXT')}
      children={componentViewManager()} />
  );
}

export default CalendarToolbarWithViews;
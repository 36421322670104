import moment from "moment";
import { FC, ChangeEvent, MouseEvent } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  LabelDisplayedRowsArgs,
  TableContainer,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { Delete, TimerOff } from "@material-ui/icons";
import { Paginated } from "../commons/Paginated";
import { Booking } from "./booking.model";
import useTableStyles from "../../hooks/styles/tableStyles";
import { Resource } from "../resource/resource.model";

require("moment/locale/es.js");

type Order = "asc" | "desc";

type Props = {
  bookings: Paginated<Booking>;
  order: Order;
  orderBy: keyof Booking;
  onSort: (e: MouseEvent<unknown>, property: keyof Booking) => void;
  onFinish: (booking: Booking) => void;
  onDelete: (booking: Booking) => void;
  onChangePage: (booking: number) => void;
  onRowsPerPageChange: (rows: number) => void;
};

const BookingsList: FC<Props> = ({
  bookings,
  order,
  orderBy,
  onSort,
  onFinish,
  onDelete,
  onChangePage,
  onRowsPerPageChange,
}) => {
  const classes = useTableStyles();

  const handleFinish = (booking: Booking) => {
    onFinish(booking);
  };

  const handleDelete = (booking: Booking) => {
    onDelete(booking);
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const pagesLimit = ({ from, count }: LabelDisplayedRowsArgs) => {
    return (
      "" +
      Math.trunc(from / bookings.limit + 1) +
      " de " +
      (count % bookings.limit === 0
        ? Math.trunc(count / bookings.limit)
        : Math.trunc(count / bookings.limit + 1))
    );
  };

  const isOngoing = (bookingStart: Date, bookingEnd: Date) => {
    const now = moment().utc();
    const start = moment(bookingStart).utc();
    const end = moment(bookingEnd).utc();
    return now.isBetween(start, end);
  };

  const isPending = (bookingStart: Date) => {
    const now = moment().utc();
    const start = moment(bookingStart).utc();
    return now.isBefore(start);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={2}
      className={classes.tableContainer}
    >
      <Table size="small" aria-label="simple table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === "resource" ? order : false}>
              <TableSortLabel
                active={orderBy === "resource"}
                direction={orderBy === "resource" ? order : "asc"}
                onClick={(e) => onSort(e, "resource")}
              >
                Recurso
              </TableSortLabel>
            </TableCell>
            <TableCell
              sortDirection={orderBy === "bookingStart" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "bookingStart"}
                direction={orderBy === "bookingStart" ? order : "asc"}
                onClick={(e) => onSort(e, "bookingStart")}
              >
                Fecha
              </TableSortLabel>
            </TableCell>
            <TableCell>Hora Inicio</TableCell>
            <TableCell>Hora Fin</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Evento</TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={"body"}>
          {bookings.docs.map((booking) => (
            <TableRow key={booking.id}>
              <TableCell>{(booking.resource as Resource).name}</TableCell>
              <TableCell style={{ textTransform: "capitalize" }}>
                {moment(booking.bookingStart).format("MMMM DD[,] dddd")}
              </TableCell>
              <TableCell>
                {moment(booking.bookingStart).format("HH:mm")}
              </TableCell>
              <TableCell>
                {moment(booking.bookingEnd).format("HH:mm")}
              </TableCell>
              <TableCell>
                {booking.userInfo.firstname} {booking.userInfo.lastname}
              </TableCell>
              <TableCell>{booking.title}</TableCell>
              <TableCell align="center">
                {isOngoing(booking.bookingStart, booking.bookingEnd) ? (
                  <Tooltip title="Finalizar">
                    <IconButton
                      aria-label="finalizar"
                      onClick={() => handleFinish(booking)}
                    >
                      <TimerOff />
                    </IconButton>
                  </Tooltip>
                ) : isPending(booking.bookingStart) && (
                  <Tooltip title="Borrar">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(booking)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={bookings.totalDocs}
        page={bookings.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={bookings.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={pagesLimit}
        labelRowsPerPage=""
        title="Cantidad de resultados por página"
        nextIconButtonText="Siguiente"
        backIconButtonText="Anterior"
        className={classes.tablePagination}
      />
    </TableContainer>
  );
};

export default BookingsList;

import React from 'react'
import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { CalendarToday, DateRange, Event } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  open: boolean,
  onClose: (action?: RemoveAction) => void,
}

export type RemoveAction = 'current' | 'from' | 'all'

const useStyles = makeStyles((theme: Theme) => ({
  title: { 
    padding: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  titleText: {
    fontSize: theme.typography.fontSize + 2,
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(0),
  },
  list: {
    paddingBlock: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(0.5, 2),
    '&:hover': {
      background: theme.palette.error.light,
    },
  },
  listItemIcon: {
    color: theme.palette.grey[800],
  },
}));

const RemoveBookingDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const handleListItemClick = (action: RemoveAction) => {
    onClose(action);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="remove-booking-dialog-title" className={classes.title}>
        <Typography variant="h6" className={classes.titleText}>¿Qué reservas desea borrar?</Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <List className={classes.list}>
          <ListItem button onClick={() => handleListItemClick('current')} key="remove-current-option" className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}><Event /></ListItemIcon>
            <ListItemText primary="Sólo esta reserva" />
          </ListItem>
          <ListItem button onClick={() => handleListItemClick("from")} key="remove-from-option" className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}><DateRange /></ListItemIcon>
            <ListItemText primary="Esta y todas las que siguen" />
          </ListItem>
          <ListItem button onClick={() => handleListItemClick("all")} key="remove-all-option" className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}><CalendarToday /></ListItemIcon>
            <ListItemText primary="Todas las reservas" />
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default RemoveBookingDialog
import moment from 'moment'

export const getWeekBegin = (date: Date) => {
  const now = moment(date).utc(false)
  now.subtract(now.weekday() + 1, "days")
  return now.startOf("day").toDate()
}

export const getWeekEnd = (date: Date) => {
  const now = moment(date).utc(false)
  now.add(6 - now.weekday(), "days")
  return now.startOf("day").toDate()
}
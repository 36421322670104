import { Moment } from "moment";

export const isTimeSameOrBefore = (time1: Moment, time2: Moment) => {
    if (time1.hour() > time2.hour()) {
        return false
    } else if (time1.hour() < time2.hour()) {
        return true
    }

    return time1.minutes() <= time2.minutes()
}


import { makeStyles, Theme } from '@material-ui/core/styles';

const calendarStyles = makeStyles((theme: Theme) => ({
  hideHeader: {
    '& .rbc-time-header': { display: 'none' },
  },
  root: {
    '& .rbc-time-view-resources': {
      '& .rbc-header': {
        width: theme.spacing(7),
      },
      '& .rbc-row-resource': {
        backgroundColor: theme.palette.grey[300],
        '& .rbc-header': {
          fontWeight: theme.typography.fontWeightBold,
          paddingTop: theme.spacing(.5),
        },
      },
      '& .rbc-day-slot': {
        minWidth: theme.spacing(7),
      },
      '& .rbc-day-slot:nth-child(7n+1)': {
        borderRight: `1px solid ${theme.palette.grey[500]}`,
      },
      '& .rbc-day-slot:last-child': {
        borderRight: 'none',
      },
    },
    '& .rbc-time-view': {
      border: 'none',
    },
    '& .rbc-header': {
      textTransform: 'capitalize',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.fontSize,
      color: theme.palette.text.secondary
    },
    '& .rbc-header > a': {
      cursor: 'default'
    },
    '& .rbc-time-content': {
      '& .rbc-day-slot': {
        '& .rbc-events-container': {
          marginRight: 0,
        },
      },  
      '& .rbc-timeslot-group:first-child .rbc-label': {
        display: 'none',
      },
      '& .rbc-time-gutter': {
        minWidth: 65,
        '& .rbc-timeslot-group': {
          position: 'relative',
          '& .disabled-timeslot, & .today-timeslot': {
            background: theme.palette.background.default,
            border: 'none',
          },
          '& .rbc-label': {
            background: theme.palette.background.default,
            color: theme.palette.text.secondary,
            fontSize: theme.typography.fontSize,
            minWidth: 50,
            position: 'absolute',
            left: 0,
            top: -10,
            zIndex: 1,
          },
        },
      },
      '& .today-timeslot': {
        background: theme.palette.background.paper,
      },
      '& .disabled-timeslot': {
        background: theme.palette.grey[200],
        border: 'none',
      },
      '& .default-timeslot': {
        background: theme.palette.background.default,
      },
    },
    '& .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event': {
      background: theme.palette.primary.main,
      borderRadius: 3,
    },
  },
}));

export default calendarStyles;
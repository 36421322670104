import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const EmptyResults: FC = () => {
  return (
    <Box
      color="grey.A400"
      bgcolor="grey.A100"
      paddingX={2}
      paddingY={1}
      borderRadius={18}
      display="flex"

    >
      <Search />
      <Typography variant='subtitle2'>No existen resultados para mostrar.</Typography>
    </Box>
  )
}

export default EmptyResults;
export class DrawingType {
    name: string
    constructor(name: string) {
        this.name = name;
    }
}

export let CIRCLE_TYPE = new DrawingType('Circulo')
export let RECTANGLE_TYPE = new DrawingType('Rectangulo')


export let drawingTypes = [CIRCLE_TYPE, RECTANGLE_TYPE]

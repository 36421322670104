import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';

import useUserDashboardStyles from '../../hooks/styles/userDashboardStyles';
import { UserSession } from '../../_services/authentication.service';
import { useLaborable } from '../commons/laborableContext/LaborableHook';
import { getGMTString } from '../commons/utils/timezoneUtils';

type UserDashboardProps = {
  user: UserSession;
}
export const UserDrawer: FC<UserDashboardProps> = ({ user }) => {

  const { weeklySchedule } = useLaborable()
  const classes = useUserDashboardStyles()

  return (
    <>
      {!!weeklySchedule &&
        <Grid container alignItems='center'>
          <Grid item xs={9}>
            <Typography className={classes.welcomeTitle}>Bienvenido,</Typography>
            <Typography className={classes.usernameTitle}>{user.firstname}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.welcomeTitle}>{getGMTString(weeklySchedule.timezone as string)}</Typography>
          </Grid>
        </Grid>
      }
    </>
  );
}

import { FC, useCallback, ChangeEvent } from "react";
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useLaborable } from "../../commons/laborableContext/LaborableHook";

// Corresponde al intervalo de tiempo entre ítems del selector
const INTERVAL = 5;

const useStyles = makeStyles(() => ({
  select: { width: '90%' }
}));

type Props = {
  value: moment.Moment;
  limitStart?: Boolean
  limitEnd?: Boolean
  onChange: (date: moment.Moment) => void;
}

const BookingHourSelector: FC<Props> = ({ value, limitStart, limitEnd, onChange }) => {
  const classes = useStyles();
  const { weeklySchedule } = useLaborable();

  const getSelectableHours = useCallback(
    () => {
      const daySchedule = weeklySchedule.week.find(day => day.weekDay === value.day());
      const minHours = moment(daySchedule.begin)
      const maxHours = moment(daySchedule.end)

      if (limitStart) {
        minHours.add(INTERVAL, "minutes");
      }
      if (limitEnd) {
        maxHours.subtract(INTERVAL, "minutes");
      }
      const hours: string[] = [];

      while (minHours.isSameOrBefore(maxHours)) {
        hours.push(minHours.format("HH:mm"));
        minHours.add(INTERVAL, "minutes");
      }

      return hours;
    }, [value, limitEnd, limitStart, weeklySchedule.week]);

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    if (!!event && !!event.target.value) {
      const stringValue = event.target.value as string
      value.hour(parseInt(stringValue.split(':')[0])).minutes(parseInt(stringValue.split(':')[1]))
      onChange(value);
    }
  }

  return (
    <Select
      className={classes.select}
      value={value.format("HH:mm")}
      onChange={handleChange}
    >
      {getSelectableHours().map(hour =>
        <MenuItem key={hour} value={hour}>{hour}</MenuItem>
      )}
    </Select>
  );
}

export default BookingHourSelector;
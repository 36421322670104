import React, { useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';

import FilterInput from '../../components/commons/filterInput/FilterInput';
import GroupsList from '../../components/user-groups/GroupsList';
import GroupDialog from '../../components/user-groups/GroupDialog';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from '../../components/commons/dialogs/ConfirmDialog';
import FAB from '../../components/commons/buttons/FAB';

import useCancelToken from '../../hooks/useCancelToken';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { groupService } from '../../_services/group.service';
import { UserGroup } from '../../components/user-groups/userGroup.model';
import { ListedUser } from '../../components/users/listedUser.model';

const UserGroups: React.FC = () => {
  const [groups, setGroups] = React.useState<UserGroup[]>([]);
  const [query, setQuery] = React.useState<string>('');
  const [group, setGroup] = React.useState<UserGroup>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);

  const { getCancelToken, isCancel } = useCancelToken()
  const { showError, showSuccess } = useSnackBars();
  const handleError = useErrorHandler();
  
  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchGroups = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const _groups = await groupService.list(query, cancelToken);
        setGroups(_groups);
      } catch (error) {
        if(!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

  // Búsqueda de grupos
  // eslint-disable-next-line
  useEffect(() => { fetchGroups() }, [fetchGroups]);

  const handleSearch = (query: string) => setQuery(query);

  const handleDelete = async (group: UserGroup) => {
    try {
      const res = await groupService.remove(group.id);
      showSuccess(res.msg)
      fetchGroups();
    } catch (error) {
      if(!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  // Handlers para el Dialog de creación/edición
  const handleCreateDialog = () => {
    setGroup(null);
    toggleDialog();
  }
  const handleUpdateDialog = (userGroup: UserGroup) => {
    setGroup(userGroup);
    toggleDialog();
  }

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
    fetchGroups();
  }

  // Handlers Dialog de confirmación
  const openDeleteDialog = (group: UserGroup) => {
    setGroup(group);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => {
    setOpenDelete(false);
    fetchGroups();
  }

  const handleRemoveUser = async (groupId: string, user: ListedUser) => {
    const res = await groupService.removeMember(groupId, user.id);
    showSuccess(res.msg)
    fetchGroups();
  }

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nuevo grupo" onClick={handleCreateDialog} />
      </Box>
      {!groups || groups.length === 0 ? <EmptyResults /> :
        <GroupsList
          groups={groups}
          onDelete={openDeleteDialog}
          onUpdate={handleUpdateDialog}
          removeUser={handleRemoveUser} />
      }
      <GroupDialog
        open={openDialog}
        onClose={toggleDialog}
        onGroupsChange={fetchGroups}
        group={group} />
      <ConfirmDialog<UserGroup>
        title="Eliminando Grupo de Usuarios"
        subtitle="¿Deseas continuar con la eliminación?"
        open={openDelete}
        holder={group}
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
}

export default UserGroups;
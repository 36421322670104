import moment from 'moment-timezone'
import { Culture, DateLocalizer, stringOrDate, View, Event } from 'react-big-calendar'
import { useCurrentUser } from '../../commons/currentuser/CurrentUserHook';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import { useCalendarContext } from './DateRangeProvider';

/** Hook para encapsular comportamiento recurrente cuando se desea implementar un React Big Calendar */
const useCalendar = () => {

  const { weeklySchedule } = useLaborable();
  const { calendarData, setCalendarData } = useCalendarContext();
  const { user } = useCurrentUser();

  const messages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay reservas registradas para el mes.'
  }

  const customFormat = {
    dateFormat: 'dd',
    dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) =>
      localizer.format(date, 'ddd DD', culture),
    dayRangeHeaderFormat: ({ start, end }: any, culture: any, localizer: any) => {
      if (start.getMonth() === end.getMonth()) {
        return localizer.format(end, 'MMMM YYYY', culture)
      } else {
        return localizer.format(start, 'MMM', culture) + ' — ' +
          localizer.format(end, 'MMM YYYY', culture)
      }
    },
    agendaHeaderFormat: ({ start, end }: any, culture: any, localizer: any) => {
      return localizer.format(start, 'MMMM YYYY', culture)
    },
  }

  const handleSelecting = (range: { start: stringOrDate; end: stringOrDate }) => {
    const hourFormat = "HH:mm";
    const startMoment = moment(range.start);
    const endMoment = moment(range.end);
    const startMomentHours = moment(startMoment.format(hourFormat), hourFormat);
    const endMomentHours = moment(endMoment.format(hourFormat), hourFormat);

    const dayStart = moment(range.start).startOf('day')
    const timeRange = weeklySchedule.week.find(tr => tr.weekDay === dayStart.day());
    if (!timeRange.laborable) {
      return false
    }
    const beginHours = moment(moment(timeRange.begin).format(hourFormat), hourFormat);
    const endHours = moment(moment(timeRange.end).format(hourFormat), hourFormat);
    return startMoment.isAfter(moment()) && startMomentHours.isBefore(endHours) && startMomentHours.isSameOrAfter(beginHours) && endMomentHours.isSameOrBefore(endHours) && endMomentHours.isAfter(beginHours)
  }

  const minHour = () => {
    if (!weeklySchedule) {
      return moment().startOf('day').add(7, 'hours').toDate()
    }

    const mins = weeklySchedule.week.filter(w => w.laborable).sort((a, b) => moment(a.begin)/* moment.tz(a.begin, weeklySchedule.timezone as string) */.format("HH:mm a").localeCompare(moment(b.begin)/* moment.tz(b.begin, weeklySchedule.timezone as string) */.format("HH:mm a")));
    if (!!mins && mins.length > 0) {

      return mins[0].begin
    } else {
      return moment().startOf('day').add(7, 'hours').toDate()
    }


  }

  const maxHour = () => {
    if (!weeklySchedule) {
      return moment().startOf('day').add(20, 'hours').toDate()
    }
    const mins = weeklySchedule.week.filter(w => w.laborable).sort((a, b) => -moment(a.end)/* moment.tz(a.end, weeklySchedule.timezone as string) */.format("HH:mm a").localeCompare(moment(b.end)/* moment.tz(b.end, weeklySchedule.timezone as string) */.format("HH:mm a")));

    if (!!mins && mins.length > 0) {
      return mins[0].end
    } else {
      return moment().startOf('day').add(20, 'hours').toDate()
    }
  }

  const handleSlotPropGetter = (date: Date, resourceId?: number | string) => {
    const hourFormat = "HH:mm";
    const dateMoment = moment(date)

    // Background styles
    const todayClass = 'today-timeslot'
    const disabledClass = 'disabled-timeslot'
    const defaultClass = 'default-timeslot'

    // Días pasados
    if (!weeklySchedule || dateMoment.isBefore(moment())) {
      return { className: disabledClass }
    }

    // Días no laborables
    const timeRange = weeklySchedule.week.find(tr => tr.weekDay === dateMoment.day());

    if (!timeRange.laborable || dateMoment.isBefore(moment())) {
      return { className: disabledClass }
    }

    const dateMomentHours = moment(dateMoment.format(hourFormat), hourFormat)

    const beginHours = moment(moment(timeRange.begin).format(hourFormat), hourFormat);
    const endHours = moment(moment(timeRange.end).format(hourFormat), hourFormat);

    // Horas no laborables
    if (beginHours.isAfter(dateMomentHours) || endHours.isSameOrBefore(dateMomentHours)) {
      return { className: disabledClass }
    }

    // Hoy
    if (moment().isSame(dateMoment, 'days')) {
      return { className: todayClass }
    }

    return { className: defaultClass }
  }

  const handleRangeChange = async (range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View) => {
    let start = null
    let end = null

    if ('start' in range) {
      start = moment((range as { start: stringOrDate; end: stringOrDate }).start)
      end = moment((range as { start: stringOrDate; end: stringOrDate }).end)
    } else {
      const _range = (range as Date[])
      start = moment(_range[0])
      end = moment(_range[_range.length - 1])
    }
    if (start) {
      if ((view && view === 'week') || (!view && calendarData.view === 'week')) {
        const newDate = start.clone()
        const weekDay = moment(calendarData.date).day() === 0 ? 7 : moment(calendarData.date).day()
        newDate.day(weekDay)
        setCalendarData({ date: newDate.toDate(), view: view || calendarData.view })
      } else if ((view && view === 'month') || (!view && calendarData.view === 'month')) {
        const newDate = start.clone()
        const monthDay = moment(calendarData.date).date()
        newDate.date(monthDay)
        setCalendarData({ date: newDate.toDate(), view: view || calendarData.view })
      } else if ((view && view === 'day') || (!view && calendarData.view === 'day')) {
        setCalendarData({ date: start.toDate(), view: view || calendarData.view })
      } else if ((view && view === 'agenda') || (!view && calendarData.view === 'agenda')) {
        const days = end.diff(start, 'days');
        const monthDay = moment(calendarData.date).date()
        const newDate = start.add(days - 15, 'days')
        newDate.date(monthDay)
        setCalendarData({ date: newDate.toDate(), view: view || calendarData.view })
      }
    }
  }

  const calcTimeRange = () => {
    if (calendarData.view === 'day') {
      return {
        'from': moment(calendarData.date).startOf('day').toDate(),
        'to': moment(calendarData.date).endOf('day').toDate()
      }
    } else if (calendarData.view === 'week') {
      return {
        'from': moment(calendarData.date).startOf('week').toDate(),
        'to': moment(calendarData.date).endOf('week').toDate()
      }
    } else {
      return {
        'from': moment(calendarData.date).startOf('month').toDate(),
        'to': moment(calendarData.date).endOf('month').toDate()
      }
    }
  }

  const customSlotPropGetter = (event: any) => {
    if (calendarData.view === 'agenda') {
      return
    }
    if (!!event.booking.user && !!event.booking.user.id && event.booking.user.id !== user.id) {
      return { className: "rbc-event", style: { backgroundColor: '#c1272d', border: 'none', boxShadow: '1px 1px 3px black' } };
    } else if (!event.booking.id) {
      return { className: "rbc-event", style: { backgroundColor: 'rgba(81, 112, 129, .75)', border: 'none', boxShadow: '1px 1px 3px black' } }
    } else {
      return {
        className: "rbc-event", style: { backgroundColor: '#517081', border: 'none', boxShadow: '1px 1px 3px black' }
      };
    }
  }

  const allDayAccessor = (event: Event): boolean => {
    if (!weeklySchedule || calendarData.view === "day") {
      return false;
    }
    const { begin, end } = weeklySchedule.week.find(day => day.weekDay === event.start.getDay());
    const eventStart = moment(event.start).tz(weeklySchedule.timezone as string);
    const eventEnd = moment(event.end).tz(weeklySchedule.timezone as string);

    let minHours = moment(begin).tz(weeklySchedule.timezone as string);
    let maxHours = moment(end).tz(weeklySchedule.timezone as string);

    if (eventStart.format('HH:mm') === minHours.format('HH:mm') &&
      eventEnd.format('HH:mm') === maxHours.format('HH:mm')) {
      return true;
    }
    return false
    /* 
        
        if (minHours.isSame(moment(), "day")) {
          minHours = moment().hours(minHours.hours()).minutes(minHours.minutes());
        } else {
          minHours = eventStart.clone().hours(minHours.hours()).minutes(minHours.minutes());
        }
        
        maxHours = eventEnd.clone().hours(maxHours.hours()).minutes(maxHours.minutes());
    
        return eventStart.isSame(minHours) && eventEnd.isSame(maxHours); */
  }

  return { messages, customFormat, calcTimeRange, handleSelecting, minHour, maxHour, handleSlotPropGetter, handleRangeChange, customSlotPropGetter, allDayAccessor }
}

export default useCalendar;

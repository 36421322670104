import { FC, useEffect, useState, useCallback } from 'react';
import { Box, Button, FormControl, FormHelperText, Grid, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../errors/ErrorBoundary";
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { userService } from '../../_services/user.service';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';
import useCancelToken from '../../hooks/useCancelToken';
import { User } from '../users/user.model';
import UpdatePassword from './UpdatePassword';
import UpdatePicture from './UpdatePicture';

interface UserForm {
  firstname: string;
  lastname: string;
  email: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  image: {
    width: "100%",
    height: "auto",
  },
}));

const EditProfile: FC = () => {
  const classes = useStyles();

  const { user, refresh } = useCurrentUser();
  const [picture, setPicture] = useState(null);

  const { showSuccess, showError } = useSnackBars()
  const { getCancelToken, isCancel } = useCancelToken()

  const [openUpdatePicture, setOpenUpdatePicture] = useState<boolean>(false);
  const [openUpdatePassword, setOpenUpdatePassword] = useState<boolean>(false);

  const loadPicture = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const currentPicture = await userService.getPictureProfile(cancelToken)
        const picture = userService.imageLink(currentPicture?.path);
        setPicture(picture);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => { loadPicture() }, [loadPicture])

  const handleClose = () => {
    setOpenUpdatePicture(false)
    setOpenUpdatePassword(false)
  }

  const handleOpenUpdatePicture = () => { setOpenUpdatePicture(true) };
  const handleOpenUpdatePassword = () => { setOpenUpdatePassword(true) };

  const { register, handleSubmit, errors, setError, clearErrors } = useForm<UserForm>({
    defaultValues: {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
    }
  });

  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (userForm: User) => {
    try {
      const res = await userService.updateProfile(userForm)
      showSuccess(res.msg)
      refresh()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleUpdatePicture = () => {
    loadPicture()
    handleClose()
  }

  return (
    <Box overflow="auto" height="100%">
      <Grid container justifyContent='space-around'>
        <Grid item>
          <Box width={200} height={200} overflow="hidden" borderRadius="100%" margin={2}>
            <img src={picture} alt="imagen de perfil" className={classes.image} />
          </Box>
          <Box textAlign="center">
            <Button variant="outlined" color="primary" onClick={handleOpenUpdatePicture}>
              Cambiar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={10} md={6}>
          <form onSubmit={handleSubmit(onSubmit)} >
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="firstname"
                    label="Nombre"
                    name="firstname"
                    size="small"
                    error={(errors.firstname ? true : false)}
                    inputRef={register({ required: true, minLength: 2 })}
                  />
                  <FormHelperText error={true}>
                    {errors?.firstname && (errors.firstname.message || "El nombre es requerido")}
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="lastname"
                    label="Apellido"
                    name="lastname"
                    size="small"
                    error={(errors.lastname ? true : false)}
                    inputRef={register({ minLength: 3 })}
                  />
                  <FormHelperText error={true}>
                    {errors?.lastname && (errors.lastname.message)}
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="email"
                    label="Correo electrónico"
                    disabled={true}
                    value={user.email}
                    name="email"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit" color="primary" onClick={() => clearErrors()}>
                  Guardar
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleOpenUpdatePassword}>
                  Cambiar contraseña
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <UpdatePicture open={openUpdatePicture} onUpdate={handleUpdatePicture} onClose={handleClose} />
      <UpdatePassword open={openUpdatePassword} onClose={handleClose} />
    </Box>
  );
}

export default EditProfile;
import React, { FC } from 'react';
import { Button } from '@material-ui/core';

type Props = {
  onClick: () => void;
}

const CancelButton: FC<Props> = ({ onClick }) => {
  return (
    <Button
      aria-label="cancelar"
      component="span"
      fullWidth
      size="small"
      variant="outlined"
      onClick={onClick}
    >
      Cancelar
    </Button>
  )
}

export default CancelButton;
import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import useTableStyles from '../../hooks/styles/tableStyles';

import UsersContainer from './users-container/UsersContainer';
import { UserGroup } from './userGroup.model';
import { ListedUser } from '../users/listedUser.model';

type Props = {
  groups: UserGroup[];
  onDelete: (group: UserGroup) => void;
  onUpdate: (group: UserGroup) => void;
  removeUser: (groupId: string, user: ListedUser) => void
}

const GroupsList: React.FC<Props> = ({ groups, onDelete, onUpdate, removeUser }) => {
  const classes = useTableStyles();

  const handleUpdate = (group: UserGroup) => onUpdate(group);

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Grupo</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Miembros</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map(group => (
            <TableRow key={group.id}>
              <TableCell>{group.name}</TableCell>
              <TableCell>{group.description}</TableCell>
              <TableCell>
                <UsersContainer
                  groupId={group.id}
                  groupUsers={group.users}
                  removeUser={removeUser}
                />
              </TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(group)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => onDelete(group)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default GroupsList;
import { Paginated } from "../components/commons/Paginated";
import baseAPI from './api';
import { ListedUser } from '../components/users/listedUser.model';
import { ResetPasswordForm, User } from '../components/users/user.model';
import { Company } from "../components/companies/company.model";
import { ValidateUserData } from "../pages/resetpassword/ReqResetPasswordForm";
import { ResetPasswordData } from "../pages/resetpassword/SetResetPasswordForm";
import { Success } from "../components/commons/Success";
import { AxiosRequestConfig, CancelToken } from "axios";

const list = async (query: string, limit: number, offset: number, order: string, orderBy: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {}
  config.params = { 'q': query, limit, offset, order, orderBy }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  const res = await baseAPI.get(`users`, config)
  return res.data as Paginated<User>
}

const getUnListed = async (query: string, exclude: string = "") => {
  const params = { q: query, exclude }
  const res = await baseAPI.get(`users/all`, { params });
  return res.data as ListedUser[];
}

const create = async (user: User) => {
  const res = await baseAPI.post(`users`, user)
  return res.data as Success<string>

}

const register = async (user: User) => {
  const res = await baseAPI.post('users/register/client', user)
  return res.data
}

const webRegister = async (user: User) => {
  const res = await baseAPI.post('users/register/webclient', user)
  return res.data
}

const update = async (user: User) => {
  const res = await baseAPI.patch(`users/${user.id}`, user)
  return res.data as Success<string>
}

const join = async (company: Company, userId: string) => {
  const body = { company, userId }
  const res = await baseAPI.patch('users/join', body)
  return res.data as Success<string>
}

const remove = async (id: string) => {
  const res = await baseAPI.delete(`users/${id}`)
  return res.data as Success<string>
}

const initResetPassword = async (data: ValidateUserData) => {
  const params = data
  const res = await baseAPI.get('users/resetpassword/init', { params })
  return res.data as Success<string>
}

const resetPassword = async (data: ResetPasswordData) => {
  const res = await baseAPI.patch('users/resetpassword', data)
  return res.data as Success<string>
}

const updateProfile = async (user: User) => {
  const res = await baseAPI.put(`users/profile`, user)
  return res.data as Success<User>
}

const updatePasswordProfile = async (password: ResetPasswordForm) => {
  await baseAPI.patch('users/profile/password', password)
}

const getPictureProfile = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const res = await baseAPI.get('users/profile/picture', axiosConfig)
  return res.data
}

const updatePictureProfile = async (image: File) => {
  const data = new FormData()
  data.append("image", image)
  const res = await baseAPI.patch('users/profile/picture', data)
  return res.data as Success<string>
}

const imageLink = (path: string) => {
  if (!!path) {
    return process.env.REACT_APP_API_URL + '/static/' + path
  }
  return '/image-no-available.png'
}

export const userService = {
  list,
  getUnListed,
  join,
  initResetPassword,
  resetPassword,
  create,
  register,
  webRegister,
  update,
  remove,
  updateProfile,
  updatePasswordProfile,
  updatePictureProfile,
  getPictureProfile,
  imageLink
};

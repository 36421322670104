import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { branchService } from '../../../_services/branch.service';
import { BranchReference } from '../../branches/branch.reference.model';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import { BranchesContext } from './BranchesContext';

const BranchesProvider: FC = ({ children }) => {

  const handleError = useErrorHandler();
  const [branches, setBranches] = useState<BranchReference[]>([{ id: '', name: '' }])

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        let branchReferences = await branchService.getReferences();
        setBranches(branchReferences);
      } catch (error) {
        handleError(error)
      }
    }
    fetchBranches()
  }, []);

  const onBranchesUpdate = useCallback(
    (branchReferences: BranchReference[]) => {
      setBranches(branchReferences);
    }, []
  )

  const value = useMemo(() => ({ branches, onBranchesUpdate }), [branches]);

  return (
    <BranchesContext.Provider value={value}>
      {children}
    </BranchesContext.Provider>
  )
}

export default BranchesProvider;
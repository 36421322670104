import { FC } from 'react';
import CustomTab, { TabData } from '../../components/commons/tabs/CustomTab';
import User from '../user/User'
import UserGroups from '../user-groups/UserGroups'
import UserRequests from '../user/UserRequests'

const UsersTab: FC = () => {

  const tabList: TabData[] = [
    { label: 'Usuarios', component: <User /> },
    { label: 'Grupos', component: <UserGroups /> },
    { label: 'Peticiones', component: <UserRequests /> },
  ]

  return (
    <CustomTab tabs={tabList} />
  );
}

export default UsersTab
import { useRef, useEffect, useCallback } from 'react'
import axios from 'axios'

const { CancelToken, isCancel } = axios

const useCancelToken = () => {

  const axiosSource = useRef(null)

  const getCancelToken = useCallback(
    () => {
      if (!axiosSource.current) {
        axiosSource.current = CancelToken.source()
      }
      return axiosSource.current.token
    },[],
  )

  useEffect(() => () => {
    if (axiosSource.current) {
      axiosSource.current.cancel()
    }
  }, [])

  return { getCancelToken, isCancel }
};

export default useCancelToken
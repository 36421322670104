import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react'
import { Grid, Box } from '@material-ui/core';
import { Booking } from '../../components/booking/booking.model'
import { Paginated } from '../../components/commons/Paginated'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook'
import { useErrorHandler } from '../../components/errors/ErrorBoundary'
import { Floor } from '../../components/floor/floor.model'
import { Resource } from '../../components/resource/resource.model'
import { ResourceType } from '../../components/resource/resourceType.model'
import { floorService } from '../../_services/floor.service'
import { bookingService } from '../../_services/booking.service'
import { resourceTypeService } from '../../_services/resourcetype.service'
import { useCurrentBranch } from "../../components/commons/currentbranch/CurrentBranchHook";
import Map from '../../components/booking/bookingMap/Map';
import { useCalendarContext } from '../../components/booking/agenda/DateRangeProvider'
import BookingSidebar from '../../components/booking/blueprint/BookingSidebar'
import useCancelToken from '../../hooks/useCancelToken'
import CalendarToolbarWithFilters from '../../components/booking/bookingToolbar/CalendarToolbarWithFilters';
import { useNextBooking } from '../../components/commons/nextBookingsContext/NextBookingHook'
import { useLaborable } from '../../components/commons/laborableContext/LaborableHook'
import { useBranches } from '../../components/commons/branches/BranchesHook';
import { useHistory } from 'react-router-dom';
import { BranchReference } from '../../components/branches/branch.reference.model';
import { Branch } from '../../components/branches/branch.model'
import { Amenity } from '../../components/amenities/amenity.model'
import { amenitiesService } from '../../_services/amenities.service'
import BookingViewDialog from '../../components/booking/bookingForm/BookingViewDialog'
import useCalendar from '../../components/booking/agenda/useCalendar';

const BlueprintBooking: React.FC = () => {

  const [bookings, setBookings] = useState<Booking[]>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [bookingView, setBookingView] = useState<Booking>(null);

  const [resources, setResources] = useState<Resource[]>(null)
  const [resource, setResource] = useState<Resource>(null)
  const [floors, setFloors] = useState<Paginated<Floor>>(null)
  const [floor, setFloor] = useState<Floor>()
  const [types, setTypes] = React.useState<ResourceType[]>([]);
  const [type, setType] = React.useState<ResourceType>(null);
  const [branch, setBranch] = React.useState<Branch>(null);
  const [extras, setExtras] = React.useState<Amenity[]>([]);
  const [extra, setExtra] = React.useState<Amenity>(null);
  const [query, setQuery] = React.useState<string>('');
  const { currentBranch, onUpdateBranch } = useCurrentBranch();
  const { calendarData, setCalendarData } = useCalendarContext();
  const { calcTimeRange } = useCalendar();
  const { updateNextBookings } = useNextBooking();
  const { getCancelToken, isCancel } = useCancelToken();
  const { showError, showSuccess, showInfo } = useSnackBars();
  const { weeklySchedule, refreshLaborable } = useLaborable();
  const handleError = useErrorHandler();
  const { branches } = useBranches()
  const history = useHistory();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
      if (field === "bookingStart" || field === "bookingEnd") {
        refreshLaborable();
      }
    })
  }, [showError])

  const handleDateChange = useCallback((date: Date) => {
    setCalendarData({ ...calendarData, date })
  }, [])

  const rangeFromCalendarData = useCallback(() => {
    const from = moment(calendarData.date).startOf('day').toDate();
    const to = moment(calendarData.date).endOf('day').toDate();
    return { from, to }
  }, [calendarData])

  const fetchResourceTypes = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const _types = await resourceTypeService.availableList(cancelToken)
        setTypes(_types);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel]
  )

  const fetchExtras = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const _extras = await amenitiesService.list('', cancelToken)
        setExtras(_extras)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel]
  )

  const fetchFloors = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken();
        let _floors = await floorService.list('', 0, Number.MAX_SAFE_INTEGER, cancelToken);
        // Remove floors without resources
        _floors.docs = _floors.docs.filter(floor => floor.resources.length > 0);
        setFloors(_floors);
        setFloor(_floors.docs.find(f => f.image))
        if (!_floors.docs.find(f => f.image, null)) {
          showInfo(
            `La sucursal seleccionada no tiene planos de oficina configurados, por favor contacte con el administrador.`,
            () => { history.push("/") }
          );
        }
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel])

  useEffect(() => {
    fetchResourceTypes();
    fetchExtras()
  }, [fetchResourceTypes, fetchExtras])

  useEffect(() => {
    fetchFloors();
  }, [currentBranch, fetchFloors]);

  useEffect(() => {
    handleDateChange(calendarData.date);
  }, [calendarData.date, currentBranch, handleDateChange])

  useEffect(() => {
    const branch = branches.find((branch: BranchReference) => branch.id === currentBranch)
    setBranch(branch)
  }, [currentBranch, branches]);

  const fetchBookingResources = useCallback(
    async () => {
      try {
        const { from, to } = rangeFromCalendarData();
        const cancelToken = getCancelToken()
        const _resources = await bookingService.resources(from, to, query, floor?.id, type?.id, extra?.id, cancelToken)
        setResources(_resources)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floor, type, query, extra, rangeFromCalendarData])

  useEffect(() => {
    if (!floor) {
      return
    }
    fetchBookingResources()
  }, [fetchBookingResources])

  const fetchBookings = useCallback(
    async (resourceId: string, from: Date, to: Date) => {
      try {
        const cancelToken = getCancelToken()
        //TODO en realidad me tengo que traer todos los del recurso
        const bookings = await bookingService.resourceBookings([resourceId], from, to, cancelToken);
        setBookings(bookings);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel]);

  useEffect(() => {
    if (open) {
      const { from, to } = rangeFromCalendarData();
      fetchBookings(resource.id, from, to);
    }
  }, [calendarData, fetchBookings]);

  const handleResourceSelected = useCallback(async (resource: Resource) => {
    setResource(resource);
    const { from, to } = rangeFromCalendarData();
    await fetchBookings(resource.id, from, to);
    setOpen(true)
  }, [rangeFromCalendarData, fetchBookings])

  const handleResourceTypeChange = useCallback((type: ResourceType) => {
    setType(type)
  }, [])

  const handleFloorChange = useCallback((floor: Floor) => {
    setResources(null)
    setFloor(floor)
  }, [])

  const handleBranchChange = useCallback((branch: BranchReference) => {
    onUpdateBranch(branch.id)
  }, [])

  const handleExtraChange = useCallback((extra: Amenity) => {
    setExtra(extra)
  }, [])

  const handleNameSearch = useCallback((nameQuery: string) => {
    setQuery(nameQuery)
  }, [])

  const closeSidebar = () => {
    setOpen(false)
    setResource(null);
  }

  const handleNewBooking = async (booking: Booking) => {
    try {
      const cancelToken = getCancelToken();
      const res = await bookingService.create(
        { ...booking } as Booking,
        weeklySchedule.timezone,
        cancelToken
      );
      updateNextBookings();
      showSuccess(res.msg);
      handleDateChange(calendarData.date);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleSelectBooking = async (bookingId: string) => {
    try {
      const cancelToken = getCancelToken()
      const bookingView = await bookingService.bokingInfo(bookingId, cancelToken);
      setBookingView(bookingView)
      setOpenView(true)
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleCancelBooking = async () => {
    try {
      const cancelToken = getCancelToken()
      const res = await bookingService.cancel(bookingView.id, cancelToken);
      const range = calcTimeRange();
      fetchBookings((bookingView.resource as Resource).id, range.from, range.to);
      updateNextBookings()
      setOpenView(false);
      showSuccess(res.msg);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleCancelRecurrentBooking = async (bookingId: string, from?: Date) => {
    try {
      const cancelToken = getCancelToken();
      const res = await bookingService.cancelRecurent(bookingId, from, cancelToken);
      const range = calcTimeRange();
      fetchBookings((bookingView.resource as Resource).id, range.from, range.to);
      updateNextBookings()
      setOpenView(false);
      showSuccess(res.msg);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleUpdate = async () => {
    try {
      const res = await bookingService.update(bookingView);
      const range = calcTimeRange();
      fetchBookings((bookingView.resource as Resource).id, range.from, range.to);
      updateNextBookings();
      showSuccess(res.msg);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const closeDialog = () => {
    setOpenView(false)
    setBookingView(null)
  }

  const shoulRenderMap = useCallback(() => {
    return types && !!floor && resources && floor.image
  }, [types, floor, resources])

  const onNavigate = (key: string) => {
    switch (key) {
      case 'TODAY':
        handleDateChange(new Date())
        break;
      case 'PREV':
        const prev = calendarData.date.getDate() - 1
        calendarData.date.setDate(prev)
        handleDateChange(calendarData.date)
        break;
      case 'NEXT':
        const next = calendarData.date.getDate() + 1
        calendarData.date.setDate(next)
        handleDateChange(calendarData.date)
        break;
    }
  }

  /* HANDLE BOOKING FINISH */
  const handleFinish = async (bookingId: string) => {
    try {
      const cancelToken = getCancelToken()
      const terminateDate = moment().startOf('second').toDate();
      await bookingService.terminateBooking(bookingId, terminateDate, cancelToken)
      showSuccess("Recurso liberado")
      const range = calcTimeRange();
      fetchBookings((bookingView.resource as Resource).id, range.from, range.to);
    } catch (error) {
      error instanceof Error
        ? handleError(error)
        : handleApiError(error.errors);
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'>
      <CalendarToolbarWithFilters
        label={moment(calendarData.date).format("dddd, D [de] MMMM")}
        type={type}
        types={types}
        floor={floor}
        floors={floors}
        branch={branch}
        branches={branches}
        extra={extra}
        extras={extras}
        onNavigate={onNavigate}
        onNameSearch={handleNameSearch}
        onResourceTypeChange={handleResourceTypeChange}
        onFloorChange={handleFloorChange}
        onBranchChange={handleBranchChange}
        onExtraChange={handleExtraChange}
      />
      {shoulRenderMap() &&
        <Grid container style={{ flexGrow: 1 }}>
          <Grid item xs={open ? 6 : 12} sm={open ? 7 : 12} md={open ? 8 : 12} lg={open ? 9 : 12}>
            <Map
              onResourceSelected={handleResourceSelected}
              resources={resources}
              floor={floor}
              date={moment(calendarData.date)}
            />
          </Grid>
          {open &&
            <Grid item xs={6} sm={5} md={4} lg={3}>
              <BookingSidebar
                bookings={bookings}
                resource={resource}
                onNewBooking={handleNewBooking}
                onSelectBooking={handleSelectBooking}
                onclose={closeSidebar}
              />
            </Grid>
          }
        </Grid>
      }
      {/* Visualizar una reserva */}
      <BookingViewDialog
        open={openView}
        bookingInfo={bookingView}
        onSave={handleUpdate}
        onFinishBooking={handleFinish}
        onUpdateBooking={newBooking => setBookingView(newBooking)}
        onCancelBooking={handleCancelBooking}
        onCancelRecurrentBooking={handleCancelRecurrentBooking}
        onClose={closeDialog}
        onlyCancelYourOwn
      />
    </Box>
  )
}

export default BlueprintBooking

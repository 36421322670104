import React, { useEffect, useState, useCallback } from 'react'
import { ResourceType } from '../../components/resource/resourceType.model'
import { availableResourceTypesService } from '../../_services/availableResourceTypes.service'
import { resourceTypeService } from '../../_services/resourcetype.service'
import AvailableResourcesTable from '../../components/available-resources/AvailableResourcesTable'
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import useCancelToken from '../../hooks/useCancelToken'
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';

const AvailableResources: React.FC = () => {
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>(null);
  const [available, setAvailable] = useState<ResourceType[]>(null)

  const { getCancelToken, isCancel } = useCancelToken()
  const { showSuccess, showError } = useSnackBars()
  const { currentBranch } = useCurrentBranch();
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    errors?.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchResources = useCallback(
    async (offset: number, limit: number) => {
      try {
        const cancelToken = getCancelToken()
        const resourceType = await resourceTypeService.list(cancelToken);
        const resourceAvailable = await resourceTypeService.availableList()

        setAvailable(resourceAvailable)
        setResourceTypes(resourceType)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBranch])

  const refreshChecked = async () => {
    try {
      const cancelToken = getCancelToken()
      const _resourceTypes = await resourceTypeService.availableList(cancelToken);
      setAvailable(_resourceTypes);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  useEffect(() => {
    fetchResources(0, 10)
  }, [fetchResources])

  const handleNewResourceType = async (resType: ResourceType) => {
    try {
      const res = await availableResourceTypesService.create(resType)
      showSuccess(res.msg)
      refreshChecked()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleDeleteResourceType = async (resType: string) => {
    try {
      if(available.length === 1) {
        showError("Debe haber por lo menos un tipo de recurso disponible.");
      } else {
        const typeToDelete = available.filter(r => r.id === resType)
        const res = await availableResourceTypesService.deleteAvailability(typeToDelete[0])
        showSuccess(res.msg)
        refreshChecked()
      }
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)

    }
  }

  return (
    <>
      {resourceTypes && available &&
        <AvailableResourcesTable
          resourceTypes={resourceTypes}
          available={available}
          allowResourceType={handleNewResourceType}
          disableResourceType={handleDeleteResourceType}
        />
      }
    </>
  )
}

export default AvailableResources

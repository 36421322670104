import moment, { Moment } from "moment";
import { Booking } from "../../booking/booking.model";
import { WeeklySchedule } from "../../weeklySchedule/weekly.schedule.model";
import { isTimeSameOrBefore } from "../../../_helpers/time";

const validateBookingChangeRange = (
  begin: Moment,
  end: Moment,
  booking: Booking,
  weeklySchedule: WeeklySchedule,
) => {
  const { minHours, maxHours } = _getAvailableRange(weeklySchedule, booking);
  return isTimeSameOrBefore(minHours, begin) && isTimeSameOrBefore(end, maxHours);
}

/**
 * Retorna el rango disponible.
 * Si es recurrente retorma el mayor intervalo en comun
 */
const _getAvailableRange = (weeklySchedule: WeeklySchedule, booking: Booking): { minHours: Moment, maxHours: Moment } => {
  const bookingDate = moment(booking.bookingStart);
  if (!booking.isRecurrent || booking.recurrenceWeekdays.filter(v => v).length === 0) {
    return _availableDateRange(weeklySchedule, bookingDate.day())
  } else {
    let min = moment().startOf('day')
    let max = moment().endOf('day');
    for (let i = 0; i < booking.recurrenceWeekdays.length; i++) {
      if (booking.recurrenceWeekdays[i]) {
        const { minHours, maxHours } = _availableDateRange(weeklySchedule, i)
        min = isTimeSameOrBefore(min, minHours) ? minHours : min;
        max = isTimeSameOrBefore(maxHours, max) ? maxHours : max;
      }
    }
    return { minHours: min, maxHours: max }
  }
}


const _availableDateRange = (weeklySchedule: WeeklySchedule, dayIndex: number) => {
  const range = weeklySchedule.week[dayIndex]
  return { minHours: moment(range.begin), maxHours: moment(range.end) }
}

export default validateBookingChangeRange;
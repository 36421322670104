import moment from 'moment-timezone'
import { FC, useState, useContext, createContext, useMemo, useEffect } from 'react'
import { View } from 'react-big-calendar'
import { useLaborable } from '../../commons/laborableContext/LaborableHook'

type CalendarDataType = {
  date: Date,
  view: View,
}
type CalendarContextType = {
  calendarData: CalendarDataType,
  setCalendarData: (calendarData: CalendarDataType) => void
}

const CalendarContext = createContext<CalendarContextType>(null);
const DateFilterProvider: FC = ({ children }) => {
  const { weeklySchedule } = useLaborable();
  const [calendarData, setCalendarData] = useState<CalendarDataType>({ date: moment().toDate(), view: 'day' });

  useEffect(() => {
    if (!!weeklySchedule)
      setCalendarData({ ...calendarData, date: moment.tz(weeklySchedule.timezone as string).toDate() })
  }, []);


  const value = useMemo<CalendarContextType>(() => { return { calendarData, setCalendarData } }, [calendarData])
  return (
    <>
      <CalendarContext.Provider value={value}>
        {children}
      </CalendarContext.Provider>
    </>
  )
}

export default DateFilterProvider
export const useCalendarContext = () => useContext(CalendarContext);
import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { ConfigContext } from './ConfigContext';
import { configService } from '../../../_services/config.service';
import { Config } from './config.model';
import useSnackBars from '../snackbar/SnackbarHook';
import { useErrorHandler } from '../../errors/ErrorBoundary';

const ConfigProvider: FC = ({ children }) => {

  const [config, setConfig] = useState<Config>(null);
  const handleError = useErrorHandler();
  const { showSuccess, showError } = useSnackBars();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetch = useCallback(async () => {
    try {
      setConfig(await configService.get());
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [handleApiError, handleError])

  const addFavouriteResource = useCallback(
    async (id: string) => {
      try {
        const response = await configService.addResourceToFav(id);
        fetch();
        showSuccess(response.msg);
      } catch (error) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }, [fetch, showSuccess, handleApiError, handleError]
  )

  const removeFavouriteResource = useCallback(
    async (id: string) => {
      try {
        const response = await configService.removeResourceFromFav(id);
        fetch();
        showSuccess(response.msg);
      } catch (error) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }

    }, [fetch, showSuccess, handleApiError, handleError]
  )

  useEffect(() => {
    fetch();
    return () => { setConfig(null) }
  }, [fetch]);

  const value = useMemo(() => ({ config, addFavouriteResource, removeFavouriteResource }), [config, addFavouriteResource, removeFavouriteResource]);

  return (
    <ConfigContext.Provider value={value}>
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider;
import React, { ReactNode } from 'react';
import { Table, TableBody, TableCell, Checkbox, TableHead, TableRow, TableContainer, Paper } from '@material-ui/core';
import { MeetingRoom, EventSeat, LocalParkingRounded, Close } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useTableStyles from '../../hooks/styles/tableStyles';
import { ResourceType } from '../resource/resourceType.model';
import clsx from 'clsx';

const styles = makeStyles((theme: Theme) => ({
  noBar: {
    // TOOLBAR + TAB + padding 
    top: theme.spacing(8) + theme.spacing(6) + theme.spacing(3),
  },
}))

const getIcon = (typeName: string): ReactNode => {
  switch (typeName) {
    case 'Escritorio':
      return <EventSeat />
    case 'Sala':
      return <MeetingRoom />
    case 'Estacionamiento':
      return <LocalParkingRounded />
    default:
      return <Close />
  }
}

type Props = {
  resourceTypes: ResourceType[];
  available: ResourceType[];
  allowResourceType: (resType: ResourceType) => void;
  disableResourceType: (resType: string) => void;
}

const AvailableResourcesTable: React.FC<Props> = ({ resourceTypes, available, allowResourceType, disableResourceType }) => {
  const classes = useTableStyles();
  const localClasses = styles();

  const isChecked = (resourceType: string) => {
    const res = available.some(r => r.id === resourceType)
    return res
  }

  const handleRuleChange = (checked: boolean, resType: string) => {
    if (checked) {
      const type = resourceTypes.filter(r => r.id === resType)
      allowResourceType(type[0]);
    } else {
      const rtype = available.filter(r => r.id === resType);
      if (!rtype || rtype.length > 1 || rtype.length === 0) {
        alert('que paso aca?');
      } else {
        disableResourceType(rtype[0].id);
      }
    }
  }

  return (
    <TableContainer component={Paper} elevation={2} className={clsx(classes.tableContainer, localClasses.noBar)}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Icono</TableCell>
            <TableCell>Tipo de Recurso</TableCell>
            <TableCell>Permitir este tipo de recurso</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resourceTypes.map((type) => (
            <TableRow key={type.id}>
              <TableCell>{getIcon(type.name)}</TableCell>
              <TableCell>{type.name}</TableCell>
              <TableCell key={type.id}>
                <Checkbox
                  checked={isChecked(type.id)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleRuleChange(event.target.checked, type.id) }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AvailableResourcesTable;
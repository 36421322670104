import React from 'react';
import { Chip, makeStyles, createStyles, Theme } from '@material-ui/core';
import { ListedUser } from '../../users/listedUser.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    displayUsers: {
      width: "100%"
    },
    users: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: "10px",
      flexWrap: 'wrap',
    }
  }),
);

type Props = {
  groupId: string;
  groupUsers: ListedUser[];
  removeUser: (groupId: string, user: ListedUser) => void
}

const UsersContainer: React.FC<Props> = ({ groupId, groupUsers, removeUser }) => {
  const classes = useStyles();

  const handleRemove = async (userId: string) => {
    const user = groupUsers.find(r => r.id === userId)
    removeUser(groupId, user);
  }

  // El método onDelete del componente Chip requiere de una función que reciba un evento como parámetro
  const handleDelete = (event: any, userId: string) => handleRemove(userId);

  return (
    <div className={classes.root}>
      <div className={classes.users}>
        {groupUsers && groupUsers.map(value => {
          return <Chip
            color="primary"
            label={value.firstname + " " + value.lastname}
            key={groupId + "-" + value.id}
            onDelete={(e) => handleDelete(e, value.id)}
          />
        })}
      </div>
    </div>
  );
}

export default UsersContainer;
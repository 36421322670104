const defaultData = {
    general: {
        background_color: '#FF0000'
    },
    header: {
        background_color: '#0C212B',
        font_color: '#D1D5DB',
    },
    info: {
        background_image: true,
        background_color_free: '#268D80',
        background_color_waiting: '#DFAC29',
        background_color_busy: '#E25936',
        font_color_free: '#F3F4F6',
        font_color_waiting: '#F3F4F6',
        font_color_busy: '#F3F4F6',
        font_color_progress: '#FFFFFF',
    },
    bookings: {
        background_color_actual: '#F9D853',
        background_color_next: '#FDEEB1',
        background_color_previous: '#9CA3AF',
        font_color_actual: '#4B5563',
        font_color_next: '#4B5563',
        font_color_previous: '#4B5563'
    },
    times: {
        cancel_countdown: 5
    }
}

export default defaultData;
import React, { FC, useState } from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { CalendarToolbarBuilder } from './CalendarToolbarBuilder';
import ResourceTypeSelect from "../../commons/selects/ResourceTypeSelect";
import FloorSelect from "../../commons/selects/FloorSelect";
import BranchSelect from "../../commons/selects/BranchSelect";
import SearchIcon from '@material-ui/icons/Search';
import SearchInput from "../../commons/filterInput/SearchInput";
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExtraSelect from "../../commons/selects/ExtraSelect";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    padding: 2,
  },
}));

const CalendarToolbarWithFilters: FC<any> = ({ onNavigate, label, type, types, floor, floors, branch, branches, extra, extras, onNameSearch, onResourceTypeChange, onFloorChange, onBranchChange, onExtraChange }) => {
  const classes = useStyles();
  const [searching, setSearching] = useState(false);

  const handleFocus = () => {
    setSearching(true)
  }

  const handleBlur = () => {
    setSearching(false)
  }

  const componentFilters = () => {
    return (types && floors && branch &&
      <Grid container alignItems='center'>
        <Grid item>
          {searching
            ? <SearchInput value={''} onSearch={onNameSearch} onBlur={handleBlur} placeholder='Buscar recursos' />
            : <Tooltip title="Buscar"><IconButton className={classes.icon} aria-label="search" onClick={handleFocus}><SearchIcon /></IconButton></Tooltip>
          }
        </Grid>
        <Grid item>
          <ResourceTypeSelect smallSize types={types} defaultValue={type} onChange={onResourceTypeChange}></ResourceTypeSelect>
        </Grid>
        <Grid item>
          <FloorSelect smallSize floors={floors?.docs ? floors?.docs : []} defaultValue={floor} onChange={onFloorChange}></FloorSelect>
        </Grid>
        <Grid item>
          <BranchSelect smallSize branches={branches} defaultValue={branch} onChange={onBranchChange}></BranchSelect>
        </Grid>
        {extras && extras.length > 0 &&
          <Grid item>
            <ExtraSelect smallSize hideLabel extras={extras} defaultValue={extra} showAll={true} onChange={onExtraChange}></ExtraSelect>
          </Grid>}
      </Grid>
    );
  }

  return (
    <CalendarToolbarBuilder
      label={label}
      onToday={() => onNavigate('TODAY')}
      onPrev={() => onNavigate('PREV')}
      onNext={() => onNavigate('NEXT')}
      children={componentFilters()} />
  );
}

export default CalendarToolbarWithFilters;
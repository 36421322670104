import { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import GlobalDataCards from '../../components/reports/global/GlobalDataCards';
import DateFilter from '../../components/reports/DateFilter';
import WeeklyBookingsChart from '../../components/reports/weekly-bookings/WeeklyBookingsChart';
import ReportUsageTable from '../../components/reports/usage/ReportUsageTable';
import BookingsHeatmapChart from '../../components/reports/bookings-heatmap/BookingsHeatmapChart';
import GeneralOcupationChart from '../../components/reports/general-ocupation/GeneralOcupationChart';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import { DailyBookingsReport } from '../../components/reports/weekly-bookings/dayliBookings.model';
import { UsageReportData } from '../../components/reports/usage/usageReportData.model';
import { GlobalReportData } from '../../components/reports/global/globalReport.model';
import { HeatmapReport } from '../../components/reports/bookings-heatmap/heatmapReport.model';
import { GeneralOcupationData } from '../../components/reports/general-ocupation/generalOcupationData.model';
import { reportsService } from '../../_services/reports.service';
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import useCancelToken from '../../hooks/useCancelToken';
import { getWeekBegin, getWeekEnd } from '../../components/reports/utils/WeekParser';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'absolute',
    top: 64,
    right: 0,
    bottom: 0,
    left: 200,
    overflow: 'scroll',
  },
  container: {
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2, 0),
  }
}));

const Reports: FC = () => {
  const classes = useStyles();

  const [bookingsReport, setBookingsReport] = useState<DailyBookingsReport[]>([]);
  const [usageReport, setUsageReport] = useState<UsageReportData[][]>(null);
  const [globalReport, setGlobalReport] = useState<GlobalReportData[]>(null);
  const [heatmapReport, setHeatmapReport] = useState<HeatmapReport[]>(null);
  const [ocupationReport, setOcupationReport] = useState<GeneralOcupationData[]>([]);

  const [begin, setBegin] = useState<Date>(getWeekBegin(new Date(Date.now())));
  const [end, setEnd] = useState<Date>(getWeekEnd(new Date(Date.now())));

  const { currentBranch } = useCurrentBranch();
  const { getCancelToken, isCancel } = useCancelToken();
  const { showError } = useSnackBars();
  const handleError = useErrorHandler();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetchReportsData = useCallback(async () => {
    try {
      const cancelToken = getCancelToken()
      const global = await reportsService.getGlobalReport(cancelToken)
      const bookings = await reportsService.getWeeklyBookingsReport(begin, end, cancelToken)
      const usage = await reportsService.getUsageReport(begin, end, cancelToken)
      const heatmap = await reportsService.getHeatmapReport(begin, end, cancelToken)
      const generalOcupation = await reportsService.getGeneralOcupationReport(begin, end, cancelToken)
      setGlobalReport(global)
      setBookingsReport(bookings)
      setUsageReport(usage)
      setHeatmapReport(heatmap)
      setOcupationReport(generalOcupation)
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }, [begin, end, isCancel, handleApiError, handleError, getCancelToken])

  useEffect(() => {
    fetchReportsData();
  }, [fetchReportsData, currentBranch])

  const onChangeBegin = (begin: Date) => {
    setBegin(begin)
  }

  const onChangeEnd = (end: Date) => {
    setEnd(end)
  }

  return (
    <Box className={classes.wrapper}>
      <Grid container className={classes.container}>
        {/* Global Cards */}
        <Grid item xs={12}>
          {globalReport && globalReport.length !== 0 &&
            <GlobalDataCards data={globalReport} />
          }
        </Grid>
        {/* Filters */}
        <Grid item xs={12}>
          <DateFilter
            begin={begin}
            end={end}
            onChangeBegin={onChangeBegin}
            onChangeEnd={onChangeEnd}
          />
        </Grid>
        {/* Data */}
        {ocupationReport && ocupationReport.length !== 0 &&
          <Grid item xs={12} className={classes.content}>
            <GeneralOcupationChart ocupationData={ocupationReport} />
          </Grid>
        }
        { heatmapReport && heatmapReport.length !== 0 &&
          <Grid item xs={12} className={classes.content}>
            <BookingsHeatmapChart reports={heatmapReport}/>
          </Grid>
        }
        <Grid item xs={12} className={classes.content}>
          {bookingsReport?.length && usageReport?.length ?
            <Grid container justifyContent='space-between' spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <WeeklyBookingsChart reportData={bookingsReport} />
              </Grid>
              {usageReport.map((data, index) =>
                <Grid item xs={12} sm={6} md={3}>
                  <ReportUsageTable
                    title={index === 0 ? 'Usuarios que más reservan' : 'Recursos más reservados'} // FIXME Move title and entity to API
                    entity={index === 0 ? 'Usuarios' : 'Recursos'}
                    usageData={data}
                  />
                </Grid>
              )}
            </Grid>
            : <EmptyResults />
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default Reports;
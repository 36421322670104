import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ResourceForm, { EditProps } from '../resource/ResourceForm';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    overflow: "auto",
  },
}));

const FloorResourceMapEdit: React.FC<EditProps> = ({ onCreate, onClose, onPlace, onFocusResource, onDeleteCoords, resource, types, amenities, groups }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box p={1}>
        <Typography variant='subtitle1' align="center">
          Actualizar Recurso
        </Typography>
      </Box>
      <Box p={1}>
        <ResourceForm
          resource={resource}
          types={types}
          place={true}
          groups={groups}
          amenities={amenities}
          onCreate={onCreate}
          onClose={onClose}
          onPlace={onPlace}
          onFocusResource={onFocusResource}
          onDeleteCoords={onDeleteCoords} />
      </Box>
    </Box>
  )
}

export default FloorResourceMapEdit
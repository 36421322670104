import { createTheme, Theme } from '@material-ui/core/styles';

const datepickerTheme = (theme: Theme) => createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: 225,
        maxWidth: 250,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 225,
        maxWidth: 250,
        minHeight: 270,
        justifyContent: 'flex-start',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        padding: theme.spacing(0),
      },
      switchHeader: {
        marginBottom: theme.spacing(1.5),
      },
      transitionContainer: {
        height: theme.spacing(2),
        '& p': {
          color: theme.palette.primary.dark,
          textTransform: 'capitalize',
          fontSize: theme.typography.fontSize * .95,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      dayLabel: {
        width: theme.spacing(4),
        textTransform: 'capitalize',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: theme.spacing(.5),
        maxWidth: 260,
      },
    },
    MuiPickersDay: {
      day: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
      daySelected: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.main,
      },
      current: {
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        border: '1px solid',
      }
    },
  }
})

export default datepickerTheme;
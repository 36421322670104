import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Amenity } from '../amenities/amenity.model';
import { Floor } from '../floor/floor.model';
import { Resource } from './resource.model';
import { ResourceGroup } from '../resourceGroup/resourceGroup.model';
import ResourceForm from './ResourceForm';
import { ResourceType } from '../../components/resource/resourceType.model'

type Props = {
  open: boolean,
  resource?: Resource,
  amenities: Amenity[],
  types: ResourceType[],
  floors: Floor[],
  groups: ResourceGroup[],
  onClose: () => void
  onCreate: () => void
}

const UpdateResource: React.FC<Props> = ({ open, resource, amenities, types, floors, groups, onClose, onCreate }) => {

  const closeDialog = () => { onClose() }
  const handleCancel = () => { onClose() }
  const onSubmit = async (resourceUpdate: Resource) => { onCreate() }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {resource ? 'Actualizar' : 'Crear'} Recurso
      </DialogTitle>
      <DialogContent>
        <ResourceForm
          resource={resource}
          amenities={amenities}
          floors={floors}
          groups={groups}
          types={types}
          onCreate={onSubmit}
          onClose={handleCancel}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UpdateResource
import { FC } from "react";
import { Grid, Card, CardHeader } from "@material-ui/core";
import BookingsHeatmap from "./BookingsHeatmap";
import { HeatmapReport } from "./heatmapReport.model";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 5
  },
}));

type Props = {
  reports: HeatmapReport[];
}

const BookingsHeatmapChart: FC<Props> = ({ reports }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        align='center'
        titleTypographyProps={{ variant: 'h5' }}
        title="Heatmap de ocupación por día/hora"
      />
      <Grid container xs={12} spacing={2} justifyContent="space-around">
        {reports.map(report => {
          return (
            <Grid item xs={12} md={6} key={`${report.type}-heatmap-report`}>
              <BookingsHeatmap height={200} report={report} />
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}

export default BookingsHeatmapChart;
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, Box } from '@material-ui/core';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "center",
  },
  container: {
    flexDirection: "column",
    textAlign: "center",
    background: "url(/webee-login.png) no-repeat center center",
    backgroundSize: "cover",
    height: "100%",
    margin: theme.spacing(2,0,2,0),
    [theme.breakpoints.down("md")]: { display: "none" },
    [theme.breakpoints.up("md")]: { display: "flex" },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 2.25,
    fontWeight: 'bolder',
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.fontSize * 1.25,
    fontWeight: 'lighter',
  },
  formContainer: {
    overflowY: "auto",
    height: "100%",
  }
}))

const PublicPages: FC = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { showSuccess, showError } = useSnackBars();
  const { height } = useWindowDimensions();

  useEffect(() => {
    const searchParam = (value: string) => {
      return new URLSearchParams(location.search).get(value);
    }
    const verify = searchParam('verify');
    if (verify) {
      verify === "true" ?
        showSuccess("Tu cuenta ha sido activada correctamente") :
        showError("El token de activación no es válido")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container className={classes.root} style={{ height }}>
      <Grid item sm={6} lg={7}
        component={Box}
        className={classes.container}>
        <Typography className={classes.title}>Comienza hoy mismo <br /> a disfrutar del trabajo híbrido</Typography>
        <Typography className={classes.subtitle}>Elige trabajar cuándo y dónde más te guste</Typography>
      </Grid>
      <Grid item xs={10} sm={6} md={6} lg={5}
        component={Box}
        className={classes.formContainer}>
        {children}
      </Grid>
    </Grid>
  );
}

export default PublicPages

import { FC } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons from "../commons/buttons/FormActionButtons";
import { useErrorHandler } from '../errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { companyService } from '../../_services/company.service';


const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  }, submit: {
    margin: theme.spacing(2, 0, 0),
  }
}));

type LogoForm = {
  image: FileList
}

type Props = {
  open: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

const UpdateLogo: FC<Props> = ({ open, onUpdate, onClose }) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm<LogoForm>();
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (data: LogoForm) => {
    try {
      const res = await companyService.updateLogo(data.image[0])
      showSuccess(res.msg)
      onUpdate()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleClose = () => {
    clearErrors();
    onClose();
  }

  return (
    <Dialog fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Actualizar Logo</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formControl}>
            <input type="file" ref={register} name="image" />
            <FormHelperText>
              {errors?.image && errors.image.message ? errors.image.message : ''}
            </FormHelperText>
          </FormControl>
          <FormActionButtons actionText="Actualizar" onCancel={onClose} />
        </form>
      </DialogContent>
    </Dialog >
  )
}

export default UpdateLogo;
import React from 'react';
import { IconButton, List, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction, Tooltip } from '@material-ui/core';
import { CenterFocusWeak, Delete, Edit, Gesture } from '@material-ui/icons';
import { Resource } from '../resource/resource.model';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

type ListProps = {
  title: string,
  resources: Resource[],
  filter: string,
  onPlace?: (res: Resource) => void
  onLocate?: (res: Resource) => void
  onEdit: (res: Resource) => void
  onDelete: (res: Resource) => void
}

const FloorResourcesList: React.FC<ListProps> = ({ title, resources, filter, onLocate, onPlace, onEdit, onDelete }) => {
  const classes = useStyles()
  return (
    <List dense subheader={<li />} className={classes.root}>
      <ListSubheader color="primary">{title}</ListSubheader>
      {!!resources && resources.filter(r => r.name.match(filter)).map((resource) => (
        <ListItem key={resource.id}>
          <ListItemText primary={resource.name} />
          <ListItemSecondaryAction>
            {!!resource.coords && resource.coords.length > 0 &&
              <IconButton edge="end" aria-label="draw" size="small" onClick={() => onLocate(resource)}>
                <CenterFocusWeak />
              </IconButton>
            }
            {(!resource.coords || resource.coords.length === 0) &&
              <Tooltip title="Marcar posición">
                <IconButton edge="end" aria-label="draw" size="small" onClick={() => onPlace(resource)}>
                  <Gesture />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Editar">
              <IconButton edge="end" aria-label="draw" size="small" onClick={() => onEdit(resource)}>
                < Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Borrar">
              <IconButton edge="end" aria-label="draw" size="small" onClick={() => onDelete(resource)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default FloorResourcesList
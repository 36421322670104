import React from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, LabelDisplayedRowsArgs, TablePagination, TableSortLabel, TableContainer, Tooltip, Paper } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import useTableStyles from '../../hooks/styles/tableStyles';
import { Paginated } from '../commons/Paginated';
import { Resource } from './resource.model';

type Props = {
  resources: Paginated<Resource>;
  order: "asc" | "desc";
  orderBy: keyof Resource;
  onSort: (e: React.MouseEvent<unknown>, property: keyof Resource) => void;
  onDelete: (resource: Resource) => void;
  onUpdate: (resource: Resource) => void;
  onChangePage: (page: number) => void;
  onRowsPerPageChange: (rows: number) => void;
}

const ResourceList: React.FC<Props> = ({ resources, order, orderBy, onSort, onDelete, onUpdate, onChangePage, onRowsPerPageChange }) => {
  const classes = useTableStyles();

  const handleDelete = (resource: Resource) => {
    onDelete(resource);
  }
  const handleUpdate = (resource: Resource) => {
    onUpdate(resource)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const pagesLimit = ({ from, count }: LabelDisplayedRowsArgs) => {
    return '' + (Math.trunc(from / resources.limit + 1)) + ' de ' + ((count % resources.limit) === 0 ?
      (Math.trunc(count / resources.limit)) : (Math.trunc(count / resources.limit + 1)))
  }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === "name" ? order : false}>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={e => onSort(e, "name")}
              >
                Nombre
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "type" ? order : false}>
              <TableSortLabel
                active={orderBy === "type"}
                direction={orderBy === "type" ? order : "asc"}
                onClick={e => onSort(e, "type")}
              >
                Tipo
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "floor" ? order : false}>
              <TableSortLabel
                active={orderBy === "floor"}
                direction={orderBy === "floor" ? order : "asc"}
                onClick={e => onSort(e, "floor")}
              >
                Piso
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "capacity" ? order : false}>
              <TableSortLabel
                active={orderBy === "capacity"}
                direction={orderBy === "capacity" ? order : "asc"}
                onClick={e => onSort(e, "capacity")}
              >
                Capacidad
              </TableSortLabel>
            </TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.docs.map((resource) => (
            <TableRow key={resource.id}>
              <TableCell>{resource.name}</TableCell>
              <TableCell>{resource.type.name}</TableCell>
              <TableCell>{resource.floor.name}</TableCell>
              <TableCell >{resource.capacity}</TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(resource)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(resource)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={resources.totalDocs}
        page={resources.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={resources.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={pagesLimit}
        labelRowsPerPage=""
        title="Cantidad de resultados por página"
        nextIconButtonText="Siguiente"
        backIconButtonText="Anterior"
        className={classes.tablePagination}
      />
    </TableContainer>
  );
}

export default ResourceList;
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { User } from '../../components/users/user.model';
import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { userService } from '../../_services/user.service'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { Link as RouterLink } from 'react-router-dom';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';

export interface RegisterData {
  firstname: string
  lastname: string
  email: string
  password?: string
  passwordMatch?: string
}

const ClientRegisterForm: React.FC = () => {
  const classes = useFormsPublicStyles();
  const history = useHistory();
  const [password, setPassword] = React.useState();
  const { register, handleSubmit, errors, setError } = useForm<RegisterData>();
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler();
  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = (data: RegisterData) => {
    create(data)
  }

  const create = async (data: RegisterData) => {
    try {
      const client: User = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password
      }
      const res = await userService.register(client)
      showSuccess(res.msg)
      history.push('/login')
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handlePassword = (e: any) => {
    setPassword(e.target.value)
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Registro de nuevo cliente</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.firstname ? true : false}
                fullWidth
                id="firstname"
                label="Nombre"
                name="firstname"
                autoComplete="firstname"
                autoFocus
                inputRef={register({ required: { value: true, message: "El nombre es obligatorio" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.firstname && errors.firstname.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.lastname ? true : false}
                fullWidth
                id="lastname"
                label="Apellido"
                name="lastname"
                autoComplete="lastname"
                inputRef={register({ required: { value: true, message: "El apellido es obligatorio" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.lastname && errors.lastname.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.email ? true : false}
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                inputRef={register({ required: { value: true, message: "El email es obligatorio" }, pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Debe ingresar un email válido" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.email && errors.email?.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.password ? true : false}
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePassword}
                inputRef={register({ required: { value: true, message: "La contraseña es obligatoria" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.password && errors.password.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={(errors.passwordMatch ? true : false)}
                fullWidth
                type="password"
                name="passwordMatch"
                label="Repetir Contraseña"
                id="passwordMatch"
                autoComplete="current-password"
                inputRef={register({ validate: value => value === password || "Las contraseñas no coinciden" })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.passwordMatch && errors.passwordMatch.message}
              </FormHelperText>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Registrarme
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <RouterLink to="/login">Ya tengo usuario</RouterLink>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ClientRegisterForm
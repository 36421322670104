import { FC } from 'react'
import { Grid } from '@material-ui/core'
import moment, { Moment } from 'moment'
import { MomentUTCUtils } from '../commons/utils/MomentUTCUtils'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

type Props = {
  begin: Date;
  onChangeBegin: (begin: Date) => void;
  end: Date;
  onChangeEnd: (end: Date) => void;
}

const DateFilter: FC<Props> = ({ begin, onChangeBegin, end, onChangeEnd }) => {

  const handleChangeBegin = (date: Moment) => {
    onChangeBegin(date.toDate())
  }

  const handleChangeEnd = (date: Moment) => {
    onChangeEnd(date.toDate())
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUTCUtils} libInstance={moment}>
      <Grid container justifyContent='flex-start' alignItems="center" spacing={2} >
        <Grid xs={6} sm={4} md={2} item>
          <DatePicker
            variant="inline"
            format="DD/MM/yy"
            margin="dense"
            label="Desde"
            inputProps={{ style: { textAlign: 'center' } }}
            value={moment.utc(begin)}
            onChange={handleChangeBegin}
            maxDate={end}
          />
        </Grid>
        <Grid xs={6} sm={4} md={2} item>
          <DatePicker
            variant="inline"
            format="DD/MM/yy"
            margin="dense"
            label="Hasta"
            inputProps={{ style: { textAlign: 'center' } }}
            value={moment.utc(end)}
            onChange={handleChangeEnd}
            minDate={begin}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default DateFilter
import { makeStyles, Theme } from '@material-ui/core/styles';

const useFABStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(1),
    '& .MuiFab-label': {
      fontWeight: theme.typography.fontWeightLight
    }
  },
}));

export default useFABStyles;
import { Resource } from '../components/resource/resource.model'
import baseAPI from './api';
import { ResourceBookings } from '../components/booking/resourceBooking.model';
import { Paginated } from '../components/commons/Paginated';
import { Success } from "../components/commons/Success";
import { Floor } from '../components/floor/floor.model';
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (resource: Resource) => {
  const amenities = resource.amenities.map(am => am.id)
  const groups = resource.groups.length ? resource.groups.map(g => g.id) : [];
  const body = { name: resource.name, description: resource.description, capacity: resource.capacity, coords: resource.coords, floor: resource.floor.id, type: resource.type.id, amenities, groups }
  const response = await baseAPI.post(`resource`, body)
  return response.data as Success<string>
}

const update = async (resource: Resource) => {
  const amenities = resource.amenities.map(am => am.id)
  const groups = resource.groups.length ? resource.groups.map(g => g.id) : [];
  const body = { name: resource.name, description: resource.description, capacity: resource.capacity, coords: resource.coords, floor: resource.floor.id, type: resource.type.id, amenities, groups }
  const response = await baseAPI.patch(`resource/${resource.id}`, body)
  return response.data as Success<string>
}


const updateCoords = async (resources: Resource[]) => {
  const body = { resources: resources.map(r => { return { id: r.id, coords: r.coords } }) }
  await baseAPI.post(`resource/coords`, body)
  return
}

const list = async (query: string, onlyFavs: boolean, offset: number, limit: number, order?: string, orderBy?: string, floor?: string, type?: string, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  axiosConfig.params = { limit, offset, order, orderBy, favourites: onlyFavs }
  if (!!query) {
    axiosConfig.params['q'] = query
  }
  if (!!floor) {
    axiosConfig.params['floor'] = floor
  }
  if (!!type) {
    axiosConfig.params['type'] = type
  }
  const response = await baseAPI.get(`resource`, axiosConfig);
  return response.data as Paginated<Resource>
}

const filterBy = async (offset: number, limit: number, type: string, floor: Floor, order?: string, orderBy?: string) => {
  const params: any = { limit, offset, order, orderBy }
  if (!!floor) {
    params['floor'] = floor.id
  }
  if (!!type) {
    params['type'] = type
  }
  const response = await baseAPI.get(`resource`, { params });
  return response.data as Paginated<Resource>
}

const unassigned = async () => {
  const response = await baseAPI.get(`resource`, { params: { assigned: false } })
  return response.data as Resource[]
}

const assigned = async (floor: string) => {
  const response = await baseAPI.get(`resource`, { params: { floor } })
  return response.data as Paginated<Resource>
}

const dailyBookings = async (day: Date) => {
  const response = await baseAPI.get(`resource/booking/daily?day=${day.toISOString()}`)
  return response.data as ResourceBookings[]
}

const remove = async (id: string) => {
  const response = await baseAPI.delete(`resource/${id}`)
  return response.data as Success<string>
}

export const resourceService = {
  create,
  update,
  remove,
  updateCoords,
  list,
  filterBy,
  unassigned,
  assigned,
  dailyBookings
};

import { ReactElement } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CancelButton from './CancelButton'
import PrimaryButton from './PrimaryButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBlock: theme.spacing(1),
  },
}));

type Props = {
  actionText: string;
  onCancel: () => void;
}

const FormActionButtons = ({ actionText, onCancel }: Props): ReactElement => {
  const classes = useStyles()
  
  return (
    <Grid container justifyContent="center" spacing={1} className={classes.root}>
      <Grid item xs={12} sm={10} md={5} lg={3}>
        <PrimaryButton title={actionText} />
      </Grid>
      <Grid item xs={12} sm={10} md={5} lg={3}>
        <CancelButton onClick={onCancel} />
      </Grid>
    </Grid>
  )
}

export default FormActionButtons;
import { FC } from 'react';
import { Button, FormHelperText, TextField, Typography, FormControl, Grid, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { Credentials } from '../../_services/authentication.service';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';
import { useHistory } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useSubdomain } from '../../components/commons/subdomain/SubdomainHook';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';

const LoginForm: FC = () => {
  const classes = useFormsPublicStyles();
  const history = useHistory();
  const { login } = useCurrentUser();
  const { showError } = useSnackBars();
  const handleError = useErrorHandler();
  const { register, handleSubmit, errors, setError, clearErrors } = useForm<Credentials>();
  const { company } = useSubdomain();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (data: Credentials) => {
    try {
      await login(data)
      history.push('/');
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Acceso a la aplicación</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={classes.textField}
                variant="filled"
                error={(errors.email ? true : false)}
                margin="normal"
                size="small"
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                inputRef={register({ required: { value: true, message: "El correo es obligatorio" }, pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Debe ingresar un email válido" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.email && (errors.email.message || "El correo es requerido")}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                error={(errors.password ? true : false)}
                margin="normal"
                size="small"
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: true })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.password && (errors.password.message || "Debe escribir su contraseña")}
              </FormHelperText>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => clearErrors()}>
                  Iniciar sesión
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <RouterLink to="/resetpassword/request">¿Olvidaste tu contraseña?</RouterLink>
                </Typography>
                <Typography className={classes.link} noWrap>
                  {company && <RouterLink to="/register/user">Solicitar adhesión a {company.name}</RouterLink>}
                </Typography>
                <Typography className={classes.link} noWrap>
                  {!company && <RouterLink to="/register/client">Quiero registrar mi compañía</RouterLink>}
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LoginForm
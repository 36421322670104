import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export const useUserDialogStyle = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.grey[100],
  }
}));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: theme.palette.grey[700],
    },
    'owner': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grey[200],
    },
    'external': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.grey[200],
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface UserDialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  background?: 'owner' | 'external' | 'default';
  children: React.ReactNode;
  onClose: () => void;
}

export const UserDialogTitle = withStyles(styles)((props: UserDialogTitleProps) => {
  const { children, classes, background = 'default', onClose, ...other } = props;
  const backgroundClass =
    background === 'owner' ? classes.owner :
      background === 'external' ? classes.external : ''

  return (
    <MuiDialogTitle disableTypography className={clsx(classes.root, backgroundClass)} {...other}>
      {children}
      {onClose ? (
        <Tooltip title="Cerrar">
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
});

export const UserDialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const UserDialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

import moment from 'moment'
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';
import { Floor } from '../../floor/floor.model';
import { floorService } from '../../../_services/floor.service';
import { resourceTypeService } from '../../../_services/resourcetype.service';
import { Booking } from '../booking.model';
import { ResourceType } from '../../resource/resourceType.model';
import { Paginated } from '../../commons/Paginated';
import { Resource } from '../../resource/resource.model';
import { bookingService } from '../../../_services/booking.service';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import useSnackBars from '../../commons/snackbar/SnackbarHook';
import FloorSelect from '../../commons/selects/FloorSelect';
import ResourceTypeSelect from '../../commons/selects/ResourceTypeSelect';
import { useCurrentBranch } from '../../commons/currentbranch/CurrentBranchHook';
import SearchInput from '../../commons/filterInput/SearchInput';
import useCancelToken from '../../../hooks/useCancelToken'
import useBookingCardStyles from '../../../hooks/styles/bookingCardStyles';
import { Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import Map from './Map';
import { BranchReference } from '../../branches/branch.reference.model';
import { useBranches } from '../../commons/branches/BranchesHook';
import BranchSelect from '../../commons/selects/BranchSelect';
import { Branch } from '../../branches/branch.model';
import { Amenity } from '../../amenities/amenity.model';
import { amenitiesService } from '../../../_services/amenities.service';
import ExtraSelect from '../../commons/selects/ExtraSelect';

type Props = {
  booking: Booking;
  onResourceSelected: (resource: Resource) => void;
  onCancel: () => void;
}

const BookingMap: React.FC<Props> = ({ booking, onResourceSelected, onCancel }) => {
  const classes = useBookingCardStyles();
  const [resources, setResources] = useState<Resource[]>(null)
  const [floors, setFloors] = useState<Paginated<Floor>>(null)
  const [floor, setFloor] = useState<Floor>()
  const [types, setTypes] = React.useState<ResourceType[]>([]);
  const [type, setType] = React.useState<ResourceType>(null);
  const [branch, setBranch] = React.useState<Branch>(null);
  const [extras, setExtras] = useState<Amenity[]>([])
  const [extra, setExtra] = useState<Amenity>()
  const [query, setQuery] = React.useState<string>('');
  const { currentBranch, onUpdateBranch } = useCurrentBranch()
  const { branches } = useBranches();
  const { showError, showInfo } = useSnackBars();
  const handleError = useErrorHandler();
  const { getCancelToken, isCancel } = useCancelToken()

  const handleApiError = useCallback(
    (errors: any) => {
      Object.keys(errors).forEach((field) => {
        const e = errors[field]
        showError(e.msg);
      });
    }, [showError]);

  const fetchData = useCallback(
    async () => {
      try {
        setResources(null)
        const cancelToken = getCancelToken()
        const _floors = await floorService.list('', 0, Number.MAX_SAFE_INTEGER, cancelToken);
        const _types = await resourceTypeService.availableList(cancelToken);
        const _amenities = await amenitiesService.list('', cancelToken);
        setFloors(_floors);
        setFloor(_floors.docs.find(f => f.image, null))
        setTypes(_types);
        setExtras(_amenities)
        if (!_floors.docs.find(f => f.image, null)) {
          showInfo(
            `La sucursal seleccionada no tiene planos de oficina configurados, por favor contacte con el administrador.`,
            () => {}
          );
        }
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBranch])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch, fetchData])

  const fetchResourceTypes = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken();
        const _types = await resourceTypeService.availableList(cancelToken);
        setTypes(_types);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel]);

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  const fetchFreeResources = useCallback(
    async () => {
      try {
        if (!booking) {
          return
        }
        const cancelToken = getCancelToken()
        const _resources = await bookingService.free(booking, query, floor?.id, type?.id, extra?.id, cancelToken);
        setResources(_resources)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floor, type, query, booking, extra])

  useEffect(() => {
    if (!floor) {
      return
    }
    fetchFreeResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFreeResources])

  useEffect(() => {
    const branch = branches.find((branch: BranchReference) => branch.id === currentBranch)
    setBranch(branch)
  }, [currentBranch, branches]);

  const handleResourceSelected = (resource: Resource) => {
    onResourceSelected(resource)
  }

  const handleResourceTypeChange = useCallback((type: ResourceType) => {
    setType(type)
  }, [])

  const handleFloorChange = useCallback((floor: Floor) => {
    setResources(null)
    setFloor(floor)
  }, [])

  const handleBranchChange = useCallback((branch: BranchReference) => {
    onUpdateBranch(branch.id)
  }, [])

  const handleExtraChange = useCallback((extra: Amenity) => {
    setExtra(extra)
  }, [])

  const handleNameSearch = (nameQuery: string) => {
    setQuery(nameQuery)
  }

  return (
    <>
      <Box
        bgcolor="grey.300"
        position="relative"
        height="100%"
        width="100%">
        {/* Float map filter bar */}
        <Box
          bgcolor="grey.50"
          padding={1}
          position="absolute"
          top={0}
          left={0}>
          <Grid container justifyContent="space-between">
            {/* Searcher */}
            <Grid item><SearchInput value={''} onSearch={handleNameSearch} placeholder='Buscar recursos' /></Grid>
            {/* Resource Type Filter */}
            <Grid item><ResourceTypeSelect smallSize hideLabel types={types} defaultValue={type} onChange={handleResourceTypeChange}></ResourceTypeSelect></Grid>
            {/* Floor Filter */}
            <Grid item><FloorSelect smallSize hideLabel floors={floors?.docs ? floors.docs : []} defaultValue={floor} onChange={handleFloorChange}></FloorSelect></Grid>
            {/* Branch Filter */}
            <Grid item><BranchSelect smallSize hideLabel branches={branches} defaultValue={branch} onChange={handleBranchChange}></BranchSelect></Grid>
            {/* Extra Filter */}
            {extras && extras.length > 0 && <Grid item><ExtraSelect smallSize hideLabel extras={extras} defaultValue={extra} showAll={true} onChange={handleExtraChange}></ExtraSelect></Grid>}
          </Grid>
        </Box>
        {/* Float booking data */}
        {resources &&
          <>
            <Box
              position="absolute"
              bottom={0}
              right={16}>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Typography className={classes.resource} color="textPrimary">
                    <strong>Nueva Reserva</strong>
                  </Typography>
                  <Typography className={classes.date} color="textPrimary">
                    {moment(booking.bookingStart).format('dddd DD [de] MMMM')}
                  </Typography>
                  <Typography className={classes.time} color="textPrimary">
                    {moment(booking.bookingStart).format('HH:mm')} - {moment(booking.bookingEnd).format('HH:mm [hs.]')}
                  </Typography>
                </CardContent>
                <CardActions className={clsx(classes.actions, classes.actionsTop)}>
                  <Tooltip title="Cancelar">
                    <IconButton size="small" onClick={onCancel}><Cancel /></IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Box>
            <Map
              onResourceSelected={handleResourceSelected}
              resources={resources}
              floor={floor}
            />
          </>
        }
      </Box>
    </>
  );
}

export default BookingMap;

import React, { FC } from 'react';
import { Button } from '@material-ui/core';

type Props = {
  title: string;
}

const PrimaryButton: FC<Props> = ({ title }) => {
  return (
    <Button
      aria-label="main-button"
      color="primary"
      fullWidth
      size="small"
      type="submit"
      variant="contained"
    >
      {title}
    </Button>
  )
}

export default PrimaryButton;
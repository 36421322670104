import { FC } from 'react';
import { Block, Check } from '@material-ui/icons';
import { Button, IconButton, TablePagination, LabelDisplayedRowsArgs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip } from '@material-ui/core';
import useTableStyles from '../../hooks/styles/tableStyles';
import { Paginated } from '../../components/commons/Paginated';
import { User } from '../users/user.model';
import moment from 'moment';

type Props = {
  users: Paginated<User>;
  onChangePage: (user: number) => void;
  onRowsPerPageChange: (rows: number) => void;
  onAccept: (user: User) => void;
  onReject: (user: User) => void;
}

const ListuserRequest: FC<Props> = ({ users, onAccept, onReject, onChangePage, onRowsPerPageChange }) => {
  const classes = useTableStyles();

  const handleAccept = (user: User) => {
    onAccept(user)
  }

  const handleReject = (user: User) => {
    onReject(user)
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const pagesLimit = ({ from, count }: LabelDisplayedRowsArgs) => {
    return '' + (Math.trunc(from / users.limit + 1)) + ' de ' + ((count % users.limit) === 0 ?
      (Math.trunc(count / users.limit)) : (Math.trunc(count / users.limit + 1)))
  }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Apellido</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell align="center"> Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.docs.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.firstname}</TableCell>
              <TableCell>{user.lastname}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{moment(user.request_date).format('DD-MM-YYYY')}</TableCell>
              <TableCell align="center">
                <Tooltip title="Aceptar">
                  <IconButton aria-label="delete">
                    <Button color="primary" onClick={() => handleAccept(user)} ><Check /></Button>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Denegar">
                  <IconButton aria-label="edit">
                    <Button color="secondary" onClick={() => handleReject(user)}><Block /></Button>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={users.totalDocs}
        page={users.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={users.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={pagesLimit}
        labelRowsPerPage=""
        title="Cantidad de resultados por página"
        nextIconButtonText="Siguiente"
        backIconButtonText="Anterior"
        className={classes.tablePagination}
      />
    </TableContainer>
  );
}

export default ListuserRequest;
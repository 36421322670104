import { FC, useEffect, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';

import FloorList from '../../components/floor/FloorList';
import UpdateFloor from '../../components/floor/UpdateFloor';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from "../../components/commons/dialogs/ConfirmDialog";
import FAB from '../../components/commons/buttons/FAB';

import { floorService } from '../../_services/floor.service';
import { Paginated } from '../../components/commons/Paginated';
import { Floor } from '../../components/floor/floor.model';
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useCancelToken from '../../hooks/useCancelToken';

const Floors: FC = () => {
  const [floors, setFloors] = useState<Paginated<Floor>>(null);
  const [query, setQuery] = useState<string>('');
  const [floor, setFloor] = useState<Floor>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { showSuccess, showError } = useSnackBars()
  const { getCancelToken, isCancel } = useCancelToken()
  const handleError = useErrorHandler();

  const { currentBranch } = useCurrentBranch();

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchFloors = useCallback(
    async (query: string, offset: number, limit: number) => {
      try {
        const cancelToken = getCancelToken()
        const floors = await floorService.list(query, offset, limit, cancelToken)
        setFloors(floors)
      } catch (error) {
        if(!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    fetchFloors(query, 0, 10)
  }, [query, currentBranch, fetchFloors])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const reloadFloors = async (offset: number, limit: number) => {
    fetchFloors('', offset, limit)
    setQuery('')
  }

  const handleDelete = async (floor: Floor) => {
    try {
      const res = await floorService.remove(floor.id)
      showSuccess(res.msg)
      reloadFloors(floors.offset, floors.limit)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setFloor(null)
  }

  const handleCreated = () => {
    setOpen(false)
    setFloor(null)
    reloadFloors((floors.page - 1) * floors.limit, floors.limit)
  }

  const handleUpdate = (floor: Floor) => {
    setFloor(floor)
    setOpen(true)
  }

  const handleCreate = () => {
    setOpen(true)
    setFloor(null)
  }

  const handleChangePage = (page: number) => {
    reloadFloors((page) * floors.limit, floors.limit)
  }
  const handleChangeRowsPerPage = (rows: number) => {
    reloadFloors(0, rows)
  }

  const openDeleteDialog = (floor: Floor) => {
    setFloor(floor);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nuevo piso" onClick={handleCreate} />
      </Box>
      {!floors || floors.totalDocs === 0 ? <EmptyResults /> :
        <FloorList floors={floors} onUpdate={handleUpdate} onDelete={openDeleteDialog}
          onChangePage={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}>
        </FloorList>
      }
      <UpdateFloor
        floor={floor}
        open={open}
        onClose={handleClose} onCreate={handleCreated}
      />
      <ConfirmDialog<Floor>
        open={openDelete}
        holder={floor}
        title="Eliminando Piso"
        subtitle="Esto eliminará el piso y todos sus recursos asignados. ¿Deseas continuar con la eliminación?"
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
}

export default Floors
import React, { FC } from 'react';
import CustomTab, { TabData } from '../../components/commons/tabs/CustomTab';
import WeeklySchedules from '../weekly-schedule/WeeklySchedules';
import Rules from '../rules/Rules'
import AvailableResources from '../available-resources/AvailableResources'
import CustomizeCompany from '../companies/CustomizeCompany';
import CustomizeRooms from '../../components/rooms/CustomizeRooms';

const ConfigTab: FC = () => {

  const tabList: TabData[] = [
    { label: 'Reglas', component: <Rules /> },
    { label: 'Horarios', component: <WeeklySchedules /> },
    { label: 'Tipos de Recursos', component: <AvailableResources /> },
    { label: 'Parámetros', component: <CustomizeCompany /> },
    { label: 'Salas', component: <CustomizeRooms /> }
  ]

  return (
    <CustomTab tabs={tabList} />
  );
}

export default ConfigTab
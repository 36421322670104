import { FC } from 'react'
import { Card } from '@material-ui/core'
import { Bar } from 'react-chartjs-2'
import { DailyBookingsReport } from './dayliBookings.model'
import { makeStyles } from '@material-ui/core/styles';
import { FULL_WEEK_DAYS } from '../../../_helpers/constants';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 5,
  },
}));

type Props = {
  reportData: DailyBookingsReport[]
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        stepSize: 1
      }
    }
  }
}

const WeeklyBookingsChart: FC<Props> = ({ reportData }) => {
  const classes = useStyles();

  const extractDataArray = (prop: keyof DailyBookingsReport) => {
    const dataArray = []
    for (let i = 0; i < 7; i++) {
      const found = reportData.find(day => day.weekDay === i)
      if (found) {
        dataArray.push(found[prop])
      } else {
        dataArray.push(0)
      }
    }
    return dataArray
  }

  const data = {
    labels: FULL_WEEK_DAYS,
    datasets: [
      {
        label: "Reservas totales",
        data: extractDataArray("count"),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        stack: "Stack 0"
      },
      {
        label: "Reservas canceladas",
        data: extractDataArray("canceled"),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 1,
        stack: "Stack 1"
      }
    ]
  }

  return (
    <Card className={classes.root}>
      <Bar
        type="bar"
        data={data}
        options={options}
        height={300}
      />
    </Card>
  )
}

export default WeeklyBookingsChart
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Amenity } from './amenity.model';
import { amenitiesService } from '../../_services/amenities.service';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import FormActionButtons from '../commons/buttons/FormActionButtons';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(1),
  }
}));

type Props = {
  amenity: Amenity,
  open: boolean,
  onClose: () => void,
  onUpdate: () => void
}

const UpdateAmenity: React.FC<Props> = ({ amenity, open, onClose, onUpdate }) => {
  const classes = useStyles();

  const defaultValues = {
    id: amenity?.id,
    name: amenity?.name,
  }

  const { register, handleSubmit, reset, errors, setError } = useForm<Amenity>({ defaultValues });
  const { showError, showSuccess } = useSnackBars();
  const handleError = useErrorHandler();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [amenity])

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const closeDialog = () => { onClose() }

  const onSubmit = async (data: Amenity) => {
    try {
      if (!!amenity) {
        data.id = amenity.id
        const res = await amenitiesService.update(data)
        showSuccess(res.msg)
      } else {
        const res = await amenitiesService.create(data)
        showSuccess(res.msg)
      }
      onUpdate()
      onClose()

    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {amenity ? 'Actualizar' : 'Crear'} Extra
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="name"
              label="Nombre"
              name="name"
              variant="outlined"
              error={(errors.name ? true : false)}
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un nombre" } })}
            />
            <FormHelperText className="error">
              {errors?.name && (errors?.name.message || "El nombre es obligatorio")}
            </FormHelperText>
          </FormControl>
          <FormActionButtons
            actionText={amenity ? "Actualizar" : "Crear"}
            onCancel={closeDialog}
          />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateAmenity
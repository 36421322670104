import { Route, Redirect } from 'react-router-dom';
import { AppRoute } from "../../_helpers/routes";
import AgendaBooking from "../../pages/booking/AgendaBooking";
import ResourceBooking from "../../pages/booking/ResourceBooking";
import { useCurrentUser } from "../commons/currentuser/CurrentUserHook";
import BlueprintBooking from '../../pages/booking/BlueprintBooking';
import EditProfile from '../profile/EditProfile';

/**
 * Definir rutas dentro del Dashboard para usuarios con Rol.user. Actualizar el menú (MainListItems)
 * para añadir / eliminar el link a tu componente.
 */
export const UserRoutes = () => {

  const { user } = useCurrentUser()

  return (
    <>
      <AppRoute exact path="/" component={AgendaBooking} />
      <AppRoute exact path="/blueprintAgenda" component={BlueprintBooking} />
      <AppRoute exact path="/resourceAgenda" component={ResourceBooking} />
      <AppRoute exact path="/profile" component={EditProfile} />
      <Route path="*">
        {
          user.company ?
            <Redirect to="/" /> :
            <Redirect to="/company/membership" />
        }
      </Route>
    </>
  )
}

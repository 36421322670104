import { makeStyles, Theme } from '@material-ui/core/styles';

const useChipStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    '& .MuiChip-label': {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}));

export default useChipStyles;
import { makeStyles, Theme } from '@material-ui/core/styles';

const useBookingCardStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    margin: theme.spacing(1, -1, 1, 0),
    borderRadius: 10,
    position: 'relative',
  },
  content: {
    padding: theme.spacing(1),
  },
  resource: {
    fontSize: theme.typography.fontSize + 1,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  date: {
    textTransform: 'capitalize',
    fontSize: theme.typography.fontSize,
  },
  time: {
    fontSize: theme.typography.fontSize - 1,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    '& button': {
      color: theme.palette.grey[700],
      '& span > svg': {
        fontSize: theme.typography.fontSize * 1.5,
      }
    },
    '& button:not(first-child)': {
      marginLeft: '0px !important',
    },
  },
  actionsTop: {
    bottom: 'unset',
    top: 0,
    padding: 4,
  },
  adjust: {
    borderRadius: 10,
    position: 'relative',
    color: '#000000',
    fontWeight: 'lighter',

  },
  iconStyle: {
    justifyContent: "bottom",
    justifyItems: "bottom"

  },
  favoriteIcon: {
    paddingTop: '12px',
    justifyContent: "bottom",
    fontSize: "35px",
    color: 'rgb(115, 140, 154)',

  }
}));

export default useBookingCardStyles;
import { FC, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import moment from 'moment-timezone';
import { Autocomplete } from '@material-ui/lab';
import { getGMTString } from '../utils/timezoneUtils';

type Props = {
  timezone: string;
  variant?: 'outlined' | 'filled';
  className?: string;
  onChange?: (timezone: string) => void;
}

const TimezoneSelect: FC<Props> = ({ timezone, variant = 'outlined', className, onChange }) => {

  const handleTimezoneChange = useCallback(
    async (event: object, value: string, reason: string) => {
      onChange && onChange(value);
    },
    [onChange],
  )

  return (
    <Autocomplete
      id="timezone-autocomplete"
      value={timezone}
      options={moment.tz.names()}
      getOptionLabel={(option) => `${option} (${getGMTString(option as string)})`}
      onChange={handleTimezoneChange}
      disableClearable={true}
      size='small'
      fullWidth={true}
      renderInput={(params) =>
        <TextField {...params}
          className={className}
          label="timezone"
          variant={variant} />}
    />
  )
}

export default TimezoneSelect
import { makeStyles, Theme } from "@material-ui/core/styles";

const useFormsPublicStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    alignSelf: "center",
    maxWidth: 300,
    margin: theme.spacing(3, 0, 3, 0),
  },
  mainContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  title: {
    color: theme.palette.grey[200],
    textAlign: "center",
    marginBottom: theme.spacing(1),
    "& p": {
      fontSize: theme.typography.fontSize * 1.25,
    }
  },
  textField: {
    margin: theme.spacing(1, 0, .5, 0),
    "& .MuiFilledInput-root": {
      borderRadius: 25,
      backgroundColor: theme.palette.grey[200],
      paddingLeft: theme.spacing(2),
    },
    "&:hover .MuiFilledInput-root": {
      backgroundColor: theme.palette.grey[50],
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: theme.palette.grey[50],
      "& .MuiFilledInput-notchedOutline": {
        borderColor: theme.palette.primary.light,
      },
      "& .MuiFilledInput-input": {
        color: theme.palette.grey[900],
      },
    },
    "& .MuiFilledInput-input": {
      color: theme.palette.grey[700],
    },
    "&:hover .MuiFilledInput-input": {
      color: theme.palette.grey[900],
    },
    "& .MuiInputLabel-filled": {
      color: theme.palette.grey[700],
      paddingLeft: theme.spacing(1),
    },
    "& .MuiInputLabel-filled.Mui-focused": {
      paddingLeft: theme.spacing(1),
    },
    "& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after": {
      border: 0,
    },
    "& .MuiAutocomplete-input": {
      paddingLeft: theme.spacing(1),
    }
  },
  formHelperText: {
    paddingLeft: theme.spacing(2),
  },
  button: {
    borderRadius: 20,
  },
  link: {
    textAlign: "center",
    marginTop: theme.spacing(.5),
    "& a": {
      textDecoration: "none",
      color: theme.palette.grey[300],
      fontWeight: theme.typography.fontWeightLight,
    }
  },
  checkbox: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  checkboxLabel: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}))

export default useFormsPublicStyles;
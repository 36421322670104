import { FC } from "react";
import { Card, CardHeader, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Badge } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UsageReportData } from "./usageReportData.model";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { durationToString } from '../utils/durationToString';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 5,
  },
  table: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1)
    },
  },
  tableContainer: {
    maxHeight: 250,
  }
}));

type Props = {
  title: string,
  entity: string,
  usageData: UsageReportData[]
}

const ReportUsageTable: FC<Props> = ({ title, entity, usageData }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        align='center'
        titleTypographyProps={{ variant: 'h6' }}
        title={title}
      />
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center'>#</TableCell>
              <TableCell>{entity}</TableCell>
              <TableCell>Horas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usageData.map((data, index) =>
              <TableRow key={`${data.name}-usage-${index}`}>
                <TableCell align='center'>{index + 1}</TableCell>
                <TableCell>{data.name}</TableCell>
                <TableCell>
                  <Badge badgeContent={durationToString(data.usage)} color="primary">
                    <AccessTimeIcon />
                  </Badge>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ReportUsageTable;
import "moment/locale/es";
import moment, { Moment } from "moment";
import { useEffect, useCallback, useState, FC } from "react";
import { Box } from "@material-ui/core";

import useSnackBars from "../../components/commons/snackbar/SnackbarHook";
import ConfirmDialog from "../../components/commons/dialogs/ConfirmDialog";
import FilterInput from "../../components/commons/filterInput/FilterInput";
import EmptyResults from "../../components/commons/empty-results/EmptyResults";
import { Paginated } from "../../components/commons/Paginated";

import useCancelToken from "../../hooks/useCancelToken";
import { useErrorHandler } from "../../components/errors/ErrorBoundary";
import { Booking } from "../../components/booking/booking.model";
import { bookingService } from "../../_services/booking.service";
import BookingsList from "../../components/booking/BookingList";
import { useCurrentBranch } from "../../components/commons/currentbranch/CurrentBranchHook";
import ResourceTypeSelect from "../../components/commons/selects/ResourceTypeSelect";
import { ResourceType } from "../../components/resource/resourceType.model";
import { resourceTypeService } from "../../_services/resourcetype.service";
import { Floor } from "../../components/floor/floor.model";
import { floorService } from "../../_services/floor.service";
import FloorSelect from "../../components/commons/selects/FloorSelect";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MomentUTCUtils } from "../../components/commons/utils/MomentUTCUtils";

require("moment/locale/es.js");

type Order = "asc" | "desc";

const AdminBooking: FC = () => {
  const { currentBranch } = useCurrentBranch();
  const { getCancelToken, isCancel } = useCancelToken();
  const { showError, showSuccess } = useSnackBars();
  const handleError = useErrorHandler();
  const handleApiError = useCallback(
    async (errors: any) => {
      errors.api?.msg ? showError(errors.api.msg) : console.error(errors);
    },
    [showError]
  );

  /* FETCH ResourceTypes */
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>(null);
  const fetchResourceTypes = useCallback(async () => {
    try {
      const cancelToken = getCancelToken();
      const resTypes = await resourceTypeService.availableList(cancelToken);
      setResourceTypes(resTypes);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error
          ? handleError(error)
          : handleApiError(error.errors);
      }
    }
  }, [getCancelToken, handleApiError, handleError, isCancel]);

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  /* FETCH Floors */
  const [floors, setFloors] = useState<Paginated<Floor>>(null);
  const fetchFloors = useCallback(async () => {
    try {
      const cancelToken = getCancelToken();
      const floors = await floorService.list(
        "",
        0,
        Number.MAX_SAFE_INTEGER,
        cancelToken
      );
      setFloors(floors);
      setFloor(null);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error
          ? handleError(error)
          : handleApiError(error.errors);
      }
    }
  }, [getCancelToken, handleApiError, handleError, isCancel]);

  useEffect(() => {
    fetchFloors();
  }, [currentBranch, fetchFloors]);

  /* HANDLE ResourceType change */
  const [resourceType, setResourceType] = useState<ResourceType>(null);
  const handleResourceTypeChange = useCallback(async (type: ResourceType) => {
    setResourceType(type);
  }, []);

  /* HANDLE Floor change */
  const [floor, setFloor] = useState<Floor>(null);
  const handleFloorChange = useCallback(async (floor: Floor) => {
    setFloor(floor);
  }, []);

  /* HANDLE Query */
  const [query, setQuery] = useState<string>("");
  const handleSearch = (query: string) => {
    setQuery(query);
  };

  /* HANDLE TABLE CHANGES */
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Booking>("bookingStart");
  const handleSort = async (
    e: React.MouseEvent<unknown>,
    property: keyof Booking
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  /* HANDLE DATES */
  const [dateFrom, setDateFrom] = useState<Date>(new Date(Date.now()));
  const onChangeDateFrom = (date: Moment | null) => {
    setDateFrom(date.toDate());
  };

  /* FETCH BOOKINGS */
  const [bookings, setBookings] = useState<Paginated<Booking>>(null);
  const fetchBookings = useCallback(
    async (offset: number, limit: number) => {
      try {
        const cancelToken = getCancelToken();
        const bookings = await bookingService.list(
          query,
          offset,
          limit,
          order,
          orderBy,
          resourceType?.id,
          floor?.id,
          dateFrom,
          cancelToken
        );
        setBookings(bookings);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error
            ? handleError(error)
            : handleApiError(error.errors);
        }
      }
    },
    [
      query,
      order,
      orderBy,
      resourceType,
      floor,
      dateFrom,
      getCancelToken,
      handleApiError,
      handleError,
      isCancel,
    ]
  );

  useEffect(() => {
    fetchBookings(0, 10);
  }, [fetchBookings, currentBranch]);

  const handleChangePage = (page: number) => {
    fetchBookings(page * bookings.limit, bookings.limit);
  };

  const handleChangeRowsPerPage = (rows: number) => {
    fetchBookings(0, rows);
  };

  /* HANDLE BOOKING FINISH */
  const handleFinish = async (booking: Booking) => {
    try {
      const cancelToken = getCancelToken()
      const terminateDate = moment().startOf('second').toDate();
      await bookingService.terminateBooking(booking.id, terminateDate, cancelToken)
      fetchBookings(0, 10)
      showSuccess("Recurso liberado")
    } catch (error) {
      error instanceof Error
        ? handleError(error)
        : handleApiError(error.errors);
    }
  }

  /* HANDLE BOOKING DELETE */
  const handleDelete = async (booking: Booking) => {
    try {
      const res = await bookingService.remove(booking.id);
      showSuccess(res.msg);
      fetchBookings(bookings.offset, bookings.limit);
    } catch (error) {
      error instanceof Error
        ? handleError(error)
        : handleApiError(error.errors);
    }
  };

  const [booking, setBooking] = useState<Booking>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const closeDeleteDialog = () => setOpenDelete(false);
  const openDeleteDialog = (booking: Booking) => {
    setBooking(booking);
    setOpenDelete(true);
  };

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box display="flex" flexGrow="1">
          <FilterInput value={query} onSearch={handleSearch} />
          {resourceTypes && floors && (
            <>
              <Box>
                <ResourceTypeSelect
                  types={resourceTypes}
                  defaultValue={resourceType}
                  onChange={handleResourceTypeChange}
                />
              </Box>
              <Box>
                <FloorSelect
                  floors={floors.docs}
                  showAll={true}
                  defaultValue={floor}
                  onChange={handleFloorChange}
                  enableAll={true}
                />
              </Box>
              <Box marginLeft={1} marginTop={-1}>
                <MuiPickersUtilsProvider
                  utils={MomentUTCUtils}
                  libInstance={moment}
                >
                    <DatePicker
                      format="DD/MM/yy"
                      margin="dense"
                      variant="inline"
                      inputVariant="outlined"
                      label="Desde"
                      value={moment.utc(dateFrom)}
                      onChange={onChangeDateFrom}
                    />
                </MuiPickersUtilsProvider>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {!bookings || bookings.totalDocs === 0 ? (
        <EmptyResults />
      ) : (
        <BookingsList
          bookings={bookings}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          onFinish={handleFinish}
          onDelete={openDeleteDialog}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <ConfirmDialog<Booking>
        open={openDelete}
        holder={booking}
        title="Eliminando Reserva"
        subtitle="¿Deseas continuar con la eliminación?"
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
};

export default AdminBooking;

import { makeStyles, Theme } from '@material-ui/core/styles';

const useTableStyles = makeStyles((theme: Theme) => ({
  tablePagination: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.grey[50],
  },
  tableContainer: {
    maxHeight: 'calc(100% - 40px - 16px)', // window - searchBar - searchBarPadding
  },
  table: {
    minWidth: 650,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.grey[600],
      '& .MuiTableCell-head': {
        color: theme.palette.common.white,
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        backgroundColor: theme.palette.grey[600],
        '& .MuiTableSortLabel-active': {
          color: theme.palette.common.white,
          fontWeight: 'bolder',
          '& .MuiTableSortLabel-icon': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiIconButton-root': {
        color: theme.palette.primary.light,
        padding: theme.spacing(.5),
        '& .MuiSvgIcon-root': {
          fontSize: theme.typography.fontSize * 1.5,
        },
      },
    },
  },
}));

export default useTableStyles;
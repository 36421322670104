import { Checkbox, TableRow, TableCell, Typography, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TimeRange } from './time.range.model';
import moment from 'moment-timezone';
import { FULL_WEEK_DAYS } from '../../_helpers/constants';

const STEP = 30; // minutes

type Props = {
  day: TimeRange,
  timezone: string;
  index: number;
  onChangeRange: (range: TimeRange) => void,
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: "50%"
  }
}));

const RangePicker: React.FC<Props> = ({ day, timezone, index, onChangeRange }: Props) => {
  const classes = useStyles();

  const handleBeginChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const begin = stringValueToMoment(event.target.value as string);
    let end = stringValueToMoment(moment(day.end).tz(timezone as string).format('HH:mm') as string).toDate();
    if (begin.isSameOrAfter(moment(end).tz(timezone as string))) {
      end = begin.clone().add(STEP, 'minutes').startOf('minute').toDate()
    }

    onChangeRange({ ...day, begin: begin.toDate(), end })
  }

  const handleEndChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const end = stringValueToMoment(event.target.value as string);
    let begin = stringValueToMoment(moment(day.begin).tz(timezone as string).format('HH:mm') as string).toDate();
    if (end.isSameOrBefore(moment(begin))) {
      begin = end.clone().subtract(STEP, 'minutes').startOf('minute').toDate()
    }

    onChangeRange({ ...day, begin, end: end.toDate() })
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRange({ ...day, laborable: event.target.checked })
  }

  const getSelectableHours = (limit: string) => {
    const hours: string[] = []
    const minHours = moment().startOf('day')
    const maxHours = moment().endOf('day')

    switch (limit) {
      case "start":
        minHours.add(STEP, "minutes");
        break;
      case "end":
        maxHours.subtract(STEP, "minutes");
        break;
    }

    while (minHours.isSameOrBefore(maxHours)) {
      hours.push(minHours.format("HH:mm"))
      minHours.add(STEP, "minutes")
    }

    return hours;
  }

  const stringValueToMoment = (value: string) => {
    const splitted = value.split(':')
    const hour = parseInt(splitted[0])
    const minutes = parseInt(splitted[1])

    return moment.tz(timezone as string).hour(hour).minute(minutes).startOf('minute');
  }

  return (
    <TableRow key={`row-${index}`}>
      <TableCell>
        <Checkbox
          checked={day.laborable}
          onChange={handleCheck}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </TableCell>
      <TableCell>
        <Typography>
          {FULL_WEEK_DAYS[day.weekDay]}
        </Typography>
      </TableCell>
      <TableCell>
        <Select
          className={classes.input}
          value={moment(day.begin).tz(timezone as string).format("HH:mm")}
          onChange={handleBeginChange}
          disabled={!day.laborable}>
          {day.laborable ?
            getSelectableHours('end').map((hour, i) => <MenuItem key={`row-${index}-cell-${i}`} value={hour}>{hour}</MenuItem>)
            : <MenuItem />
          }
        </Select>
      </TableCell>
      <TableCell>
        <Select
          className={classes.input}
          value={moment(day.end).tz(timezone as string).format("HH:mm")}
          onChange={handleEndChange}
          disabled={!day.laborable}>
          {day.laborable ?
            getSelectableHours("start").map((hour, i) => <MenuItem key={`row-${index}-cell-${i}`} value={hour}>{hour}</MenuItem>)
            : <MenuItem />
          }
        </Select>
      </TableCell>
    </TableRow>
  );
}




export default RangePicker;
import React from 'react';
import { AppBar, Avatar, Box, CssBaseline, Drawer, Grid, Hidden, IconButton, MuiThemeProvider, Toolbar, Typography, Tooltip, useTheme } from '@material-ui/core/';
import { ExitToApp, Person } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../commons/currentuser/CurrentUserHook';
import { UserRoutes } from '../routes/UserRoutes';
import CurrentBranchProvider from '../commons/currentbranch/CurrentBranchProvider';
import BranchesProvider from '../commons/branches/BranchesProvider';
import ConfigProvider from '../commons/configContext/ConfigProvider';
import userWebeeTheme from './userThemeConfig';
import LaborableProvider from '../commons/laborableContext/LaborableProvider';
import NextBookingProvider from '../commons/nextBookingsContext/NextBookingProvider';
import useUserDashboardStyles from '../../hooks/styles/userDashboardStyles';
import DateFilterProvider from '../booking/agenda/DateRangeProvider';
import MenuIcon from '@material-ui/icons/Menu';
import CalendarPanel from './CalendarPanel';
import NextBookingPanel from './NextBookingPanel';
import SideNavbar from './SideNavbar';
import { UserDrawer } from './UserDrawer';
import { ROLES } from '../../_helpers';
import { AssistantRoutes } from '../routes/AssistantRoutes';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const UserDashboard = () => {

  const theme = useTheme()
  const history = useHistory()
  const classes = useUserDashboardStyles()
  const { user, logout } = useCurrentUser()
  const { height, width } = useWindowDimensions()

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };

  const goToProfile = () => { history.push('/profile') }

  const getTitle = () => {
    switch (history.location.pathname) {
      case '/':
        return 'Mi Calendario'
      case '/blueprintAgenda':
        return 'Recursos'
      case '/resourceAgenda':
        return 'Favoritos'
      case '/profile':
        return 'Mi Perfil'
      default:
        return 'Mi Calendario'
    }
  }

  const drawer = (
    <div className={classes.drawerContainer}>
      <Box p={1} className={classes.stickyHeader}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center">
          <Grid>
            <Avatar
              alt="webee logo"
              src="/logo512.png"
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={8}>
            <UserDrawer user={user}></UserDrawer>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <CalendarPanel />
      </Box>
      <Box>
        <Box paddingX={4} className={classes.nextBookingContainer}>
          <NextBookingPanel />
        </Box>
      </Box>
    </div>
  )

  return (
    <MuiThemeProvider theme={userWebeeTheme}>
      <CurrentBranchProvider>
        <BranchesProvider>
          <ConfigProvider>
            <LaborableProvider>
              <DateFilterProvider>
                <NextBookingProvider>
                  <div className={classes.container}>
                    <CssBaseline />
                    <AppBar position="relative" className={classes.appBar} elevation={0} color="transparent">
                      <Toolbar>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={handleDrawerToggle}
                          className={classes.menuButton}>
                          <MenuIcon />
                        </IconButton>
                        <Typography variant="h4" noWrap className={classes.title}>
                          {getTitle()}
                        </Typography>
                        <Box className={classes.actions}>
                          <Tooltip title="Mi cuenta">
                            <IconButton onClick={goToProfile} color="primary" size="small">
                              <Person />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cerrar Sesión">
                            <IconButton onClick={logout} color="primary" size="small">
                              <ExitToApp />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer}>
                      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                      <Hidden mdUp implementation="css">
                        <Drawer
                          variant="temporary"
                          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                          open={mobileOpen}
                          onClose={handleDrawerToggle}
                          classes={{ paper: classes.drawerPaper }}
                          ModalProps={{ keepMounted: true }}>
                          {drawer}
                        </Drawer>
                      </Hidden>
                      <Hidden smDown implementation="css">
                        <Drawer
                          classes={{ paper: classes.drawerPaper }}
                          variant="permanent"
                          open>
                          {drawer}
                        </Drawer>
                      </Hidden>
                    </nav>
                    <Box
                      height={height - 64}
                      width={width - 320}
                      className={classes.wrapContent}>
                      <Box className={classes.appContent} padding={1}>
                        {user.role === ROLES.user && <UserRoutes />}
                        {user.role === ROLES.assistant && <AssistantRoutes />}
                      </Box>
                      <Box className={classes.toolbarContent} display='flex' flexDirection='column'>
                        <SideNavbar />
                      </Box>
                    </Box>
                  </div>
                </NextBookingProvider>
              </DateFilterProvider>
            </LaborableProvider>
          </ConfigProvider>
        </BranchesProvider>
      </CurrentBranchProvider>
    </MuiThemeProvider >
  );
}


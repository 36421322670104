import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'
import addClass from 'dom-helpers/addClass'
import removeClass from 'dom-helpers/removeClass'
import getWidth from 'dom-helpers/width'
import scrollbarSize from 'dom-helpers/scrollbarSize'

import { navigate } from 'react-big-calendar/lib/utils/constants'
import { inRange } from 'react-big-calendar/lib/utils/eventLevels'
import { isSelected } from 'react-big-calendar/lib/utils/selection'

import * as dates from 'react-big-calendar/lib/utils/dates'

function AgendaView({
    selected,
    getters,
    accessors,
    localizer,
    components,
    length,
    date,
    events,
}) {
    const headerRef = useRef(null)
    const dateColRef = useRef(null)
    const timeColRef = useRef(null)
    const contentRef = useRef(null)
    const tbodyRef = useRef(null)

    useEffect(() => {
        _adjustHeader()
    })

    const renderDay = (day, events, dayKey) => {
        const { event: Event, date: AgendaDate } = components

        events = events.filter(e =>
            inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors, localizer)
        )

        return events.map((event, idx) => {
            let title = accessors.title(event)
            let end = accessors.end(event)
            let start = accessors.start(event)

            const userProps = getters.eventProp(
                event,
                start,
                end,
                isSelected(event, selected)
            )

            let dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat')
            let first =
                idx === 0 ? (
                    <td rowSpan={events.length} className="rbc-agenda-date-cell">
                        {AgendaDate ? (
                            <AgendaDate day={day} label={dateLabel} />
                        ) : (
                            dateLabel
                        )}
                    </td>
                ) : (
                    false
                )

            return (
                <tr
                    key={dayKey + '_' + idx}
                    className={userProps.className}
                    style={userProps.style}
                >
                    {first}
                    <td className="rbc-agenda-time-cell">{timeRangeLabel(day, event)}</td>
                    <td className="rbc-agenda-event-cell">
                        {Event ? <Event event={event} title={title} /> : title}
                    </td>
                </tr>
            )
        }, [])
    }

    const timeRangeLabel = (day, event) => {
        let labelClass = '',
            TimeComponent = components.time,
            label = localizer.messages.allDay

        let end = accessors.end(event)
        let start = accessors.start(event)

        if (!accessors.allDay(event)) {
            if (dates.eq(start, end)) {
                label = localizer.format(start, 'agendaTimeFormat')
            } else if (dates.eq(start, end, 'day')) {
                label = localizer.format({ start, end }, 'agendaTimeRangeFormat')
            } else if (dates.eq(day, start, 'day')) {
                label = localizer.format(start, 'agendaTimeFormat')
            } else if (dates.eq(day, end, 'day')) {
                label = localizer.format(end, 'agendaTimeFormat')
            }
        }

        if (dates.gt(day, start, 'day')) labelClass = 'rbc-continues-prior'
        if (dates.lt(day, end, 'day')) labelClass += ' rbc-continues-after'

        return (
            <span className={labelClass.trim()}>
                {TimeComponent ? (
                    <TimeComponent event={event} day={day} label={label} />
                ) : (
                    label
                )}
            </span>
        )
    }

    const _adjustHeader = () => {
        if (!tbodyRef.current) return

        let header = headerRef.current
        let firstRow = tbodyRef.current.firstChild

        if (!firstRow) return

        let isOverflowing =
            contentRef.current.scrollHeight > contentRef.current.clientHeight

        let _widths = []
        let widths = _widths

        _widths = [getWidth(firstRow.children[0]), getWidth(firstRow.children[1])]

        if (widths[0] !== _widths[0] || widths[1] !== _widths[1]) {
            dateColRef.current.style.width = _widths[0] + 'px'
            timeColRef.current.style.width = _widths[1] + 'px'
        }

        if (isOverflowing) {
            addClass(header, 'rbc-header-overflowing')
            header.style.marginRight = scrollbarSize() + 'px'
        } else {
            removeClass(header, 'rbc-header-overflowing')
        }
    }

    let { messages } = localizer
    date = dates.startOf(date, 'month')
    let end = dates.endOf(date, 'month')

    let range = dates.range(date, end, 'day')

    events = events.filter(event => inRange(event, dates.startOf(date, 'day'), dates.endOf(end, 'day'), accessors, localizer))

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

    return (
        <div className="rbc-agenda-view">
            {events.length !== 0 ? (
                <React.Fragment>
                    <table ref={headerRef} className="rbc-agenda-table">
                        <thead>
                            <tr>
                                <th className="rbc-header" ref={dateColRef}>
                                    {messages.date}
                                </th>
                                <th className="rbc-header" ref={timeColRef}>
                                    {messages.time}
                                </th>
                                <th className="rbc-header">{messages.event}</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="rbc-agenda-content" ref={contentRef}>
                        <table className="rbc-agenda-table">
                            <tbody ref={tbodyRef}>
                                {range.map((day, idx) => renderDay(day, events, idx))}
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            ) : (
                <span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
            )}
        </div>
    )
}

AgendaView.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number.isRequired,

    selected: PropTypes.object,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
}

AgendaView.defaultProps = {
    length: 30,
}

AgendaView.range = (start, { length = AgendaView.defaultProps.length }) => {
    start = dates.startOf(start, 'month')
    let end = dates.endOf(start, 'month')
    return { start, end }
}

AgendaView.navigate = (date, action, { length = AgendaView.defaultProps.length }) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -1, 'month')

        case navigate.NEXT:
            return dates.add(date, 1, 'month')

        default:
            return date
    }
}

AgendaView.title = (start, { length = AgendaView.defaultProps.length, localizer }) => {
    let end = dates.endOf(start, 'month')
    return localizer.format({ start, end }, 'agendaHeaderFormat')
}

export default AgendaView

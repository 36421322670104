import { createTheme } from '@material-ui/core/styles';

const webeeTheme = createTheme({
  palette: {
    primary: {
      light: '#7592A3',
      main: '#517081',
      dark: '#344955',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#FEF7C4',
      main: '#F5BB35',
      dark: '#F2A42D',
      contrastText: '#000000',
    },
    error: {
      main: '#E9002A',
    },
    background: {
      paper: '#FAFAFA',
      default: '#EEEEEE',
    },
    action: {
      selected: '#8DA6B5',
      hover: '#5D7F92',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#F0F0F0',
        "&$focused": {
          backgroundColor: '#FAFAFA',
        },
      },
    },
    MuiButton: {
      root: {
        padding: 5,
        borderRadius: 10,
      },
      label: {
        textTransform: 'none',
        fontSize: 16,
      },
      containedPrimary: {
        color: '#FFFFFF',
        fontWeight: 'lighter',
        paddingInline: 16,
      },
      containedSecondary: {
        color: '#000000',
        fontWeight: 'lighter',
        paddingInline: 16,
      },
      outlined: {
        color: '#757575',
        fontWeight: 'lighter',
      },
    },
    MuiListItemIcon: {
      root: {
        color: '#F5F5F5',
      },
    },
  }
})

export default webeeTheme;
import { useEffect, useState, useCallback } from "react";
import { FC } from "react";
import { Company } from "../../components/companies/company.model";
import EditCompany from "../../components/companies/EditCompany";
import { companyService } from "../../_services/company.service";
import useCancelToken from "../../hooks/useCancelToken";
import { useErrorHandler } from "../../components/errors/ErrorBoundary";

const Companies: FC = () => {
  const [company, setCompany] = useState<Company>();
  const { getCancelToken, isCancel } = useCancelToken()
  const handleError = useErrorHandler()

  const fetchCurrentCompany = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const currentCompany = await companyService.getCurrentCompany(cancelToken)
        setCompany(currentCompany)
      } catch (error) {
        if(!isCancel(error)) {
          handleError(error)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    fetchCurrentCompany()
  }, [fetchCurrentCompany])

  return (
    <>
      {company ? <EditCompany company={company} /> : ""}
    </>
  );
}

export default Companies;
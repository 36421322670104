import React, { useEffect, useState, useCallback } from 'react';
import { Box } from "@material-ui/core";

import FAB from '../../components/commons/buttons/FAB';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import ResourceList from '../../components/resource/ResourceList';
import UpdateResource from '../../components/resource/UpdateResource';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from '../../components/commons/dialogs/ConfirmDialog';
import ResourceTypeSelect from '../../components/commons/selects/ResourceTypeSelect'

import { resourceService } from '../../_services/resource.service';
import { amenitiesService } from '../../_services/amenities.service'
import { resourceGroupService } from '../../_services/resourcegroup.service';
import { floorService } from '../../_services/floor.service';
import { Paginated } from '../../components/commons/Paginated';
import { ResourceGroup } from '../../components/resourceGroup/resourceGroup.model';
import { Resource } from '../../components/resource/resource.model';
import { Amenity } from '../../components/amenities/amenity.model';
import { Floor } from '../../components/floor/floor.model';
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import FloorSelect from '../../components/commons/selects/FloorSelect';
import useCancelToken from '../../hooks/useCancelToken'
import { ResourceType } from '../../components/resource/resourceType.model'
import { resourceTypeService } from '../../_services/resourcetype.service';

type Order = "asc" | "desc";

const Resources: React.FC = () => {
  const [resources, setResources] = useState<Paginated<Resource>>(null);
  const [resourceTypes, setResourceTypes] = React.useState<ResourceType[]>(null);
  const [amenities, setAmenities] = React.useState<Amenity[]>(null);
  const [floors, setFloors] = React.useState<Paginated<Floor>>(null);
  const [groups, setGroups] = React.useState<ResourceGroup[]>([]);
  const [query, setQuery] = useState<string>('');
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Resource>("name");
  const [resource, setResource] = useState<Resource>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [type, setType] = React.useState<ResourceType>(null);
  const [floor, setFloor] = useState<Floor>(null)

  const { getCancelToken, isCancel } = useCancelToken()
  const { showSuccess, showError } = useSnackBars()
  const { currentBranch } = useCurrentBranch();
  const handleError = useErrorHandler();

  const handleApiError = useCallback(
    (errors: any) => {
      errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
    }, [showError]);

  const fetchGroups = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const groups = await resourceGroupService.list("", cancelToken);
        setGroups(groups);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => { fetchGroups() }, [fetchGroups]);

  const fetchResourceTypes = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken();
        const resTypes = await resourceTypeService.availableList(cancelToken);
        setResourceTypes(resTypes)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel]);

  useEffect(() => {
    fetchResourceTypes();
  }, [fetchResourceTypes]);

  const fetchBranchDependencies = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const amenities = await amenitiesService.list("", cancelToken);
        const floors = await floorService.list('', 0, Number.MAX_SAFE_INTEGER, cancelToken);
        setFloors(floors)
        setFloor(null)
        setAmenities(amenities)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    fetchBranchDependencies()
  }, [currentBranch, fetchBranchDependencies])

  const fetchResources = useCallback(
    async (offset: number, limit: number) => {
      try {
        const cancelToken = getCancelToken()
        const resources = await resourceService.list(query, false, offset, limit, order, orderBy, floor ? floor.id : null, type ? type.id : null, cancelToken);
        setResources(resources)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, order, orderBy, currentBranch, type, floor])

  useEffect(() => {
    fetchResources(0, 10)
  }, [fetchResources])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const reloadResources = async (limit: number, offset: number) => {
    fetchResources(offset, limit)
    setQuery('')
  }

  const handleDelete = async (resource: Resource) => {
    try {
      const res = await resourceService.remove(resource.id)
      showSuccess(res.msg)
      reloadResources(resources.limit, resources.offset)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setResource(null)
  }

  const handleCreated = () => {
    setOpen(false)
    setResource(null)
    reloadResources(resources.limit, resources.offset)
  }

  const handleUpdate = (resource: Resource) => {
    setResource(resource)
    setOpen(true)
  }

  const handleCreate = () => {
    setOpen(true)
    setResource(null)
  }

  const handleChangePage = (page: number) => {
    reloadResources(resources.limit, (page) * resources.limit)
  }
  const handleChangeRowsPerPage = (rows: number) => {
    reloadResources(rows, 0)
  }

  const openDeleteDialog = (resource: Resource) => {
    setResource(resource);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  // Table sorting
  const handleSort = async (e: React.MouseEvent<unknown>, property: keyof Resource) => {
    try {
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(property);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleResourceTypeChange = useCallback(async (type: ResourceType) => {
    setType(type)
  }, [])

  const handleFloorChange = useCallback(async (floor: Floor) => {
    setFloor(floor)
  }, [])

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box display="flex" flexGrow="1">
          <FilterInput value={query} onSearch={handleSearch} />
          {resourceTypes && floors &&
            <>
              <Box>
                <ResourceTypeSelect
                  types={resourceTypes}
                  defaultValue={type}
                  onChange={handleResourceTypeChange} />
              </Box>
              <Box>
                <FloorSelect
                  floors={floors.docs}
                  showAll={true}
                  defaultValue={floor}
                  onChange={handleFloorChange}
                  enableAll={true} />
              </Box>
            </>
          }
        </Box>
        <FAB ariaLabel="Nuevo recurso" onClick={handleCreate} />
      </Box>
      {!resources || resources.totalDocs === 0 ? <EmptyResults /> :
        <ResourceList
          resources={resources}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          onUpdate={handleUpdate}
          onDelete={openDeleteDialog}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
      <UpdateResource
        resource={resource}
        open={open}
        amenities={amenities}
        types={resourceTypes}
        floors={floors?.docs}
        onClose={handleClose}
        onCreate={handleCreated}
        groups={groups}
      />
      <ConfirmDialog<Resource>
        open={openDelete}
        holder={resource}
        title="Eliminando Recurso"
        subtitle="¿Deseas continuar con la eliminación?"
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );
}
export default Resources

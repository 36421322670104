import baseAPI from './api';
import { Company } from '../components/companies/company.model';
import { Success } from "../components/commons/Success";
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (company: Company, loadDemo: boolean, timezone?: string) => {
  const response = await baseAPI.post(`companies`, { ...company, timezone, loadDemo })
  return response.data
}

const list = async () => {
  const response = await baseAPI.get('companies')
  return response.data as Company[]
}

const getCurrentCompany = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get("companies/current", axiosConfig);
  const company = response.data;
  if (company.image) company.image = response.data.image.path;
  return company as Company;
}

const getBookingStep = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get("companies/calendar-step", axiosConfig)
  return response.data.bookingStep as number
}

const update = async (company: Company) => {
  const res = await baseAPI.put(`companies`, company)
  return res.data as Success<Company>
}

const getLogo = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const res = await baseAPI.get('companies/logo', axiosConfig)
  return res.data
}

const updateLogo = async (image: File) => {
  const data = new FormData()
  data.append("image", image)
  const res = await baseAPI.patch('companies/logo', data)
  return res.data as Success<string>
}

const imageLink = (path: string) => {
  if (!!path) {
    return process.env.REACT_APP_API_URL + '/static/' + path
  }
  return '/image-no-available.png'
}

const getBySubdomain = async (subdomain: string) => {
  const res = await baseAPI.get(`companies/subdomain/${subdomain}`)
  return res.data as Company
}

export const companyService = {
  create,
  imageLink,
  update,
  getLogo,
  updateLogo,
  getCurrentCompany,
  list,
  getBookingStep,
  getBySubdomain
};

import React, { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FilterInputSmallStyle, FilterInputStyle } from './FloorSelect';
import { Branch } from '../../branches/branch.model';
import { BranchReference } from '../../branches/branch.reference.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 100,
    '& label': {
      backgroundColor: theme.palette.background.default,
      paddingInline: theme.spacing(.5),
    },
  },
  menuItem: {
    fontSize: theme.typography.fontSize,
    paddingBlock: 4,
    paddingInline: 8,
  },
}));

type Props = {
  branches: Branch[];
  defaultValue?: Branch;
  smallSize?: boolean;
  hideLabel?: boolean;
  showAll?: boolean;
  onChange: (branch: BranchReference) => void;
}

const BranchSelect: FC<Props> = ({ branches, defaultValue, smallSize, hideLabel, onChange }) => {

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<any>) => {
    onChange(event.target.value)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {!hideLabel && <InputLabel shrink htmlFor="branch-select">Sucursal</InputLabel>}
      <Select
        id="branch-select"
        labelId="branch-select"
        label='Sucursal'
        value={defaultValue}
        onChange={handleChange}
        input={smallSize ? <FilterInputSmallStyle /> : <FilterInputStyle />}
      >
        {branches.map((t: any, index) => (
          <MenuItem key={`${t.id}-${index}`} value={t} className={classes.menuItem}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default BranchSelect

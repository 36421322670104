import { FC } from 'react';
import CustomTab, { TabData } from '../../components/commons/tabs/CustomTab';
import Resources from '../resource/Resources'
import Groups from '../resource-groups/ResourceGroups'
import Extras from '../amenities/Amenities'

const ResourcesTab: FC = () => {

  const tabList: TabData[] = [
    { label: 'Recursos', component: <Resources /> },
    { label: 'Grupos', component: <Groups /> },
    { label: 'Extras', component: <Extras /> },
  ]

  return (
    <CustomTab tabs={tabList} />
  );
}

export default ResourcesTab
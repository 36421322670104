import { createTheme, Theme } from '@material-ui/core/styles';

const webeeTheme = (theme: Theme) => createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: '#517081',
      contrastText: '#000',
    },
    secondary: {
      main: '#F8D33C',
      light: '#FEFAEB',
    },
    background: {
      paper: '#E8F0F6',
      default: '#FFF',
    },
    error: {
      main: '#C1272D'
    }
  },
  shape: {
    ...theme.shape,
  },
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: 5,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: theme.palette.primary.main,
      },
      transitionContainer: {
        height: theme.spacing(2),
        '& p': {
          color: theme.palette.primary.dark,
          textTransform: 'capitalize',
          fontSize: theme.typography.fontSize * .95,
          fontWeight: theme.typography.fontWeightBold,
        },
      },
      dayLabel: {
        width: theme.spacing(4),
        textTransform: 'capitalize',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.secondary.main,
      },
      current: {
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
        border: '1px solid',
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        '& h4, & h6': {
          color: theme.palette.common.white,
        }
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: theme.palette.background.paper,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    ...theme.overrides,
  }
})

export default webeeTheme;
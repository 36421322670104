import baseAPI from './api';
import { RoomConfig } from '../components/rooms/roomConfig.model';

const getConfig = async () => {
  const response = await baseAPI.get(`roomconfig`);
  return response.data as RoomConfig
}

const create = async (config: any) => {
  await baseAPI.post(`roomconfig`, config);
}

const update = async (id: string, config: any) => {
  await baseAPI.put(`roomconfig/${id}`, config);
}

const updateLogo = async (logo: any) => {
  const data = new FormData()
  data.append("image", logo)
  await baseAPI.post(`roomconfig/logo`, data);
}
const deleteLogo = async () => {
  await baseAPI.delete(`roomconfig/logo`);
}



export const roomConfigService = {
  create,
  update,
  getConfig,
  updateLogo,
  deleteLogo
};
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Dialog, Grid, Typography } from '@material-ui/core';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EditIcon from '@material-ui/icons/Edit';
import { UserDialogActions, UserDialogContent, UserDialogTitle, useUserDialogStyle } from '../../commons/dialog/UserDialog';
import { CurrentUserContext } from '../../commons/currentuser/CurrentUserContext';
import BookingView from './BookingView';
import { Booking } from '../booking.model';
import CancelBookingButton from '../../commons/buttons/CancelBookingButton';
import UpdateBookingButton from '../../commons/buttons/UpdateBookingButton';
import RemoveBookingDialog, { RemoveAction } from './RemoveBookingDialog';
import { ROLES } from '../../../_helpers';
import BookingUpdateDialog from './BookingUpdateDialog';
import FinishBookingButton from '../../commons/buttons/FinishBookingButton';
import { TimerOff } from '@material-ui/icons';

type Props = {
  open: boolean;
  bookingInfo: Booking;
  onlyCancelYourOwn?: boolean;
  onSave: () => void;
  onFinishBooking: (bookingId: string) => void;
  onUpdateBooking: (booking: Booking) => void;
  onCancelBooking: () => void;
  onCancelRecurrentBooking: (bookingId: string, from?: Date) => void;
  onClose: () => void;
}

const BookingViewDialog: React.FC<Props> = ({ open, bookingInfo, onlyCancelYourOwn, onSave, onFinishBooking, onUpdateBooking, onCancelBooking, onCancelRecurrentBooking, onClose, }) => {
  const dialogClasses = useUserDialogStyle();
  const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
  const { user } = useContext(CurrentUserContext);

  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true);
  }

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  }

  const handleOnSave = () => {
    onSave();
    setOpenUpdateDialog(false);
  }

  const finishBooking = () => {
    onFinishBooking(bookingInfo.id)
    onClose()
  }

  const cancelBooking = () => bookingInfo.isRecurrent ? setOpenRemoveDialog(true) : onCancelBooking();

  const handleCloseRemoveDialog = (action: RemoveAction) => {
    setOpenRemoveDialog(false);
    switch (action) {
      case 'current':
        onCancelBooking();
        break;
      case 'from':
        onCancelRecurrentBooking(bookingInfo.id, bookingInfo.bookingStart);
        break;
      case 'all':
        onCancelRecurrentBooking(bookingInfo.id);
        break;
    }
  }

  const isOwner = () => bookingInfo?.user === user.id;

  const userDialogActions = () => {
    if (bookingInfo && (!onlyCancelYourOwn || isOwner() || user.role === ROLES.assistant)) {
      // Booking is coming
      if(moment(bookingInfo.bookingStart).isAfter(moment())) {
        return (
          <UserDialogActions>
            <CancelBookingButton
              startIcon={<EventBusyIcon />}
              onClick={cancelBooking} >
              Cancelar
            </CancelBookingButton>
            <UpdateBookingButton
              startIcon={<EditIcon />}
              onClick={handleOpenUpdateDialog} >
              Editar
            </UpdateBookingButton>
          </UserDialogActions>
        )
      } else if (moment().isBetween(moment(bookingInfo.bookingStart), moment(bookingInfo.bookingEnd))) {
        return (
          <UserDialogActions>
            <FinishBookingButton
              startIcon={<TimerOff />}
              onClick={finishBooking} >
              Finalizar
            </FinishBookingButton>
          </UserDialogActions>
        )
      }
    }
  }

  return (
    <>
      <Dialog
        classes={dialogClasses}
        onClose={onClose}
        aria-labelledby="booking-view-dialog"
        open={open}
        maxWidth={'sm'}
        fullWidth={true}>
        <UserDialogTitle id="booking-view-dialog" onClose={onClose} background={isOwner() ? 'owner' : 'external'}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={11}>
              <Typography variant="h5">{bookingInfo?.title || "Reservado"}</Typography>
            </Grid>
          </Grid>
        </UserDialogTitle>
        <UserDialogContent dividers>
          <BookingView booking={bookingInfo} />
        </UserDialogContent>
        {userDialogActions()}
      </Dialog>
      <RemoveBookingDialog open={openRemoveDialog} onClose={handleCloseRemoveDialog} />
      <BookingUpdateDialog
        open={openUpdateDialog}
        booking={bookingInfo}
        onClose={handleCloseUpdateDialog}
        onSave={handleOnSave}
        onUpdateBooking={onUpdateBooking} />
    </>
  )
}

export default BookingViewDialog
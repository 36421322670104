import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { Company } from '../../components/companies/company.model';
import { companyService } from '../../_services/company.service'
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment } from '@material-ui/core';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';
import TimezoneSelect from '../../components/commons/selects/TimezoneSelect';
import moment from 'moment-timezone';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';

export interface RegisterCompany {
  name: string,
  phone: string,
  domain: string,
  api: string,
  timezone: string
}

const CompanyRegister: React.FC = () => {
  const { refresh, logout, loginWithToken } = useCurrentUser();
  const classes = useFormsPublicStyles();
  const history = useHistory();
  const { showSuccess, showError } = useSnackBars();
  const [loadDemo, setLoadDemo] = useState<boolean>();
  const handleError = useErrorHandler();

  const { register, handleSubmit, errors, setError, setValue } = useForm<RegisterCompany>({
    defaultValues: { timezone: moment.tz.guess() },
  });

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  useEffect(() => {
    register('timezone', {
      validate: (value) => value.length || 'This is required.',
    });
  }, [register])


  const onSubmit = async (data: RegisterCompany) => {
    try {
      const company: Company = {
        id: null,
        name: data.name,
        domain: data.domain,
        phone: data.phone,
      }

      const res = await companyService.create(company, loadDemo, data.timezone)
      if (!!res && !!res.token) {
        loginWithToken(res.token)
      }
      showSuccess(res.msg)
      await refresh();

      history.push('/login')
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const onLogout = () => {
    logout();
    history.push('/')
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Crea tu compañía</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.name ? true : false}
                fullWidth
                id="name"
                label="Compañía"
                name="name"
                autoComplete="name"
                autoFocus
                inputRef={register({ required: { value: true, message: "La compañía es obligatorio" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.name && (errors.name as any)?.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.domain ? true : false}
                fullWidth
                id="domain"
                label="Dominio"
                name="domain"
                autoComplete="domain"
                inputRef={register({
                  required: { value: true, message: "El dominio es obligatorio" },
                  pattern: { value: new RegExp("^[a-z0-9]+$", "i"), message: "Sólo se aceptan letras y números" }
                })}
                InputProps={{
                  endAdornment: <InputAdornment position="start">.webee.ar</InputAdornment>,
                }}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.domain && errors.domain.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                type="number"
                margin="normal"
                size="small"
                error={errors.phone ? true : false}
                fullWidth
                id="phone"
                label="Teléfono"
                name="phone"
                autoComplete="phone"
                inputRef={register({
                  required: { value: true, message: "El teléfono es obligatorio" },
                  pattern: { value: new RegExp(/^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/), message: 'Ingresá un teléfono con código de área. Ejemplo: 1122334455' }
                })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.phone && errors.phone.message}
              </FormHelperText>
              <TimezoneSelect
                timezone={moment.tz.guess()}
                variant="filled"
                className={classes.textField}
                onChange={(timezone: string) => {
                  setValue('timezone', timezone as string)
                }}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.timezone && errors.timezone.message}
              </FormHelperText>
              <FormControl size="small">
                <FormControlLabel
                  className={classes.checkboxLabel}
                  label="Generar datos de prueba. Luego los podrá editar o borrar con el usuario administrador."
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={!!loadDemo}
                      onChange={() => setLoadDemo(!loadDemo)}
                    />
                  }
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Registrar
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <Link onClick={onLogout}>Cerrar sesión</Link>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CompanyRegister
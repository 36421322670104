import { Button } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const CancelBookingButton = withStyles((theme: Theme) => ({
  root: {
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 2),
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    '&$focused': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  label: {
    textTransform: 'capitalize',
  },
}))(Button);

export default CancelBookingButton;
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Checkbox, Divider } from '@material-ui/core';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { ListedUser } from '../../users/listedUser.model';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
  })
);

type Props = {
  title: React.ReactNode;
  items: ListedUser[];
  checked: ListedUser[];
  handleToggle: (value: ListedUser) => void;
  handleToggleAll: (items: ListedUser[]) => any;
  numberOfChecked: (items: ListedUser[]) => number;
}

const UserList: React.FC<Props> = ({ title, items, checked, handleToggle, handleToggleAll, numberOfChecked }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list" >
        {items.map((value: ListedUser) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={e => handleToggle(value)} >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.firstname} ${value.lastname}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
}

export default UserList;
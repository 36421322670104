import { BranchReference } from '../components/branches/branch.reference.model';
import { Branch } from '../components/branches/branch.model';
import baseAPI from './api';
import { configService } from './config.service';
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';

const list = async (query?: string, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if(query) {
    axiosConfig.params = { "q": query }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get("branches", axiosConfig)
  return response.data as Branch[]
}

const create = async (branch: Branch) => {
  const response = await baseAPI.post("branches", branch)
  return response.data as Success<string>
}
const update = async (branch: Branch) => {
  const { id } = branch
  delete branch.id
  const response = await baseAPI.put(`branches/${id}`, branch)
  return response.data as Success<string>
}

const remove = async (id: string) => {
  const response = await baseAPI.delete(`branches/${id}`)
  return response.data as Success<string>
}

const getReferences = async () => {
  try {
    const response = await baseAPI.get("branches/references")
    return response.data as BranchReference[]
  } catch (error) {
    // FIXME Me estaba tirando un error raro y muy aleatorio
    return []
  }
}

const loadDefaultBranch = async () => {
  const branchReferences = await getReferences()
  // Existe un branch en el servidor y valido que pertenezca a la compañía
  const currentBranch = await configService.fetchCurrentBranch()
  if (currentBranch) {
    const found = branchReferences?.find(branch => branch.id === currentBranch)
    if (found) {
      configService.refreshCurrentBranch(currentBranch, true)
      return currentBranch
    }
  }
  // No hay branches (para el caso de que aún no se creó la company). Limpio el local storage.
  if (!branchReferences.length) {
    return null
  }
  // Selecciono la primera sucursal por defecto
  await configService.refreshCurrentBranch(branchReferences[0].id)
  return branchReferences[0].id
}

export const branchService = {
  list,
  create,
  update,
  remove,
  getReferences,
  loadDefaultBranch,
}
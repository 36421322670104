import moment from 'moment';
import { Card, CardActions, CardContent, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Delete, Launch } from '@material-ui/icons';
import { FC, useCallback, useState } from 'react'
import { useHistory } from 'react-router';
import useBookingCardStyles from '../../hooks/styles/bookingCardStyles';
import { bookingService } from '../../_services/booking.service';
import { useCalendarContext } from '../booking/agenda/DateRangeProvider';
import { Booking } from '../booking/booking.model';
import RemoveBookingDialog, { RemoveAction } from '../booking/bookingForm/RemoveBookingDialog';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../errors/ErrorBoundary';
import { Resource } from '../resource/resource.model';

type NextBookingProps = {
  booking: Booking,
  onUpdateBookings: () => void
}

const NextBooking: FC<NextBookingProps> = ({ booking, onUpdateBookings }) => {
  const classes = useBookingCardStyles();
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const { calendarData, setCalendarData } = useCalendarContext();
  const { showError, showSuccess } = useSnackBars();
  const handleError = useErrorHandler();
  const history = useHistory()

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }

  const handleGoToBooking = () => {
    setCalendarData({ ...calendarData, date: booking.bookingStart })
    history.push(`/?id=${booking.id}`)
  }

  const handleCancelBooking = async () => booking.isRecurrent ? setOpenRemoveDialog(true) : onCancelBooking();

  const onCancelBooking = async () => {
    try {
      const res = await bookingService.cancel(booking.id);
      showSuccess(res.msg);
      onUpdateBookings();
      setCalendarData({ ...calendarData, date: calendarData.date });
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const onCancelRecurrentBooking = useCallback(
    async (bookingId: string, from?: Date) => {
      try {
        const res = await bookingService.cancelRecurent(bookingId, from);
        showSuccess(res.msg);
        onUpdateBookings();
        setCalendarData({ ...calendarData, date: calendarData.date });
      } catch (error) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }, [])

  const handleCloseRemoveDialog = (action: RemoveAction) => {
    setOpenRemoveDialog(false);
    switch (action) {
      case 'current':
        onCancelBooking();
        break;
      case 'from':
        onCancelRecurrentBooking(booking.id, booking.bookingStart);
        break;
      case 'all':
        onCancelRecurrentBooking(booking.id);
        break;
    }
  }

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography className={classes.resource} color="textPrimary">
            <strong>{(booking.resource as Resource).name}</strong>
          </Typography>
          <Typography className={classes.date} color="textPrimary">
            {moment(booking.bookingStart).format('dddd DD [de] MMMM')}
          </Typography>
          <Typography className={classes.time} color="textPrimary">
            {moment(booking.bookingStart).format('HH:mm')} - {moment(booking.bookingEnd).format('HH:mm [hs.]')}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Tooltip title="Ir a la reserva">
            <IconButton size="small" onClick={handleGoToBooking}><Launch /></IconButton>
          </Tooltip>
          <Tooltip title="Cancelar Reserva">
            <IconButton size="small" onClick={handleCancelBooking}><Delete /></IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <RemoveBookingDialog open={openRemoveDialog} onClose={handleCloseRemoveDialog} />
    </>
  )
}



export default NextBooking;
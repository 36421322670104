import { FC, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import UserTransferList from './users-container/UserTransferList';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { groupService } from '../../_services/group.service';
import { UserGroup } from '../user-groups/userGroup.model';
import { ListedUser } from '../users/listedUser.model';
import FormActionButtons from '../commons/buttons/FormActionButtons';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  }
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onGroupsChange: () => void;
  group?: UserGroup;
}

const GroupDialog: FC<Props> = ({ open, onClose, onGroupsChange, group }) => {
  const classes = useStyles();

  const defaultValues = {
    id: group?.id,
    name: group?.name,
    description: group?.description,
    users: group?.users
  }

  const [selectedUsers, setSelectedUsers] = useState<ListedUser[]>();

  const { showError, showSuccess } = useSnackBars();
  const { register, handleSubmit, reset, errors, setError } = useForm<UserGroup>({ defaultValues });
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  useEffect(() => {
    if (group && group.users) {
      setSelectedUsers(group.users)
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    reset(defaultValues);
    setSelectedUsers(group?.users)
    // eslint-disable-next-line
  }, [group])

  const handleSelectedUsers = (selected: ListedUser[]) => setSelectedUsers(selected);

  const onSubmit = async (groupData: UserGroup) => {
    try {
      if (!!group) {
        groupData.id = group.id;

        if (selectedUsers.length) {
          groupData.users = selectedUsers.map(u => u as ListedUser);
        } else {
          groupData.users = [];
        }

        const res = await groupService.update(groupData);
        showSuccess(res.msg)
      } else {
        const res = await groupService.create(groupData);
        showSuccess(res.msg)
      }

      onGroupsChange();
      onClose();

    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Dialog fullWidth onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {group ? "Actualizar" : "Crear"} grupo
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="name"
              label="Nombre del grupo"
              name="name"
              error={(errors.name ? true : false)}
              variant="outlined"
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un nombre" } })}
            />
            <FormHelperText error={true}>
              {errors?.name && (errors.name.message || "El nombre es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="description"
              label="Descripción"
              name="description"
              variant="outlined"
              size="small"
              inputRef={register({ required: false })} />
            <FormHelperText>
              {errors?.description && errors.description.message}
            </FormHelperText>
          </FormControl>
          {group &&
            <UserTransferList
              groupId={group.id}
              selectedUsers={selectedUsers}
              handleSelectedUsers={handleSelectedUsers}
            />
          }
          <FormActionButtons
            actionText={group ? "Actualizar" : "Crear"}
            onCancel={onClose}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default GroupDialog;
import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { NextBookingContext } from './NextBookingContext';
import useSnackBars from '../snackbar/SnackbarHook';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import { Booking } from '../../booking/booking.model';
import { bookingService } from '../../../_services/booking.service';
import { Moment } from 'moment';
import moment from 'moment';

const NextBookingProvider: FC = ({ children }) => {

  const [nextBookings, setNextBookings] = useState<Booking[]>(null);
  const [lastUpdate, setLastUpdate] = useState<Moment>(null);
  const handleError = useErrorHandler();
  const { showError } = useSnackBars();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetch = useCallback(async () => {
    try {
      setNextBookings(await bookingService.nextBookings());
      setLastUpdate(moment())
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [handleApiError, handleError])

  useEffect(() => {
    fetch();
    return () => { setNextBookings(null) }
  }, [fetch]);

  const updateNextBookings = useCallback(async () => {
    try {
      fetch()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [fetch, lastUpdate, handleApiError, handleError])

  const value = useMemo(() => ({ nextBookings, updateNextBookings }), [nextBookings, updateNextBookings]);

  return (
    <NextBookingContext.Provider value={value}>
      {children}
    </NextBookingContext.Provider>
  )
}

export default NextBookingProvider;
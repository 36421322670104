import { makeStyles, Theme } from '@material-ui/core/styles';

const useIconLinkButtonStyles = makeStyles((theme: Theme) => ({
  container: {
    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.background.default,
      border: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      borderRadius: 0,
      fontSize: theme.typography.fontSize,
      paddingInline: 0,
      paddingBlock: theme.spacing(1),
      '& .MuiIconButton-label': {
        '& .MuiSvgIcon-root': {
          marginInline: theme.spacing(1)
        }
      },
    },
    '& .MuiIconButton-root:hover': {
      borderColor: theme.palette.grey[700],
    }
  },
}));

export default useIconLinkButtonStyles;
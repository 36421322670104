import { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import { GlobalReportData } from './globalReport.model';
import { Person, MeetingRoom, EventSeat, HelpOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 180,
    margin: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 2px 10px 0 rgba(0,0,0,0.12)",
  },
  iconWrap: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    position: 'absolute',
    top: theme.spacing(-1.5),
    left: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
  },
  icon: {
    alignSelf: 'center',
    fontSize: theme.typography.fontSize * 2.5,
    color: theme.palette.common.white,
  },
  userBackground: {
    backgroundColor: theme.palette.primary.main
  },
  roomBackgroud: {
    backgroundColor: theme.palette.error.main
  },
  deskBackground: {
    backgroundColor: theme.palette.secondary.main
  }
}));

type Props = {
  data: GlobalReportData[]
}

const GlobalDataCards: FC<Props> = ({ data }) => {
  const classes = useStyles();

  const getCardIcon = (entity: string) => {
    switch (entity) {
      case "Usuarios":
        return <Person className={classes.icon} />;
      case "Sala":
        return <MeetingRoom className={classes.icon} />;
      case "Escritorio":
        return <EventSeat className={classes.icon} />;
      default:
        return <HelpOutline className={classes.icon} />;
    }
  }

  const getCardBackground = (entity: string) => {
    switch (entity) {
      case "Usuarios":
        return classes.userBackground;
      case "Sala":
        return classes.roomBackgroud;
      case "Escritorio":
        return classes.deskBackground;
    }
  }

  return (
    <Grid container justifyContent='center'>
      {data.map((entry, index) =>
        <Grid item className={classes.root} xs={2} key={`data-card-${index}`}>
          <Grid container justifyContent='space-around' alignItems='center'>
            <Grid item xs={6}>
              <Paper className={`${classes.iconWrap} ${getCardBackground(entry.name)}`} elevation={4}>
                {getCardIcon(entry.name)}
              </Paper>
            </Grid>
            <Grid item xs={6} container direction='column' alignItems='flex-end' style={{ paddingRight: 8 }}>
              <Grid item>
                <Typography variant='button'>{entry.name}</Typography>
              </Grid>
              <Grid item>
                <Typography variant='h6'>{entry.count}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default GlobalDataCards;
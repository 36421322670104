import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Booking } from '../booking.model'
import { bookingService } from '../../../_services/booking.service'
import { useErrorHandler } from '../../errors/ErrorBoundary';
import useSnackBars from '../../commons/snackbar/SnackbarHook';
import useCancelToken from '../../../hooks/useCancelToken'
import { Resource } from '../../resource/resource.model'
import BookingCreateDialog from '../bookingForm/BookingCreateDialog'
import ResourceCalendar from './ResourceCalendar'
import { useCalendarContext } from './DateRangeProvider'
import BookingViewDialog from '../bookingForm/BookingViewDialog';
import CalendarToolbarWithViews from '../bookingToolbar/CalendarToolbarWithViews'
import { useNextBooking } from '../../commons/nextBookingsContext/NextBookingHook'
import useCalendar from './useCalendar'
import { Favorite } from '@material-ui/icons'
import { Typography, Card, CardContent, Icon } from '@material-ui/core/';
import useBookingCardStyles from '../../../hooks/styles/bookingCardStyles';
import validateBookingChangeRange from "../../commons/utils/validateBookingChangeRange";
import { useLaborable } from '../../commons/laborableContext/LaborableHook';

require('moment/locale/es.js')

type Props = {
  resources: Resource[],
  onNewEvent?: (booking: Booking) => Promise<boolean>;
  onCancelBooking?: (bookingId: string) => Promise<boolean>;
}

const ResourceAgenda: React.FC<Props> = ({ resources, onNewEvent, onCancelBooking }) => {
  const [bookings, setBookings] = React.useState<Booking[]>([]);

  const [booking, setBooking] = useState<Booking>(null);
  const [openView, setopenView] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const { calendarData } = useCalendarContext();

  const { updateNextBookings } = useNextBooking();
  const { getCancelToken, isCancel } = useCancelToken();
  const { showError, showSuccess } = useSnackBars();
  const { calcTimeRange } = useCalendar();
  const { weeklySchedule, refreshLaborable } = useLaborable();
  const handleError = useErrorHandler();
  const classes = useBookingCardStyles();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
      if (field === "bookingStart" || field === "bookingEnd") {
        refreshLaborable();
      }
    })
  }

  const fetchData = useCallback(
    async (resources: Resource[], from: Date, to: Date) => {
      try {
        const cancelToken = getCancelToken()
        // TODO en realidad me tengo que traer todos los del recurso
        const bookings = await bookingService.resourceBookings(resources.map(r => r.id), from, to, cancelToken);
        setBookings(bookings);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    if (!!resources) {
      const range = calcTimeRange();
      fetchData(resources, range.from, range.to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, calendarData, fetchData])

  const handleSelectEvent = async (bookingId: string) => {
    try {
      const cancelToken = getCancelToken()
      const bookingInfo = await bookingService.bokingInfo(bookingId, cancelToken);
      setBooking(bookingInfo)
      setopenView(true)
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleSelectSlot = (_booking: Booking) => {
    try {
      setBooking(_booking);
      setOpen(true)
    } catch (error) {
      handleError(error)
    }
  }

  const closeDialog = () => {
    setOpen(false)
    setopenView(false)
    setBooking(null)
  }

  const cancelBooking = async () => {
    closeDialog();
    try {
      const cancelToken = getCancelToken()
      const res = await bookingService.cancel(booking.id, cancelToken);
      updateNextBookings()
      showSuccess(res.msg);
      onCancelBooking && onCancelBooking(booking.id)
      const range = calcTimeRange();
      fetchData(resources, range.from, range.to);
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleCancelRecurrentBooking = useCallback(
    async (bookingId: string, from?: Date) => {
      try {
        const cancelToken = getCancelToken();
        const res = await bookingService.cancelRecurent(bookingId, from, cancelToken);
        const range = calcTimeRange();
        fetchData(resources, range.from, range.to);
        setopenView(false);
        updateNextBookings();
        showSuccess(res.msg);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, []);

  const handleCreate = async (_booking: Booking) => {
    closeDialog();

    try {
      const cancelToken = getCancelToken()
      const res = await bookingService.create(
        { ..._booking, 'resource': (_booking.resource as Resource).id } as Booking,
        weeklySchedule.timezone,
        cancelToken
      );
      updateNextBookings()
      showSuccess(res.msg);
      onNewEvent && onNewEvent(_booking);
      const range = calcTimeRange();
      fetchData(resources, range.from, range.to);
      return true;
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleTimeChange = (
    begin: moment.Moment,
    end: moment.Moment,
    title?: string,
    isRecurrent?: boolean,
    recurrenceWeekdays?: boolean[],
    recurrenceEndDate?: Date) => {
    if (validateBookingChangeRange(begin, end, { ...booking, isRecurrent, recurrenceWeekdays }, weeklySchedule)) {
      setBooking({
        ...booking,
        bookingStart: begin.toDate(),
        bookingEnd: end.toDate(),
        title,
        isRecurrent,
        recurrenceWeekdays,
        recurrenceEndDate
      })
    } else {
      showError('No es posible realizar una reserva para la combinación de días.');
    }
  }

  const handleUpdate = async () => {
    try {
      const res = await bookingService.update(booking);
      updateNextBookings()
      showSuccess(res.msg);
      const range = calcTimeRange();
      fetchData(resources, range.from, range.to);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  /* HANDLE BOOKING FINISH */
  const handleFinish = async (bookingId: string) => {
    try {
      const cancelToken = getCancelToken()
      const terminateDate = moment().startOf('second').toDate();
      await bookingService.terminateBooking(bookingId, terminateDate, cancelToken)
      showSuccess("Recurso liberado")
      const range = calcTimeRange();
      fetchData(resources, range.from, range.to);
    } catch (error) {
      error instanceof Error
        ? handleError(error)
        : handleApiError(error.errors);
    }
  }

  return (
    <>
      {(resources.length !== 0) ?
        <ResourceCalendar
          resources={resources}
          bookings={bookings}
          components={{ toolbar: CalendarToolbarWithViews }}
          views={['day', 'week']}
          onNewEvent={handleSelectSlot}
          onEventSelect={handleSelectEvent}
          minimizeAllDay
        /> :
        <Card className={classes.adjust}>
          <CardContent >
            <Typography color='textSecondary' variant='subtitle1' align="center"> Tu lista de favoritos esta vacía. Marcá con <Icon className={classes.iconStyle} > <Favorite className={classes.favoriteIcon} /> </Icon> tus recursos preferidos </Typography>
          </CardContent>
        </Card>
      }
      {/* Crear una reserva en el recurso actual y en el rango horario seleccionado */}
      <BookingCreateDialog
        open={open}
        booking={booking}
        showUserSelect={true}
        onClose={closeDialog}
        onCreate={handleCreate}
        onChangeTime={handleTimeChange}
      />
      {/* Visualizar una reserva */}
      <BookingViewDialog
        open={openView}
        bookingInfo={booking}
        onSave={handleUpdate}
        onFinishBooking={handleFinish}
        onUpdateBooking={newBooking => setBooking(newBooking)}
        onCancelBooking={cancelBooking}
        onCancelRecurrentBooking={handleCancelRecurrentBooking}
        onClose={closeDialog}
        onlyCancelYourOwn
      />
    </>)
}

export default ResourceAgenda

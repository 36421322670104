import React, { useEffect, useState, useCallback } from 'react'
import { ResourceGroup } from '../../components/resourceGroup/resourceGroup.model'
import { Rule } from '../../components/rules/rule.model'
import { UserGroup } from '../../components/user-groups/userGroup.model'
import { rulesService } from '../../_services/rules.service'
import { groupService } from '../../_services/group.service'
import { resourceGroupService } from '../../_services/resourcegroup.service'
import RulesTable from '../../components/rules/RulesTable'
import { Link } from 'react-router-dom'
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import useCancelToken from '../../hooks/useCancelToken'


const Rules: React.FC = () => {

  const [resGroup, setResGroup] = useState<ResourceGroup[]>()
  const [userGroup, setUserGroup] = useState<UserGroup[]>()
  const [rules, setRules] = useState<Rule[]>(null);
  const { showSuccess, showError } = useSnackBars()
  const { getCancelToken, isCancel } = useCancelToken()
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchData = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const [_rules, _userGroup, _resGroup] = await Promise.all([rulesService.list(cancelToken), groupService.list('', cancelToken), resourceGroupService.list('', cancelToken)])
        setResGroup(_resGroup);
        setUserGroup(_userGroup);
        setRules(_rules);
      } catch (error) {
        if(!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
      fetchData();
    }, [fetchData]);


  const refreshRules = async () => {
    try {
      const cancelToken = getCancelToken()
      const _rules = await rulesService.list(cancelToken);
      setRules(_rules);
    } catch (error) {
      if(!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleNewRule = async (rGroup: string, uGroup: string) => {
    try {
      const res = await rulesService.create(uGroup, rGroup);
      showSuccess(res.msg)
      refreshRules();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleDeleteRule = async (rule: string) => {
    try {
      const res = await rulesService.deleteRule(rule);
      showSuccess(res.msg)
      refreshRules();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }

  }

  const hasGroups = () => {
    return ((!!resGroup && resGroup.length > 0) && (!!userGroup && userGroup.length > 0))
  }

  return (
    <>
      {!hasGroups() && !!rules &&
        <div>Para poder crear reglas de acceso es necesario definir <Link to={'/user'} style={{ textDecoration: 'none' }}>grupos de usuario</Link> y <Link to={'/resource'} style={{ textDecoration: 'none' }}>grupos de recursos</Link></div>
      }
      {hasGroups() && !!rules &&
        <RulesTable
          rules={rules}
          resourceGroups={resGroup}
          userGroups={userGroup}
          onNewRule={handleNewRule}
          onDeleteRule={handleDeleteRule}
        />
      }
    </>
  )
}

export default Rules

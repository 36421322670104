import React from 'react';
import { Chip, makeStyles, createStyles, Theme } from '@material-ui/core';
import { Resource } from '../../resource/resource.model';
import { ResourceGroup } from '../resourceGroup.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    displayResource: {
      width: "100%"
    },
    resource: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: "10px",
      flexWrap: 'wrap',
    },
    closeButton: {
      position: "absolute",
      right: '-25px',
    },
    editing: {
      position: 'absolute',
      top: '0',
      right: '0',
      transform: 'translate(116%, 0%)',
      backgroundColor: '#FAFAFA',
      padding: '0 0 1rem 0',
      zIndex: 2
    }
  }),
);

type Props = {
  group: ResourceGroup;
  groupResources: Resource[];
  removeResource: (group: ResourceGroup, resource: Resource) => void
}

const ResourcesContainer: React.FC<Props> = ({ group, groupResources, removeResource }) => {
  const classes = useStyles();

  const handleRemove = async (resourceId: string) => {
    const resource = groupResources.find( r => r.id === resourceId)
    removeResource(group, resource);
  }

  // El método onDelete del componente Chip requiere de una función que reciba un evento como parámetro
  const handleDelete = (event: any, resourceId: string) => handleRemove(resourceId);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.resource}>
          {groupResources ? groupResources.map(value => {
            return <Chip
              color="primary"
              label={value.name}
              key={group.id + "-" + value.id}
              onDelete={(e) => handleDelete(e, value.id)}
            />
          })
          : null
          }
        </div>
      </div>

    </React.Fragment>
  );

}

export default ResourcesContainer;
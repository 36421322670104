import { FC } from 'react';
import CustomTab, { TabData } from '../../components/commons/tabs/CustomTab';
import AdminBooking from '../booking/AdminBooking';

const BookingsTab: FC = () => {

  const tabList: TabData[] = [
    { label: 'Reservas', component: <AdminBooking /> },
  ]

  return (
    <CustomTab tabs={tabList} />
  );
}

export default BookingsTab
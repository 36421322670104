import baseAPI from './api';
import { Rule } from '../components/rules/rule.model'
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (userGroupId: string, resourceGroupId: string) => {
  const body = { userGroup: userGroupId, resourceGroup: resourceGroupId }
  const response = await baseAPI.post(`rules`, body)
  return response.data as Success<string>
}

const list = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`rules`, axiosConfig)
  return response.data as Rule[]
}

const deleteRule = async (id: string) => {
  const response = await baseAPI.delete(`rules/${id}`)
  return response.data as Success<string>
}

export const rulesService = {
  create,
  list,
  deleteRule
};
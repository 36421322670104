import moment, { Moment } from 'moment'
import React, { FC, useRef } from 'react'
import { Calendar, Components, momentLocalizer, SlotInfo, View } from 'react-big-calendar'
import { useLaborable } from '../../commons/laborableContext/LaborableHook'
import { Resource } from '../../resource/resource.model'
import { Booking } from '../booking.model'
import { useCalendarContext } from './DateRangeProvider'
import calendarStyles from '../../../hooks/styles/calendarStyles';
import useCalendar from './useCalendar'
import BookingEvent from './BookingEvent'
import clsx from 'clsx';
require('moment/locale/es.js')

type Props = {
  resources: Resource[],
  bookings: Booking[],
  views?: View[],
  hideHeader?: boolean,
  onNewEvent?: (booking: Booking) => void,
  onEventSelect?: (bookingId: string) => void,
  onSelectionStart?: () => void
  components?: Components<BookingEvent, Resource>
  currentBooking?: Booking,
  minimizeAllDay?: boolean
}

const ResourceCalendar: FC<Props> = ({ resources, bookings, components, views, hideHeader, onNewEvent, onEventSelect, onSelectionStart, currentBooking, minimizeAllDay }) => {
  const classes = calendarStyles();
  const { calendarData } = useCalendarContext();
  const { bookingStep } = useLaborable();
  const { messages, customFormat, handleSelecting, minHour, maxHour, handleSlotPropGetter, handleRangeChange, customSlotPropGetter } = useCalendar();

  const handleSelectEvent = async (event: BookingEvent, e: React.SyntheticEvent<HTMLElement>) => {
    onEventSelect && onEventSelect(event.booking.id)
  }

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    if (!handleSelecting(slotInfo)) {
      return
    }
    const startMoment = moment(slotInfo.start);
    const endMoment = moment(slotInfo.end);

    const _resource = resources.filter(r => r.id === slotInfo.resourceId);
    // Si no se encuentra el recurso es que paso algo raro y dejo que explote
    onNewEvent({ resource: _resource[0], bookingStart: startMoment.toDate(), bookingEnd: endMoment.toDate() } as Booking)
  }

  const handleSelectingResource = (range: any) => {
    if (!handleSelecting(range)) {
      return false;
    }
    const startMoment = moment(range.start);
    const endMoment = moment(range.end);
    const overlap = (start1: Moment, end1: Moment, start2: Moment, end2: Moment) => { return ((start1.isBefore(end2)) && (start2.isBefore(end1))) }

    for (const booking of bookings) {
      if (booking && booking === currentBooking) {
        continue;
      } else if ((range.resourceId && booking.id === range.resourceId) && overlap(startMoment, endMoment, moment(booking.bookingStart), moment(booking.bookingEnd)))
        return false;
    }
    return true;
  }

  const defaultView = () => {
    if (!views) {
      return calendarData.view
    }
    if (views.indexOf(calendarData.view) !== -1) {
      return calendarData.view;
    }
    return views[0]
  }

  const defaultDateRef = useRef<Date>(calendarData.date);

  return (
    <Calendar
      className={clsx(classes.root, hideHeader ? classes.hideHeader : '')}
      selectable
      date={calendarData.date}
      defaultDate={defaultDateRef.current}
      min={minHour()}
      max={maxHour()}
      step={bookingStep}
      localizer={momentLocalizer(moment)}
      events={bookings.map(b => new BookingEvent(b))}
      views={views}
      onSelectEvent={handleSelectEvent}
      onSelectSlot={handleSelectSlot}
      onSelecting={handleSelectingResource}
      slotPropGetter={handleSlotPropGetter}
      onRangeChange={handleRangeChange}
      defaultView={defaultView()}
      messages={messages}
      formats={customFormat}
      components={components}
      resources={resources}
      resourceIdAccessor='id'
      resourceTitleAccessor='name'
      eventPropGetter={customSlotPropGetter}
      allDayAccessor={() => false}
    />
  )
}

export default ResourceCalendar

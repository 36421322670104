import { FC, useState, useEffect } from 'react';
import { Box, ButtonGroup, Divider, FormControlLabel, Grid, IconButton, Paper, Slider, Switch, Tooltip } from '@material-ui/core';
import { Cancel, CheckBoxOutlineBlank, PhotoSizeSelectLarge, PhotoSizeSelectSmall, RadioButtonUnchecked, Save } from '@material-ui/icons';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';
import { useHistory } from 'react-router-dom';
import useSnackBars from "../commons/snackbar/SnackbarHook";
import { floorService } from '../../_services/floor.service';
import { resourceService } from '../../_services/resource.service'
import { Floor } from './floor.model';
import { Coords, Resource } from '../resource/resource.model';
import { ResourceGroup } from '../resourceGroup/resourceGroup.model';
import { ResourceType } from '../resource/resourceType.model';
import { Amenity } from '../../components/amenities/amenity.model';
import { drawingTypes, DrawingType, CIRCLE_TYPE } from '../resource/drawingTypes'
import FloorResources from './FloorResources';
import FloorMap from './FloorMap';
import FAB from '../commons/buttons/FAB';
import LeaveGuard from '../routes/leaveGuard';
import './FloorComponent.css'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import FloorResourceMapEdit from './FloorResource';
import FloorSelect from '../commons/selects/FloorSelect';

type FloorProps = {
  resourceTypes: ResourceType[];
  amenities: Amenity[];
  floor: Floor;
  floors: Floor[];
  groups: ResourceGroup[];
  onFloorChange: (floor: Floor) => void;
}

const FloorComponent: FC<FloorProps> = ({ resourceTypes, amenities, groups, floor, floors, onFloorChange }) => {
  const [fastPlacement, setFastPlacement] = useState<boolean>(false)
  const [freeEdition, setFreeEdition] = useState<boolean>(false)
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false)
  const [resources, setResources] = useState<Resource[]>(null)
  const [resource, setResource] = useState<Resource>(null)
  const [locateResource, setLocateResource] = useState<Resource>(null)
  const [drawingType, setDrawingType] = useState<DrawingType>(CIRCLE_TYPE)
  const [circleRadius, setCircleRadius] = useState(floor.circleSize)
  const { showError } = useSnackBars();
  const handleError = useErrorHandler();
  const { currentBranch, onUpdateBranch } = useCurrentBranch();
  const history = useHistory();
  const { height } = useWindowDimensions();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field];
      showError(e.msg);
    })
  }

  const fetchResources = async () => {
    try {
      const resourceList = await resourceService.assigned(floor.id);
      setResources(resourceList.docs);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  useEffect(() => {
    fetchResources();
  }, [floor])

  useEffect(() => {
    if (floor.branch !== currentBranch) {
      history.push('/company')
    }
  }, [currentBranch])


  const handleRadiusChange = (event: any, newValue: number | number[]) => {
    setCircleRadius(newValue as number)
  }

  const handleRadiusCommited = async (event: any, newValue: number | number[]) => {
    try {
      setCircleRadius(newValue as number)
      await floorService.updateResourceSize(floor.id, newValue as number);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const isDrawing = () => {
    return !!freeEdition || !!fastPlacement
  }

  const shouldShowList = () => {
    return !isDrawing() && !createDialogOpen && resources;
  }

  const updateCoords = async (resources: Resource[]) => {
    try {
      await resourceService.updateCoords(resources)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const shouldShowForm = () => {
    return !isDrawing() && !!createDialogOpen && resource;
  }

  const handleFinishPlacing = async () => {
    setFreeEdition(false);
    setFastPlacement(false);
    if (!createDialogOpen && resource) {
      saveFastPlacing(resource);
      setResource(null);
      fetchResources();
    } else if (!createDialogOpen && !resource) {
      await updateCoords(resources);
      fetchResources();
    }
  }

  const saveFastPlacing = async (resource: Resource) => {
    updateCoords([resource])
  }

  /******************** MAPA *****************************/

  const handleOnPlaced = (coords: Coords[]) => {

    setFastPlacement(false)
    setFreeEdition(true)

    setResource({ ...resource, coords })
    setResources([...resources.filter((r: Resource) => r.id !== resource.id), { ...resources.filter((r: Resource) => r.id === resource.id)[0], coords }])
  }

  const handleResourceMoved = (resourceId: string, coords: Coords[]) => {
    setResources([...resources.filter((r: Resource) => r.id !== resourceId), { ...resources.filter((r: Resource) => r.id === resourceId)[0], coords }])
    if (resource) {
      setResource({ ...resource, coords })
    }
  }

  const handleResourceSelected = (resource: Resource, coords: Coords[]) => {
    setResource({ ...resource });
    setCreateDialogOpen(true);
  }

  /******************** LIST ******************************/

  const handleFastPlacementList = (resource: Resource) => {
    setResource(resource);
    setFastPlacement(true)
  }

  const handleDeleteResource = async (resource: Resource) => {
    try {
      await resourceService.remove(resource.id);
      fetchResources();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleEditResource = async (resource: Resource) => {
    setResource(resource);
    setCreateDialogOpen(true);
  }

  /******************** FORM ******************************/

  const onCreateResource = async (data: Resource) => {
    await fetchResources();
    setCreateDialogOpen(false);
    setFastPlacement(false)
    setResource(null);
  }

  const closeCreateDialog = async () => {
    await fetchResources()
    setCreateDialogOpen(false);
    setResource(null);
  }

  const handlePlaced = (resource: Resource) => {
    setResources([...resources.filter((r: Resource) => r.id !== resource.id), resource])
    setFastPlacement(true)
    setResource(resource)
  }

  const handleLocateRequest = (resource: Resource) => {
    setLocateResource(resource);
  }

  const handleDeleteCoords = (_resource: Resource) => {
    setResources([...resources.filter((r: Resource) => r.id !== resource.id), { ...resource, coords: null }])
    setResource({ ..._resource, coords: null })
  }

  /******************* FAST PLACING **********************/

  const handleCancelFastEdition = () => {
    setFastPlacement(false);
    setFreeEdition(false);
    if (fastPlacement) {
      handleDeleteCoords(resource);
    }
    if (!createDialogOpen) {
      setResource(null)
      fetchResources()
    }
  }

  const handleDrawingTypeChange = (drawingType: DrawingType) => () => {
    setDrawingType(drawingType)

    setFastPlacement(true)
    setFreeEdition(false)

    setResource({ ...resource, coords: null })
    setResources([...resources.filter((r: Resource) => r.id !== resource.id), { ...resources.filter((r: Resource) => r.id === resource.id)[0], coords: null }])
  }

  /******************* FREE EDITION **********************/

  const handleFreeEdition = () => {
    if (freeEdition) {
      handleFinishPlacing()
    } else {
      setFreeEdition(true)
    }
  }

  /********************* NEW RESOURCE **********************/

  const handleNewResource = () => {
    setResource({ name: '', floor } as Resource)
    setResources([...resources, { name: '', floor } as Resource])
    setCreateDialogOpen(true);
  }

  const handleCancelLeave = () => {
    onUpdateBranch(floor.branch)
  }

  /*********************************************************/

  const handleFloorChange = (floor: Floor) => {
    onFloorChange(floor);
    setCircleRadius(floor.circleSize);
    setFastPlacement(false);
    setFreeEdition(false);
    setCreateDialogOpen(false);
    setResource(null);
  } 

  const calcHeight = () => height - 64 - 48; // window - barSpace - 

  return (
    <div style={{ height: height - 64 }}>
      {/* Main bar */}
      <Box display="flex" height={48}>
        {/* Float map filter bar */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FloorSelect smallSize floors={floors} defaultValue={floor} onChange={handleFloorChange} />
          </Grid>
          <Grid item>
            {!fastPlacement && !freeEdition && !createDialogOpen &&
              <FAB ariaLabel="Nuevo recurso" onClick={handleNewResource} />
            }
          </Grid>
        </Grid>
      </Box>
      {/* Main content */}
      <Box bgcolor="grey.300" position="relative" >
        {/* Float map bar */}
        <Box
          borderRadius={20}
          bgcolor="background.default"
          color="grey.600"
          paddingX={2}
          position="absolute"
          top={10}
          left={10}
          width={300}>
          <Grid container justifyContent="space-between">
            {/* Slider */}
            <Grid item xs={7} container spacing={1} alignContent="flex-end">
              <Grid item><PhotoSizeSelectSmall /></Grid>
              <Grid item xs>
                <Slider
                  min={10}
                  max={200}
                  value={circleRadius}
                  onChange={handleRadiusChange}
                  onChangeCommitted={handleRadiusCommited}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
              <Grid item><PhotoSizeSelectLarge /></Grid>
            </Grid>
            {/* Edit */}
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={freeEdition}
                    onChange={handleFreeEdition}
                    name="freeEdition"
                    color="primary"
                  />
                }
                label="Edición"
                labelPlacement="start"
                disabled={fastPlacement && freeEdition && createDialogOpen}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Float edition bar */}
        {(!!fastPlacement || freeEdition) &&
          <Box
            borderRadius={20}
            bgcolor="background.default"
            color="grey.600"
            paddingX={1}
            paddingY={2}
            position="absolute"
            top={10}
            right={10}>
            <Grid container direction="column" justifyContent="space-between">
              <Tooltip title="Guardar">
                <IconButton
                  aria-label="save"
                  color="primary"
                  size="small"
                  onClick={handleFinishPlacing}>
                  <Save />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar">
                <IconButton
                  aria-label="cancel"
                  color="inherit"
                  size="small"
                  onClick={handleCancelFastEdition}>
                  <Cancel />
                </IconButton>
              </Tooltip>
              {(!!fastPlacement || (freeEdition && !!resource)) &&
                <>
                  <Divider />
                  <ButtonGroup
                    aria-label="resource type"
                    orientation="vertical"
                  >
                    <Tooltip title={drawingTypes[0].name}>
                      <IconButton
                        aria-label="circle"
                        color={drawingType === drawingTypes[0] ? 'secondary' : 'default'}
                        size="small"
                        onClick={handleDrawingTypeChange(drawingTypes[0])}>
                        <RadioButtonUnchecked />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={drawingTypes[1].name}>
                      <IconButton
                        aria-label="rectangle"
                        color={drawingType === drawingTypes[1] ? 'secondary' : 'default'}
                        size="small"
                        onClick={handleDrawingTypeChange(drawingTypes[1])}>
                        <CheckBoxOutlineBlank />
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                </>
              }
            </Grid>
          </Box>
        }
        <Grid container>
          {/* Map */}
          <Grid item xs={!isDrawing() ? 8 : 12}>
            {resources &&
              <Box height={calcHeight()}>
                <FloorMap floor={floor} resources={resources} circleRadius={circleRadius}
                  freeEdition={freeEdition} placing={fastPlacement} drawingType={drawingType}
                  locate={locateResource} selectedResource={resource}
                  onPlaced={handleOnPlaced}
                  onResourceMoved={handleResourceMoved}
                  onResourceSelected={handleResourceSelected}
                />
              </Box>
            }
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={3}>
              <Box height={calcHeight()} bgcolor="grey.50" >
                {/* Resource List */}
                {shouldShowList() &&
                  <FloorResources
                    assigned={[...resources.filter(r => !!r.coords && r.coords.length > 0)]}
                    unassigned={[...resources.filter(r => !r.coords || r.coords.length === 0)]}
                    onLocate={handleLocateRequest}
                    onPlace={handleFastPlacementList}
                    onDelete={handleDeleteResource}
                    onEdit={handleEditResource}
                  />
                }
                {/* Resource Form */}
                {shouldShowForm() &&
                  <FloorResourceMapEdit
                    resource={resource}
                    types={resourceTypes}
                    place={true}
                    groups={groups}
                    amenities={amenities}
                    onCreate={onCreateResource}
                    onClose={closeCreateDialog}
                    onPlace={handlePlaced}
                    onFocusResource={handleLocateRequest}
                    onDeleteCoords={handleDeleteCoords}
                  />
                }
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <LeaveGuard
        when={createDialogOpen || freeEdition || fastPlacement}
        onCancel={handleCancelLeave}>
      </LeaveGuard>
    </div>
  );

}

export default FloorComponent

import React, { FC, useEffect, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import FilterInput from '../../components/commons/filterInput/FilterInput';

import FAB from '../../components/commons/buttons/FAB';
import BranchesList from '../../components/branches/BranchesList';
import BranchDialog from '../../components/branches/BranchDialog';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from "../../components/commons/dialogs/ConfirmDialog";
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import useCancelToken from '../../hooks/useCancelToken';

import { branchService } from '../../_services/branch.service';
import { Branch } from '../../components/branches/branch.model';
import { useBranches } from '../../components/commons/branches/BranchesHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook';


const Branches: FC = () => {

  const { currentBranch, onUpdateBranch } = useCurrentBranch();
  const { onBranchesUpdate } = useBranches();
  const [branches, setBranches] = useState<Branch[]>();
  const [query, setQuery] = useState<string>("");
  const [branch, setBranch] = useState<Branch>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const { showError, showSuccess } = useSnackBars();
  const { getCancelToken, isCancel } = useCancelToken()
  const handleError = useErrorHandler();

  const fetchBranches = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const branches = await branchService.list(query, cancelToken);
        setBranches(branches);
        return branches;
      } catch (error) {
        if(!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

  // Búsqueda y filtrado
  // eslint-disable-next-line
  useEffect(() => { fetchBranches() }, [fetchBranches]);

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const handleSearch = (query: string) => setQuery(query);

  const toggleDialog = () => setOpenDialog(!openDialog);

  const handleCreateDialog = () => {
    setBranch(null);
    toggleDialog();
  }

  const handleUpdateDialog = (branch: Branch) => {
    setBranch(branch);
    toggleDialog();
  }

  const openDeleteDialog = (branch: Branch) => {
    setBranch(branch);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  const handleUpdate = async () => {
    const branches = await fetchBranches()
    updateBranchReferences(branches)
  }

  const handleDelete = async (branch: Branch) => {
    try {
      // Remove
      const res = await branchService.remove(branch.id)
      showSuccess(res.msg)
      // Refresh 
      const branches = await fetchBranches()
      updateBranchReferences(branches)
      // Update current branch
      if(currentBranch === branch.id) {
        onUpdateBranch(branches[0].id)
      }
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const updateBranchReferences = (branches: Branch[]) => {
    // Evito llamar a getBranchReferences porque ya tengo los branches
    const branchReferences = branches.map(({ id, name }) => ({ id, name }));
    onBranchesUpdate(branchReferences)
  }

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nueva sucursal" onClick={handleCreateDialog} />
      </Box>
      {!branches || branches.length === 0 ? <EmptyResults /> :
        <BranchesList
          branches={branches}
          onDelete={openDeleteDialog}
          onUpdate={handleUpdateDialog}
        />
      }
      <BranchDialog
        open={openDialog}
        onClose={toggleDialog}
        onBranchesChange={handleUpdate}
        branch={branch}
      />
      <ConfirmDialog<Branch>
        holder={branch}
        open={openDelete}
        title="Eliminando Sucursal"
        subtitle="Esto eliminará la sucursal, todos los pisos asociados y todos los recursos asignados a estos pisos. ¿Deseas continuar con la eliminación?"
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  );

}

export default Branches;
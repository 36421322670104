import { FormControl, InputBase, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { FC } from 'react'
import { Floor } from '../../floor/floor.model'
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Amenity } from '../../amenities/amenity.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 100,
    '& label': {
      backgroundColor: theme.palette.background.default,
      paddingInline: theme.spacing(.5),
    },
  },
  menuItem: {
    fontSize: theme.typography.fontSize,
    paddingBlock: 4,
    paddingInline: 8,
  }
}));

export const FilterInputSmallStyle = withStyles((theme: Theme) => ({
  input: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 3,
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    padding: '3px',
    '&:focus': {
      backgroundColor: 'inherit',
      borderRadius: 3,
    },
  },
}))(InputBase);

export const FilterInputStyle = withStyles((theme: Theme) => ({
  input: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 0,
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    lineHeight: 1.5,
    padding: 7,
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
}))(InputBase);

type Props = {
  extras: Amenity[];
  defaultValue?: Floor;
  smallSize?: boolean;
  hideLabel?: boolean;
  showAll?: boolean;
  onChange: (floor: Floor) => void;
}

const ExtraSelect: FC<Props> = ({ extras, defaultValue, smallSize, hideLabel, showAll, onChange }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<any>) => {
    onChange(event.target.value)
  }

  const getDefaultValue = () => {
    return defaultValue
      ? (defaultValue && extras.find(t => t.id === defaultValue.id)) || ''
      : showAll
        ? ''
        : null
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {!hideLabel && <InputLabel shrink htmlFor="extra-select">Extra</InputLabel>}
      <Select
        id="extra-select"
        labelId="extra-select"
        label='Extra'
        displayEmpty={showAll}
        value={getDefaultValue()}
        onChange={handleChange}
        input={smallSize ? <FilterInputSmallStyle /> : <FilterInputStyle />}
      >
        {showAll && <MenuItem key="empty" value={''} className={classes.menuItem}>Extras</MenuItem>}
        {extras.map((t: any, index) => (
          <MenuItem key={`${t.id}-${index}`} value={t} className={classes.menuItem}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ExtraSelect

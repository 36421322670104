import baseAPI from './api';
import { Amenity } from '../components/amenities/amenity.model'
import { Success } from "../components/commons/Success";
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (resource: Amenity) => {
  const body = { name: resource.name }
  const response = await baseAPI.post(`amenities`, body)
  return response.data as Success<string>
}

const update = async (amenity: Amenity) => {
  const body = { name: amenity.name }
  const response = await baseAPI.patch(`amenities/${amenity.id}`, body)
  return response.data as Success<string>
}

const list = async (query?: string, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if(query) {
    axiosConfig.params = { 'q': query }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`amenities`, axiosConfig)
  return response.data as Amenity[]
}

const deleteAmenity = async (id: string) => {
  const response = await baseAPI.delete(`amenities/${id}`)
  return response.data as Success<string>
}

export const amenitiesService = {
  create,
  update,
  list,
  deleteAmenity
};
/** Hook para encapsular comportamiento de una búsqueda */

import { useRef, useState } from "react";

const useSearch = (value: string, onSearch: (query: string) => void) => {

  const [query, setQuery] = useState<string>(value);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const textfieldRef = useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") handleSearch();
  }

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  }

  const handleCancel = () => {
    textfieldRef.current.value = '';
    setQuery('');
    onSearch('');
    setShowCancel(false);
  }

  const handleSearch = () => {
    onSearch(query);
    setShowCancel(query !== "");
  }

  return { showCancel, textfieldRef, handleQueryChange, handleKeyPress, handleSearch, handleCancel }

}

export default useSearch;
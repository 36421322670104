import { FC, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, FormHelperText, Grid, Container, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useErrorHandler } from "../../components/errors/ErrorBoundary";
import { companyService } from '../../_services/company.service';
import { Company } from '../../components/companies/company.model';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useSubdomain } from '../../components/commons/subdomain/SubdomainHook';
import PrimaryButton from "../../components/commons/buttons/PrimaryButton";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 1, 0),
  }
}));

const ALLOWED_RECURRENCE_MONTHS = (function () {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months[i] = i + 1;
  }
  return months;
})();

const CustomizeCompany: FC = () => {
  const classes = useStyles();
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler();
  const { company, refreshCompany } = useSubdomain();

  const { handleSubmit, setError, control } = useForm<Company>({ defaultValues: company });

  const handleApiError = useCallback(
    (errors: any) => {
      Object.keys(errors).forEach((field) => {
        const e = errors[field]
        e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
      })
    }, [setError, showError]
  );

  const onSubmit = async (companyForm: Company) => {
    try {
      const res = await companyService.update({
        id: company.id,
        name: company.name,
        phone: company.phone,
        bookingStep: companyForm.bookingStep,
        recurrenceMonths: companyForm.recurrenceMonths
      });
      showSuccess(res.msg);
      refreshCompany();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Container component="main">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" justifyContent='center'>
          <Grid item xs={8} md={5}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="booking-step-selec-label">Tiempo mínimo de reservas</InputLabel>
              <Controller
                control={control}
                name="bookingStep"
                defaultValue={company.bookingStep}
                as={
                  <Select labelId="booking-step-select-label">
                    <MenuItem key="booking-step-15" value={15} selected={company.bookingStep === 15}>15</MenuItem>
                    <MenuItem key="booking-step-30" value={30} selected={company.bookingStep === 30}>30</MenuItem>
                    <MenuItem key="booking-step-60" value={60} selected={company.bookingStep === 60}>60</MenuItem>
                  </Select>
                }
              />
              <FormHelperText>(minutos)</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={8} md={5}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="recurence-months-select-label">Duración de reservas recurrentes</InputLabel>
              <Controller
                control={control}
                name="recurrenceMonths"
                defaultValue={company.recurrenceMonths}
                as={
                  <Select labelId="recurence-months-select-label">
                    {ALLOWED_RECURRENCE_MONTHS.map((month, index) => {
                      return <MenuItem key={`recurence-months-${index}`} value={month} selected={company.recurrenceMonths === month}>{month}</MenuItem>
                    })}
                  </Select>
                }
              />
              <FormHelperText>(meses)</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3} lg={3}>
            <PrimaryButton title='Actualizar' />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default CustomizeCompany;
import { makeStyles, Theme } from "@material-ui/core/styles";

export const calendarToolbarStyles = makeStyles((theme: Theme) => ({
    root: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& button': {
        '& span': {
          fontSize: theme.typography.fontSize - 2,
          textTransform: 'uppercase',
        }
      },
    },
    todayBtn: {
      borderRadius: 4,
      padding: 2,
    },
    navigateBtn: {
      marginLeft: theme.spacing(1),
      '& button': {
        '& svg': {
          fontSize: theme.typography.fontSize * 2,
        },
        padding: 0,
        minWidth: 0,
      },
      '& button:hover': {
        borderRadius: 4,
      },
    },
    middle: {
      flexGrow: 1,
      fontWeight: 'bold',
      fontSize: theme.typography.fontSize * 1.5,
      padding: theme.spacing(0, 1),
      textTransform: 'capitalize',
      textAlign: 'center',
    },
    right: {
    },
    views: {
      '& button': {
        padding: '.1rem .75rem',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 4,
        backgroundColor: 'transparent',
      },
      '& .Mui-selected': {
        backgroundColor: theme.palette.secondary.main,
      },
      '& button:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }));
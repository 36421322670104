import { useEffect, FC } from 'react'
import useSnackBars from '../snackbar/SnackbarHook'
import { useLocation } from 'react-router';
import { authenticationService } from '../../../_services/authentication.service';

const useQuery = () => new URLSearchParams(useLocation().search)

/**
 * Componente interceptor para mensajes enviados desde el back-end.
 * Cuando se hace una redirección desde el BE con res.redirect, si se agrega
 * el query param "msg", este componente atrapa dicho mensaje y lo muestra en
 * un snackbar que permanecerá abierto hasta que el usuario lo cierre.
 * Opcionalmente, se puede añadir el query param "msgseverity" para cambiar el
 * color de alerta, que por defecto será "success".
 * Por ejemplo: res.redirect("WEB_HOST/?msg=No pudimos procesar su solicitud&msgseverity=error");
 */
const GlobalMessageInterceptor: FC = ({ children }) => {
  const query = useQuery()
  const { showError, showInfo, showSuccess, showWarning } = useSnackBars()

  useEffect(() => {
    const msg = query.get("msg")

    if (msg) {
      switch (query.get("msgseverity")) {
        case "error":
          showError(msg);
          break;
        case "info":
          showInfo(msg);
          break;
        case "warning":
          showWarning(msg);
          break;
        default:
          showSuccess(msg);
          break;
      }
    }

    const action = query.get("action");

    if(action === "confirmsynchronize") {
      confirmSynchronize();
    }
  }, [])

  const confirmSynchronize = async () => {
    await authenticationService.refresh();
    window.location.reload();
  }

  return (
    <>
      {children}
    </>
  )
}

export default GlobalMessageInterceptor
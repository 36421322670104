import 'fontsource-roboto';
import { FC } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import SnackBarProvider from './components/commons/snackbar/SnackbarProvider';
import CurrentUserProvider from './components/commons/currentuser/CurrentUserProvider';
import GlobalMessageInterceptor from './components/commons/globalMessage/GlobalMessageInterceptor';
import DeviceDetector from './components/commons/device-detector/DeviceDetector';
import { ErrorBoundary } from './components/errors/ErrorBoundary';

import webeeTheme from './themeConfig';
import ErrorFallback from './pages/error/ErrorFallback';

const App: FC = () => {

  const createIssue = (error: Error, info: { componentStack: string }) => {
    console.log('Message: ', error.message)
    console.log('Name: ', error.name)
    console.log('Stack: ', error.stack)
    console.log('Component: ', info.componentStack)
  }

  return (
    <MuiThemeProvider theme={webeeTheme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={createIssue}>
        <SnackBarProvider>
          <GlobalMessageInterceptor>
            <DeviceDetector>
              <CurrentUserProvider />
            </DeviceDetector>
          </GlobalMessageInterceptor>
        </SnackBarProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
}

export default App;
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type Props<T> = {
  holder: T;
  open: boolean;
  title: string;
  subtitle?: string;
  onAccept: (t: T) => any;
  onClose: () => void;
}

function ConfirmDialog<T>({ holder, open, title, subtitle, onAccept, onClose }: Props<T>): React.ReactElement {

  const handleClose = () => onClose();

  const handleAccept = () => {
    onAccept(holder);
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      { subtitle &&
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subtitle}
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={handleAccept} color="primary">Aceptar</Button>
        <Button onClick={handleClose} color="secondary" autoFocus>Rechazar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
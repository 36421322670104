import React from 'react';
import { Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import RangePicker from './RangePicker'
import { TimeRange } from './time.range.model'
import clsx from 'clsx';
import useTableStyles from '../../hooks/styles/tableStyles';

type Props = {
  week: TimeRange[];
  timezone: string;
  onUpdate: (week: TimeRange[]) => Promise<void>;
}

const WeeklyScheduleForm: React.FC<Props> = ({ week, timezone, onUpdate }) => {
  const classes = useTableStyles()

  const handleChangeRange = (newDay: TimeRange) => {
    const weekUpdated = week.map(day => day.weekDay === newDay.weekDay ? newDay : day)
    onUpdate(weekUpdated)
  }

  return (
    <TableContainer component={Paper} elevation={2} className={clsx(classes.tableContainer)}>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Laborable</TableCell>
            <TableCell>Día</TableCell>
            <TableCell>Desde</TableCell>
            <TableCell>Hasta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {week.map((day, index) =>
            <RangePicker
              key={`range-picker-${index}`}
              day={day}
              index={index}
              timezone={timezone}
              onChangeRange={handleChangeRange}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WeeklyScheduleForm

import { FC, useEffect, useMemo, useState } from "react"
import { CurrentUserContext, CurrentUserContextType } from './CurrentUserContext';
import { PrivateRoutes } from "../../routes/PrivateRoutes";
import { PublicRoutes } from "../../routes/PublicRoutes";
import { authenticationService, Credentials, UserSession } from "../../../_services/authentication.service";
import SubdomainProvider from "../subdomain/SubdomainProvider";
import { useHistory, useLocation } from "react-router-dom";
import { removeQueryFromBrowser } from '../../../_helpers/routes'

const CurrentUserProvider: FC = () => {

  const [user, setUser] = useState<UserSession>(authenticationService.currentUserValue)
  const { search } = useLocation();
  const history = useHistory();
  const _updateCurrentUser = () => {
    const user = authenticationService.currentUserValue;
    setUser(user);
    return user;
  }

  const value = useMemo<CurrentUserContextType>(() => {

    const login = async (credentials: Credentials) => {
      await authenticationService.login(credentials)
      return _updateCurrentUser();
    }

    const loginWithToken = async (token: string) => {
      await authenticationService.loginWithToken(token)
      return _updateCurrentUser();
    }

    const logout = () => {
      setUser(null)
      authenticationService.logout()
    }

    const refresh = async () => {
      await authenticationService.refresh()
      _updateCurrentUser();
    }

    return { user, login, logout, refresh, loginWithToken }
  }, [user]);


  useEffect(() => {

    const queryParams = new URLSearchParams(search);
    const token = queryParams.get('subdomain')
    if (token) {
      queryParams.delete('subdomain')
      history.replace({
        search: queryParams.toString(),
      })
    }

    removeQueryFromBrowser(history.location.pathname)
    if (token && (!user || token !== user.token)) {
      authenticationService.loginWithToken(token)
      _updateCurrentUser();
      history.push('/')
    }

  }, [user, search, history])


  return (
    <CurrentUserContext.Provider value={value}>
      <SubdomainProvider>
        {!!user ? <PrivateRoutes /> : <PublicRoutes />}
      </SubdomainProvider>
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserProvider;
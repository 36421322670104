import { useEffect, useCallback, useState, FC } from 'react'
import { Amenity } from '../../components/amenities/amenity.model'
import { Floor } from '../../components/floor/floor.model'
import FloorComponent from '../../components/floor/FloorComponent'
import { amenitiesService } from '../../_services/amenities.service'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { resourceGroupService } from '../../_services/resourcegroup.service'
import { ResourceGroup } from '../../components/resourceGroup/resourceGroup.model'
import { ResourceType } from '../../components/resource/resourceType.model'
import { resourceTypeService } from '../../_services/resourcetype.service'
import { useErrorHandler } from '../../components/errors/ErrorBoundary'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook'
import useCancelToken from '../../hooks/useCancelToken'
import { floorService } from '../../_services/floor.service';
import { Paginated } from '../../components/commons/Paginated'

interface stateType {
  floor: Floor
}

const Designer: FC<RouteComponentProps> = () => {
  const [resourceTypes, setResourceTypes] = useState<ResourceType[]>(null);
  const [amenities, setAmenities] = useState<Amenity[]>(null);
  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[]>(null);
  const [floors, setFloors] = useState<Paginated<Floor>>(null);
  const [floor, setFloor] = useState<Floor>(null);
  const { state } = useLocation<stateType>();
  const history = useHistory()
  const { getCancelToken, isCancel } = useCancelToken()
  const { showError } = useSnackBars()
  const handleError = useErrorHandler()

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetchData = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const resTypes = await resourceTypeService.availableList(cancelToken)
        const amenities = await amenitiesService.list('', cancelToken)
        const resourceGroups = await resourceGroupService.list('', cancelToken)
        const floors = await floorService.list('', 0, Number.MAX_SAFE_INTEGER, cancelToken)
        setResourceTypes(resTypes)
        setAmenities(amenities)
        setResourceGroups(resourceGroups)
        setFloors(floors)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel])

  useEffect(() => {
    if (!state?.floor) {
      history.push('/company')
      return
    }
    setFloor(state.floor)
    fetchData()
  }, [fetchData])

  const handleFloorChange = useCallback((floor: Floor) => {
    setFloor(floor)
  }, [])

  return (
    <>
      {resourceTypes &&
        <FloorComponent
          floor={floor}
          floors={floors ? floors.docs : []}
          amenities={amenities}
          groups={resourceGroups}
          resourceTypes={resourceTypes}
          onFloorChange={handleFloorChange}
        />}
    </>
  )
}

export default Designer

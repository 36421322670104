import React, { useState } from 'react';
import { TextField, Box } from '@material-ui/core';
import { Resource } from '../resource/resource.model';
import FloorResourceList from './FloorResourceList';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    overflow: "auto",
  },
  sticky: {
    position: "sticky",
    left: 0,
    top: 0,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.white,
    zIndex: 2,
  },
}));

type ListProps = {
  assigned: Resource[],
  unassigned: Resource[],
  onPlace?: (res: Resource) => void
  onLocate?: (res: Resource) => void
  onEdit: (res: Resource) => void
  onDelete: (res: Resource) => void
}

const FloorResources: React.FC<ListProps> = ({ assigned, unassigned, onLocate, onPlace, onEdit, onDelete }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<string>('')

  const filterResources = (event: any) => {
    setFilter(event.target.value)
  }

  return (
    <Box className={classes.container}>
      <Box p={1} className={classes.sticky}>
        <TextField
          id="filled-basic"
          label="Filtrar recursos..."
          variant="outlined"
          size="small"
          fullWidth
          onChange={filterResources}
        />
      </Box>
      {!!assigned.length &&
        <FloorResourceList
          title="Recursos En Plano"
          resources={assigned}
          onPlace={onPlace}
          onLocate={onLocate}
          onEdit={onEdit}
          onDelete={onDelete}
          filter={filter}
        />
      }
      {!!unassigned.length &&
        <FloorResourceList
          title="Recursos Sin Asignar"
          resources={unassigned}
          onPlace={onPlace}
          onLocate={onLocate}
          onEdit={onEdit}
          onDelete={onDelete}
          filter={filter}
        />
      }
    </Box>
  )
}

export default FloorResources
import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'
import { Calendar, momentLocalizer, stringOrDate } from 'react-big-calendar'
import { useLocation } from 'react-router-dom'
import { Booking } from '../booking.model'
import { bookingService } from '../../../_services/booking.service'
import { parseQueryParams, removeQueryFromBrowser } from '../../../_helpers/routes'
import useSnackBars from '../../commons/snackbar/SnackbarHook';
import { useLaborable } from '../../commons/laborableContext/LaborableHook'
import useCancelToken from '../../../hooks/useCancelToken'
import calendarStyles from '../../../hooks/styles/calendarStyles';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import CalendarToolbarWithViews from '../bookingToolbar/CalendarToolbarWithViews'
import { useCalendarContext } from './DateRangeProvider'
import BookingViewDialog from '../bookingForm/BookingViewDialog'
import BookingCreateDialog from '../bookingForm/BookingCreateDialog';
import { useNextBooking } from '../../commons/nextBookingsContext/NextBookingHook';
import useCalendar from './useCalendar'
import BookingEvent from './BookingEvent'
import clsx from 'clsx';
import AgendaView from './AgendaView'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import validateBookingChangeRange from "../../commons/utils/validateBookingChangeRange";

require('moment/locale/es.js')

type Props = {
  onNewEvent: (booking: Booking) => void
  onFinishBooking?: (bookingId: string) => void
  onCancelBooking?: (bookingId: string) => void;
  onUpdateBooking?: (booking: Booking) => void;
  onCancelRecurrentBooking: (bookingId: string, from?: Date) => void;
}

const Agenda: React.FC<Props> = ({ onNewEvent, onFinishBooking, onCancelBooking, onCancelRecurrentBooking, onUpdateBooking }) => {
  const classes = calendarStyles();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [booking, setBooking] = useState<Booking>(null);
  const { bookingStep, weeklySchedule } = useLaborable();
  const [bookings, setBookings] = React.useState<Booking[]>([]);
  const { getCancelToken, isCancel } = useCancelToken();
  const { showError } = useSnackBars();
  const handleError = useErrorHandler();
  const { calendarData } = useCalendarContext();
  const { messages, customFormat, calcTimeRange, handleSelecting, minHour, maxHour, handleSlotPropGetter, handleRangeChange, customSlotPropGetter, allDayAccessor } = useCalendar();
  const { nextBookings } = useNextBooking();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }

  const fetchData = useCallback(
    async (from: Date, to: Date) => {
      try {
        const cancelToken = getCancelToken()
        const bookings = await bookingService.userBookings(from, to, cancelToken);
        setBookings(bookings);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  let location = useLocation();

  useEffect(() => {
    const fetch = async (id: string) => { handleSelectEvent(new BookingEvent({ id } as Booking), null) }
    const query = parseQueryParams(location)
    if (!!query && !!query.get('id')) {
      fetch(query.get('id'))
      removeQueryFromBrowser('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    const range = calcTimeRange();
    fetchData(range.from, range.to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, calendarData, nextBookings])

  const closeDialog = () => {
    setOpenCreate(false)
    setOpenView(false)
    setBooking(null)
  }

  const handleCreate = (_booking: Booking) => {
    onNewEvent(_booking)
    closeDialog();
  }

  const handleUpdate = async () => {
    await onUpdateBooking(booking);
  }

  const finishBooking = (_bookingId: string) => {
    onFinishBooking(_bookingId)
  }

  const cancelBooking = async () => {
    await onCancelBooking(booking.id);
    closeDialog();
    const range = calcTimeRange();
    fetchData(range.from, range.to)
  }

  const handleCancelRecurrentBooking = async (bookingId: string, from?: Date) => {
    await onCancelRecurrentBooking(bookingId, from);
    closeDialog();
    const range = calcTimeRange();
    fetchData(range.from, range.to)
  }

  const handleSelectEvent = async (event: BookingEvent, e: React.SyntheticEvent<HTMLElement>) => {
    try {
      const cancelToken = getCancelToken()
      const bookingInfo = await bookingService.bokingInfo(event.booking.id, cancelToken);
      setBooking(bookingInfo)
      setOpenView(true)
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  const handleSelectSlot = (slotInfo: {
    start: stringOrDate;
    end: stringOrDate;
    slots: Date[] | string[];
    action: 'select' | 'click' | 'doubleClick';
  }) => {
    if (!handleSelecting(slotInfo)) {
      return
    }
    const startMoment = moment(slotInfo.start);
    const endMoment = moment(slotInfo.end);

    setBooking({ bookingStart: startMoment.toDate(), bookingEnd: endMoment.toDate() } as Booking)
    setOpenCreate(true)
  }

  const handleTimeChange = (
    begin: moment.Moment,
    end: moment.Moment,
    title?: string,
    isRecurrent?: boolean,
    recurrenceWeekdays?: boolean[],
    recurrenceEndDate?: Date) => {
    const newBooking = { 
      ...booking, 
      title, 
      bookingStart: begin.toDate(), 
      bookingEnd: end.toDate(), 
      isRecurrent, 
      recurrenceWeekdays,
      recurrenceEndDate
     }
    if (validateBookingChangeRange(begin, end, newBooking, weeklySchedule)) {
      setBooking(newBooking)
    } else {
      showError('No es posible realizar una reserva para la combinación de días.');
    }
  }

  return (
    <>
      {/* Mi calendario */}
      {
        weeklySchedule &&
        <Calendar
          className={clsx(classes.root)}
          selectable
          min={minHour()}
          max={maxHour()}
          step={bookingStep}
          localizer={momentLocalizer(moment)}
          events={bookings.map(b => new BookingEvent(b))}
          defaultDate={calendarData.date}
          views={{ 'day': true, 'week': true, 'agenda': AgendaView as any }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          onSelecting={handleSelecting}
          slotPropGetter={handleSlotPropGetter}
          onRangeChange={handleRangeChange}
          date={calendarData.date}
          defaultView={calendarData.view}
          messages={messages}
          formats={customFormat}
          components={{ toolbar: CalendarToolbarWithViews }}
          length={31}
          eventPropGetter={customSlotPropGetter}
          allDayAccessor={allDayAccessor}
        />
      }
      {/* Crear una reserva en el rango horario seleccionado */}
      <BookingCreateDialog
        open={openCreate}
        booking={booking}
        textBtn="Ver recursos"
        iconBtn={<ArrowForwardIcon />}
        onClose={closeDialog}
        onCreate={handleCreate}
        onChangeTime={handleTimeChange}
      />
      {/* Visualizar una reserva */}
      <BookingViewDialog
        open={openView}
        bookingInfo={booking}
        onSave={handleUpdate}
        onFinishBooking={finishBooking}
        onUpdateBooking={newBooking => setBooking(newBooking)}
        onCancelBooking={cancelBooking}
        onCancelRecurrentBooking={handleCancelRecurrentBooking}
        onClose={closeDialog}
      />
    </>
  )
}

export default Agenda

import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { LaborableContext } from './LaborableContext';
import { WeeklySchedule } from '../../weeklySchedule/weekly.schedule.model';
import useSnackBars from '../snackbar/SnackbarHook';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import { weeklyScheduleService } from '../../../_services/weeklyschedule.service';
import { companyService } from '../../../_services/company.service';
import moment from 'moment-timezone';

const LaborableProvider: FC = ({ children }) => {

  const [weeklySchedule, setWeeklySchedule] = useState<WeeklySchedule>(null);
  const [bookingStep, setBookingStep] = useState<number>(30);
  const handleError = useErrorHandler();
  const { showError } = useSnackBars();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetch = useCallback(async () => {
    try {
      const _weeklySchedule = await weeklyScheduleService.getWeek();
      moment.tz.setDefault(_weeklySchedule.timezone as string);
      setWeeklySchedule(_weeklySchedule);
      setBookingStep(await companyService.getBookingStep());

    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [handleApiError, handleError])

  useEffect(() => {
    fetch();
    return () => { setWeeklySchedule(null) }
  }, [fetch]);

  const value = useMemo(() => ({ weeklySchedule, bookingStep, refreshLaborable: fetch }), [weeklySchedule, bookingStep, fetch]);

  return (
    <LaborableContext.Provider value={value}>
      {children}
    </LaborableContext.Provider>
  )
}

export default LaborableProvider;
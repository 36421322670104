import React, { FC } from 'react';
import { useForm } from "react-hook-form";
import { Dialog, FormControl, FormHelperText, Grid, Typography } from '@material-ui/core';
import { useErrorHandler } from '../errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { userService } from '../../_services/user.service';
import { UserDialogActions, UserDialogContent, UserDialogTitle, useUserDialogStyle } from '../commons/dialog/UserDialog';
import PrimaryButton from '../commons/buttons/PrimaryButton';

type PictureForm = {
  image: FileList
}

type Props = {
  open: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

const UpdatePicture: FC<Props> = ({ open, onUpdate, onClose }) => {
  const dialogClasses = useUserDialogStyle();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm<PictureForm>();
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (data: PictureForm) => {
    try {
      const res = await userService.updatePictureProfile(data.image[0])
      showSuccess(res.msg)
      onUpdate()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleClose = () => {
    clearErrors();
    onClose();
  }

  return (
    <Dialog
      classes={dialogClasses}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth='xs'
    >
      <UserDialogTitle id="simple-dialog-title" onClose={onClose}>
        <Typography variant="h5">Actualizar Imagen</Typography>
      </UserDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserDialogContent>
          <FormControl>
            <input type="file" ref={register} name="image" />
            <FormHelperText>
              {errors?.image && errors.image.message ? errors.image.message : ''}
            </FormHelperText>
          </FormControl>
        </UserDialogContent>
        <UserDialogActions>
          <Grid container justifyContent='flex-end'>
            <Grid item xs={6} lg={4}>
              <PrimaryButton title="Subir imagen" />
            </Grid>
          </Grid>
        </UserDialogActions>
      </form>
    </Dialog >
  )
}

export default UpdatePicture
import React, { useState, useEffect, useCallback } from 'react'
import WeeklyScheduleForm from '../../components/weeklySchedule/WeeklyScheduleForm'
import { weeklyScheduleService } from '../../_services/weeklyschedule.service'
import { TimeRange } from '../../components/weeklySchedule/time.range.model'
import { WeeklySchedule } from '../../components/weeklySchedule/weekly.schedule.model'
import { useErrorHandler } from '../../components/errors/ErrorBoundary'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook'
import moment from 'moment-timezone'
import TimezoneSelect from '../../components/commons/selects/TimezoneSelect'
import { Box, Grid } from '@material-ui/core'

const WeeklySchedules: React.FC = () => {
  const [weeklySchedule, setWeeklySchedule] = useState<WeeklySchedule>()
  const handleError = useErrorHandler();
  const { showSuccess, showWarning, showError } = useSnackBars();

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  useEffect(() => {
    let offsets = new Array<Number>();
    moment.tz.countries().forEach(c => {
      for (const tz of moment.tz.zonesForCountry(c, true)) {
        offsets.push(tz.offset)
      }
    })

    const fetchData = async () => {
      const response = await weeklyScheduleService.getWeek()
      response.week = response.week.sort((a: TimeRange, b: TimeRange) => a.weekDay > b.weekDay ? 1 : -1)
      setWeeklySchedule(response)
    }

    fetchData()
  }, []);

  const update = useCallback(async (week: TimeRange[], timezone: string) => {
    try {
      const response1 = await weeklyScheduleService.update(weeklySchedule.id, week, timezone)
      const response = await weeklyScheduleService.getWeek()
      response.week = response.week.sort((a: TimeRange, b: TimeRange) => a.weekDay > b.weekDay ? 1 : -1)
      setWeeklySchedule(response)
      showSuccess(response1.msg)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [weeklySchedule, handleApiError, handleError, showSuccess])

  const handleUpdate = useCallback(async (week: TimeRange[]) => {
    await update(week, weeklySchedule.timezone)
  }, [weeklySchedule, update])

  const handleTimezoneChange = useCallback(
    async (timezone: string) => {
      const newWeek = weeklySchedule.week.map((tr: TimeRange) => {
        return {
          ...tr,
          begin: moment.tz(tr.begin, weeklySchedule.timezone as string).tz(timezone as string, true).toDate(),
          end: moment.tz(tr.end, weeklySchedule.timezone as string).tz(timezone as string, true).toDate()
        };
      });
      showWarning('Las reservas existentes aún conservarán el timezone anterior. Seguro que desea continuar?', () => {}, async () => {
        await update(newWeek, timezone)
      })
    },
    [weeklySchedule, update, showWarning],
  )

  return (
    <>
      {weeklySchedule?.week &&
        <>
          <Box paddingBottom={1}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={8} md={6} lg={4}>
                <TimezoneSelect
                  timezone={weeklySchedule.timezone}
                  onChange={handleTimezoneChange} />
              </Grid>
            </Grid>
          </Box>
          <WeeklyScheduleForm
            week={weeklySchedule.week}
            timezone={weeklySchedule.timezone}
            onUpdate={handleUpdate}
          />
        </>
      }
    </>
  );
}

export default WeeklySchedules
import React from 'react'
import { ResourceGroup } from '../resourceGroup/resourceGroup.model'
import { UserGroup } from '../user-groups/userGroup.model'
import { Rule } from './rule.model'
import { Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import useTableStyles from '../../hooks/styles/tableStyles';

const styles = makeStyles((theme: Theme) => ({
  noBar: {
    maxHeight: "calc(100% - 8px)",
    overflow: 'auto',
  },
  sticky: {
    position: "sticky",
    left: 0,
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.common.white,
    zIndex: 1,
  },
}));

type Props = {
  resourceGroups: ResourceGroup[],
  userGroups: UserGroup[]
  rules: Rule[],
  onNewRule: (rGroup: string, uGroup: string) => void;
  onDeleteRule: (ruleId: string) => void;
}
const RulesTable: React.FC<Props> = ({ resourceGroups, userGroups, rules, onNewRule, onDeleteRule }) => {
  const classes = useTableStyles();
  const localClasses = styles();

  const isChecked = (rGroup: string, uGroup: string) => {
    return rules.some(r => r.resourceGroup.id === rGroup && r.userGroup.id === uGroup);
  }

  const handleRuleChange = (checked: boolean, rGroup: string, uGroup: string) => {
    if (checked) {
      onNewRule(rGroup, uGroup);
    } else {
      const rule = rules.filter(r => r.resourceGroup.id === rGroup && r.userGroup.id === uGroup);
      if (!rule || rule.length > 1 || rule.length === 0) {
        alert('Error de reglas - Informar al proveedor');
      } else {
        onDeleteRule(rule[0].id);
      }
    }
  }

  return (
    <>
      <TableContainer component={Paper} elevation={2} className={clsx(classes.tableContainer, localClasses.noBar)}>
        <Table
          className={classes.table}
          size="small"
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={userGroups.length}>Grupos de Usuarios</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Grupos Recursos</TableCell>
              {userGroups?.map((group: UserGroup) => (
                <TableCell align='center' key={group.id}>{group.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceGroups?.map((rGroup: ResourceGroup) => (
              <TableRow key={rGroup.id}>
                <TableCell align='center' className={localClasses.sticky}>{rGroup.name}</TableCell>
                {userGroups?.map((uGroup: UserGroup) => (
                  <TableCell align='center' key={uGroup.id}>
                    <Checkbox
                      checked={isChecked(rGroup.id, uGroup.id)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleRuleChange(event.target.checked, rGroup.id, uGroup.id) }}
                      inputProps={{ 'aria-label': 'primary checkbox' }} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default RulesTable

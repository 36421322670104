import React, { useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Chip, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { CenterFocusWeak, Delete, Gesture } from '@material-ui/icons';
import { resourceService } from '../../_services/resource.service';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { Coords, Resource } from "./resource.model";
import { ResourceGroup } from '../resourceGroup/resourceGroup.model';
import { Amenity } from "../amenities/amenity.model";
import { Floor } from "../floor/floor.model";
import FormActionButtons from '../commons/buttons/FormActionButtons';
import useSnackBars from "../commons/snackbar/SnackbarHook";
import useChipStyles from '../../hooks/styles/chipStyles';
import useIconLinkButtonStyles from '../../hooks/styles/iconLinkButtonStyles';
import { ResourceType } from '../../components/resource/resourceType.model'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 0, 2, 0)
  },
}));

export type EditProps = {
  onCreate: (data: Resource) => void
  onClose: () => void
  onPlace?: (resource: Resource) => void
  onFocusResource?: (data: Resource) => void
  onDeleteCoords?: (resource: Resource) => void
  resource: Resource
  types: ResourceType[]
  coordinates?: Coords[]
  floors?: Floor[]
  amenities: Amenity[]
  place?: boolean,
  groups: ResourceGroup[]
}

const ResourceForm: React.FC<EditProps> = ({ onCreate, onClose, onPlace, onFocusResource, onDeleteCoords, resource, types, amenities, place, coordinates, floors, groups }) => {
  const FAKE_ID = 'fake-id'
  const classes = useStyles()
  const chipStyles = useChipStyles()
  const iconButtonStyles = useIconLinkButtonStyles()
  const handleError = useErrorHandler()
  const { showError, showSuccess } = useSnackBars()

  const defaultValues = useCallback(() => {
    let actualFloor = '' as any;
    if (floors && floors.length === 1) {
      actualFloor = floors[0]
    } else if (floors) {
      actualFloor = resource?.floor ? floors.filter((e) =>
        e.id === resource.floor.id
      )[0] : '' as any;
    } else {
      actualFloor = resource?.floor ? resource.floor : '' as any;
    }
    return {
      id: resource?.id,
      name: resource?.name,
      description: resource?.description,
      type: resource?.type ? types.filter((e) =>
        e.id === resource.type.id
      )[0] : types[0] as any,
      capacity: resource?.capacity,
      floor: actualFloor,
      amenities: resource?.amenities ? amenities.filter((e) =>
        resource?.amenities?.some((ae) => e.id === ae.id)
      ) : [],
      groups: resource?.groups ?
        groups.filter(g => resource.groups.some(rg => g.id === rg.id))
        : []
    }
  }, [amenities, floors, groups, resource, types])

  const { register, handleSubmit, reset, errors, getValues, control, setError, clearErrors } = useForm<Resource>({
    defaultValues: defaultValues()
  });

  useEffect(() => {
    reset(defaultValues())
    clearErrors();
  }, [resource, defaultValues, clearErrors, reset])

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (data: Resource) => {
    try {
      data.id = resource?.id === FAKE_ID ? null : resource?.id;
      data.coords = coordinates ? coordinates : resource?.coords;
      data.floor = data.floor ? data.floor : resource.floor;

      if (!!data.id) {
        const res = await resourceService.update(data);
        showSuccess(res.msg)
      } else {
        const res = await resourceService.create(data);
        showSuccess(res.msg)
      }
      clearErrors();
      onCreate(data)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const actualData = () => {
    const { name, amenities, type, floor, groups, capacity } = getValues();
    const data = resource ? { ...resource } : {} as Resource;
    data['id'] = data['id'] ? data['id'] : FAKE_ID;
    data['name'] = name;
    data['capacity'] = capacity
    data['amenities'] = amenities;
    data['type'] = type;
    data['groups'] = groups;
    data['floor'] = floor ? floor : resource?.floor;
    return data
  }

  const handleClose = () => { onClose() }
  const handleLocate = () => { onPlace(actualData()) }
  const handleFocus = () => { onFocusResource(actualData()) }
  const handleDeleteCoords = () => { onDeleteCoords(actualData()) }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth size="small" className={classes.formControl}>
        <TextField
          id="name"
          label="Nombre"
          name="name"
          variant="outlined"
          size="small"
          error={(errors.name ? true : false)}
          inputRef={register({
            required: { value: true, message: "El nombre es requerido" },
            minLength: { value: 2, message: "Debe tener al menos 2 caracteres" }
          })}
        />
        <FormHelperText error={true}>
          {errors?.name && ((errors.name as any)?.message || "El nombre es requerido")}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth className={classes.formControl}>
        <TextField
          id="capacity"
          type="number"
          InputProps={{ inputProps: { min: 0, message: "Debe ingresar un número positivo" } }}
          label="Capacidad"
          name="capacity"
          variant="outlined"
          error={(errors.capacity ? true : false)}
          size="small"
          defaultValue="1"
          inputRef={register({ required: true })} />
      </FormControl>
      <FormHelperText error={true}>
        {errors?.capacity && ((errors.capacity as any)?.message || "Debe elegir un número positivo")}
      </FormHelperText>
      <FormControl size="small" variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel id="recource-type-label">Tipo de Recurso</InputLabel>
        <Controller
          as={
            <Select
              labelId="resource-type-label"
              label="Tipo de Recurso"
              error={(errors.type ? true : false)}
              inputProps={{ name: 'type', id: 'role-select-input' }}>
              {types.map((t: any) => (
                <MenuItem key={`resource-type-${t.id}`} value={t}>
                  {t.name}
                </MenuItem>
              ))}
            </Select>
          }
          name={"type"}
          control={control}
          rules={{ required: true }}
        />
        <FormHelperText error={true}>
          {errors?.type && ((errors.type as any)?.message || "El tipo es requerido")}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth size="small" className={classes.formControl} variant="outlined" >
        <InputLabel id="amenities-label">Extras</InputLabel>
        <Controller
          as={
            <Select
              multiple
              labelId="amenities-label"
              label="extras"
              error={(errors.amenities ? true : false)}
              inputProps={{ name: 'amenities', id: 'amenities-select-input' }}
              renderValue={(selected) => {
                return (
                  <>
                    {(selected as Amenity[]).map((value, index) => (
                      <Chip key={`${value.id}-${index}`} label={value.name} className={chipStyles.root} />
                    ))}
                  </>
                )
              }}>
              {amenities.map((am, index) => (
                <MenuItem key={`amenity-${am.id}-${index}`} value={am as any} dense >
                  {am.name}
                </MenuItem>
              ))}
            </Select>
          }
          name={"amenities"}
          control={control}
        />
        <FormHelperText error={true}>
          {errors?.amenities && ((errors.amenities as any)?.message || "El tipo es requerido")}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth className={classes.formControl} size="small" variant="outlined">
        <InputLabel id="groups-label">Grupos</InputLabel>
        <Controller
          as={
            <Select
              multiple
              label="grupos"
              labelId="groups-label"
              error={(errors.groups ? true : false)}
              inputProps={{
                name: 'groups',
                id: 'groups-select-input',
              }}
              renderValue={(selected) => {
                return (
                  <div>
                    {(selected as ResourceGroup[]).map((value) => (
                      <Chip key={value.id} label={value.name} className={chipStyles.root} />
                    ))}
                  </div>
                )
              }} >
              {groups.map((g, index) => (
                <MenuItem key={`resource-group-${g.id}-${index}`} value={g as any} dense >
                  {g.name}
                </MenuItem>
              ))}
            </Select>
          }
          name={"groups"}
          control={control}
          rules={{ required: true }}
        />
        <FormHelperText error={true}>
          {errors?.groups && (errors.groups as any)?.message}
        </FormHelperText>
      </FormControl>
      {!place &&
        <FormControl fullWidth className={classes.formControl} size="small" variant="outlined">
          <InputLabel id="recource-type-label">Piso</InputLabel>
          <Controller
            as={
              <Select
                labelId="floor-label"
                label="Piso"
                error={(errors.floor ? true : false)}
                inputProps={{ name: 'floor', id: 'floor-select-input' }}>
                {floors.map((f: any) => (
                  <MenuItem key={f.id} value={f}>
                    {f.name}
                  </MenuItem>
                ))}
              </Select>
            }
            name={"floor"}
            control={control}
            rules={{ required: true }}
          />
          <FormHelperText error={true}>
            {errors?.floor && ((errors.floor as any)?.message || "El piso es requerido")}
          </FormHelperText>
        </FormControl>
      }
      {!!place && !!resource.coords && resource.coords.length > 0 &&
        <>
          <FormControl fullWidth className={`${classes.formControl} ${iconButtonStyles.container}`}>
            <IconButton onClick={handleFocus}>
              <CenterFocusWeak /> Clic para ver posición
            </IconButton>
          </FormControl>
          <FormControl fullWidth className={`${classes.formControl} ${iconButtonStyles.container}`}>
            <IconButton onClick={handleDeleteCoords}>
              <Delete /> Clic para eliminar posición
            </IconButton>
          </FormControl>
        </>
      }
      {!!place && (!resource.coords || resource.coords.length === 0) &&
        <FormControl fullWidth className={`${classes.formControl} ${iconButtonStyles.container}`}>
          <IconButton onClick={handleLocate}>
            <Gesture /> Clic para marcar posición
          </IconButton>
        </FormControl>
      }
      <FormActionButtons
        actionText={resource?.id ? 'Actualizar' : 'Crear'}
        onCancel={handleClose}
      />
    </form>
  )
}

export default ResourceForm

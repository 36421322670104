import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import useTableStyles from '../../hooks/styles/tableStyles';
import { Delete, Edit } from '@material-ui/icons';
import { Branch } from './branch.model';

type Props = {
  branches: Branch[];
  onDelete: (branch: Branch) => void;
  onUpdate: (branch: Branch) => void;
}

const BranchesList: React.FC<Props> = ({ branches, onDelete, onUpdate }) => {
  const classes = useTableStyles();

  const handleDelete = (branch: Branch) => onDelete(branch);
  const handleUpdate = (branch: Branch) => onUpdate(branch);

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Domicilio</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {branches.map((branch, index) => (
            <TableRow key={`${branch.id}-${index}`}>
              <TableCell>{branch.name}</TableCell>
              <TableCell>{branch.address}</TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(branch)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(branch)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default BranchesList;
import baseAPI from './api';
import { BehaviorSubject } from 'rxjs';
import { Success } from "../components/commons/Success";
import { Config } from '../components/commons/configContext/config.model';

const get = async () => {
  const res = await baseAPI.get(`config`)
  return res.data as Config
}

const addResourceToFav = async (resourceId: string) => {
  const res = await baseAPI.patch(`config/fav/${resourceId}`)
  return res.data as Success<string>
}

const removeResourceFromFav = async (resourceId: string) => {
  const res = await baseAPI.patch(`config/unfav/${resourceId}`)
  return res.data as Success<string>
}

const fetchCurrentBranch = async () => {
  const res = await baseAPI.get('config/branch')
  return res.data.currentBranch as string
}

const getCurrentBranch = () => {
  return currentBranchSubject.getValue()
}

const refreshCurrentBranch = (currentBranch: string, isStored?: boolean) => {
  currentBranchSubject.next(currentBranch)
  if(!isStored) {
    baseAPI.patch(`config/branch?b=${currentBranch}`)
  }
}

const currentBranchSubject = new BehaviorSubject<string | null>(null)

export const configService = {
  get,
  addResourceToFav,
  removeResourceFromFav,
  fetchCurrentBranch,
  getCurrentBranch,
  refreshCurrentBranch
};

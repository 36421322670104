import { FC, useEffect, useState } from 'react'
import { floorService } from '../../_services/floor.service'
import { CIRCLE_TYPE, DrawingType, drawingTypes, RECTANGLE_TYPE } from '../resource/drawingTypes'
import { Coords, Resource } from '../resource/resource.model'
import * as d3 from "d3";
import { Floor } from './floor.model';
import { MapState } from '../booking/bookingMap/Map';
import { Box } from '@material-ui/core';

type Props = {
  floor: Floor;
  resources: Resource[];
  selectedResource: Resource;
  placing: boolean;
  freeEdition: boolean;
  drawingType: DrawingType;
  circleRadius: number;
  locate: Resource;
  onPlaced: (coords: Coords[]) => void;
  onResourceSelected: (resource: Resource, coords: Coords[]) => void;
  onResourceMoved: (resource: string, coords: Coords[]) => void;
}

const FloorMap: FC<Props> = ({ floor, resources, selectedResource, placing, freeEdition, drawingType, locate, circleRadius, onPlaced, onResourceSelected, onResourceMoved }) => {
  const ROOM_RADIUS = 10;
  const [mapSize, setMapSize] = useState<MapState>(null);
  const [zoomState, setZoomState] = useState<{ 'zoom': any }>(null);

  useEffect(() => {
    if (mapSize) {
      draw(mapSize.width, mapSize.height)
    }
    if (freeEdition && selectedResource) {
      selectRoomByid(selectedResource.id)
    } else if (freeEdition && !selectedResource) {
      selectAllResource()
    }
  }, [mapSize, resources, placing, circleRadius, selectedResource, drawingType, freeEdition])

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setMapSize({ width: img.width, height: img.height })
    }
    img.src = floorService.imageLink(floor.image.path);
  }, [floor])

  useEffect(() => {
    if (locate) {
      onLocateHandler(locate);
    }
  }, [locate])

  const onLocateHandler = (resource: Resource) => {
    const sel = (d3 as any).select('#canvasMap').select('svg');

    const w = ((d3 as any).select('#canvasMap').node() as any).getBoundingClientRect().width;
    const h = ((d3 as any).select('#canvasMap').node() as any).getBoundingClientRect().height;
    const xs = resource.coords.map(c => c.x)
    const ys = resource.coords.map(c => c.y)
    const minX = Math.min(...xs)
    const minY = Math.min(...ys)
    const maxX = Math.max(...xs)
    const maxY = Math.max(...ys)

    const transform = d3.zoomIdentity
      .translate(w / 2, h / 2)
      .scale(1)
      .translate(-(minX + (maxX - minX) / 2), -(minY + (maxY - minY) / 2));

    sel.transition()
      .duration(750)
      .call(zoomState.zoom.transform, transform);
  }

  const onRoomDotMoved = (x: any, y: any, roomSvg: any, circle: any, line1: any, line2: any, polygon: any, polyPoints: any, pointIndex: any) => {
    if (!canEditResource(roomSvg)) {
      return
    }

    circle.attr('cx', x).attr('cy', y)
    line1.attr('x1', x)
    line1.attr('y1', y)
    line2.attr('x2', x)
    line2.attr('y2', y)
    polyPoints[pointIndex].x = x
    polyPoints[pointIndex].y = y

    polygon.attr("points", polyPoints.map(function (d: any) {
      return [d.x, d.y].join(",");
    }).join(" "))
  }

  const drawRoomDot = (svg: any, x: number, y: number, xOffset: number, yOffset: number) => {
    const circle = svg.append('circle')
      .attr('cx', x - xOffset)
      .attr('cy', y + yOffset)
      .attr('r', ROOM_RADIUS)

    return circle
  }

  const drawRoomLine = (line: any, x1: number, y1: number, x2: number, y2: number) => {
    return line
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2);
  }

  const drawRoom = (svg: any, coords: Coords[], offset: number, resource: Resource) => {
    //setState({ isDrawing: false })
    const roomSvg = svg.append('svg')
      .attr('type', RECTANGLE_TYPE.name)
      .attr('resource-id', resource?.id)
      .attr('editing', true)
      .attr('width', mapSize.width)
      .attr('height', mapSize.height).on('mouseover', (event: any) => {
        event.stopPropagation();
        svg.style("cursor", 'default');
      })
    const text = roomSvg.append("text")
      .attr("x", coords[0].x + (coords[1].x - coords[0].x) / 2)
      .attr("y", coords[0].y - 2)
      .style("font-size", circleRadius * .8 + 'px')
      .style("text-anchor", "middle")
      .text(() => { return resource.name; });
    const l1 = roomSvg.append('line');
    const l2 = roomSvg.append('line');
    const l3 = roomSvg.append('line');
    const l4 = roomSvg.append('line');

    const polygon = roomSvg.append('polygon')
      .on('click', (event: any) => {
        event.stopPropagation();
      })
      .on('dblclick', (event: any) => {
        // Si el usario esta posicionando un recurso no quiero editar uno ya posicionado
        if (!canSelectResource()) {
          return
        }
        event.stopPropagation();

        selectRoom(roomSvg)
      })

    let polyPoints: any[] = []

    const c1 = drawRoomDot(roomSvg, coords[0].x, coords[0].y, -offset, -offset)
      .call(d3.drag()
        .on('drag', (event: any) => {
          onRoomDotMoved(event.x, event.y, roomSvg, c1, l1, l2, polygon, polyPoints, 0)
          text.attr("x", event.x + (c2.attr('cx') - event.x) / 2)
            .attr("y", Math.min(event.y, c2.attr('cy')) - 2)
        }).on('end', (event: any) => {
          if (!canEditResource(roomSvg)) {
            return
          }
          onResourceMoved(getId(roomSvg), getCoords(roomSvg))

        }))
    const c2 = drawRoomDot(roomSvg, coords[1].x, coords[1].y, offset, -offset)
      .call(d3.drag()
        .on('drag', (event: any) => {
          onRoomDotMoved(event.x, event.y, roomSvg, c2, l2, l3, polygon, polyPoints, 1)
          text.attr("x", event.x + (c1.attr('cx') - event.x) / 2)
            .attr("y", Math.min(event.y, c1.attr('cy')) - 2)
        }).on('end', (event: any) => {
          if (!canEditResource(roomSvg)) {
            return
          }
          onResourceMoved(getId(roomSvg), getCoords(roomSvg))
        }))
    const c3 = drawRoomDot(roomSvg, coords[2].x, coords[2].y, offset, offset)
      .call(d3.drag()
        .on('drag', (event: any) => {
          onRoomDotMoved(event.x, event.y, roomSvg, c3, l3, l4, polygon, polyPoints, 2)
        }).on('end', (event: any) => {
          if (!canEditResource(roomSvg)) {
            return
          }
          onResourceMoved(getId(roomSvg), getCoords(roomSvg))
        }))
    const c4 = drawRoomDot(roomSvg, coords[3].x, coords[3].y, -offset, offset)
      .call(d3.drag()
        .on('drag', (event: any) => {
          onRoomDotMoved(event.x, event.y, roomSvg, c4, l4, l1, polygon, polyPoints, 3)
        }).on('end', (event: any) => {
          if (!canEditResource(roomSvg)) {
            return
          }
          onResourceMoved(getId(roomSvg), getCoords(roomSvg))
        }))

    polyPoints = [{ 'x': c1.attr('cx'), 'y': c1.attr('cy') },
    { 'x': c2.attr('cx'), 'y': c2.attr('cy') },
    { 'x': c3.attr('cx'), 'y': c3.attr('cy') },
    { 'x': c4.attr('cx'), 'y': c4.attr('cy') }]

    const p = polyPoints.map(function (d: any) {
      return [d.x, d.y].join(",");
    }).join(" ")
    polygon.attr("points", p)

    drawRoomLine(l1, c1.attr('cx'), c1.attr('cy'), c4.attr('cx'), c4.attr('cy'))
    drawRoomLine(l2, c2.attr('cx'), c2.attr('cy'), c1.attr('cx'), c1.attr('cy'))
    drawRoomLine(l3, c3.attr('cx'), c3.attr('cy'), c2.attr('cx'), c2.attr('cy'))
    drawRoomLine(l4, c4.attr('cx'), c4.attr('cy'), c3.attr('cx'), c3.attr('cy'))

    return roomSvg
  }

  const canSelectResource = () => {
    return !selectedResource && !freeEdition && !placing
  }

  const canEditResource = (svg: any) => {
    return freeEdition && (svg.attr('editing') === 'true');
  }

  const drawDesk = (svg: any, coords: Coords, resource: Resource) => {
    const circleSvg = svg.append('svg')
      .attr('type', CIRCLE_TYPE.name)
      .attr('width', mapSize.width)
      .attr('height', mapSize.height)
      .attr('resource-id', resource?.id)
      .attr('editing', true)
      .on('mouseover', (event: any) => {
        if (event.shiftKey) {
          svg.style("cursor", 'not-allowed');
        } else {
          svg.style("cursor", 'default');
        }
        event.stopPropagation()
      })

    const text = circleSvg.append("text")
      .attr("x", coords.x)
      .attr("y", coords.y - circleRadius - 2)
      .style("font-size", circleRadius * .8 + 'px')
      .style("text-anchor", "middle")
      .text(() => { return resource.name; });

    const circle = circleSvg.append('circle');

    circle
      .attr('cx', coords.x)
      .attr('cy', coords.y)
      .attr('r', circleRadius)
      .on('click', (event: any) => {
        event.stopPropagation();
      })
      .on('dblclick', (event: any) => {
        // Si el usario esta posicionando un recurso no quiero editar uno ya posicionado
        if (!canSelectResource()) {
          return
        }
        event.stopPropagation();
        selectRoom(circleSvg)
      })
      .call(d3.drag()
        .on('drag', (event: any) => {
          if (!canEditResource(circleSvg)) {
            return
          }
          circle.attr('cx', event.x).attr('cy', event.y)
          text.style("font-size", circleRadius * .8 + 'px').attr("x", event.x)
            .attr("y", event.y - circleRadius - 2)
        }).on('end', (event: any) => {
          if (!canEditResource(circleSvg)) {
            return
          }
          onResourceMoved(getId(circleSvg), getCoords(circleSvg))
        }))

    return circleSvg
  }

  const getSelectedSvg = () => {
    return (d3 as any).selectAll('svg[editing=true]')
  }

  const deselectResourceSvg = (svg: any) => {
    if (!!svg) {
      svg.attr('editing', false)
    }
  }

  const selectRoom = (svg: any) => {
    deselectResourceSvg(getSelectedSvg())
    svg.attr('editing', true)
    let filteredRes = resources.filter(r => r.id === svg.attr('resource-id'))
    let resource = null
    if (filteredRes.length > 0) {
      resource = filteredRes[0]
    }
    onResourceSelected(resource, getCoords(svg))
  }

  const selectRoomByid = (id: string) => {
    deselectResourceSvg(getSelectedSvg())
    const svg = (d3 as any).select('#canvasMap').select(`svg[resource-id="${id}"]`)
    svg.attr('editing', true)
  }

  const draw = (width: number, height: number) => {

    const _svg = (d3 as any).select('#canvasMap').select('svg')
    let lastZoom = null;
    if (!!_svg && !!_svg.node()) {
      lastZoom = d3.zoomTransform(_svg.node())
    }

    (d3 as any).select('#canvasMap').select('svg').remove()
    const w = ((d3 as any).select('#canvasMap').node() as any).getBoundingClientRect().width;
    const zoom = d3.zoom().scaleExtent([0.1, 8]).translateExtent([
      [0, 0],
      [width, height]]).filter((event: any) => {
        return event.type !== 'dblclick';
      }).on('zoom', (event: any) => {

        svg.attr('transform', event.transform);
      })

    const svg = ((d3 as any).select('#canvasMap') as any)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .call(zoom)
      .append("g").on('mouseover', () => {
        if (!!placing) {
          svg.style("cursor", 'copy');
        } else {
          svg.style("cursor", 'default');
        }
      })

    svg.append('image')
      .attr("xlink:href", floorService.imageLink(floor.image.path))
      .attr('width', width)
      .attr('height', height);

    if (!zoomState) {
      (d3 as any).select('#canvasMap').select('svg').call(zoom).call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(w / width))
      setZoomState({ zoom });
    } else {
      let x0 = -(lastZoom.x * (1 / lastZoom.k))
      if (x0 > width - (w / 2)) {
        x0 = width - (w / 2)
      }
      (d3 as any).select('#canvasMap').select('svg').call(zoom.transform, d3.zoomIdentity.translate(-0, lastZoom.y).scale(lastZoom.k).translate(-x0, 0));
      setZoomState({ zoom });
    }

    redrawResources();
    svg.on('click', onClickOverMap);
  }

  const onClickOverMap = async (event: any) => {
    const m = (d3).pointer(event);
    if (placing) {
      let coords = null
      if (drawingType && drawingType === RECTANGLE_TYPE) {
        coords = [{ x: m[0] - circleRadius, y: m[1] - circleRadius }, { x: m[0], y: m[1] - circleRadius }, { x: m[0], y: m[1] }, { x: m[0] - circleRadius, y: m[1] }]
        onPlaced(coords);
      } else {
        coords = [{ x: m[0], y: m[1] }]
        onPlaced(coords);
      }
    }
  }

  const redrawResources = () => {
    for (const type of drawingTypes) {
      (d3 as any).selectAll(`svg[type="${type.name}"]`).remove()
    }
    const svg = ((d3 as any).select('#canvasMap') as any).select('g')
    for (const resource of resources) {
      if (!resource.coords || resource.coords.length === 0) {
        continue
      }
      if (resource.coords.length > 1) {
        drawRoom(svg, resource.coords, 0, resource)
      } else {
        drawDesk(svg, resource.coords[0], resource)
      }
    }
    deselectResourceSvg(getSelectedSvg());
  }

  const getCoords = (svg: any) => {
    if (!!svg.node() && svg.attr('type') === CIRCLE_TYPE.name) {
      const circle = svg.select('circle')
      return [{ x: circle.attr('cx'), y: circle.attr('cy') }]
    } else if (!!svg.node() && svg.attr('type') === RECTANGLE_TYPE.name) {
      const polygon = svg.select('polygon')
      const points = polygon.attr('points').split(' ').map((p: string) => { return { 'x': Number.parseInt(p.split(',')[0]), 'y': Number.parseInt(p.split(',')[1]) } as Coords });
      return points
    }
    return []
  }

  const getId = (svg: any) => {
    return svg.attr('resource-id')
  }

  const selectAllResource = () => {
    (d3 as any).select('#canvasMap').selectAll('svg').attr('editing', true)
  }

  return (
    <Box
      id="canvasMap"
      component="div"
      bgcolor="grey.300"
      style={{ height: '100%' }}
    />
  )
}

export default FloorMap

import React, { FC } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { ResourceType } from '../../resource/resourceType.model'
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FilterInputSmallStyle, FilterInputStyle } from './FloorSelect';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 100,
    '& label': {
      backgroundColor: theme.palette.background.default,
      paddingInline: theme.spacing(.5),
    },
  },
  menuItem: {
    fontSize: theme.typography.fontSize,
    paddingBlock: 4,
    paddingInline: 8,
  },
}));

type Props = {
  types: ResourceType[];
  defaultValue: ResourceType;
  smallSize?: boolean;
  hideLabel?: boolean;
  onChange: (type: ResourceType) => void;
}

const ResourceTypeSelect: FC<Props> = ({ types, defaultValue, smallSize, hideLabel, onChange }) => {

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<any>) => {
    onChange(event.target.value)
  }
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      {!hideLabel && <InputLabel shrink htmlFor="resource-select">Tipo de Rec</InputLabel>}
      <Select
        id="resource-select"
        labelId="resource-select"
        label='Tipo de Rec'
        displayEmpty={true}
        value={(defaultValue && types.find(t => t.id === defaultValue.id)) || ''}
        onChange={handleChange}
        input={smallSize ? <FilterInputSmallStyle /> : <FilterInputStyle />}
      >
        <MenuItem key="empty" value={''} className={classes.menuItem}>Todos</MenuItem>
        {types.map((t: any, index) => (
          <MenuItem key={`${t.id}-${index}`} value={t} className={classes.menuItem}>
            {t.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ResourceTypeSelect

import moment, { Moment } from 'moment';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { Chip, FormControl, Grid, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ComputerIcon from '@material-ui/icons/Computer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TitleIcon from '@material-ui/icons/Title';
import PersonIcon from '@material-ui/icons/Person';
import EventIcon from '@material-ui/icons/Event';
import BookingHourSelector from './BookingHourSelector';
import AllDaySelector from './AllDaySelector';
import LoopIcon from '@material-ui/icons/Loop';
import { Booking } from '../booking.model';
import { Resource } from '../../resource/resource.model';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import { getDefaultGMTString } from '../../commons/utils/timezoneUtils';
import UserSelect from '../../commons/selects/UserSelect';
import { useCurrentUser } from '../../commons/currentuser/CurrentUserHook';
import { ROLES } from '../../../_helpers';
import { ListedUser } from '../../users/listedUser.model';
import { FULL_WEEK_DAYS, SHORT_WEEK_DAYS } from '../../../_helpers/constants';
import DateSlider from '../../dateSlider/DateSlider';
import { useSubdomain } from '../../commons/subdomain/SubdomainHook';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%'
  },
  centerIcon: {
    padding: theme.spacing(0.5)
  }
}));

type Props = {
  booking: Booking;
  title: string;
  userId?: string;
  showUserSelect?: boolean;
  onChangeTime?: (begin: moment.Moment, end: moment.Moment, title: string, isRecurrent?: boolean, recurrenceWeekdays?: any, recurrenceEndDate?: Date) => void;
  onUserChange?: (user: string) => void;
  onTitleChange: (title: string) => void;
}

/** Componente para mostrar campos para crear una reserva */
const BookingCreate: React.FC<Props> = ({ booking, userId, title, showUserSelect, onChangeTime, onUserChange, onTitleChange }) => {
  const { user } = useCurrentUser();
  const classes = useStyles();
  const { bookingStep, weeklySchedule } = useLaborable();
  const { company } = useSubdomain();
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date>();

  const recurrenceWeekdays = useCallback((index) => {
    return booking?.isRecurrent && !!booking.recurrenceWeekdays ?
      booking.recurrenceWeekdays[index] : false;
  }, [booking]);

  const handleTimeChange = (begin: Moment, end: Moment, isRecurrent?: boolean, recurrenceWeekdays?: any, recurrenceEndDate?: Date) => {
    onChangeTime && onChangeTime(
      begin,
      end,
      title,
      typeof isRecurrent !== 'undefined' ? isRecurrent : booking.isRecurrent,
      !!recurrenceWeekdays ? recurrenceWeekdays : booking.recurrenceWeekdays,
      recurrenceEndDate
    );
  }

  const switchWeekDay = (weekday: number) => {
    const newStateArray = !!booking.recurrenceWeekdays ? [...booking.recurrenceWeekdays] : (new Array<boolean>(7)).fill(false);
    newStateArray[weekday] = !newStateArray[weekday];
    return newStateArray
  }

  const handleWeekdayCheck = (weekday: number) => {
    const newStateArray = switchWeekDay(weekday)
    const isRecurrent = newStateArray.some(v => !!v)
    handleTimeChange(moment(booking.bookingStart), moment(booking.bookingEnd), isRecurrent, newStateArray, booking.recurrenceEndDate)
  }

  const handleRecurrentCheckbox = () => {
    // Set last allowed date for recursive booking
    const defaultRecurrentEndDate = moment(booking.bookingStart).add(7, 'day').toDate();
    setRecurrenceEndDate(defaultRecurrentEndDate)
    if (!booking.recurrenceWeekdays) {
      const newStateArray = switchWeekDay(moment(booking.bookingStart).day())
      handleTimeChange(moment(booking.bookingStart), moment(booking.bookingEnd), true, newStateArray, defaultRecurrentEndDate)
    } else {
      handleTimeChange(moment(booking.bookingStart), moment(booking.bookingEnd), !booking.isRecurrent, null, defaultRecurrentEndDate)
    }
  }

  const handleStartTimeChange = (date: Moment) => {
    let end = moment(booking.bookingEnd)
    if (date.isSameOrAfter(moment(booking.bookingEnd))) {
      end = date.clone().add(bookingStep, 'minutes')
    }
    handleTimeChange(date, end, booking.isRecurrent, booking.recurrenceWeekdays, booking.recurrenceEndDate)
  }

  const handleEndTimeChange = (date: Moment) => {
    let start = moment(booking.bookingStart)
    if (date.isSameOrBefore(moment(booking.bookingStart))) {
      start = date.clone().subtract(bookingStep, 'minutes')
    }
    handleTimeChange(start, date, booking.isRecurrent, booking.recurrenceWeekdays, booking.recurrenceEndDate)
  }

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onTitleChange(event.target.value)
  }

  const handleUserChange = (user: ListedUser) => {
    onUserChange(user.id)
  }

  const handleRecurrenceEndDateChange = (date: Date) => {
    handleTimeChange(moment(booking.bookingStart), moment(booking.bookingEnd), booking.isRecurrent, booking.recurrenceWeekdays, date);
    setRecurrenceEndDate(date)
  }

  const getLastAllowedDate = () =>
    moment(booking.bookingStart)
      .add(company.recurrenceMonths, 'month')
      .toDate()

  return (
    <Grid container alignItems='center'>
      <Grid item xs={1} className={classes.centerIcon}><TitleIcon color='primary' /></Grid>
      <Grid item xs={11}>
        <FormControl className={classes.formControl} size="small">
          <TextField
            fullWidth
            id="title"
            margin="dense"
            name="title"
            size="small"
            placeholder='Añade un título'
            value={title}
            onChange={handleTitleChange}
          />
        </FormControl>
      </Grid>

      {showUserSelect && user.role === ROLES.assistant &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><PersonIcon color='primary' /></Grid>
          <Grid item xs={11}>
            <UserSelect onChange={handleUserChange} defaultValue={userId} />
          </Grid>
        </>
      }
      {booking?.bookingStart &&
        <>
          {!!onChangeTime &&
            <>
              <Grid item xs={1} className={classes.centerIcon}><EventIcon color='primary' /> </Grid>
              <Grid item xs={11}>{moment(booking?.bookingStart).format("dddd, D [de] MMMM")}</Grid>
            </>
          }
          {!onChangeTime &&
            <>
              <Grid item xs={1} className={classes.centerIcon}><AccessTimeIcon color='primary' /> </Grid>
              <Grid item xs={11}>{
                moment(booking?.bookingStart).format("dddd, D [de] MMMM") + ' ' +
                moment(booking?.bookingStart).format("HH:mm") + ' a ' +
                moment(booking?.bookingEnd).format("HH:mm") + ' ' +
                getDefaultGMTString()}</Grid>
            </>
          }
          {!!onChangeTime &&
            <>
              <Grid item xs={1} className={classes.centerIcon}><AccessTimeIcon color='primary' /></Grid>
              <Grid item xs={1}>Inicio:</Grid>
              <Grid item xs={3}>
                <BookingHourSelector
                  value={moment(booking.bookingStart)}
                  onChange={handleStartTimeChange}
                  limitEnd={true}
                />
              </Grid>
              <Grid item xs={1}>Fin:</Grid>
              <Grid item xs={3}>
                <BookingHourSelector
                  value={moment(booking.bookingEnd)}
                  onChange={handleEndTimeChange}
                  limitStart={true}
                />
              </Grid>
              <Grid item xs={3}>
                <AllDaySelector
                  begin={moment(booking.bookingStart)}
                  end={moment(booking.bookingEnd)}
                  onChange={handleTimeChange}
                />
              </Grid>
            </>
          }
        </>
      }
      {!onChangeTime && booking.isRecurrent &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><LoopIcon color='primary' /></Grid>
          <Grid item xs={11}>
            {booking.recurrenceWeekdays.map((day, index) => {
              if (day) {
                return FULL_WEEK_DAYS[index] + "  ";
              }
              return ''
            })}
          </Grid>
        </>
      }
      {!!onChangeTime && <>
        <Grid item xs={1} className={classes.centerIcon}>
          <LoopIcon color='primary' />
        </Grid>
        <Grid item xs={11}>
          <FormControl size="small">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!booking.isRecurrent}
                  id="isRecurrent"
                  name="isRecurrent"
                  onChange={handleRecurrentCheckbox}
                  disabled={!onChangeTime}
                />
              }
              label="Repetir"
            />
          </FormControl>
        </Grid>
      </>}

      {!!onChangeTime && booking.isRecurrent &&
        <>
          <Grid item xs={1} className={classes.centerIcon}>
          </Grid>
          <Grid item xs={11}>
            <Grid container justifyContent='space-between'>
              {SHORT_WEEK_DAYS.map((day, index) =>
                <Grid item xs={1} key={`check-day-${index}`}>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={!weeklySchedule.week.some(range => range.weekDay === index && range.laborable)}
                          checked={recurrenceWeekdays(index)}
                          onChange={() => handleWeekdayCheck(index)}
                        />
                      }
                      label={day}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent='flex-start'>
              <Grid item xs={4} style={{ alignSelf: 'center' }}>Fecha de finalización:</Grid>
              <Grid item xs={4}>
                <DateSlider
                  currentDate={recurrenceEndDate}
                  minDate={booking.bookingStart}
                  maxDate={getLastAllowedDate()}
                  onChange={handleRecurrenceEndDateChange} />
              </Grid>
            </Grid>
          </Grid>
        </>
      }

      {(booking?.resource as Resource)?.floor?.name &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><LocationOnIcon color='primary' /> </Grid>
          <Grid item xs={11}>{(booking?.resource as Resource)?.floor.name}</Grid>
        </>
      }
      {(booking?.resource as Resource)?.name &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><ComputerIcon color='primary' /> </Grid>
          <Grid item xs={11}>{(booking?.resource as Resource)?.name}</Grid>
        </>
      }
      {(booking?.resource as Resource)?.capacity &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><AccessibilityIcon color='primary' /> </Grid>
          <Grid item xs={11}>{(booking?.resource as Resource)?.capacity}</Grid>
        </>
      }
      {(booking?.resource as Resource)?.amenities &&
        <>
          <Grid item xs={1} className={classes.centerIcon}><b><AttachFileIcon color='primary' /></b> </Grid>
          <Grid item xs={11}>
            {(booking?.resource as Resource)?.amenities && (booking?.resource as Resource).amenities.map((value, index) => (
              <Chip key={`${value.id}-${index}`} label={value.name} />
            ))}
            {(!(booking?.resource as Resource)?.amenities || (booking?.resource as Resource)?.amenities.length === 0) &&
              'No posee extras'}
          </Grid>
        </>
      }
    </Grid>
  )
}

export default BookingCreate;
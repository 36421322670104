import React, { FC, useRef, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, Slider } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AvatarEditor from 'react-avatar-editor'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  }, submit: {
    margin: theme.spacing(2, 0, 0),
  }
}));

type Props = {
  open: boolean;
  onUpdate: (imagedata: any) => void;
  onClose: () => void;
}

const UpdateRoomLogo: FC<Props> = ({ open, onUpdate, onClose }) => {
  const classes = useStyles();
  const editor = useRef(null)
  const [image, setImage] = useState<any>(null)
  const [scale, setScale] = useState<number>(1)

  const handleClose = () => {
    onClose();
  }

  const handleImageChange = (e: any) => {
    if (!!e?.target?.files) {
      setImage(e.target.files[0])
    }
  }
  const handleUpdate = () => {
    if (editor?.current) {
      onUpdate(editor.current.getImageScaledToCanvas())
    } else {
      onUpdate(null);
    }
  }

  const handleScaleChange = (e: any, newValue: number | number[]) => {
    setScale(newValue as number)
  }

  return (
    <Dialog maxWidth={'md'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Actualizar Logo</DialogTitle>
      <DialogContent>
        <Grid container direction="column" justifyContent='center' alignItems='center' >

          {!!image &&
            <AvatarEditor
              ref={editor}
              image={image}
              width={200}
              height={200}
              border={50}
              color={[110, 110, 110, 0.7]} // RGBA
              scale={scale}
              rotate={0}
            />
          }
          <FormControl className={classes.formControl}>
            <input type="file" name="image" onChange={handleImageChange} />
          </FormControl>
          <Slider onChange={handleScaleChange} valueLabelDisplay="auto" defaultValue={scale} min={0.1} max={5} step={.1} aria-label="Disabled slider" />
          <Button onClick={handleUpdate}>Actualizar</Button>
        </Grid>
      </DialogContent>
    </Dialog >
  )
}

export default UpdateRoomLogo;
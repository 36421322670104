import baseAPI from './api';
import { Role } from '../components/users/role.model';
import { AxiosRequestConfig, CancelToken } from 'axios';

const list = async (cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {}
  if(cancelToken) {
    config.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`roles`, config)
  return response.data as Role[]
}

export const roleService = {
  list
};
import { useCallback, useEffect, useState } from 'react';
import { FC } from 'react';
import * as d3 from "d3";
import { Coords, Resource } from '../../resource/resource.model';
import { Floor } from '../../floor/floor.model';
import { CIRCLE_TYPE, RECTANGLE_TYPE } from '../../resource/drawingTypes';
import { floorService } from '../../../_services/floor.service';
import { makeStyles, Theme } from '@material-ui/core/styles';
import './BookingMap.css'
import useSnackBars from '../../commons/snackbar/SnackbarHook'
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import { Moment } from 'moment';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  canvas: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
  },
}));

type MapProps = {
  resources: Resource[];
  floor: Floor;
  smallSize?: boolean;
  date?: Moment;
  onResourceSelected: (resource: Resource) => void;
}

export type MapState = {
  width: number;
  height: number;
};

const Map: FC<MapProps> = ({ resources, floor, smallSize = false, date, onResourceSelected }) => {

  const classes = useStyles();
  const [mapSize, setMapSize] = useState<MapState>(null);
  const { showInfo } = useSnackBars();
  const { weeklySchedule } = useLaborable();

  const maxDuration = useCallback(
    () => {
      if (!!date) {
        const range = weeklySchedule.week.find(day => day.weekDay === date.day());
        if (!range.laborable) {
          return 0
        }
        const diff = moment(range.end).diff(moment(range.begin), 'minutes');
        return diff;
      }
      return 12 * 60;
    },
    [date, weeklySchedule],
  )


  const drawRoomLine = (line: any, x1: number, y1: number, x2: number, y2: number) => {
    return line
      .attr("x1", x1)
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2);
  }

  const drawRoom = (svg: any, coords: Coords[], offset: number, resource: Resource) => {
    const roomSvg = svg.append('svg')
      .attr('type', RECTANGLE_TYPE.name)
      .attr('resource-id', resource?.id)
      .attr('selected', true)
      .attr('busy', resource.duration > 0 && resource.duration < maxDuration())
      .attr('disabled', resource.duration >= maxDuration())
      .attr('width', mapSize.width)
      .attr('height', mapSize.height).on('mouseover', (event: any) => {
        event.stopPropagation();
        svg.style("cursor", 'default');
      });

    roomSvg.append("text")
      .attr("x", coords[0].x + (coords[1].x - coords[0].x) / 2)
      .attr("y", coords[0].y - 2)
      .style("font-size", floor.circleSize * .8 + 'px')
      .style("text-anchor", "middle")
      .text(() => { return resource.name; });
    const l1 = roomSvg.append('line');
    const l2 = roomSvg.append('line');
    const l3 = roomSvg.append('line');
    const l4 = roomSvg.append('line');

    const polygon = roomSvg.append('polygon')
      .on('click', (event: any) => {
        // Si el usario esta posicionando un recurso no quiero editar uno ya posicionado
        event.stopPropagation();
        selectRoom(roomSvg);
      })

    let polyPoints: any[] = []

    polyPoints = [{ 'x': coords[0].x + offset, 'y': coords[0].y - offset },
    { 'x': coords[1].x - offset, 'y': coords[1].y - offset },
    { 'x': coords[2].x - offset, 'y': coords[2].y + offset },
    { 'x': coords[3].x + offset, 'y': coords[3].y + offset }]

    const p = polyPoints.map(function (d: any) {
      return [d.x, d.y].join(",");
    }).join(" ")
    polygon.attr("points", p)

    drawRoomLine(l1, polyPoints[0].x, polyPoints[0].y, polyPoints[3].x, polyPoints[3].y)
    drawRoomLine(l2, polyPoints[1].x, polyPoints[1].y, polyPoints[0].x, polyPoints[0].y)
    drawRoomLine(l3, polyPoints[2].x, polyPoints[2].y, polyPoints[1].x, polyPoints[1].y)
    drawRoomLine(l4, polyPoints[3].x, polyPoints[3].y, polyPoints[2].x, polyPoints[2].y)

    return roomSvg
  }

  const drawDesk = (svg: any, coords: Coords, resource: Resource) => {
    const circleSvg = svg.append('svg')
      .attr('type', CIRCLE_TYPE.name)
      .attr('width', mapSize.width)
      .attr('height', mapSize.height)
      .attr('resource-id', resource?.id)
      .attr('busy', resource.duration > 0 && resource.duration < maxDuration())
      .attr('disabled', resource.duration >= maxDuration())
      .attr('selected', true)
      .on('mouseover', (event: any) => {
        if (event.shiftKey) {
          svg.style("cursor", 'not-allowed');
        } else {
          svg.style("cursor", 'default');
        }
        event.stopPropagation()
      })

    circleSvg.append("text")
      .attr("x", coords.x)
      .attr("y", coords.y - floor.circleSize - 2)
      .style("font-size", floor.circleSize * .8 + 'px')
      .style("text-anchor", "middle")
      .text(() => { return resource.name; });

    const circle = circleSvg.append('circle');

    circle
      .attr('cx', coords.x)
      .attr('cy', coords.y)
      .attr('r', floor.circleSize)
      .on('click', (event: any) => {
        // Si el usario esta posicionando un recurso no quiero editar uno ya posicionado
        event.stopPropagation();
        selectRoom(circleSvg)
      })

    return circleSvg
  }

  const getSelectedSvg = () => {
    return (d3 as any).selectAll('svg[selected=true]')
  }

  const deselectResourceSvg = (svg: any) => {
    if (!!svg) {
      svg.attr('selected', false)
    }
  }

  const selectRoom = (svg: any) => {

    deselectResourceSvg(getSelectedSvg())
    svg.attr('selected', true)
    let filteredRes = resources.filter(r => r.id === svg.attr('resource-id'))
    let resource = null
    if (filteredRes.length > 0) {
      resource = filteredRes[0]
    }
    //TODO se selecciono un recurso
    onResourceSelected(resource)
  }


  const draw = (width: number, height: number) => {
    (d3 as any).select('#canvasMap').select('svg').remove()

    let w = ((d3 as any).select('#canvasMap').node() as any).getBoundingClientRect().width;
    w = w === 0 ? 1 : w;
    const zoom = d3.zoom().scaleExtent([0.1, 8]).translateExtent([
      [0, 0],
      [width, height]]).filter((event: any) => {
        return event.type !== 'dblclick';
      }).on('zoom', (event: any) => {
        svg.attr('transform', event.transform);
      })

    const svg = ((d3 as any).select('#canvasMap') as any)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .call(zoom)
      .append("g")

    svg.append('image')
      .attr("xlink:href", floorService.imageLink(floor.image.path))
      .attr('width', width)
      .attr('height', height);

    redrawResources();

    (d3 as any).select('#canvasMap').select('svg').call(zoom).call(zoom.transform, d3.zoomIdentity.translate(0, 0).scale(w / width))
  }

  const redrawResources = () => {

    const svg = ((d3 as any).select('#canvasMap') as any).select('g')
    for (const resource of resources) {
      if (!resource.coords || resource.coords.length === 0) {
        continue
      }
      if (resource.coords.length > 1) {
        drawRoom(svg, resource.coords, 0, resource)
      } else {
        drawDesk(svg, resource.coords[0], resource)
      }
    }
    deselectResourceSvg(getSelectedSvg());
  }

  useEffect(() => {
    const img = new Image();
    img.onload = function () {
      setMapSize({ width: img.width, height: img.height })
    }
    img.src = floorService.imageLink(floor.image.path);
  }, [floor])

  useEffect(() => {
    if (mapSize) {
      draw(mapSize.width, mapSize.height)
    }
  }, [mapSize])

  useEffect(() => {
    if (mapSize) {
      if ((d3 as any).select('#canvasMap')) {
        if ((d3 as any).select('#canvasMap').selectAll('svg[type="Rectangulo"]')) {
          (d3 as any).select('#canvasMap').selectAll('svg[type="Rectangulo"]').remove()
        }
        if ((d3 as any).select('#canvasMap').selectAll('svg[type="Circulo"]')) {
          (d3 as any).select('#canvasMap').selectAll('svg[type="Circulo"]').remove()
        }
      }
      (d3 as any).select('#canvasMap').selectAll('svg[type="Circulo"]').remove()
      redrawResources()
    }
    if (resources.length === 0) {
      showInfo("No existen recursos en este plano. Intente con otro piso")
    }

  }, [resources])


  return (
    <div id="canvasMap" className={classes.canvas} />
  );

}

export default Map;

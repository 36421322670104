import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { SubdomainContext } from './SubdomainContext';
import useSnackBars from '../snackbar/SnackbarHook';
import { useErrorHandler } from '../../errors/ErrorBoundary';
import { Company } from '../../companies/company.model';
import { companyService } from '../../../_services/company.service';
import { useCurrentUser } from '../currentuser/CurrentUserHook';

const SubdomainProvider: FC = ({ children }) => {

  const handleError = useErrorHandler();
  const { showError } = useSnackBars();
  const { user, logout } = useCurrentUser();

  const [company, setCompany] = useState<Company>(null);

  const getHostname = () => {
    const { hostname } = window.location;
    const hostnameSplit = hostname.replace(process.env.REACT_APP_FE_URL, '').split('.')
    if (hostname && hostnameSplit.length > 1 && hostnameSplit[0] !== process.env.REACT_APP_FE_SUBDOMAIN) {
      return hostnameSplit[0];
    }
    return null;
  }

  const handleApiError = useCallback((errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
    })
  }, [showError])

  const fetch = useCallback(async (subdomain: string) => {
    try {
      const company = await companyService.getBySubdomain(subdomain);
      if (company) {
        const hostname = getHostname();
        if (!hostname || hostname !== company.domain) {
          logout()
          window.location.href = `${process.env.REACT_APP_FE_SCHEME}://${company.domain}.${process.env.REACT_APP_FE_URL}`
        }

        setCompany(company)
      } else {
        logout()
        window.location.href = `${process.env.REACT_APP_FE_SCHEME}://${process.env.REACT_APP_FE_SUBDOMAIN}.${process.env.REACT_APP_FE_URL}`
      }

    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }, [handleApiError, handleError, logout])

  const fetchById = useCallback(async () => {
    try {
      const company = await companyService.getCurrentCompany();
      if (company) {
        const hostname = getHostname();
        if (hostname !== company.domain) {
          logout()
          window.location.href = `${process.env.REACT_APP_FE_SCHEME}://${company.domain}.${process.env.REACT_APP_FE_URL}?subdomain=${user.token}`
        }

        setCompany(company)
      } else {
        logout()
        window.location.href = `${process.env.REACT_APP_FE_SCHEME}://${process.env.REACT_APP_FE_SUBDOMAIN}.${process.env.REACT_APP_FE_URL}`
      }

    } catch (error) {

      if (error instanceof Error) {
        handleError(error)
      }
    }
  }, [handleError, user, logout])

  const refreshCompany = useCallback(
    async () => {
      try {
        const company = await companyService.getCurrentCompany();
        setCompany(company);
      } catch (error) {
        if (error instanceof Error) {
          handleError(error)
        }
      }
    }, [handleError]);

  useEffect(() => {
    if (!!user) {
      fetchById();
    } else {
      const subdomanin = getHostname();
      if (subdomanin) {
        fetch(subdomanin);
      }
    }
  }, [user, fetchById, fetch]);

  const value = useMemo(() => ({ company, refreshCompany }), [company, refreshCompany]);

  return (
    <SubdomainContext.Provider value={value}>
      {children}
    </SubdomainContext.Provider>
  )
}

export default SubdomainProvider;
import { FC } from 'react';
import { Typography, Box } from '@material-ui/core/';
import { useNextBooking } from '../commons/nextBookingsContext/NextBookingHook';
import useUserDashboardStyles from '../../hooks/styles/userDashboardStyles';
import NextBooking from './NextBooking';

const NextBookingPanel: FC = () => {
  const dashClasses = useUserDashboardStyles()

  const { nextBookings, updateNextBookings } = useNextBooking()
  return (
    <>
      {!!nextBookings && nextBookings.length > 0 &&
        <Box>
          <Typography className={dashClasses.subtitle}>
            Próximas Reservas
          </Typography>
          {nextBookings.map((b, index) =>
            <NextBooking
              key={`next-booking-${index}`}
              booking={b}
              onUpdateBookings={updateNextBookings}
            />
          )}
        </Box>
      }
    </>
  )
}

export default NextBookingPanel;
import { TextField } from '@material-ui/core'
import { FC, useCallback, useEffect, useState } from 'react'
import { useCurrentUser } from '../../commons/currentuser/CurrentUserHook';
import { userService } from '../../../_services/user.service';
import { Autocomplete } from '@material-ui/lab';
import { ListedUser } from '../../users/listedUser.model';

type Props = {
  defaultValue?: string;
  onChange?: (user: ListedUser) => void;
}

const UserSelect: FC<Props> = ({ defaultValue, onChange }) => {

  const { user } = useCurrentUser();
  const [users, setUsers] = useState<ListedUser[]>()

  const fetch = useCallback(
    async () => {
      const users = await userService.getUnListed('')
      setUsers(users)
    },
    [],
  )

  useEffect(() => {
    fetch()
  }, [user, fetch])

  const handleChange = (event: object, value: string | ListedUser, reason: string) => {
    !!onChange && onChange(value as ListedUser)
  }

  const getDefaultValue = () => {
    return defaultValue
      ? (defaultValue && users.find(t => t.id === defaultValue)) || null
      : null
  }

  return (
    <>
      {
        !!users &&
        <Autocomplete
          id="timezone-users"
          value={getDefaultValue()}
          options={users}
          getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
          onChange={handleChange}
          disableClearable={true}
          fullWidth={true}
          renderInput={(params) => <TextField {...params} />}
        />
      }
    </>
  )
}

export default UserSelect

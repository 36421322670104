import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { Language, Android, Apple } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  promptTitle: {
    textAlign: "center"
  },
  listItemIcon: {
    color: "black",
    fontSize: "28px"
  }
}));

type Props = {
  isAndroid: boolean;
  isIOS: boolean;
}

const MobilePrompt: FC<Props> = ({ isAndroid, isIOS }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const openAppOrRedirectToStore = () => {
    if (isAndroid) {
      const intent = `intent://Login/#Intent;scheme=${process.env.REACT_APP_MOBILE_SCHEME};package=${process.env.REACT_APP_MOBILE_PACKAGE};end`;
      window.location.replace(intent);
    } else if (isIOS) {
      window.location.replace(`${process.env.REACT_APP_MOBILE_SCHEME}://`);
      setTimeout(() => {
        window.location.replace(process.env.REACT_APP_MOBILE_APPSTORE_URL);
      }, 1000);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => { }}
      aria-labelledby="mobile-prompt-dialog"
    >
      <DialogTitle id="mobile-prompt-dialog" className={classes.promptTitle}>
        Estimado usuario.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para una mejor experiencia, le recomendamos el uso de la app de Webee.
        </DialogContentText>
        <List>
          <ListItem button onClick={openAppOrRedirectToStore}>
            <ListItemIcon>
              {
                isAndroid ?
                  <Android className={classes.listItemIcon} /> :
                  <Apple className={classes.listItemIcon} />
              }
            </ListItemIcon>
            <ListItemText>Ir a la app</ListItemText>
          </ListItem>
          <ListItem button onClick={() => setIsOpen(false)}>
            <ListItemIcon>
              <Language className={classes.listItemIcon} />
            </ListItemIcon>
            <ListItemText>Continuar en el navegador</ListItemText>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default MobilePrompt;
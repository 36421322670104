import { FC } from 'react';
import CustomTab, { TabData } from '../../components/commons/tabs/CustomTab';
import Branches from '../branches/Branches'
import Company from '../companies/Companies'
import Floor from '../floor/Floors'

const CompanyTab: FC = () => {

    const tabList: TabData[] = [
        { label: 'Pisos', component: <Floor /> },
        { label: 'Sucursales', component: <Branches /> },
        { label: 'Compañía', component: <Company /> },
    ]

    return (
        <CustomTab tabs={tabList} />
    );
}

export default CompanyTab
import { FC } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { CalendarToday, Favorite, Room } from '@material-ui/icons'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main,
    border: 0,
    borderRadius: 0,
    '& svg': {
      color: theme.palette.grey[100],
    },
  },
}))

const SideNavbar: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Tooltip title="Mi Calendario">
        <IconButton
          component={Link}
          to={'/'}
          className={history.location.pathname === '/' ? classes.selected : ''}>
          <CalendarToday color="primary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Recursos">
        <IconButton
          component={Link}
          to={'/blueprintAgenda'}
          className={history.location.pathname === '/blueprintAgenda' ? classes.selected : ''}>
          <Room color="primary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Favoritos">
        <IconButton
          component={Link}
          to={'/resourceAgenda'}
          className={history.location.pathname === '/resourceAgenda' ? classes.selected : ''}>
          <Favorite color="primary" fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default SideNavbar;
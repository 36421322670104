import React, { FC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';
import useFABStyles from '../../../hooks/styles/fabStyles';

type Props = {
  ariaLabel: string
  onClick: () => void
}

const FAB: FC<Props> = ({ ariaLabel, onClick }) => {
  const classes = useFABStyles();
  return (
    <Fab
      aria-label={ariaLabel}
      className={classes.root}
      color="secondary"
      size="small"
      variant="extended"
      onClick={onClick}
    >
      <AddIcon />
      Crear
    </Fab>
  )
}

export default FAB;
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, TextField, Grid, Tooltip, InputAdornment, IconButton, Box } from '@material-ui/core';
import { useErrorHandler } from "../errors/ErrorBoundary";
import { companyService } from '../../_services/company.service';
import { Company } from './company.model';
import UpdateLogo from './UpdateLogo';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import useCancelToken from '../../hooks/useCancelToken';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 1, 0),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  image: {
    width: "100%",
    height: "auto",
  },
  form: {
    marginTop: 13
  },
  subdomain: {
    width: "100%"
  }
}));

type Props = {
  company: Company
}

const EditCompany: FC<Props> = ({ company }) => {
  const classes = useStyles();

  const [logo, setLogo] = useState(null);
  const { showSuccess, showError } = useSnackBars()
  const [open, setOpen] = useState<boolean>(false);
  const { register, handleSubmit, errors, setError, clearErrors } = useForm<Company>({ defaultValues: company });
  const handleError = useErrorHandler();
  const { getCancelToken, isCancel } = useCancelToken()
  const fullDomain = `${company.domain}.webee.ar`;

  const handleApiError = useCallback(
    (errors: any) => {
      Object.keys(errors).forEach((field) => {
        const e = errors[field]
        e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
      })
    }, [setError, showError]
  )

  const refreshLogo = useCallback(async () => {
    try {
      const cancelToken = getCancelToken()
      const currentLogo = await companyService.getLogo(cancelToken)
      const logoPath = companyService.imageLink(currentLogo?.path)
      setLogo(logoPath)
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { refreshLogo() }, [refreshLogo])

  const onSubmit = async (companyForm: Company) => {
    try {
      const res = await companyService.update(companyForm)
      showSuccess(res.msg)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleOpen = () => { setOpen(true) };
  const handleClose = () => { setOpen(false) }
  const handleUpdateLogo = () => {
    refreshLogo()
    handleClose()
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(fullDomain);
      showSuccess("Dominio copiado");
    } catch (error) {
      showError("Error al copiar el dominio");
    }
  }

  return (
    <Box overflow="auto" height="100%">
      <Grid container justifyContent='space-around'>
        <Grid item>
          <Box width={200} height={200} overflow="hidden" borderRadius="100%" margin={2}>
            <img src={logo} alt="logo de la compañía" className={classes.image} />
          </Box>
          <Box textAlign="center">
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              Cambiar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={10} md={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item>
                <Tooltip title="Comparta este subdominio con los miembros de su organización">
                  <TextField
                    variant="standard"
                    margin="normal"
                    label="Dominio"
                    size="small"
                    className={classes.subdomain}
                    defaultValue={fullDomain}
                    InputProps={{
                      readOnly: true,
                      endAdornment:
                        <InputAdornment position="end">
                          <Tooltip title="Copiar" >
                            <IconButton onClick={copyToClipboard}>
                              <FileCopy />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                    }}
                  />
                </Tooltip>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="name"
                    label="Nombre de la companía"
                    name="name"
                    size="small"
                    error={(errors.name ? true : false)}
                    inputRef={register({ required: true, minLength: 2 })}
                  />
                  <FormHelperText error={true}>
                    {errors?.name && (errors.name.message || "El nombre es requerido")}
                  </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    variant="outlined"
                    type="number"
                    margin="normal"
                    id="phone"
                    label="Teléfono de la companía"
                    name="phone"
                    size="small"
                    error={(errors.phone ? true : false)}
                    inputRef={register({ minLength: 3 })}
                  />
                  <FormHelperText>
                    {errors?.phone && (errors.phone.message)}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => clearErrors()} type="submit" size="small">
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <UpdateLogo open={open} onUpdate={handleUpdateLogo} onClose={handleClose} />
    </Box>
  );
}

export default EditCompany;

import baseAPI from './api';
import { Booking } from '../components/booking/booking.model'
import { Resource } from '../components/resource/resource.model';
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';
import moment from 'moment-timezone';
import { Paginated } from '../components/commons/Paginated';

const create = async (booking: Booking, timezone: string, cancelToken?: CancelToken) => {
  const { title, user, bookingStart, bookingEnd, resource, isRecurrent, recurrenceWeekdays, recurrenceEndDate } = booking
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }

  const response = await baseAPI.post(`booking`,
    {
      title,
      user,
      bookingStart: moment(bookingStart).tz(timezone as string).toDate(),
      bookingEnd: moment(bookingEnd).tz(timezone as string).toDate(),
      resourceId: resource,
      isRecurrent,
      recurrenceWeekdays,
      recurrenceEndDate
    },
    axiosConfig
  );
  return response.data as Success<string>
}

const cancel = async (bookingId: string, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.post(`booking/cancel/${bookingId}`, null, axiosConfig);
  return response.data as Success<string>
}

const cancelRecurent = async (bookingId: string, from?: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }

  const data = {
    isRecurrent: true,
    from
  }

  const response = await baseAPI.post(`booking/cancel/${bookingId}`, data, axiosConfig);
  return response.data as Success<string>;
}

const update = async (booking: Booking) => {
  const data = {
    title: booking.title,
    bookingStart: moment(booking.bookingStart).toDate(),
    bookingEnd: moment(booking.bookingEnd).toDate()
  }
  const response = await baseAPI.put(`/booking/${booking.id}`, data);
  return response.data as Success<string>;
}

const list = async (
  query: string, 
  offset: number, 
  limit: number, 
  order: string, 
  orderBy: string, 
  resourceType: string,
  floor: string,
  dateFrom: Date,
  cancelToken?: CancelToken,
) => {
  const config: AxiosRequestConfig = {}
  config.params = { query, offset, limit, order, orderBy, resourceType, floor, dateFrom }
  if (cancelToken) {
    config.cancelToken = cancelToken
  }
  const res = await baseAPI.get(`booking`, config)
  return res.data as Paginated<Booking>
}

const remove = async (id: string) => {
  const res = await baseAPI.delete(`booking/${id}`)
  return res.data as Success<string>
}

const userBookings = async (bookingStart: Date, bookingEnd: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.post(`booking/user`, { bookingStart, bookingEnd }, axiosConfig)
  return response.data as Booking[]
}

const resourceBookings = async (resourceIds: string[], bookingStart: Date, bookingEnd: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.post(`booking/resource`, { resourceIds, bookingStart, bookingEnd }, axiosConfig)
  return response.data as Booking[]
}

const bokingInfo = async (bookingId: string, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`booking/${bookingId}`, axiosConfig);
  return response.data as Booking
}

const free = async (booking: Booking, nameQuery: string, floor: string, type: string, extra: string, cancelToken?: CancelToken) => {
  let data: any = {
    bookingStart: booking.bookingStart,
    bookingEnd: booking.bookingEnd,
    isRecurrent: booking.isRecurrent,
    recurrenceWeekdays: booking.recurrenceWeekdays,
    recurrenceEndDate: booking.recurrenceEndDate
  }

  if (!!floor) {
    data = { ...data, floor }
  }
  if (!!type) {
    data = { ...data, type }
  }
  if (!!extra) {
    data = { ...data, extra }
  }
  if (!!nameQuery && nameQuery.trim() !== '') {
    data = { ...data, nameQuery }
  }
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.post<Resource[]>(`booking/free`, data, axiosConfig)
  return response.data as Resource[]
}

const resources = async (bookingStart: Date, bookingEnd: Date, nameQuery: string, floor: string, type: string, extra: string, cancelToken?: CancelToken) => {
  let data: any = { bookingStart, bookingEnd }
  if (!!floor) {
    data = { ...data, floor }
  }
  if (!!type) {
    data = { ...data, type }
  }
  if (!!extra) {
    data = { ...data, extra }
  }
  if (!!nameQuery && nameQuery.trim() !== '') {
    data = { ...data, nameQuery }
  }
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }

  const response = await baseAPI.post<Resource[]>(`booking/resources`, data, axiosConfig)
  return response.data
}

const nextBookings = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`booking/next`, axiosConfig);
  return response.data as Booking[]
}

const terminateBooking = async (bookingId: string, date: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.post(`booking/terminate/${bookingId}/${date}`, axiosConfig)
  return response.data as Success<string>
}

export const bookingService = {
  create,
  cancel,
  cancelRecurent,
  update,
  list,
  remove,
  free,
  userBookings,
  resourceBookings,
  resources,
  bokingInfo,
  nextBookings,
  terminateBooking
};
import moment from 'moment'
import { useState, useCallback, FC } from 'react';
import Agenda from '../../components/booking/agenda/Agenda';
import BookingMap from '../../components/booking/bookingMap/BookingMap';
import { Booking } from '../../components/booking/booking.model';
import { Resource } from '../../components/resource/resource.model';
import { bookingService } from '../../_services/booking.service';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import BookingCreateDialog from '../../components/booking/bookingForm/BookingCreateDialog';
import { useNextBooking } from '../../components/commons/nextBookingsContext/NextBookingHook';
import { useLaborable } from '../../components/commons/laborableContext/LaborableHook';
import useCancelToken from '../../hooks/useCancelToken';

require('moment/locale/es.js')

const AgendaBooking: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [booking, setBooking] = useState<Booking>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const { getCancelToken, isCancel } = useCancelToken();
  const { showInfo, showError, showSuccess } = useSnackBars();
  const { updateNextBookings } = useNextBooking();
  const handleError = useErrorHandler();
  const { weeklySchedule, refreshLaborable } = useLaborable();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg);
      if (field === "bookingStart" || field === "bookingEnd") {
        refreshLaborable();
      }
    })
  }

  const handleNewEvent = (booking: Booking) => {
    setBooking(booking);
    setActiveStep(1);
  }

  const handleResource = async (resource: Resource) => {
    setBooking({ ...booking, resource })
    setOpenConfirm(true)
  }

  const handleCancelBooking = async (bookingId: string) => {
    try {
      const res = await bookingService.cancel(bookingId);
      updateNextBookings()
      showSuccess(res.msg);
      setActiveStep(0);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
    return false;
  }

  const handleUpdateBooking = async (_booking: Booking) => {
    try {
      const res = await bookingService.update(_booking);
      updateNextBookings()
      showSuccess(res.msg);
      setActiveStep(0);
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleCancelRecurrentBooking = useCallback(
    async (bookingId: string, from?: Date) => {
      try {
        const res = await bookingService.cancelRecurent(bookingId, from);
        updateNextBookings();
        showSuccess(res.msg);
        setActiveStep(0);
      } catch (error) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }, [])

  const handleCancelResource = async () => {
    setActiveStep(0);
    showInfo('Nueva reserva cancelada. Seleccione un recurso en el plano para reservar.');
  }

  /* HANDLE BOOKING FINISH */
  const handleFinishBooking = async (bookingId: string) => {
    try {
      const cancelToken = getCancelToken()
      const terminateDate = moment().startOf('second').toDate();
      await bookingService.terminateBooking(bookingId, terminateDate, cancelToken)
      showSuccess("Recurso liberado")
      updateNextBookings()
    } catch (error) {
      error instanceof Error
        ? handleError(error)
        : handleApiError(error.errors);
    }
  }

  const stepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Agenda
          onNewEvent={handleNewEvent}
          onFinishBooking={handleFinishBooking}
          onCancelBooking={handleCancelBooking}
          onCancelRecurrentBooking={handleCancelRecurrentBooking}
          onUpdateBooking={handleUpdateBooking}
        />;
      case 1:
        return <BookingMap onResourceSelected={handleResource} onCancel={handleCancelResource} booking={booking}></BookingMap>
    }
  }

  const closeDialog = () => {
    setActiveStep(0);
    setOpenConfirm(false);
    setBooking(null)
  }

  const confirm = async (booking: Booking) => {
    try {
      const cancelToken = getCancelToken()
      const res = await bookingService.create(
        { ...booking, 'resource': (booking.resource as Resource).id } as Booking,
        weeklySchedule.timezone,
        cancelToken
      );
      updateNextBookings();
      showSuccess(res.msg);
      setActiveStep(0);
      closeDialog();
    } catch (error) {
      if (!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  }

  return (
    <>
      {stepContent(activeStep)}
      <BookingCreateDialog
        open={openConfirm}
        booking={booking}
        showUserSelect={false}
        onCreate={confirm}
        onClose={closeDialog}
      />
    </>
  )
}

export default AgendaBooking;
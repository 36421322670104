import { FC } from "react";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { HeatmapReport, WeekdayPercentages } from "./heatmapReport.model";
import { FULL_WEEK_DAYS } from "../../../_helpers/constants";

type Props = {
  height: number;
  report: HeatmapReport;
}

const BookingsHeatmap: FC<Props> = ({ height, report }) => {
  return (
    <Chart
      type="heatmap"
      options={generateChartOptions(height, report.type, report.xAxisHours)}
      series={generateChartSeries(report.data)}
    />
  );
}

// https://apexcharts.com/docs/options/chart/toolbar/
function generateChartOptions(height: number, title: string, xAxisHours: number[]) {
  const options: ApexOptions = {
    chart: {
      height: height,
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 0,
        enableShades: false,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: -1,
              to: 0,
              name: "No reservable",
              color: "#eeeeee"
            },
            {
              from: 0,
              to: 0.25,
              name: 'Muy baja',
              color: '#00CE68'
            },
            {
              from: 0.26,
              to: 0.5,
              name: 'Baja',
              color: '#F5BB35'
            },
            {
              from: 0.51,
              to: 0.75,
              name: 'Media',
              color: '#CC6B15'
            },
            {
              from: 0.76,
              to: 1,
              name: 'Alta',
              color: '#CE270C'
            }
          ]
        }
      }
    },
    xaxis: {
      type: "category",
      categories: xAxisHours.map(hour => hour + "hs")
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1
    },
    title: {
      text: title,
      align: "center",
      style: {
        fontFamily: "inherit"
      }
    },
    tooltip: {
      x: {
        show: false
      },
      y: {
        formatter: (value) => {
          if (value >= 0) {
            const percentage = (value * 100).toFixed(2);
            return percentage.replace(".", ",") + "%";
          } else {
            return "No reservable";
          }
        },
        title: {
          formatter: (seriesName) => ""
        }
      }
    }
  }

  return options;
}

function generateChartSeries(weekdayPercentages: WeekdayPercentages[]) {

  let series: ApexAxisChartSeries = [];
  FULL_WEEK_DAYS.forEach((day, index) => {
    const weekdayPercentage = weekdayPercentages.find(weekdayPercentage => weekdayPercentage.weekDay === index);
    if (weekdayPercentage) {
      series.push({
        name: day,
        data: weekdayPercentage.percentages
      });
    }
  });

  return series;
}

export default BookingsHeatmap;
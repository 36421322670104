import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Resource } from '../../resource/resource.model';
import { resourceService } from '../../../_services/resource.service';
import { TextField, FormControl } from '@material-ui/core';
import { Paginated } from '../../commons/Paginated';
import transferListUtils from '../../commons/utils/TransferListUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    formControl: {
      padding: theme.spacing(0, 2, 2, 0)
    },
  }),
);

type Props = {
  selectedResources: Resource[],
  handleSelectedResources: (selectedResources: Resource[]) => void
}

const { not, intersection, union } = transferListUtils<Resource>();

const ResourceTransferList: React.FC<Props> = ({ selectedResources, handleSelectedResources }) => {
  const classes = useStyles();
  const [query, setQuery] = React.useState<string>('');
  const [checked, setChecked] = React.useState<Resource[]>([]);
  const [left, setLeft] = React.useState<Resource[]>(selectedResources);
  const [right, setRight] = React.useState<Resource[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  React.useEffect(() => {
    handleSelectedResources(left);
    // eslint-disable-next-line
  }, [left])

  React.useEffect(() => {
    const fetchData = async () => {
      const resources: Paginated<Resource> = await resourceService.list(query, false, 0, Number.MAX_SAFE_INTEGER);
      // Falta agregar param query a list, ver backend y field Text para buascar.
      const unAssigned: Resource[] = resources.docs.filter(r => !left.some(l => l.id === r.id));
      setRight(unAssigned);
    }
    fetchData()
    // eslint-disable-next-line
  }, [query]);

  const handleSearch = (query: string) => setQuery(query);

  const handleToggle = (value: Resource) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: Resource[]) => intersection(checked, items).length;

  const handleToggleAll = (items: Resource[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: Resource[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list" >
        {items.map((value: Resource) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <FormControl fullWidth className={classes.formControl}>
        <TextField
          id="query" label="Filtrar recursos" name="query" variant="outlined" size="small"
          onChange={e => handleSearch(e.target.value)}
        />
      </FormControl>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          {customList('Asignados', left)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList('Sin asignar', right)}
        </Grid>
      </Grid>
    </>
  );
}

export default ResourceTransferList;
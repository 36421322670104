import React, { useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';

import FAB from '../../components/commons/buttons/FAB';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import GroupsList from '../../components/resourceGroup/ResourceGroupList';
import GroupDialog from '../../components/resourceGroup/ResourceGroupDialog';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from "../../components/commons/dialogs/ConfirmDialog";
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';

import { ResourceGroup } from '../../components/resourceGroup/resourceGroup.model';
import { Resource } from '../../components/resource/resource.model';
import { resourceGroupService } from '../../_services/resourcegroup.service';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useCancelToken from '../../hooks/useCancelToken';

const ResourcesGroups: React.FC = () => {

  const [groups, setGroups] = React.useState<ResourceGroup[]>();
  const [query, setQuery] = React.useState<string>('');
  const [group, setGroup] = React.useState<ResourceGroup>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const { getCancelToken, isCancel } = useCancelToken()
  const { showSuccess, showError } = useSnackBars()
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      showError(e.msg)
    })
  }

  const fetchGroups = useCallback(async () => {
    try {
      const cancelToken = getCancelToken()
      const groups = await resourceGroupService.list(query, cancelToken);
      setGroups(groups);
    } catch (error) {
      if(!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  
  // Búsqueda de grupos
  // eslint-disable-next-line
  useEffect(() => { fetchGroups() }, [fetchGroups]);


  const handleSearch = (query: string) => setQuery(query);

  // Handlers para el Dialog de creación/edición
  const handleCreateDialog = () => {
    setGroup(null);
    toggleDialog();
  }

  const handleUpdateDialog = (group: ResourceGroup) => {
    setGroup(group);
    toggleDialog();
  }

  const toggleDialog = () => setOpenDialog(!openDialog);

  // Handlers para el ABM de Grupos
  const handleCreate = async (group: ResourceGroup) => {
    try {
      const res = await resourceGroupService.create(group);
      showSuccess(res.msg)
      fetchGroups();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleUpdate = async (group: ResourceGroup) => {
    try {
      const res = await resourceGroupService.update(group);
      showSuccess(res.msg)
      fetchGroups();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleDelete = async (group: ResourceGroup) => {
    try {
      const res = await resourceGroupService.remove(group.id)
      showSuccess(res.msg)
      fetchGroups()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleRemoveResource = async (group: ResourceGroup, resource: Resource) => {
    try {
      const res = await resourceGroupService.removeResource(group.id, resource.id)
      showSuccess(res.msg)
      fetchGroups()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const openDeleteDialog = (group: ResourceGroup) => {
    setGroup(group);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nuevo grupo" onClick={handleCreateDialog} />
      </Box>
      {!groups || groups.length === 0 ? <EmptyResults /> :
        <GroupsList
          groups={groups}
          onDelete={openDeleteDialog}
          onUpdate={handleUpdateDialog}
          removeResource={handleRemoveResource} />
      }
      <GroupDialog
        open={openDialog}
        onClose={toggleDialog}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        group={group}
      />
      <ConfirmDialog<ResourceGroup>
        open={openDelete}
        title="Eliminando Grupo de Recursos"
        subtitle="¿Deseas continuar con la eliminación?"
        holder={group}
        onClose={closeDeleteDialog}
        onAccept={handleDelete}
      />
    </>
  );

}

export default ResourcesGroups;
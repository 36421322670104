import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authenticationService } from './authentication.service'
import { configService } from './config.service';

const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
})

baseAPI.interceptors.request.use((config: AxiosRequestConfig) => {
  const user = authenticationService.currentUserValue
  config.headers['Authorization'] = `Bearer ${user?.token}`
  return config
})

baseAPI.interceptors.request.use((config: AxiosRequestConfig) => {
  const branch = configService.getCurrentBranch()
  if (branch) {
    config.headers["Branch"] = branch;
  }
  return config;
});

baseAPI.interceptors.response.use((response: AxiosResponse) => {
  return response
}, (error: AxiosError) => {
  if (error.response) {
    // client received response with 4xx/5xx error
    if ((error.response.status === 403 || error.response.status === 401) && !window.location.href.endsWith('/login')) {
      authenticationService.logout()
      window.location.href = '/login'
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.response.data)
  } else if (error.request) {
    // client never received a response, or request never left
    // TODO implementar una notificación de error de conexión, no debería ir como error boundary
    return Promise.reject(error)
  }
  // not an axios error
  return Promise.reject(error)
})

export default baseAPI
import moment from 'moment';
import { FC, useEffect, useCallback, useState } from 'react'
import { Box, Chip, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Resource } from '../../resource/resource.model'
import ResourceCalendar from '../agenda/ResourceCalendar'
import { Booking } from '../booking.model'
import { EmptyHeader, ResourceEmptyHeader } from '../agenda/ResourceHeader'
import { Close, Favorite, FavoriteBorder } from '@material-ui/icons'
import { useConfig } from '../../commons/configContext/ConfigHook'
import CalendarToolbarEmpty from '../bookingToolbar/CalendarToolbarEmpty'
import { useCalendarContext } from '../agenda/DateRangeProvider';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import BookingCreateDialog from '../bookingForm/BookingCreateDialog';
import useSnackBars from '../../commons/snackbar/SnackbarHook';
import validateBookingChangeRange from "../../commons/utils/validateBookingChangeRange";
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
  },
  menuContent: {
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  lineItem: {
    padding: theme.spacing(0.75),
  }
}));

type Props = {
  bookings: Booking[];
  resource: Resource;
  onNewBooking: (booking: Booking) => void;
  onSelectBooking: (bookingId: string) => void;
  onclose: () => void;
}

const BookingSidebar: FC<Props> = ({ bookings, resource, onNewBooking, onSelectBooking, onclose }) => {
  const classes = useStyles();
  const { calendarData } = useCalendarContext();
  const [booking, setBooking] = useState<Booking>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { config, addFavouriteResource, removeFavouriteResource } = useConfig();
  const { weeklySchedule } = useLaborable();
  const { showError } = useSnackBars();
  const { height } = useWindowDimensions();

  useEffect(() => {
    setBooking(null); // remove selected by user on calendar navigation
  }, [bookings, calendarData.date]);

  /* RESOURCE CALENDAR HANDLERS */

  const getBookings = () => !!booking ? [...bookings, booking] : bookings;

  const handleNewEvent = useCallback((booking: Booking) => {
    setBooking(booking)
    setOpen(true)
  }, [])

  const handleSelectEvent = (bookingId: string) => {
    onSelectBooking(bookingId);
  }

  /* SIDEBAR HANDLERS */

  const handleClose = () => {
    onclose();
  }

  const isFaved = () => config.favouriteResources.some((r: string) => r === resource.id);

  const handleFavouriteResource = () => {
    isFaved() ?
      removeFavouriteResource(resource.id) :
      addFavouriteResource(resource.id);
  }

  /* BOOKING CREATE DIALOG HANDLERS */

  const closeDialog = () => {
    setOpen(false)
    setBooking(null)
  }

  const handleCreate = async (booking: Booking) => {
    onNewBooking({ ...booking, resource: (booking.resource as Resource).id });
    closeDialog();
  }

  const handleTimeChange = (
    begin: moment.Moment,
    end: moment.Moment,
    title: string,
    isRecurrent?: boolean,
    recurrenceWeekdays?: boolean[],
    recurrenceEndDate?: Date) => {
    if (validateBookingChangeRange(begin, end, { ...booking, isRecurrent, recurrenceWeekdays }, weeklySchedule)) {
      setBooking({
        ...booking,
        bookingStart: begin.toDate(),
        bookingEnd: end.toDate(),
        title,
        isRecurrent,
        recurrenceWeekdays,
        recurrenceEndDate
      })
    } else {
      showError('No es posible realizar una reserva para la combinación de días.');
    }
  }

  // FIXME ¿Es posible evitar este cálculo? No quiero usar posiciones absolutas ni height porcentuales
  const _getContentHeight = () => height // window
    - 64 // Main Bar
    - 63 // Filter Bar
    - 16 // paddings
    - 16 // margins
    - 48 // header

  return (
    <>
      <Paper className={classes.paper}>
        {/* HEADER */}
        <Box display='flex' justifyContent='space-between' alignItems='center' height={48}>
          <Tooltip title={isFaved() ? "Quitar de favoritos" : "Añadir a favoritos"} >
            <IconButton onClick={handleFavouriteResource}>
              {isFaved() ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Nombre del recurso">
            <Typography variant='h5'>{resource?.name}</Typography>
          </Tooltip>
          <Tooltip title="Cerrar menú">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        {/* CONTENT */}
        <Box
          className={classes.menuContent}
          height={_getContentHeight()}
          display='flex'
          flexDirection='column'>
          <Box className={classes.lineItem}>
            <b>Tipo: </b>{resource?.type.name}
          </Box>
          <Box className={classes.lineItem}>
            <b>Extras: </b>{resource?.amenities?.length
              ? resource.amenities.map((value, index) => (<Chip key={`${value.id}-${index}`} label={value.name} />))
              : 'Ninguno'}
          </Box>
          <Box flexGrow={1} margin={.5}>
            <ResourceCalendar
              hideHeader
              resources={[resource]}
              bookings={getBookings()}
              currentBooking={booking}
              onNewEvent={handleNewEvent}
              onEventSelect={handleSelectEvent}
              views={['day']}
              components={{ toolbar: CalendarToolbarEmpty, resourceHeader: ResourceEmptyHeader, header: EmptyHeader }}
            />
          </Box>
        </Box>
      </Paper>
      <BookingCreateDialog
        open={open}
        booking={booking}
        onClose={closeDialog}
        onCreate={handleCreate}
        onChangeTime={handleTimeChange}
        showUserSelect={true}
      />
    </>
  )
}

export default BookingSidebar
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { User } from '../../components/users/user.model';
import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { userService } from '../../_services/user.service'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';
import { parseQueryParams } from '../../_helpers/routes';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';

export interface RegisterData {
  password?: string
  passwordMatch?: string
}

const WebClientRegisterForm: React.FC = () => {
  const classes = useFormsPublicStyles();
  const [params, setParams] = React.useState<URLSearchParams>();
  const [password, setPassword] = React.useState();
  const { register, handleSubmit, errors, setError } = useForm<RegisterData>();
  const { showError } = useSnackBars();
  const { loginWithToken } = useCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    errors = errors.errors;
    if (errors?.api?.param === 'api') {
      showError(errors.api.msg);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    } else {
      Object.keys(errors).forEach((field) => {
        const e = errors[field]
        setError(e.param, { type: e.param, message: e.msg })
      })
    }
  }

  useEffect(() => {
    const _params = parseQueryParams(location);
    setParams(_params)
  }, [location])

  const create = async (formData: RegisterData) => {
    try {
      const client: User = {
        firstname: params.get("firstname"),
        lastname: params.get("lastname"),
        email: params.get("email"),
        password: formData.password
      }
      const res = await userService.webRegister(client)
      loginWithToken(res.accessToken)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error)
    }
  }

  const handlePassword = (e: any) => {
    setPassword(e.target.value)
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Crea tu cuenta</Typography>
            </Box>
            <form onSubmit={handleSubmit(create)}>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.password ? true : false}
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePassword}
                inputRef={register({ required: { value: true, message: "La contraseña es obligatoria" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.password && errors.password.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={(errors.passwordMatch ? true : false)}
                fullWidth
                type="password"
                name="passwordMatch"
                label="Repetir Contraseña"
                id="passwordMatch"
                autoComplete="current-password"
                inputRef={register({ validate: value => value === password || "Las contraseñas no coinciden" })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.passwordMatch && errors.passwordMatch.message}
              </FormHelperText>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Siguiente
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <RouterLink to="/login">Ya tengo usuario</RouterLink>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default WebClientRegisterForm
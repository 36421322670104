import React, { useState } from "react"
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Favorite, People, Room, Settings, Today, BarChart, HomeWork } from '@material-ui/icons';
import { ROLES } from '../../_helpers/constants';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
    '& .Mui-selected': {
      borderLeft: `solid 5px ${theme.palette.secondary.main}`
    }
  },
}))

export const MenuListItems = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { user } = useCurrentUser()
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement> | null, index: number) => {
    setSelectedIndex(index);
  };
  return (
    <div className={classes.container}>
      {user && user.role === ROLES.user &&
        <>
          <Link to={'/'}>
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}>
              <ListItemIcon>
                <Today />
              </ListItemIcon>
              <ListItemText primary="Calendario" />
            </ListItem>
          </Link>
          <Link to={'/blueprintAgenda'}>
            <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}>
              <ListItemIcon>
                <Room />
              </ListItemIcon>
              <ListItemText primary="Recursos" />
            </ListItem>
          </Link>
          <Link to={'/resourceAgenda'}>
            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}>
              <ListItemIcon>
                <Favorite />
              </ListItemIcon>
              <ListItemText primary="Favoritos" />
            </ListItem>
          </Link>
        </>
      }
      {user && user.role === ROLES.admin &&
        <>
          <Link to={'/reports'}>
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItem>
          </Link>
          <Link to={'/bookings'}>
            <ListItem
              button
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}>
              <ListItemIcon>
                <Today />
              </ListItemIcon>
              <ListItemText primary="Reservas" />
            </ListItem>
          </Link>
          <Link to={'/resource'}>
            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}>
              <ListItemIcon>
                <Room />
              </ListItemIcon>
              <ListItemText primary="Recursos" />
            </ListItem>
          </Link>
          <Link to={'/user'}>
            <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary="Usuarios" />
            </ListItem>
          </Link>
          <Link to={'/company'}>
            <ListItem
              button
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}>
              <ListItemIcon>
                <HomeWork />
              </ListItemIcon>
              <ListItemText primary="Compañía" />
            </ListItem>
          </Link>
          <Link to={'/config'}>
            <ListItem
              button
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Configuración" />
            </ListItem>
          </Link>
        </>
      }
    </div>
  )
};
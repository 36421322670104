import { Snackbar, IconButton } from "@material-ui/core";
import { Close, Check } from '@material-ui/icons'
import { createContext, useEffect, useState, FC, useCallback } from "react"
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';

/**
 * Componente proveedor de mensajes en snackbars desplegables.
 * Sobreescrituras:
 * showSeverity(message) -> Renderiza un snackbar sin acciones que se disipa al pasar
 * un tiempo determinado mostrando como mensaje el parámetro message (string).
 * showSeverity(message, onReject) -> Renderiza un snackbar que además de mostrar el mensaje,
 * añade un botón con una [✗] el cual ejecuta el callback (ó promesa) "onReject" y luego disipa el snackbar.
 * showSeverity(message, onReject, onAccept) -> Actúa de manera similar al anterior sólo que agregando un botón
 * con un check [✓] el cual ejecuta el callback/promesa "onAccept".
 * 
 * Dado que el método "onClose" (disipar el snackbar) siempre es ejecutado luego de realizar una acción, se puede
 * obtener un snackbar cuya única acción sea cerrarse por acción del usuario si se pasa un callback sin líneas, por
 * ejemplo: showSeverity(messaje, () => {}).
 */

type SnackBarContextState = {
  alerts: Alert[];
  showSuccess: (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => void;
  showInfo: (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => void;
  showWarning: (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => void;
  showError: (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => void;
};

const SnackBarContextValues: SnackBarContextState = {
  alerts: [],
  showSuccess: () => { },
  showInfo: () => { },
  showWarning: () => { },
  showError: () => { }
};

type Alert = {
  message: string,
  type: Color,
  onReject?: () => any | Promise<any>,
  onAccept?: () => any | Promise<any>
}

function MyAlert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackBarContext = createContext(SnackBarContextValues);

const AUTO_DISMISS = 5000

const SnackBarProvider: FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  const activeAlertIds = alerts.join(',')
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(() => {
        if (!alerts[alerts.length - 1].onReject) {
          setAlerts((alerts) => alerts.slice(0, alerts.length - 1))
        }
      }, AUTO_DISMISS)
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const showSuccess = useCallback(
    (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => {
      setAlerts((alerts) => [{ message, type: "success", onReject, onAccept }, ...alerts]);
    }, [])

  const showInfo = useCallback(
    (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => {
      setAlerts((alerts) => [{ message, type: "info", onReject, onAccept }, ...alerts]);
    }, [])

  const showWarning = useCallback(
    (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => {
      setAlerts((alerts) => [{ message, type: "warning", onReject, onAccept }, ...alerts]);
    }, [])

  const showError = useCallback(
    (message: string, onReject?: () => any | Promise<any>, onAccept?: () => any | Promise<any>) => {
      setAlerts((alerts) => [{ message, type: "error", onReject, onAccept }, ...alerts]);
    }, [])


  const chooseActions = (alert: Alert) => {
    if (alert.onReject) {
      return alert.onAccept ?
        <>
          <IconButton size="small" color="inherit"
            onClick={e => {
              alert.onAccept();
              handleClose();
            }} >
            <Check fontSize="small" />
          </IconButton>
          <IconButton size="small" color="inherit"
            onClick={e => {
              alert.onReject();
              handleClose();
            }} >
            <Close fontSize="small" />
          </IconButton>
        </> :
        <IconButton size="small" color="inherit"
          onClick={e => {
            alert.onReject();
            handleClose();
          }} >
          <Close fontSize="small" />
        </IconButton>
    } else {
      return undefined;
    }
  }

  const handleClose = () => { setAlerts(alerts.slice(1)) }
  const value = { showSuccess, showInfo, showWarning, showError, alerts }
  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert, index) =>
        <Snackbar
          key={`alert-message-${index}`}
          open={!!alerts && alerts.length > 0}
          onClose={handleClose}>
          <MyAlert
            severity={alert.type}
            action={chooseActions(alert)}
          >
            {alert.message}
          </MyAlert>
        </Snackbar>)
      }
    </SnackBarContext.Provider>
  )
}
export default SnackBarProvider;
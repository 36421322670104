import moment from 'moment-timezone'
import { Event } from 'react-big-calendar'
import { Booking } from '../booking.model';

class BookingEvent implements Event {

  public booking: Booking;

  constructor(booking: Booking) {
    this.booking = booking;
  }

  get title(): string {
    return this.booking.title;
  }

  get start(): Date {
    return moment(this.booking.bookingStart).toDate();
  }

  get end(): Date {
    return moment(this.booking.bookingEnd).toDate();
  }

  get allDay(): boolean {
    return false;
  }

  get resourceId(): any {
    return this.booking.resource;
  }
}

export default BookingEvent;
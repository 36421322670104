import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, Chip, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons from '../commons/buttons/FormActionButtons';
import { userService } from '../../_services/user.service';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { Controller, useForm } from "react-hook-form";
import { UserGroup } from '../user-groups/userGroup.model';
import { User } from './user.model';
import { Role } from './role.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

type Props = {
  open: boolean,
  user?: User,
  roles: Role[],
  groups: UserGroup[],
  onClose: () => void,
  onCreate: () => void
}

const UpdateUser: React.FC<Props> = ({ open, user, roles, groups, onClose, onCreate }) => {
  const classes = useStyles();
  const { showSuccess, showError } = useSnackBars()

  const defaultValues = {
    id: user?.id,
    firstname: user?.firstname,
    lastname: user?.lastname,
    email: user?.email,
    role: user?.role,
    groups: user?.groups ?
      groups.filter(g => user.groups.some(ug => g.id === ug.id))
      : []
  }

  const { register, handleSubmit, reset, control, errors, setError } = useForm<User>({ defaultValues });
  const handleError = useErrorHandler();

  useEffect(() => {
    reset(
      {
        ...defaultValues,
        role: user ? roles.filter(r => r.id === user?.role.id)[0] : roles[1]
      }
    );
    // eslint-disable-next-line
  }, [user])

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const closeDialog = () => { onClose() }

  const handleCancel = () => { onClose() }

  const onSubmit = async (userData: User) => {
    try {
      if (!!user) {
        userData.id = user.id
        const res = await userService.update(userData)
        showSuccess(res.msg)
      } else {
        const res = await userService.create(userData)
        showSuccess(res.msg)
      }
      onCreate();

    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {user ? 'Actualizar' : 'Crear'} Usuario
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="firstname"
              label="Nombre"
              name="firstname"
              error={(errors.firstname ? true : false)}
              variant="outlined"
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un nombre" } })}
            />
            <FormHelperText error={true}>
              {errors?.firstname && (errors.firstname.message || "El nombre es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="lastname"
              label="Apellido"
              name="lastname"
              variant="outlined"
              error={(errors.lastname ? true : false)}
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un apellido" } })}
            />
            <FormHelperText error={true}>
              {errors?.lastname && (errors.lastname.message || "El apellido es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="email"
              label="Email"
              name="email"
              variant="outlined"
              error={(errors.email ? true : false)}
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un email" } })}
            />
            <FormHelperText error={true}>
              {errors?.email && (errors.email.message || "El email es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
            <InputLabel htmlFor="role-select-input">
              Rol
            </InputLabel>
            <Controller
              defaultValue={roles[1]}
              as={
                <Select
                  label="Role"
                  inputProps={{
                    name: 'role',
                    id: 'role-select-input',
                  }}>
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role as any}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="role"
              error={(errors.role ? true : false)}
              rules={{ required: true }}
              control={control}
            >
            </Controller>
            <FormHelperText error={true}>
              {errors?.role && ((errors.role as any)?.message || "El rol es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl size="small" variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="usergroup-label">Grupos</InputLabel>
            <Controller
              as={
                <Select
                  multiple
                  label="Groups"
                  labelId="groups-label"
                  inputProps={{
                    name: 'groups',
                    id: 'groups-select-input',
                  }}
                  renderValue={(selected) => {
                    return (
                      <div className={classes.chips}>
                        {(selected as UserGroup[]).map((value) => (
                          <Chip key={value.id} label={value.name} className={classes.chip} />
                        ))}
                      </div>
                    )
                  }} >
                  {groups.map((g) => (
                    <MenuItem key={g.id} value={g as any} >
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name={"groups"}
              control={control}
              rules={{ required: true }}
            />
            <FormHelperText>
              {errors?.groups && (errors.groups as any)?.message}
            </FormHelperText>
          </FormControl>
          <FormActionButtons
            actionText={!user ? 'Crear' : 'Actualizar'}
            onCancel={handleCancel}
          />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateUser

import React, { FC, ChangeEvent, MouseEvent } from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TablePagination, LabelDisplayedRowsArgs, TableContainer, Tooltip, Paper } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { Paginated } from '../commons/Paginated';
import { User } from './user.model';
import useTableStyles from '../../hooks/styles/tableStyles';

type Order = "asc" | "desc";

type Props = {
  users: Paginated<User>;
  order: Order;
  orderBy: keyof User;
  onSort: (e: MouseEvent<unknown>, property: keyof User) => void;
  onDelete: (user: User) => void;
  onUpdate: (user: User) => void;
  onChangePage: (user: number) => void;
  onRowsPerPageChange: (rows: number) => void;
}

const UsersList: FC<Props> = ({ users, order, orderBy, onSort, onDelete, onUpdate, onChangePage, onRowsPerPageChange }) => {
  const classes = useTableStyles();

  const handleDelete = (user: User) => {
    onDelete(user);
  }
  const handleUpdate = (user: User) => {
    onUpdate(user)
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const pagesLimit = ({ from, count }: LabelDisplayedRowsArgs) => {
    return '' + (Math.trunc(from / users.limit + 1)) + ' de ' + ((count % users.limit) === 0 ?
      (Math.trunc(count / users.limit)) : (Math.trunc(count / users.limit + 1)))
  }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={orderBy === "firstname" ? order : false}>
              <TableSortLabel
                active={orderBy === "firstname"}
                direction={orderBy === "firstname" ? order : "asc"}
                onClick={e => onSort(e, "firstname")}>
                Nombre
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "lastname" ? order : false}>
              <TableSortLabel
                active={orderBy === "lastname"}
                direction={orderBy === "lastname" ? order : "asc"}
                onClick={e => onSort(e, "lastname")}>
                Apellido
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === "email" ? order : false}>
              <TableSortLabel
                active={orderBy === "email"}
                direction={orderBy === "email" ? order : "asc"}
                onClick={e => onSort(e, "email")}>
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell>Rol</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>

        <TableBody className={"body"}>
          {users.docs.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.firstname}</TableCell>
              <TableCell>{user.lastname}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role.name}</TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(user)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(user)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={users.totalDocs}
        page={users.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={users.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={pagesLimit}
        labelRowsPerPage=""
        title="Cantidad de resultados por página"
        nextIconButtonText="Siguiente"
        backIconButtonText="Anterior"
        className={classes.tablePagination}
      />
    </TableContainer>
  );
}

export default UsersList;
import baseAPI from './api';
import { ResourceType } from '../components/resource/resourceType.model';
import { AxiosRequestConfig, CancelToken } from 'axios';

const list = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`resourcetype`, axiosConfig)
  return response.data as ResourceType[]
}

const availableList = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if (cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`resourcetype/available`, axiosConfig)
  return response.data as ResourceType[]
}

export const resourceTypeService = {
  list,
  availableList
};
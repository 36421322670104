import React from 'react';
import { useForm } from "react-hook-form";
import { Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons from '../commons/buttons/FormActionButtons';
import ResourceTransferList from './resourcesContainer/ResourceTransferList';
import { ResourceGroup } from './resourceGroup.model';
import { Resource } from '../resource/resource.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0)
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (group: ResourceGroup) => void;
  onUpdate?: (group: ResourceGroup) => void;
  group?: ResourceGroup;
}

const ResourceGroupDialog: React.FC<Props> = ({ open, onClose, onCreate, group, onUpdate }) => {
  const [selectedResources, setSelectedResources] = React.useState<Resource[]>([])
  const classes = useStyles();
  const defaultValues: ResourceGroup = {
    id: group?.id,
    name: group?.name,
    description: group?.description,
    resources: group?.resources
  }

  const { register, handleSubmit, reset, errors } = useForm<ResourceGroup>({ defaultValues });

  React.useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line
  }, [group])

  React.useEffect(() => {
    if (group && open) {
      setSelectedResources(group.resources);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleSelectedResources = (newSelectedResources: Resource[]) => {
    setSelectedResources(newSelectedResources)
  }

  const closeDialog = () => {
    setSelectedResources([])
    onClose()
  };
  const handleCancel = () => onClose();

  const onSubmit = (groupData: ResourceGroup) => {
    if (group) {
      groupData.id = group.id;
      groupData.resources = selectedResources;
      onUpdate(groupData);
    } else {
      onCreate(groupData);
    }
    reset(defaultValues);
    onClose();
  }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {group ? "Actualizar grupo" : "Crear grupo"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="name"
              label="Nombre del grupo"
              name="name"
              error={(errors.name ? true : false)}
              variant="outlined"
              size="small"
              inputRef={register({ required: { value: true, message: "Debe ingresar un nombre" } })}
            />
            <FormHelperText error={true}>
              {errors?.name && errors?.name.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="description" label="Descripción" name="description" multiline variant="outlined" size="small"
              inputRef={register({ required: false })}
            />
            <FormHelperText>
              {errors?.description && (<div className="error">Campo obligatório</div>)}
            </FormHelperText>
          </FormControl>
          {
            group
              ? <ResourceTransferList
                selectedResources={selectedResources}
                handleSelectedResources={handleSelectedResources}
              />
              : null
          }
          <FormActionButtons
            actionText={group ? "Actualizar" : "Crear"}
            onCancel={handleCancel}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ResourceGroupDialog;
import { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm, } from "react-hook-form";
import { Box, FormControl, FormHelperText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { userService } from '../../_services/user.service'
import { useHistory, useLocation } from 'react-router-dom';
import { useErrorHandler } from "../../components/errors/ErrorBoundary";
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { Link as RouterLink } from 'react-router-dom';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';

export interface ResetPasswordData {
  token: string
  password: string
  passwordMatch?: string
}

const SetResetPasswordForm: FC = () => {
  const classes = useFormsPublicStyles();
  const history = useHistory();
  const [password, setPassword] = useState();
  const { showSuccess, showError } = useSnackBars();
  const { register, handleSubmit, errors, setError } = useForm<ResetPasswordData>();
  const handleError = useErrorHandler();

  const handlePassword = (e: any) => {
    setPassword(e.target.value)
  }

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery()
  const token = query.get("token")
  const verify = query.get("verify")

  const onSubmit = async (data: ResetPasswordData) => {
    try {
      const resetData = { password: data.password, token: token }
      const res: any = await userService.resetPassword(resetData)
      verify && verify === "true" ? showSuccess("Tu cuenta ha sido activada correctamente") : showSuccess(res.msg)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    } finally {
      history.push("/login")
    }
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Solicitar nueva contraseña</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={errors.password ? true : false}
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handlePassword}
                inputRef={register({ required: { value: true, message: "La contraseña es obligatoria" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.password && errors.password.message}
              </FormHelperText>
              <TextField
                className={classes.textField}
                variant="filled"
                margin="normal"
                size="small"
                error={(errors.passwordMatch ? true : false)}
                fullWidth
                name="passwordMatch"
                label="Repetir Contraseña"
                type="password"
                id="passwordMatch"
                autoComplete="current-password"
                inputRef={register({ validate: value => value === password || "Las contraseñas no coinciden" })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.passwordMatch && errors.passwordMatch.message}
              </FormHelperText>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Guardar nueva contraseña
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <RouterLink to="/login">Volver al inicio de sesión</RouterLink>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default SetResetPasswordForm
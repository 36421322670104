import React from "react"
import { IconButton, InputAdornment, FormControl, makeStyles, OutlinedInputProps, TextField, Theme, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Clear } from "@material-ui/icons";
import Collapse from '@material-ui/core/Collapse';
import useSearch from "./SearchHook";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    borderRadius: 3,
    backgroundColor: theme.palette.grey[50],
    '& input': {
      paddingBlock: 4,
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
    },
  },
}));

type Props = {
  value: string;
  placeholder: string;
  onSearch: (query: string) => void;
  onBlur?: () => void;
}

const SearchInput: React.FC<Props> = ({ value, placeholder, onSearch, onBlur }) => {
  const classes = useStyles();
  const search = useSearch(value, onSearch);

  return (
    <FormControl>
      <TextField
        id="search"
        size="small"
        variant="outlined"
        placeholder={placeholder}
        inputRef={search.textfieldRef}
        onChange={search.handleQueryChange}
        onBlur={onBlur}
        autoFocus
        onKeyPress={e => search.handleKeyPress(e)}
        InputProps={
          {
            classes,
            disableUnderline: false,
            startAdornment:
              <InputAdornment position="start">
                <Tooltip title="Buscar">
                  <IconButton edge="start" onClick={search.handleSearch}>
                    <SearchIcon />
                  </IconButton >
                </Tooltip>
              </InputAdornment>,
            endAdornment:
              <InputAdornment position="end">
                <Collapse in={search.showCancel}>
                  <Tooltip title="Borrar">
                    <IconButton edge="end" onClick={search.handleCancel}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                </Collapse>
              </InputAdornment>,
          } as Partial<OutlinedInputProps>
        }
      />
    </FormControl>
  )
}

export default SearchInput
import moment, { Moment } from 'moment';
import { FC, ChangeEvent } from 'react';
import { Chip, FormControl, Grid, TextField, Tooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Booking } from '../booking.model';
import { Resource } from '../../resource/resource.model';
import BookingHourSelector from './BookingHourSelector';
import AllDaySelector from './AllDaySelector';
import TitleIcon from '@material-ui/icons/Title';
import EventIcon from '@material-ui/icons/Event';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ComputerIcon from '@material-ui/icons/Computer';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MomentUTCUtils } from '../../commons/utils/MomentUTCUtils';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
  centerIcon: {
    padding: theme.spacing(0.5)
  }
}));

type Props = {
  booking: Booking;
  title: string;
  onDateTimeChange: (begin: moment.Moment, end: moment.Moment) => void;
  onTitleChange: (title: string) => void;
}

const BookingUpdate: FC<Props> = ({ booking, title, onTitleChange, onDateTimeChange }) => {
  const classes = useStyles();
  const { bookingStep, weeklySchedule } = useLaborable();

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onTitleChange(event.target.value)
  }

  const handleDateTimeChange = (begin: Moment, end: Moment) => {
    onDateTimeChange(begin, end);
  }

  const handleStartTimeChange = (date: Moment) => {
    let end = moment(booking.bookingEnd)
    if (date.isSameOrAfter(moment(booking.bookingEnd))) {
      end = date.clone().add(bookingStep, 'minutes')
    }
    handleDateTimeChange(date, end)
  }

  const handleEndTimeChange = (date: Moment) => {
    let start = moment(booking.bookingStart)
    if (date.isSameOrBefore(moment(booking.bookingStart))) {
      start = date.clone().subtract(bookingStep, 'minutes')
    }
    handleDateTimeChange(start, date)
  }

  const handleDateChange = (date: Moment) => {
    const start = moment(booking.bookingStart).year(date.year()).dayOfYear(date.dayOfYear());
    const end = moment(booking.bookingEnd).year(date.year()).dayOfYear(date.dayOfYear());
    handleDateTimeChange(start, end);
  }

  return (
    <Grid container alignItems='center'>
      <Grid item xs={1} className={classes.centerIcon}>
        <TitleIcon color='primary' />
      </Grid>
      <Grid item xs={11}>
        <FormControl className={classes.formControl} size="small">
          <TextField
            fullWidth
            id="title"
            margin="dense"
            name="title"
            size="small"
            placeholder='Añade un título'
            value={title}
            onChange={handleTitleChange}
          />
        </FormControl>
      </Grid>
      {/* FIXME Por qué valida el start y no el end ?? Para qué valida ?? */}
      {booking?.bookingStart && 
        <>
          {/* Date */}
          <Grid item xs={1} className={classes.centerIcon}>
            <EventIcon color='primary' />
          </Grid>
          <Grid item xs={11}>
            <MuiPickersUtilsProvider utils={MomentUTCUtils} libInstance={moment}>
              <DatePicker
                format="DD/MM/yy"
                margin="dense"
                value={moment.utc(booking.bookingStart)}
                onChange={handleDateChange}
                minDate={moment()}
                shouldDisableDate={(day) => {
                  return !weeklySchedule.week.find(value => value.weekDay === day.day()).laborable
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* Time */}
          <Grid item xs={1} className={classes.centerIcon}>
            <AccessTimeIcon color='primary' />
          </Grid>
          <Grid item xs={1}>Inicio:</Grid>
          <Grid item xs={3}>
            <BookingHourSelector
              value={moment(booking.bookingStart)}
              onChange={handleStartTimeChange}
              limitEnd={true} />
          </Grid>
          <Grid item xs={1}>Fin:</Grid>
          <Grid item xs={3}>
            <BookingHourSelector
              value={moment(booking.bookingEnd)}
              onChange={handleEndTimeChange}
              limitStart={true} />
          </Grid>
          <Grid item xs={3}>
            <AllDaySelector
              begin={moment(booking.bookingStart)}
              end={moment(booking.bookingEnd)}
              onChange={handleDateTimeChange} />
          </Grid>
        </>
      }
      <Grid item xs={1} className={classes.centerIcon}>
        <Tooltip title="Usuario"><PersonIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{booking?.userInfo?.firstname + ' ' + booking?.userInfo?.lastname}</Grid>
      <Grid item xs={1} className={classes.centerIcon}>
        <Tooltip title="Lugar"><LocationOnIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.branch.name + ' - ' + (booking?.resource as Resource)?.floor.name}</Grid>
      <Grid item xs={1} className={classes.centerIcon}>
        <Tooltip title="Nombre de recurso"><ComputerIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.name}</Grid>
      <Grid item xs={1} className={classes.centerIcon}>
        <Tooltip title="Capacidad"><AccessibilityIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.capacity}</Grid>
      <Grid item xs={1} className={classes.centerIcon}>
        <Tooltip title="Recursos adicionales"><b><AttachFileIcon color='primary' /></b></Tooltip>
      </Grid>
      <Grid item xs={11}>
        {(booking?.resource as Resource)?.amenities?.map((value, index) => (
          <Chip key={`${value.id}-${index}`} label={value.name} />
        ))}
        {(!(booking?.resource as Resource)?.amenities || (booking?.resource as Resource)?.amenities.length === 0) &&
          'No posee extras'
        }
      </Grid>
    </Grid>
  );
}

export default BookingUpdate;
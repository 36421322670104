import baseAPI from './api';
import { UserGroup } from '../components/user-groups/userGroup.model';
import { User } from '../components/users/user.model';
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';

const list = async (query?: string, cancelToken?: CancelToken) => {
  const config: AxiosRequestConfig = {}
  config.params = query ? { 'q': query } : {}
  if(cancelToken) {
    config.cancelToken = cancelToken
  }
  const response = await baseAPI.get("usergroup", config)
  return response.data as UserGroup[];
}

const create = async (group: UserGroup) => {
  const response = await baseAPI.post('usergroup', group);
  return response.data as Success<string>

}

const remove = async (id: string) => {
  const response = await baseAPI.delete(`usergroup/${id}`);
  return response.data as Success<string>
}
const update = async (group: UserGroup) => {
  const { id, name, description, users } = group;
  const usersId = users.map(u => u.id)
  const response = await baseAPI.patch(`usergroup/${id}`, { name, description, users: usersId });
  return response.data as Success<string>

}

const getMebers = async (id: string) => {
  const response = await baseAPI.get(`usergroup/${id}/members`);
  return response.data as User[];
}

const assignMember = async (groupId: string, userId: string) => {
  const response = await baseAPI.put(`usergroup/${groupId}/members/${userId}`);
  return response.data as Success<string>
}

const removeMember = async (groupId: string, userId: string) => {
  const response = await baseAPI.delete(`usergroup/${groupId}/members/${userId}`);
  return response.data as Success<string>
}

export const groupService = {
  list,
  create,
  remove,
  update,
  getMebers,
  assignMember,
  removeMember
}
import baseAPI from './api'
import { DailyBookingsReport } from '../components/reports/weekly-bookings/dayliBookings.model'
import { UsageReportData } from '../components/reports/usage/usageReportData.model'
import { GlobalReportData } from '../components/reports/global/globalReport.model'
import { HeatmapReport } from '../components/reports/bookings-heatmap/heatmapReport.model'
import { GeneralOcupationData } from '../components/reports/general-ocupation/generalOcupationData.model'
import { AxiosRequestConfig, CancelToken } from 'axios'

const getWeeklyBookingsReport = async (begin: Date, end: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {
    params: { begin, end }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }

  const response = await baseAPI.get('reports/weekly-bookings', axiosConfig)
  return response.data as DailyBookingsReport[]
}

const getUsageReport = async (begin: Date, end: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {
    params: { begin, end }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get('reports/usage', axiosConfig)
  return response.data as UsageReportData[][]
}

const getGlobalReport = async (cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get('reports/global', axiosConfig)
  return response.data as GlobalReportData[]
}

const getHeatmapReport = async (begin: Date, end: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {
    params: { begin, end }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken;
  }
  const response = await baseAPI.get('reports/bookings-heatmap', axiosConfig);
  return response.data.data as HeatmapReport[];
}

const getGeneralOcupationReport = async (begin: Date, end: Date, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {
    params: { begin, end }
  }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get("reports/general-ocupation", axiosConfig);
  return response.data.data as GeneralOcupationData[];
}

export const reportsService = {
  getWeeklyBookingsReport,
  getUsageReport,
  getGlobalReport,
  getHeatmapReport,
  getGeneralOcupationReport
}
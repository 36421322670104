import { FC } from "react";
import { HeaderProps, ResourceHeaderProps } from "react-big-calendar";
import { Resource } from "../../resource/resource.model";

export const ResourceEmptyHeader: FC<ResourceHeaderProps<Resource>> = ({
  label,
  index,
  resource,
}) => {
  return <></>;
};

export const EmptyHeader: FC<HeaderProps> = ({ date, label, localizer }) => {
  return <></>;
};

import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useErrorHandler } from "../errors/ErrorBoundary";
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons from '../commons/buttons/FormActionButtons';
import { branchService } from '../../_services/branch.service';
import { Branch } from './branch.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0)
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onBranchesChange: () => void;
  branch?: Branch;
}

const BranchDialog: React.FC<Props> = ({ open, onClose, onBranchesChange, branch }) => {
  const { showError, showSuccess } = useSnackBars();

  const defaultValues = {
    id: branch?.id,
    name: branch?.name,
    address: branch?.address
  }

  const classes = useStyles();
  const { register, handleSubmit, reset, errors, setError, clearErrors } = useForm<Branch>({ defaultValues });
  const handleError = useErrorHandler();

  // eslint-disable-next-line
  useEffect(() => reset(defaultValues), [branch]);

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const closeDialog = () => {
    clearErrors();
    onClose();
  };

  const handleCancel = () => closeDialog();

  const onSubmit = async (branchData: Branch) => {
    try {
      if (branch) {
        branchData.id = branch.id;
        const res = await branchService.update(branchData);
        showSuccess(res.msg)

      } else {
        const res = await branchService.create(branchData);
        showSuccess(res.msg)
      }
      onBranchesChange();
      onClose();
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {branch ? `Actualizar sucursal "${branch.name}"` : "Crear sucursal"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="name"
              label="Nombre de sucursal"
              name="name"
              variant="outlined"
              error={(errors.name ? true : false)}
              size="small"
              inputRef={register({ min: 3, required: { value: true, message: "Debe ingresar un nombre" } })}
            />
            <FormHelperText error={true}>
              {errors?.name && errors.name.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="address"
              label="Domicilio"
              name="address"
              variant="outlined"
              size="small"
              inputRef={register({ required: false })}
            />
            <FormHelperText error={true}>
              {errors?.address && errors.address.message}
            </FormHelperText>
          </FormControl>
          <FormActionButtons
            actionText={branch ? "Actualizar" : "Crear"}
            onCancel={handleCancel}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default BranchDialog;
import { FC } from 'react';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { UserDialogActions, UserDialogContent, UserDialogTitle, useUserDialogStyle } from '../../commons/dialog/UserDialog';
import BookingUpdate from "./BookingUpdate";
import { Booking } from '../booking.model';
import useSnackBars from '../../commons/snackbar/SnackbarHook';
import validateBookingChangeRange from '../../commons/utils/validateBookingChangeRange';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';

type Props = {
  open: boolean;
  booking: Booking;
  onClose: () => void;
  onSave: () => void;
  onUpdateBooking: (booking: Booking) => void;
}

const BookingUpdateDialog: FC<Props> = ({ open, booking, onClose, onSave, onUpdateBooking }) => {
  const dialogClasses = useUserDialogStyle();
  const { showError } = useSnackBars();
  const { weeklySchedule } = useLaborable();

  const handleTitleChange = (title: string) => {
    const _booking = { ...booking, title };
    onUpdateBooking(_booking);
  }

  const handleDateTimeChange = (begin: moment.Moment, end: moment.Moment) => {
    const _booking = { ...booking, bookingStart: begin.toDate(), bookingEnd: end.toDate() }
    const valid = validateBookingChangeRange(begin, end, _booking, weeklySchedule);
    if (valid) {
      onUpdateBooking(_booking);
    } else {
      showError('No es posible realizar una reserva para la combinación de días.');
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={open && !!booking}
      classes={dialogClasses}>
      <UserDialogTitle id="customized-dialog-title" onClose={onClose} background='owner'>
        <Typography variant="h6">Editar reserva</Typography>
      </UserDialogTitle>
      <UserDialogContent dividers>
        <BookingUpdate
          title={booking?.title}
          booking={booking}
          onTitleChange={handleTitleChange}
          onDateTimeChange={handleDateTimeChange}
        />
      </UserDialogContent>
      <UserDialogActions>
        <Grid container justifyContent="flex-end">
          <Grid item xs={4}>
            <Button
              autoFocus
              color="secondary"
              variant="contained"
              fullWidth
              startIcon={<EditIcon />}
              onClick={onSave}>
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </UserDialogActions>
    </Dialog>
  )
}

export default BookingUpdateDialog;
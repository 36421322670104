import { Switch } from 'react-router-dom';
import { AppRoute } from '../../_helpers/routes';
import CompanyRegister from '../../pages/register/CompanyRegister';
import { Dashboard } from "../dashboard/Dashboard";
import { useCurrentUser } from '../commons/currentuser/CurrentUserHook';
import { ROLES } from '../../_helpers/constants';
import { UserDashboard } from '../dashboard/UserDashboard';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import SetResetPasswordForm from '../../pages/resetpassword/SetResetPasswordForm';
import { useSubdomain } from '../commons/subdomain/SubdomainHook';
import { userService } from '../../_services/user.service';
import PublicPages from '../../pages/public-pages/PublicPages';

class RejectedJoinError extends Error {
  public constructor(message: string) {
    super(message)
  }
}

/**
 * Definir rutas para usuarios logueados. Si el componente a renderizar es una página
 * se coloca aquí, mientras que si estas necesitando una ruta para renderizar algo dentro del
 * dashboard deberás añadirlas en AdminRoutes o UserRoutes según corresponda.
 */
export const PrivateRoutes = () => {

  const { user, logout } = useCurrentUser()
  const { showError, showInfo, showSuccess } = useSnackBars()
  const { company } = useSubdomain()
  try {
    if (user.company && user.active) {

      let component;

      if (user.role === ROLES.admin) {
        component = Dashboard
      } else if (user.role === ROLES.user) {
        component = UserDashboard
      } else if (user.role === ROLES.assistant) {
        component = UserDashboard
      } else {
        throw new Error('Rol no válido')
      }

      return (
        <Switch>
          <AppRoute path="/" component={component} />
        </Switch>
      )
    }

    if (user.role === ROLES.admin) {
      return (
        <PublicPages>
          <Switch>
            <AppRoute path="*" component={CompanyRegister} />
          </Switch>
        </PublicPages>
      )
    }

    if (user.role === ROLES.user && !user.active) {
      return (
        <Switch>
          <AppRoute path="*" component={SetResetPasswordForm} />
        </Switch>
      )
    }

    if (company) {
      throw new RejectedJoinError(`No se encuentra adherido a ${company.name} ¿quiere enviar una solicitud de adhesión?`)
    } else {
      throw new Error('Usuario no adherido, por favor contactar al administrador de la organización.')
    }
  } catch (error) {
    const message = (error as Error).message;
    if (message) {
      if (error instanceof RejectedJoinError) {
        showInfo(message, () => {}, async () => {
          const response = await userService.join(company, user.id);
          showSuccess(response.msg);
        });
      } else {
        showError(message);
      }
    }
    logout();
    return null;
  }
}
import { createContext } from 'react';
import { UserSession, Credentials } from '../../../_services/authentication.service';

export type CurrentUserContextType = {
  user: UserSession;
  login: (credentials: Credentials) => Promise<UserSession>;
  loginWithToken: (token: string) => Promise<UserSession>;
  logout: () => void;
  refresh: () => Promise<void>;
}

export const CurrentUserContext = createContext<CurrentUserContextType>(null);
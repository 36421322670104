import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import React from "react"
import { Prompt } from "react-router"
import { useHistory } from "react-router-dom"

type Props = {
  when: boolean;
  onCancel?: () => void;
}
const LeaveGuard: React.FC<Props> = ({ when, onCancel }) => {

  const [open, setOpen] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState(null);
  const [navigate, setNavigate] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (navigate) {
      history.push(lastLocation);
    }
    // eslint-disable-next-line
  }, [navigate])

  const handleBlockedNavigation = (nextLocation: any) => {
    if (when && !navigate) {
      setLastLocation(nextLocation)
      setOpen(true)
      return false
    }
    setNavigate(false)
    return true
  }

  const handleClose = () => {
    setOpen(false)
    onCancel && onCancel();
  }

  const handleAgree = () => {
    setOpen(false)
    setNavigate(true)

  }

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation}></Prompt>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Aviso importante"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Posee cambios sin guardar. ¿Desea continuar y perder los cambios no guardados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Volver
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LeaveGuard
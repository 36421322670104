import React from 'react';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Tooltip, Paper } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import useTableStyles from '../../hooks/styles/tableStyles';
import ResourcesContainer from './resourcesContainer/ResourcesContainer';
import { ResourceGroup } from './resourceGroup.model';
import { Resource } from '../resource/resource.model';

type Props = {
  groups: ResourceGroup[];
  onDelete: (resourceGroup: ResourceGroup) => void;
  onUpdate: (resourceGroup: ResourceGroup) => void;
  removeResource: (group: ResourceGroup, resource: Resource) => void;
}

const ResourceGroupsList: React.FC<Props> = ({ groups, onDelete, onUpdate, removeResource }) => {
  const classes = useTableStyles();

  const handleDelete = (group: ResourceGroup) => {
    onDelete(group);
  }
  const handleUpdate = (group: ResourceGroup) => {
    onUpdate(group)
  }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Recursos</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group: ResourceGroup) => (
            <TableRow key={group.id}>
              <TableCell>{group.name}</TableCell>
              <TableCell>{group.description}</TableCell>
              <TableCell>
                <ResourcesContainer group={group} groupResources={group.resources} removeResource={removeResource} />
              </TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(group)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(group)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ResourceGroupsList;
import { FC, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormActionButtons from '../commons/buttons/FormActionButtons';
import { floorService } from '../../_services/floor.service';
import { Floor } from './floor.model';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  }
}));

type Props = {
  open: boolean,
  floor?: Floor,
  onClose: () => void
  onCreate: () => void
}

const FloorUser: FC<Props> = ({ open, floor, onClose, onCreate }) => {
  const classes = useStyles();

  const [file, setFile] = useState<File>(null);
  const [changeImage, setChangeImage] = useState<boolean>(false);
  const { showError } = useSnackBars()
  const { register, handleSubmit, reset, errors, setError, clearErrors } = useForm<Floor>({
    defaultValues: {
      id: floor?.id,
      name: floor?.name,
      description: floor?.description,
      order: floor?.order
    }
  });
  const { showSuccess } = useSnackBars()
  const handleError = useErrorHandler();

  useEffect(() => {
    setChangeImage(false)
    reset(
      {
        id: floor?.id,
        name: floor?.name,
        description: floor?.description,
        order: floor?.order
      }
    );
    // eslint-disable-next-line
  }, [floor])

  const closeDialog = () => {
    setFile(null)
    clearErrors()
    onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  const handleFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0])
    }
  }

  const onSubmit = async (floorData: Floor) => {
    try {
      if (!!floor) {
        floorData.id = floor.id;
        const res = await floorService.update(floorData, file, changeImage)
        showSuccess(res.msg)
      } else {
        const res = await floorService.create(floorData, file)
        showSuccess(res.msg)
      }
      onCreate();
      setFile(null)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        error.errors?.image ?
        showError(error.errors.image.msg) :
        handleApiError(error.errors)
    }
  }

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const handleChangeImage = () => {
    setChangeImage(true)
  }

  return (
    <Dialog fullWidth onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {!floor ? 'Crear' : 'Actualizar'} Piso
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="name" label="Nombre"
              name="name"
              variant="outlined"
              error={(errors.name ? true : false)}
              size="small"
              inputRef={register({
                required: { value: true, message: "El nombre es requerido" },
                minLength: { value: 2, message: "Debe tener al menos 2 caracteres" } 
              })}
            />
            <FormHelperText error={true}>
              {errors?.name && (errors.name.message || "El nombre es requerido")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField id="description" label="Descripción" name="description" variant="outlined" size="small" inputRef={register({ required: false })} />
            <FormHelperText error={true}>
              {errors?.description?.message}
            </FormHelperText>
          </FormControl>
          { floor &&
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                id="order"
                name="order"
                label="Orden"
                size="small"
                variant="outlined"
                error={!!errors.order}
                inputRef={register({
                  required: false,
                  pattern: { value: new RegExp("[0-9]*"), message: "Sólo se admiten enteros mayores ó iguales a 0"}
                })}
              />
              <FormHelperText error={true}>
                {errors?.order?.message}
              </FormHelperText>
            </FormControl>
          }
          <FormControl fullWidth className={classes.formControl}>
            {!floor?.image || changeImage ?
              <input type='file' onChange={handleFile}></input> :
              <div>
                <a target='_blank' href={floorService.imageLink(floor.image.path)} rel="noreferrer">
                  {floor.image.originalName}
                </a>
                <Button onClick={handleChangeImage}>Cambiar Imagen</Button>
              </div>
            }
          </FormControl>
          <FormActionButtons
            actionText={!floor ? 'Crear' : 'Actualizar'}
            onCancel={handleCancel}
          />
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default FloorUser
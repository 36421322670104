import { Redirect, Switch } from 'react-router-dom';
import { AppRoute } from "../../_helpers/routes"
import LoginForm from "../../pages/login/LoginForm"
import ClientRegisterForm from "../../pages/register/ClientRegisterForm"
import WebClientRegisterForm from '../../pages/register/WebClientRegisterForm';
import UserRegisterForm from "../../pages/register/UserRegisterForm"
import RequestResetPasswordForm from "../../pages/resetpassword/ReqResetPasswordForm"
import SetResetPasswordForm from '../../pages/resetpassword/SetResetPasswordForm';
import PublicPages from '../../pages/public-pages/PublicPages';

/**
 * Definir rutas para usuarios que aún no iniciaron sesión.
 * Si no hace match con ningún path va a redireccionar al login.
 */
export const PublicRoutes = () => {
  return (
    <PublicPages>
      <Switch>
        <AppRoute exact path="/login" component={LoginForm} />
        <AppRoute exact path="/register/client" component={ClientRegisterForm} />
        <AppRoute exact path="/register/webclient" component={WebClientRegisterForm} />
        <AppRoute exact path="/register/user" component={UserRegisterForm} />
        <AppRoute exact path="/resetpassword/request" component={RequestResetPasswordForm} />
        <AppRoute exact path="/resetpassword" component={SetResetPasswordForm} />
        <Redirect path="*" to="/login" />
      </Switch>
    </PublicPages>


  )
}
import { Box, Button, IconButton, ButtonGroup, Tooltip } from '@material-ui/core';
import React, { FC, } from "react";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { calendarToolbarStyles } from './calendarToolbarStyles';

type BuilderProps = {
  label: string;
  onToday: () => void;
  onPrev: () => void;
  onNext: () => void;
  children?: React.ReactNode;
};


export const CalendarToolbarBuilder: FC<BuilderProps> = ({ label, onToday, onPrev, onNext, children }) => {
  const classes = calendarToolbarStyles()
  return (
    <Box className={classes.root}>
      <Button
        variant="outlined"
        className={classes.todayBtn}
        onClick={onToday}>
        Hoy
      </Button>
      <ButtonGroup className={classes.navigateBtn}>
        <Tooltip title="Anterior">
          <IconButton onClick={onPrev}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Siguiente">
          <IconButton onClick={onNext}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
      <div className={classes.middle}>{label}</div>
      <div className={classes.right}>{children}</div>
    </Box>
  );
};

export default CalendarToolbarBuilder;
import { FC } from "react";
import { Card, CardHeader, Grid, Typography } from '@material-ui/core';
import GaugeChart from "react-gauge-chart";
import { GeneralOcupationData } from "./generalOcupationData.model";
import { durationToString } from "../utils/durationToString";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 5,
    paddingBottom: "15px"
  },
  chartContainer: {
    position: "relative",
    maxHeight: 200,
    overflow: "hidden"
  }
}));

type Props = {
  ocupationData: GeneralOcupationData[]
}

const GeneralOcupationChart: FC<Props> = ({ ocupationData }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        align='center'
        titleTypographyProps={{ variant: 'h5' }}
        title="Promedio de ocupación general"
      />
      <Grid container justifyContent='space-around' spacing={2}>
        {ocupationData.map((report, index) => {
          return (
            <Grid item xs={12} md={4}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="h6">
                    {report.type}
                  </Typography>
                </Grid>
                <Grid item className={classes.chartContainer}>
                  <GaugeChart
                    id={`gauge-chart-${index}`}
                    nrOfLevels={4}
                    percent={report.ocupationPercentaje}
                    textColor={"#000000"}
                    cornerRadius={0}
                    arcPadding={0.025}
                    arcWidth={0.3}
                    colors={['#00CE68', '#D2BB25', '#CC6B15', '#CE270C']}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    Hs. reservadas: {durationToString(report.bookedDuration)}
                  </Typography>
                  <Typography>
                    Hs. reservables: {durationToString(report.totalDurationInMins)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}

export default GeneralOcupationChart;
import baseAPI from './api';
import { Floor } from '../components/floor/floor.model'
import { Paginated } from '../components/commons/Paginated';
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (floor: Floor, image: File) => {
  const data = new FormData()
  data.append('image', image)
  data.append('name', floor.name)
  data.append('description', floor.description)
  const response = await baseAPI.post(`floor`, data)
  return response.data as Success<string>
}

const update = async (floor: Floor, image: File, changeImage: boolean) => {
  const data = new FormData()
  if (!!image) {
    data.append('image', image)
  }
  if (!image && changeImage) {
    data.append('removeImage', 'true')
  }
  if(floor.order) {
    data.append('order', floor.order.toString())
  }
  data.append('name', floor.name)
  data.append('description', floor.description)
  const response = await baseAPI.patch(`floor/${floor.id}`, data)
  return response.data as Success<string>
}

const list = async (query: string, offset: number, limit: number, cancelToken?: CancelToken) => {
  const axiosConfig: AxiosRequestConfig = {}
  axiosConfig.params = { 'q': query, limit, offset }
  if(cancelToken) {
    axiosConfig.cancelToken = cancelToken
  }
  const response = await baseAPI.get(`floor`, axiosConfig)
  const resp = response.data as Paginated<Floor>
  return resp
}

const assignResource = async (floor: string, resource: string) => {
  await baseAPI.put(`/floor/${floor}/resources/${resource}`)
  return
}

const unassignResource = async (floor: string, resource: string) => {
  await baseAPI.delete(`/floor/${floor}/resources/${resource}`)
  return
}

const remove = async (id: string) => {
  const response = await baseAPI.delete(`floor/${id}`)
  return response.data as Success<string>
}

const imageLink = (path: string) => {
  return process.env.REACT_APP_API_URL + '/static' + path
}

const updateResourceSize = async (id: string, size: number) => {
  await baseAPI.patch(`floor/${id}/resources/size`, { size })
}

export const floorService = {
  create,
  update,
  list,
  remove,
  assignResource,
  unassignResource,
  imageLink,
  updateResourceSize
};
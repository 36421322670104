import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import { useCalendarContext } from '../booking/agenda/DateRangeProvider';
import { MuiThemeProvider } from '@material-ui/core';
import datepickerTheme from './useThemeDatePicker';

const CalendarPanel = () => {

  const { calendarData, setCalendarData } = useCalendarContext();

  const handleDateChange = (date: moment.Moment | null) => {
    setCalendarData({ ...calendarData, 'date': date.toDate() });
  };

  return (
    <MuiThemeProvider theme={datepickerTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          format='DD/MM/YYYY'
          autoOk
          disableToolbar
          variant="static"
          openTo="date"
          value={calendarData.date}
          onChange={handleDateChange} />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default CalendarPanel;
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useForm } from "react-hook-form";
import { Box, FormHelperText, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { userService } from '../../_services/user.service';
import { useErrorHandler } from "../../components/errors/ErrorBoundary";
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { Link as RouterLink } from 'react-router-dom';
import useFormsPublicStyles from '../public-pages/useFormsPublicStyles';

export interface ValidateUserData {
  email: string
}

const RequestResetPasswordForm: React.FC = () => {
  const classes = useFormsPublicStyles();
  const history = useHistory()
  const { register, handleSubmit, errors, setError } = useForm<ValidateUserData>();
  const handleError = useErrorHandler();
  const { showSuccess } = useSnackBars();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showSuccess(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const onSubmit = async (data: ValidateUserData) => {
    try {
      const response = await userService.initResetPassword(data)
      showSuccess(response.msg)
      history.push("/login")
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
      history.push("/login")
    }
  }

  return (
    <Box height='100%' display="flex" flexDirection="column">
      <Box className={classes.imageContainer}>
        <img src={"/logo-negativo.png"} alt="logo de webee" />
      </Box>
      <Box className={classes.mainContainer}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} md={9}>
            <Box className={classes.title}>
              <Typography>Solicitar nueva contraseña</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                className={classes.textField}
                variant="filled"
                error={(errors.email ? true : false)}
                margin="normal"
                size="small"
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                inputRef={register({ required: { value: true, message: "Escriba su email" }, pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Debe ingresar un email válido" } })}
              />
              <FormHelperText error={true} className={classes.formHelperText}>
                {errors?.email && errors.email.message}
              </FormHelperText>
              <FormControl margin="normal" fullWidth>
                <Button
                  className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary">
                  Enviar solicitud
                </Button>
              </FormControl>
              <Box marginTop={3}>
                <Typography className={classes.link} noWrap>
                  <RouterLink to="/login">Volver al inicio de sesión</RouterLink>
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default RequestResetPasswordForm

import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { CurrentBranchContext } from './CurrentBranchContext';
import { branchService } from '../../../_services/branch.service';
import { configService } from '../../../_services/config.service';

const CurrentBranchProvider: FC = ({ children }) => {

  const [currentBranch, setCurrentBranch] = useState<string>(configService.getCurrentBranch())

  const onUpdateBranch = useCallback(
    (id: string) => {
      configService.refreshCurrentBranch(id)
      setCurrentBranch(id)
    }, []
  )

  useEffect(() => {
    const loadDefaultBranch = async () => {
      const defaultBranch = await branchService.loadDefaultBranch();
      onUpdateBranch(defaultBranch)
    }
    loadDefaultBranch()
  }, [onUpdateBranch]);

  const value = useMemo(() => ({ currentBranch, onUpdateBranch }), [currentBranch, onUpdateBranch]);

  return (
    <CurrentBranchContext.Provider value={value}>
      {currentBranch && children}
    </CurrentBranchContext.Provider>
  )
}

export default CurrentBranchProvider;
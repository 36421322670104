import React, { useCallback, useEffect } from 'react';
import { Box } from '@material-ui/core';
import FAB from '../../components/commons/buttons/FAB';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import AmenityList from '../../components/amenities/AmenityList';
import UpdateAmenity from '../../components/amenities/UpdateAmenity';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import ConfirmDialog from '../../components/commons/dialogs/ConfirmDialog';
import { amenitiesService } from '../../_services/amenities.service';
import { Amenity } from '../../components/amenities/amenity.model';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useCancelToken from '../../hooks/useCancelToken';

const Amenities: React.FC = () => {
  const [amenities, setAmenities] = React.useState<Amenity[]>();
  const [query, setQuery] = React.useState<string>("")
  const [amenity, setAmenity] = React.useState<Amenity>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const { getCancelToken, isCancel } = useCancelToken()
  const { showSuccess, showError } = useSnackBars()
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchAmenities = useCallback(async () => {
    try {
      const cancelToken = getCancelToken()
      let response = await amenitiesService.list(query, cancelToken)
      setAmenities(response)
    } catch (error) {
      if(!isCancel(error)) {
        error instanceof Error ?
          handleError(error) :
          handleApiError(error.errors)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => { fetchAmenities() }, [fetchAmenities])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const handleCreate = () => {
    setOpen(true)
    setAmenity(null)
  }

  const handleUpdate = (amenity: Amenity) => {
    setOpen(true)
    setAmenity(amenity)
  }

  const handleDelete = async (amenity: Amenity) => {
    try {
      const res = await amenitiesService.deleteAmenity(amenity.id)
      showSuccess(res.msg)
      fetchAmenities()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleUpdated = () => {
    setOpen(false)
    setAmenity(null)
    fetchAmenities()
  }

  const handleClose = () => {
    setOpen(false)
    setAmenity(null)
  }

  // Handlers Dialog de confirmación
  const openDeleteDialog = (amenity: Amenity) => {
    setAmenity(amenity);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nuevo extra" onClick={handleCreate} />
      </Box>
      {!amenities || amenities.length === 0 ? <EmptyResults /> :
        <AmenityList
          amenities={amenities}
          onUpdate={handleUpdate}
          onDelete={openDeleteDialog}
        />
      }
      <UpdateAmenity
        amenity={amenity}
        open={open}
        onUpdate={handleUpdated}
        onClose={handleClose}
      />
      <ConfirmDialog<Amenity>
        title="Eliminando Extra"
        subtitle="Eliminará también este extra de todos los recursos a los que esté asociados. ¿Deseas continuar con la eliminación?"
        open={openDelete}
        holder={amenity}
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  )
}

export default Amenities
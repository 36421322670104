import React, { FC, useEffect, useRef, useState } from "react";
import { FormControl, Button, Container, InputAdornment, Typography, FormHelperText, Grid, TextField } from '@material-ui/core';
import { roomConfigService } from "../../_services/roomconfig.service";
import { RoomConfig } from "./roomConfig.model";
import useFormsPublicStyles from "../../pages/public-pages/useFormsPublicStyles";
import UpdateRoomLogo from "./UpdateRoomLogo";
import { companyService } from "../../_services/company.service";
import defaultData from "./DefaultConfig"
import useSnackBars from "../commons/snackbar/SnackbarHook";
import Switch from '@material-ui/core/Switch';
import ColorPreview from "./ColorPreview";
import ConfirmDialog from '../../components/commons/dialogs/ConfirmDialog';

const CustomizeRooms: FC = () => {
  const classes = useFormsPublicStyles();
  const [config, setConfig] = useState<RoomConfig>(defaultData as RoomConfig)
  const [logoUploadOpen, setLogoUploadOpen] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [image, setImage] = useState<any>(null)
  const [imageURL, setImageURL] = useState<any>(null)
  const myCanvas = useRef(null)
  const { showError, showSuccess } = useSnackBars()

  useEffect(() => {
    if (!myCanvas || !image) {
      return
    }
    const context = myCanvas.current.getContext("2d");
    context.clearRect(0, 0, 200, 200);
    context.drawImage(image, 0, 0, 200, 200);
  }, [image])

  const load = async () => {
    try {
      let _config = await roomConfigService.getConfig();

      if (!_config || !_config.header) {
        _config = config
      }


      if (_config?.general?.background_color) {
        _config.general.background_color = _config.general?.background_color.replace('#', '')
      }

      if (_config?.header?.background_color) {
        _config.header.background_color = _config.header?.background_color.replace('#', '')
      }
      if (_config?.header?.font_color) {
        _config.header.font_color = _config.header?.font_color.replace('#', '')
      }

      if (_config?.info?.background_color_free) {
        _config.info.background_color_free = _config.info?.background_color_free.replace('#', '')
      }
      if (_config?.info?.background_color_waiting) {
        _config.info.background_color_waiting = _config.info?.background_color_waiting.replace('#', '')
      }
      if (_config?.info?.background_color_busy) {
        _config.info.background_color_busy = _config.info?.background_color_busy.replace('#', '')
      }
      if (_config?.info?.font_color_free) {
        _config.info.font_color_free = _config.info?.font_color_free.replace('#', '')
      }
      if (_config?.info?.font_color_waiting) {
        _config.info.font_color_waiting = _config.info?.font_color_waiting.replace('#', '')
      }
      if (_config?.info?.font_color_busy) {
        _config.info.font_color_busy = _config.info?.font_color_busy.replace('#', '')
      }
      if (_config?.info?.font_color_progress) {
        _config.info.font_color_progress = _config.info?.font_color_progress.replace('#', '')
      }

      if (_config?.bookings?.background_color_actual) {
        _config.bookings.background_color_actual = _config.bookings?.background_color_actual.replace('#', '')
      }
      if (_config?.bookings?.background_color_next) {
        _config.bookings.background_color_next = _config.bookings?.background_color_next.replace('#', '')
      }
      if (_config?.bookings?.background_color_previous) {
        _config.bookings.background_color_previous = _config.bookings?.background_color_previous.replace('#', '')
      }
      if (_config?.bookings?.font_color_actual) {
        _config.bookings.font_color_actual = _config.bookings?.font_color_actual.replace('#', '')
      }
      if (_config?.bookings?.font_color_next) {
        _config.bookings.font_color_next = _config.bookings?.font_color_next.replace('#', '')
      }
      if (_config?.bookings?.font_color_previous) {
        _config.bookings.font_color_previous = _config.bookings?.font_color_previous.replace('#', '')
      }


      if (!!_config?.general) {
        setConfig(_config)
        setImageURL(companyService.imageLink(_config.header.image))
        setImage(null)
      }
    } catch (e) {
      // FIXME 
    }
  }
  useEffect(() => {
    load()
  }, [])


  const createConfig = (_configData: any) => {
    _configData.general.background_color = '#' + _configData.general.background_color

    _configData.header.background_color = '#' + _configData.header.background_color
    _configData.header.font_color = '#' + _configData.header.font_color

    _configData.info.background_color_free = '#' + _configData.info.background_color_free
    _configData.info.background_color_waiting = '#' + _configData.info.background_color_waiting
    _configData.info.background_color_busy = '#' + _configData.info.background_color_busy
    _configData.info.font_color_free = '#' + _configData.info.font_color_free
    _configData.info.font_color_waiting = '#' + _configData.info.font_color_waiting
    _configData.info.font_color_busy = '#' + _configData.info.font_color_busy
    _configData.info.font_color_progress = '#' + _configData.info.font_color_progress

    _configData.bookings.background_color_actual = '#' + _configData.bookings.background_color_actual
    _configData.bookings.background_color_next = '#' + _configData.bookings.background_color_next
    _configData.bookings.background_color_previous = '#' + _configData.bookings.background_color_previous
    _configData.bookings.font_color_actual = '#' + _configData.bookings.font_color_actual
    _configData.bookings.font_color_next = '#' + _configData.bookings.font_color_next
    _configData.bookings.font_color_previous = '#' + _configData.bookings.font_color_previous

    _configData.times.cancel_countdown = parseInt(_configData.times.cancel_countdown)

    return _configData
  }

  const onSubmit = async () => {
    try {
      if (config?.id) {
        await roomConfigService.update(config.id, JSON.parse(JSON.stringify(createConfig(config))));
      } else {
        await roomConfigService.create(createConfig(config));
      }
      if (!!image) {
        await roomConfigService.updateLogo(dataURItoBlob(image));
      } else {
        await roomConfigService.deleteLogo();
      }
      showSuccess('Configuración actualizada.')
    } catch (e) {
      showError('Error al actualizar la configuración.')
    }
    load()
  }

  const dataURItoBlob = (dataURI: any) => {
    dataURI = dataURI.toDataURL();
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const handleUpdateLogo = async (imageData: any) => {
    setImage(imageData)
    setImageURL(null)
    setLogoUploadOpen(false);
  }

  const handleClose = () => {
    setLogoUploadOpen(false);
  }

  const handleReset = async () => {
    await roomConfigService.update(config.id, defaultData);
    load()
  }

  const hasColorError = (color: string) => {
    if (!color) {
      return true;
    }
    if (color.match(/^[0-F]{6}$/i)) {
      return false
    }
    return true
  }

  const hasFormError = () => {
    return hasColorError(config.general.background_color) ||
      hasColorError(config.header.background_color) ||
      hasColorError(config.header.font_color) ||

      hasColorError(config.info.background_color_free) ||
      hasColorError(config.info.background_color_waiting) ||
      hasColorError(config.info.background_color_busy) ||
      hasColorError(config.info.font_color_free) ||
      hasColorError(config.info.font_color_waiting) ||
      hasColorError(config.info.font_color_busy) ||
      hasColorError(config.info.font_color_progress) ||

      hasColorError(config.bookings.background_color_actual) ||
      hasColorError(config.bookings.background_color_next) ||
      hasColorError(config.bookings.background_color_previous) ||
      hasColorError(config.bookings.font_color_actual) ||
      hasColorError(config.bookings.font_color_next) ||
      hasColorError(config.bookings.font_color_previous)
  }

  const openConfirmDialog = () => setOpenConfirm(true);
  const closeConfirmDialog = () => setOpenConfirm(false);

  return (
    <Container component="main" style={{ 'height': '100%', 'overflowY': 'scroll' }}>
      <Container component="main">
        <Grid container direction="column"flex-start='center' alignItems='center' style={{ marginBottom: 10 }}>
          <UpdateRoomLogo open={logoUploadOpen} onUpdate={handleUpdateLogo} onClose={handleClose} />
          {!!image && <canvas ref={myCanvas} width={200} height={200}></canvas>}
          {(!!imageURL && !image) && < img src={imageURL} alt="logo de la compañía" style={{ width: 200, height: 200 }} />}
          <Button color='primary' variant='outlined' style={{ marginTop: 10 }} onClick={() => { setLogoUploadOpen(true); }}>Cambiar</Button>
        </Grid>
        <form >
          <Grid container direction="column"flex-start='center' alignItems='center' >
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant="h5">General</Typography>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography>Background color</Typography>
              </Grid>
              <Grid md={8} container direction="row" >
                <>
                  <FormControl>
                    <TextField
                      id="general.background_color"
                      name="general.background_color"
                      error={hasColorError(config.general?.background_color)}
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.general.background_color}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ general: { background_color: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.general?.background_color) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.general?.background_color} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant="h5">Header</Typography>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography>Background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="header.background_color"
                      name="header.background_color"

                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.header.background_color}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ header: { ...config.header, background_color: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.header?.background_color) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.header?.background_color} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="header.font_color"
                      name="header.font_color"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}

                      value={config.header.font_color}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ header: { ...config.header, font_color: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.header?.font_color) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.header?.font_color} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant="h5">Info</Typography>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Background image</Typography>
              </Grid>
              <Grid md={8}>
                <Switch
                  id="info.background_image"
                  name="info.background_image"
                  checked={config.info.background_image}
                  onChange={(e: any, checked: boolean) => {
                    setConfig({ ...config, ...{ 'info': { ...config.info, ...{ 'background_image': checked } } } })
                  }}
                />
                <FormHelperText error={true} className={classes.formHelperText} />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography>Free background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.background_color_free"
                      name="info.background_color_free"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.background_color_free}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, background_color_free: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.background_color_free) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.background_color_free} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography>Waiting background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.background_color_waiting"
                      name="info.background_color_waiting"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.background_color_waiting}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, background_color_waiting: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.background_color_waiting) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.background_color_waiting} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Busy background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.background_color_busy"
                      name="info.background_color_busy"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.background_color_busy}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, background_color_busy: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.background_color_busy) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.background_color_busy} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Free font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.font_color_free"
                      name="info.font_color_free"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.font_color_free}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, font_color_free: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.font_color_free) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.font_color_free} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Waiting font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.font_color_waiting"
                      name="info.font_color_waiting"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.font_color_waiting}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, font_color_waiting: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.font_color_waiting) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.font_color_waiting} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Busy font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.font_color_busy"
                      name="info.font_color_busy"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.font_color_busy}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, font_color_busy: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.font_color_busy) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.font_color_busy} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Progress spinner color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="info.font_color_progress"
                      name="info.font_color_progress"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.info.font_color_progress}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ info: { ...config.info, font_color_progress: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.info?.font_color_progress) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.info?.font_color_progress} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant="h5">Bookings</Typography>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Actual booking background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.background_color_actual"
                      name="bookings.background_color_actual"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.bookings.background_color_actual}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, background_color_actual: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.background_color_actual) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.background_color_actual} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Next booking background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.background_color_next"
                      name="bookings.background_color_next"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.bookings.background_color_next}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, background_color_next: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.background_color_next) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.background_color_next} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Previous booking background color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.background_color_previous"
                      name="bookings.background_color_previous"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.bookings.background_color_previous}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, background_color_previous: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.background_color_previous) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.background_color_previous} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Actual booking font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.font_color_actual"
                      name="bookings.font_color_actual"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.bookings.font_color_actual}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, font_color_actual: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.font_color_actual) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.font_color_actual} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Next booking font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.font_color_next"
                      name="bookings.font_color_next"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config.bookings.font_color_next}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, font_color_next: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.font_color_next) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.font_color_next} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Previous booking font color</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <>
                  <FormControl>
                    <TextField
                      id="bookings.font_color_previous"
                      name="bookings.font_color_previous"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start" >#</InputAdornment>
                      }}
                      value={config?.bookings?.font_color_previous}
                      onChange={(event: any) => {
                        setConfig({ ...config, ...{ bookings: { ...config.bookings, font_color_previous: event.target.value } } })
                      }}
                    />
                  </FormControl>
                  <FormHelperText error={true} className={classes.formHelperText}>
                    {hasColorError(config.bookings?.font_color_previous) && "Color invalido"}
                  </FormHelperText>
                </>
                <ColorPreview color={config?.bookings?.font_color_previous} width={50} height={null}></ColorPreview>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Typography variant="h5">Times</Typography>
            </Grid>
            <Grid container direction="row" justifyContent='flex-start' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid md={4}>
                <Typography >Cancel countdown minutes</Typography>
              </Grid>
              <Grid md={8} container direction="row">
                <FormControl>
                  <TextField
                    id="times.cancel_countdown"
                    name="times.cancel_countdown"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={config?.times?.cancel_countdown}
                    onChange={(event: any) => {
                      setConfig({ ...config, ...{ times: { ...config.times, cancel_countdown: event.target.value } } })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent='flex-end' alignItems='center' spacing={2}>
              <Grid item md={2}>
                {!!config?.id &&
                  <Button
                    autoFocus
                    color="default"
                    variant="outlined"
                    onClick={openConfirmDialog}
                    fullWidth>
                    Reiniciar
                  </Button>
                }
              </Grid>
              <Grid item md={2}>
                <Button
                  disabled={hasFormError()}
                  autoFocus
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={onSubmit}>
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
      <ConfirmDialog<RoomConfig>
        open={openConfirm}
        holder={config}
        title="Cargando configuración predeterminada"
        subtitle="Perderás la configuración actual ¿Deseas continuar?"
        onAccept={handleReset}
        onClose={closeConfirmDialog}
      />
    </Container>
  );
}

export default CustomizeRooms;
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, Toolbar, Tooltip } from '@material-ui/core/';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { MenuListItems } from './MainListItems';
import { useHistory } from 'react-router';
import { useCurrentUser } from '../../components/commons/currentuser/CurrentUserHook';
import BranchSelector from "./BranchSelector";
import { AdminRoutes } from '../routes/AdminRoutes';
import { UserRoutes } from '../routes/UserRoutes';
import { ROLES } from '../../_helpers/constants';
import CurrentBranchProvider from '../commons/currentbranch/CurrentBranchProvider';
import BranchesProvider from '../commons/branches/BranchesProvider';
import ConfigProvider from '../commons/configContext/ConfigProvider';
import LaborableProvider from '../commons/laborableContext/LaborableProvider';
import { AssistantRoutes } from '../routes/AssistantRoutes';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerContainer: {
    overflow: 'auto',
    marginTop: theme.spacing(13),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    '& img': {
      maxWidth: 120,
    }
  },
}));


export const Dashboard = () => {

  const { user, logout } = useCurrentUser()

  const classes = useStyles();
  const history = useHistory();

  const goToProfile = () => { history.push('/profile') }

  return (
    <CurrentBranchProvider>
      <BranchesProvider>
        <ConfigProvider>
          <LaborableProvider>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Box display='flex' className={classes.title}>
                    <img src="/logo-negativo.png" alt="logo webee" />
                  </Box>
                  <BranchSelector />
                  <Tooltip title="Mi cuenta">
                    <IconButton color="inherit" onClick={goToProfile}>
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cerrar sesión">
                    <IconButton color="inherit" onClick={logout}>
                      <ExitToApp />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </AppBar>
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
              >
                <div className={classes.drawerContainer}>
                  <List>
                    <MenuListItems />
                  </List>
                </div>
              </Drawer>
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {
                  user.role === ROLES.admin && <AdminRoutes />
                }
                {
                  user.role === ROLES.user && <UserRoutes />
                }
                {
                  user.role === ROLES.assistant && <AssistantRoutes />
                }
              </main>
            </div>
          </LaborableProvider>
        </ConfigProvider>
      </BranchesProvider>
    </CurrentBranchProvider>
  );
}

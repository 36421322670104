import baseAPI from './api';
import { Resource } from '../components/resource/resource.model'
import { ResourceGroup } from '../components/resourceGroup/resourceGroup.model'
import { Success } from "../components/commons/Success";
import { AxiosRequestConfig, CancelToken } from 'axios';

const create = async (resourceGroup: ResourceGroup) => {
    const { name, description } = resourceGroup
    const body = { name, description }
    const response = await baseAPI.post(`resourceGroup`, body)
    return response.data as Success<string>
}

const update = async (resourceGroup: ResourceGroup) => {
    const { id, name, description, resources } = resourceGroup
    const resourcesId = resources.map(r => r.id)
    const body = { name, description, resources: resourcesId }
    const response = await baseAPI.patch(`resourceGroup/${id}`, body)
    return response.data as Success<string>
}

const list = async (query?: string, cancelToken?: CancelToken) => {
    const axiosConfig: AxiosRequestConfig = {}
    if(query) {
      axiosConfig.params = { 'q': query }
    }
    if(cancelToken) {
      axiosConfig.cancelToken = cancelToken
    }
    const response = await baseAPI.get(`resourceGroup`, axiosConfig)
    return response.data as Resource[]
}

const remove = async (id: string) => {
    const response = await baseAPI.delete(`resourceGroup/${id}`)
    return response.data as Success<string>

}

const getById = async (id: string) => {
    const group: ResourceGroup = await baseAPI.get(`resourceGroup/${id}`)
    return group
}

const getResources = async (id: string) => {
    const response = await baseAPI.get(`resourceGroup/${id}`)
    return response.data as Resource[]
}

const assignResource = async (id: string, resourceId: string) => {
    const response = await baseAPI.put(`resourceGroup/${id}/resources/${resourceId}`)
    return response.data as Success<string>

}

const removeResource = async (id: string, resourceId: string) => {
    const response = await baseAPI.delete(`resourceGroup/${id}/resources/${resourceId}`)
    return response.data as Success<string>
}

export const resourceGroupService = {
    create,
    update,
    list,
    remove,
    getById,
    getResources,
    assignResource,
    removeResource
};
import React, { FC, useState } from 'react';
import { useForm } from "react-hook-form";
import { Dialog, FormControl, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useErrorHandler } from '../errors/ErrorBoundary';
import useSnackBars from '../commons/snackbar/SnackbarHook';
import { ResetPasswordForm } from '../users/user.model'
import { userService } from '../../_services/user.service';
import { UserDialogActions, UserDialogContent, UserDialogTitle, useUserDialogStyle } from '../commons/dialog/UserDialog';
import PrimaryButton from '../commons/buttons/PrimaryButton';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(0, 2, 2, 0),
  }, submit: {
    margin: theme.spacing(2, 0, 0),
  }
}));

type Props = {
  open: boolean,
  onClose: () => void
}

const UpdatePassword: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const dialogClasses = useUserDialogStyle();

  const { register, handleSubmit, errors, setError } = useForm<ResetPasswordForm>({
    defaultValues: { password: "", newPassword: "", repeatPassword: "" }
  });
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler();

  const handleApiError = (errors: any) => {
    Object.keys(errors).forEach((field) => {
      const e = errors[field]
      e.param === 'api' ? showError(e.msg) : setError(e.param, { type: e.param, message: e.msg })
    })
  }

  const [newPassword, setNewPassword] = useState<string>(null);

  const onSubmit = async (password: ResetPasswordForm) => {
    try {
      await userService.updatePasswordProfile(password)
      showSuccess('Contraseña actualizada')
      onClose()
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handlePassword = (e: any) => {
    setNewPassword(e.target.value)
  }

  return (
    <Dialog
      classes={dialogClasses}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth='xs'
    >
      <UserDialogTitle id="simple-dialog-title" onClose={onClose}>
        <Typography variant="h5">Cambiar Contraseña</Typography>
      </UserDialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserDialogContent>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="password"
              error={(errors.password ? true : false)}
              label="Contraseña actual"
              name="password"
              type="password"
              variant="outlined"
              size="small"
              inputRef={register({ required: true })}
            />
            <FormHelperText error={true}>
              {errors?.password && (errors.password.message || "Debe escribir su contraseña actual")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="newPassword"
              label="Nueva contraseña"
              name="newPassword"
              error={(errors.newPassword ? true : false)}
              type="password"
              variant="outlined"
              size="small"
              inputRef={register({ required: { value: true, message: "Escriba su nueva contraseña" } })}
              onChange={handlePassword}
            />
            <FormHelperText error={true}>
              {errors?.newPassword && (errors.newPassword.message || "Escriba su nueva contraseña")}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="repeatPassword"
              error={(errors.repeatPassword ? true : false)}
              label="Repetir contraseña"
              name="repeatPassword"
              type="password"
              variant="outlined"
              size="small"
              inputRef={register({ validate: value => value === newPassword || "Las contraseñas no coinciden" })}
            />
            <FormHelperText error={true}>
              {errors?.repeatPassword && (errors.repeatPassword.message || "Repita su nueva contraseña")}
            </FormHelperText>
          </FormControl>
        </UserDialogContent>
        <UserDialogActions>
          <Grid container justifyContent='flex-end'>
            <Grid item xs={6} lg={4}>
              <PrimaryButton title="Guardar" />
            </Grid>
          </Grid>
        </UserDialogActions>
      </form>
    </Dialog>
  )
}

export default UpdatePassword
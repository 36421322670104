import { FC } from "react"

type ColorPreviewParams = {
    color: string,
    width: number,
    height: number,

}

const ColorPreview: FC<ColorPreviewParams> = ({ color, width, height }) => {

    const isValid = (color: string) => {
        return !!color.match(/^#?[0-F]{6}$/i)
    }

    const curateColor = (color: string) => {
        if (!isValid(color)) {
            return '#ffffff'
        }
        if (color.length === 6) {
            return ('#' + color).toLowerCase()
        }

        if (color.length === 7 && color.startsWith('#')) {
            return color.toLowerCase()
        }

        return '#ffffff'
    }

    return (
        <>
            {isValid(color) &&
                < div style={{ backgroundColor: curateColor(color), width: width, height }}></div >
            }
        </>
    )
}
export default ColorPreview
import React from "react"
import { IconButton, InputAdornment, FormControl, TextField, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Cancel from '@material-ui/icons/Cancel';
import Collapse from '@material-ui/core/Collapse';

type Props = {
  value: string
  onSearch: (query: string) => void
}

const FilterInput: React.FC<Props> = ({ value, onSearch }) => {
  const [query, setQuery] = React.useState<string>(value);
  const [showCancel, setShowCancel] = React.useState<boolean>(false);
  const textfieldRef = React.useRef(null);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") handleSearch();
  }

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  }

  const handleSearch = () => {
    onSearch(query);
    const mustShow = query !== "";
    setShowCancel(mustShow);
  }

  const handleCancel = () => {
    textfieldRef.current.value = '';
    setQuery('');
    onSearch('');
    setShowCancel(false);
  }

  return (
    <FormControl >
      <TextField
        label="Filtrar"
        id="standard-start-adornment"
        name="title"
        size="small"
        variant="outlined"
        inputRef={textfieldRef}
        onChange={handleQueryChange}
        onBlur={handleSearch}
        onKeyPress={e => handleKeyPress(e)}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Collapse in={showCancel}>
              <Tooltip title="Limpiar búsqueda">
                <IconButton edge="end" onClick={handleCancel}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Collapse>
            <Tooltip title="Buscar">
              <IconButton edge="end" onClick={handleSearch}>
                <SearchIcon />
              </IconButton >
            </Tooltip>
          </InputAdornment>,
        }}
      />
    </FormControl>
  )
}

export default FilterInput
import React, { useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';

import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import ConfirmDialog from '../../components/commons/dialogs/ConfirmDialog';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import UpdateUser from '../../components/users/UpdateUser';
import UsersList from '../../components/users/UserList';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import { Paginated } from '../../components/commons/Paginated';
import FAB from '../../components/commons/buttons/FAB';

import useCancelToken from '../../hooks/useCancelToken';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import { roleService } from '../../_services/role.service';
import { groupService } from '../../_services/group.service';
import { userService } from '../../_services/user.service';
import { Role } from '../../components/users/role.model';
import { UserGroup } from '../../components/user-groups/userGroup.model';
import { User } from '../../components/users/user.model';

type Order = "asc" | "desc";

const Users: React.FC = () => {
  const [users, setUsers] = React.useState<Paginated<User>>(null);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [groups, setGroups] = React.useState<UserGroup[]>([]);
  const [query, setQuery] = React.useState<string>('');
  const [user, setUser] = React.useState<User>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof User>("firstname");
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const { getCancelToken, isCancel } = useCancelToken()
  const { showError, showSuccess } = useSnackBars()
  const handleError = useErrorHandler();

  const fetchGroups = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const groups = await groupService.list("", cancelToken);
        setGroups(groups)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  const fetchRoles = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const roles = await roleService.list(cancelToken)
        setRoles(roles)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    fetchGroups()
    fetchRoles()
  }, [fetchGroups, fetchRoles])

  const fetchUsers = useCallback(
    async (query: string, limit: number, offset: number) => {
      try {
        const cancelToken = getCancelToken()
        const users = await userService.list(query, limit, offset, order, orderBy, cancelToken)
        setUsers(users)
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy])

  useEffect(() => {
    fetchUsers(query, 10, 0)
  }, [query, fetchUsers])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const reloadUsers = async (limit: number, offset: number) => {
    fetchUsers('', limit, offset)
    setQuery('')
  }

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const handleDelete = async (user: User) => {
    try {
      const res = await userService.remove(user.id)
      showSuccess(res.msg)
      reloadUsers(users.limit, users.offset)
    }
    catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setUser(null)
  }

  const handleCreated = () => {
    reloadUsers(users.limit, users.offset)
    setOpen(false)
    setUser(null)
  }

  const handleUpdate = (user: User) => {
    setOpen(true)
    setUser(user)
  }

  const handleCreate = () => {
    setOpen(true)
    setUser(null)
  }

  const handleChangePage = (page: number) => {
    reloadUsers(users.limit, (page) * users.limit)
  }
  const handleChangeRowsPerPage = (rows: number) => {
    reloadUsers(rows, 0)
  }

  const handleSort = async (e: React.MouseEvent<unknown>, property: keyof User) => {
    try {
      const isAsc = orderBy === property && order === 'asc';
      const newOrder = isAsc ? 'desc' : 'asc';
      setOrder(newOrder);
      setOrderBy(property);

      const cancelToken = getCancelToken()
      const sorted = await userService.list(query, users.limit, users.offset, newOrder, orderBy, cancelToken)
      setUsers(sorted)
    } catch (error) {
      error instanceof Error ?
        handleError(error) :
        handleApiError(error.errors)
    }
  };

  const openDeleteDialog = (user: User) => {
    setUser(user);
    setOpenDelete(true);
  }

  const closeDeleteDialog = () => setOpenDelete(false);

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flexGrow={1}>
          <FilterInput value={query} onSearch={handleSearch} />
        </Box>
        <FAB ariaLabel="Nuevo usuario" onClick={handleCreate} />
      </Box>
      {!users || users.totalDocs === 0 ? <EmptyResults /> :
        <UsersList
          users={users}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          onUpdate={handleUpdate}
          onDelete={openDeleteDialog}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
      <UpdateUser
        roles={roles}
        user={user}
        open={open}
        groups={groups}
        onCreate={handleCreated}
        onClose={handleClose}
      />
      <ConfirmDialog<User>
        open={openDelete}
        holder={user}
        title="Eliminando Usuario"
        subtitle="¿Deseas continuar con la eliminación?"
        onAccept={handleDelete}
        onClose={closeDeleteDialog}
      />
    </>
  )
}
export default Users

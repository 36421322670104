import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Table, LabelDisplayedRowsArgs, TableBody, TableCell, TableHead, TableRow, TablePagination, TableContainer, Tooltip, Paper } from '@material-ui/core';
import { Delete, Edit, Palette } from '@material-ui/icons';
import useTableStyles from '../../hooks/styles/tableStyles';
import { Paginated } from '../commons/Paginated';
import { Floor } from './floor.model';

type Props = {
  floors: Paginated<Floor>;
  onDelete: (floor: Floor) => void;
  onUpdate: (floor: Floor) => void;
  onChangePage: (page: number) => void;
  onRowsPerPageChange: (rows: number) => void;
}

const FloorList: React.FC<Props> = ({ floors, onDelete, onUpdate, onChangePage, onRowsPerPageChange }) => {
  const classes = useTableStyles();

  const handleDelete = (floor: Floor) => {
    onDelete(floor);
  }
  const handleUpdate = (floor: Floor) => {
    onUpdate(floor)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };
  const pagesLimit = ({ from, count }: LabelDisplayedRowsArgs) => {
    return '' + (Math.trunc(from / floors.limit + 1)) + ' de ' + ((count % floors.limit) === 0 ?
      (Math.trunc(count / floors.limit)) : (Math.trunc(count / floors.limit + 1)))
  }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {floors.docs.map((floor) => (
            <TableRow key={floor.id}>
              <TableCell>{floor.name}</TableCell>
              <TableCell>{floor.description}</TableCell>
              <TableCell align='right'>
                {floor.image ?
                  <Link to={{ pathname: '/floor/designer', state: { floor } }} style={{ textDecoration: 'none' }}>
                    <Tooltip title="Editar plano">
                      <IconButton color="primary" aria-label="upload picture" size="small">
                        <Palette />
                      </IconButton>
                    </Tooltip>
                  </Link>
                  : ''
                }
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(floor)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(floor)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={floors.totalDocs}
        page={floors.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={floors.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={pagesLimit}
        labelRowsPerPage=""
        title="Cantidad de resultados por página"
        nextIconButtonText="Siguiente"
        backIconButtonText="Anterior"
        className={classes.tablePagination}
      />
    </TableContainer>
  );
}

export default FloorList;
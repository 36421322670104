import baseAPI from './api'
import { WeeklySchedule } from '../components/weeklySchedule/weekly.schedule.model'
import { TimeRange } from '../components/weeklySchedule/time.range.model'
import { Success } from '../components/commons/Success'

const getWeek = async () => {
  const response = await baseAPI.get('weekly-schedule')
  return response.data as WeeklySchedule
}

const update = async (id: string, week: TimeRange[], timezone: string) => {
  const res = await baseAPI.put(`weekly-schedule/${id}`, { week, timezone })
  return res.data as Success<string>
}

export const weeklyScheduleService = {
  getWeek,
  update
}
import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl } from '@material-ui/core';
import UserList from './UserList';
import transferListUtils from '../../commons/utils/TransferListUtils';
import { userService } from '../../../_services/user.service';
import { ListedUser } from '../../users/listedUser.model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 200,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    formControl: {
      padding: theme.spacing(0, 2, 2, 0)
    },
  }),
);

type Props = {
  groupId: string;
  selectedUsers: ListedUser[];
  handleSelectedUsers: (selected: ListedUser[]) => void;
}

const { not, intersection, union } = transferListUtils<ListedUser>();

const UserTransferList: FC<Props> = ({ groupId, selectedUsers, handleSelectedUsers }) => {
  const classes = useStyles();
  
  const [query, setQuery] = useState<string>('');
  const [checked, setChecked] = useState<ListedUser[]>([]);
  const [left, setLeft] = useState<ListedUser[]>(selectedUsers);
  const [right, setRight] = useState<ListedUser[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    handleSelectedUsers(left)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left]);

  useEffect(() => {
    const fetchData = async () => {
      let unListed = await userService.getUnListed(query, groupId);
      if(left.length) unListed = unListed.filter(ul => !left.some(l => l.id === ul.id));
      setRight(unListed);
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSearch = (query: string) => setQuery(query);

  const handleToggle = (value: ListedUser) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  }

  const numberOfChecked = (items: ListedUser[]) => intersection(checked, items).length;

  const handleToggleAll = (items: ListedUser[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <div>
      <FormControl fullWidth className={classes.formControl}>
        <TextField
          id="query"
          label="Filtrar usuarios"
          name="query"
          variant="outlined"
          size="small"
          onChange={e => handleSearch(e.target.value)}
        />
      </FormControl>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <UserList
            title="Asignados"
            items={left}
            checked={checked}
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <UserList
            title="Sin asignar"
            items={right}
            checked={checked}
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default UserTransferList;
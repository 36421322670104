import { FC, useState, ReactNode } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export type TabData = {
  label: string;
  component: ReactNode;
}

type CustomTabProps = {
  tabs: TabData[];
}

const CustomTab: FC<CustomTabProps> = ({ tabs }) => {
  const [value, setValue] = useState(0);
  const { height, width } = useWindowDimensions();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="pestañas de navegación"
          selectionFollowsFocus
        >
          {tabs.map((tab, index) =>
            <Tab
              key={`tab-${index}`}
              id={`tab-${index}`}
              aria-controls={`tab-${index}`}
              label={tab.label}
            />
          )}
        </Tabs>
      </Paper>
      {
        tabs.map((tab, index) => {
          return (
            <div
              id={`tabpanel-${index}`}
              key={`tab-panel-${index}`}
              hidden={value !== index}
              style={{ 
                height: height - 64 - 48, // window - BarSpacer - TabsMenu
                width: width - 200, // window - drawer
              }}
            >
              <Box p={1}
                paddingTop={2}
                style={{ height: '100%' }}>{tab.component}</Box>
            </div>
          )
        })
      }
    </>
  );
}

export default CustomTab
import { ElementType, FunctionComponent } from "react";
import { Route, RouteProps } from "react-router-dom";

type Props = {
  component: ElementType;
  path: RouteProps['path'];
  exact?: any
};

/**
 * Este wrapper de Route se crea para que todas las rutas se creen usando "render" en lugar de "component"
 * por razones de performance, en especial porque estamos utilizando functional components.
 * IMPORTANTE: esto significa que constructor, componentDidMount, shouldComponentUpdate se ejecutarán sólo una vez. 
 */
export const AppRoute: FunctionComponent<Props> = ({
  component: Component,
  ...routeProps
}) => {
  return <Route {...routeProps} render={(props) => <Component />} />
};

export const parseQueryParams = (location: any) => {
  return new URLSearchParams(location.search);
}

export const removeQueryFromBrowser = (location: string) => {
  window.history.replaceState(null, '', location)
}

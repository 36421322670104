import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { UserDialogActions, UserDialogContent, UserDialogTitle, useUserDialogStyle } from '../../commons/dialog/UserDialog';
import BookingCreate from './BookingCreate';
import { Booking } from '../booking.model';
import { Moment } from "moment";

type Props = {
  open: boolean;
  booking: Booking;
  textBtn?: string;
  iconBtn?: ReactNode;
  showUserSelect?: boolean;
  onClose: () => void;
  onCreate: (booking: Booking) => void;
  onChangeTime?: (begin: Moment, end: Moment, title: string, isRecurrent?: boolean, recurrenceWeekdays?: boolean[], recurrenceEndDate?: Date) => void;
}

const BookingCreateDialog: React.FC<Props> = ({ open, booking, textBtn = "Reservar", iconBtn = <EventAvailableIcon />, showUserSelect, onClose, onCreate, onChangeTime }) => {
  const dialogClasses = useUserDialogStyle()
  const [title, setTitle] = useState<string>(booking?.title)
  const [user, setUser] = useState<string>(booking?.user)

  const closeDialog = () => {
    onClose();
  }

  useEffect(() => {
    setTitle(booking?.title as string)
  }, [booking])


  const onSubmit = async (e: any) => {
    e.preventDefault()
    onCreate({ ...booking, 'title': title, user } as Booking);
  }

  const handleTitleChange = (title: string) => {
    setTitle(title as string)
  }

  const handleUserChange = (user: string) => {
    setUser(user as string)
  }

  return (
    <>
      {booking &&
        <Dialog onClose={closeDialog}
          open={open && !!booking}
          classes={dialogClasses}>
          <UserDialogTitle id="customized-dialog-title" onClose={closeDialog} background='owner'>
            <Typography variant="h6">Nueva Reserva</Typography>
          </UserDialogTitle>
          <form onSubmit={onSubmit}>
            <UserDialogContent dividers>
              <BookingCreate
                title={title}
                userId={user}
                booking={booking}
                showUserSelect={showUserSelect}
                onChangeTime={onChangeTime}
                onTitleChange={handleTitleChange}
                onUserChange={handleUserChange}
              />
            </UserDialogContent>
            <UserDialogActions>
              <Grid container justifyContent="flex-end">
                <Grid item xs={4}>
                  <Button
                    autoFocus
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    endIcon={iconBtn}>
                    {textBtn}
                  </Button>
                </Grid>
              </Grid>
            </UserDialogActions>
          </form>
        </Dialog>
      }
    </>
  )
}

export default BookingCreateDialog
import React, { useEffect, useState, useCallback } from 'react'
import ResourceAgenda from '../../components/booking/agenda/ResourceAgenda'
import { Paginated } from '../../components/commons/Paginated'
import useSnackBars from '../../components/commons/snackbar/SnackbarHook'
import { useCurrentBranch } from '../../components/commons/currentbranch/CurrentBranchHook'
import { useErrorHandler } from '../../components/errors/ErrorBoundary'
import { Resource } from '../../components/resource/resource.model'
import { resourceService } from '../../_services/resource.service'
import useCancelToken from '../../hooks/useCancelToken'

const ResourceBooking: React.FC = () => {
  const [resources, setResources] = useState<Paginated<Resource>>();

  const { showError } = useSnackBars();
  const handleError = useErrorHandler();
  const { currentBranch } = useCurrentBranch();
  const { getCancelToken, isCancel } = useCancelToken()

  const handleApiError = useCallback(
    (errors: any) => {
      Object.keys(errors).forEach((field) => {
        const e = errors[field]
        showError(e.msg);
      })
    }, [showError])

  const fetchResources = useCallback(
    async () => {
      try {
        const cancelToken = getCancelToken()
        const _resources = await resourceService.list(null, true, 0, 5, null, null, null, null, cancelToken);
        setResources(_resources);
      } catch (error) {
        if (!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    }, [getCancelToken, handleApiError, handleError, isCancel])

  useEffect(() => {
    fetchResources()
  }, [currentBranch, fetchResources])

  return (
    <>
      {resources && <ResourceAgenda resources={resources.docs} />}
    </>
  )
}

export default ResourceBooking

import { FC } from "react";
import CustomTab, { TabData } from "../../components/commons/tabs/CustomTab";
import EditProfile from "../../components/profile/EditProfile";

const ProfileTab: FC = () => {
  const tabList: TabData[] = [
    { label: 'Perfil de usuario', component: <EditProfile /> },
  ]

  return (<CustomTab tabs={tabList} />);
}

export default ProfileTab
import baseAPI from './api';
import { User } from '../components/users/user.model';
import { Paginated } from "../components/commons/Paginated";
import { Success } from '../components/commons/Success';
import { AxiosRequestConfig, CancelToken } from 'axios';

const list = async (query: string, limit: number, offset: number, cancelToken?: CancelToken) => {
    const axiosConfig: AxiosRequestConfig = {}
    axiosConfig.params = { 'q': query, limit, offset }
    if(cancelToken) {
      axiosConfig.cancelToken = cancelToken
    }
    const response = await baseAPI.get(`users/join/list`, axiosConfig)
    return response.data as Paginated<User>
}

const approved = async (user: User) => {
    const data = { id: user.id, email: user.email, firstname: user.firstname }
    const res = await baseAPI.patch('users/join/approve', data)
    return res.data as Success<string>
}

const reject = async (user: User) => {
    const data = { id: user.id, email: user.email, firstname: user.firstname }
    const res = await baseAPI.patch('users/join/reject', data)
    return res.data as Success<string>

}

export const joinService = {
    list,
    approved,
    reject
}
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FC } from 'react';
import type { FallbackProps } from '../../components/errors/ErrorBoundary';

const useImage = makeStyles((theme: Theme) => ({
  image: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'url(webee-error.jpg) no-repeat center center fixed',
    backgroundSize: 'cover',
    minWidth: '100%',
    minHeight: '100%',
  },
  text: {
    "@media (min-width:1000px)": {
      fontWeight: 600,
      fontSize: '42px',
    },
    "@media (max-width:1000px)": {
      fontWeight: 400,
      fontSize: '32px',
    },
    color: theme.palette.grey[600],
    position: 'relative',
    fontFamily: 'Rubik',
  },
  textContainer: {
    width: '100%',
    textAlign: 'center'
  },
  button: {
    backgroundColor: '#f8d33c',
    borderRadius: '30px',
    padding: '15px 30px',
    fontSize: '16px',
    position: 'relative',
    color: '#fff'
  }
}));
const ErrorFallback: FC<FallbackProps> = ({ error }: FallbackProps) => {
  const classes = useImage()
  return (
    <div className={classes.image}>
      <Grid container direction="column">
        <Grid>
          <div className={classes.textContainer}>
            <h2 className={classes.text}>Ouch! Parece que metimos la pata...</h2>
          </div>
        </Grid>
        <Grid lg={8} xs={12}>
          <Grid container justifyContent='center'>
            { /* eslint-disable-next-line no-self-assign */}
            <Button className={classes.button} onClick={() => { window.location.href = window.location.href }}>
              Ir al inicio
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ErrorFallback;
import { makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

const useUserDashboardStyles = makeStyles((theme: Theme) => ({

  // Container
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  // AppBar
  appBar: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    color: theme.palette.grey[700],
    marginLeft: 5
  },
  actions: {
    '& button': {
      padding: theme.spacing(1),
    },
    '& button:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[300],
    }
  },

  // Drawer
  drawer: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },

  // DrawerContainer
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%'
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: '#E8F0F6',
    zIndex: 2,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  welcomeTitle: {
    color: theme.palette.primary.light,
    fontSize: theme.typography.fontSize,
  },
  usernameTitle: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  subtitle: {
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 'lighter',
    color: theme.palette.text.secondary,
  },
  nextBookingContainer: {
    overflowY: 'auto',
    '& a': {
      textAlign: 'center',
    }
  },

  // Content
  wrapContent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: { 
      width: '100%',
    },
  },
  appContent: {
    flexGrow: 1,
    borderTop: `5px solid ${theme.palette.secondary.main}`,
    overflowX: 'auto',
    overflowY: 'auto',
  },
  toolbarContent: {
    zIndex: 10,
    backgroundColor: theme.palette.grey[100],
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    '& a:first-child': {
      marginTop: theme.spacing(2.25),
    }
  },
}));

export default useUserDashboardStyles;
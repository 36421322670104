import { Route, Redirect } from 'react-router-dom';
import { AppRoute } from '../../_helpers/routes';
import Designer from '../../pages/designer/Designer';
import ProfileTab from '../../pages/user/ProfileTab';
import ConfigTab from '../../pages/tabs/ConfigTab';
import ResourcesTab from '../../pages/tabs/ResourcesTab';
import Rules from '../../pages/rules/Rules';
import UsersTab from '../../pages/tabs/UsersTab';
import Reports from '../../pages/reports/Reports';
import AvailableResources from '../../pages/available-resources/AvailableResources';
import CompanyTab from '../../pages/tabs/CompanyTab';
import BookingsTab from '../../pages/tabs/BookingsTab';

/**
 * Definir rutas dentro del Dashboard para usuarios con Role.admin. Actualizar el menú (MainListItems)
 * para añadir / eliminar el link a tu componente.
 * Los componentes que se desean mostrar para ambos usuarios se agregan rutas en AdminRoutes y UserRoutes.
 */
export const AdminRoutes = () => {

  return (
    <>
      {/* Redirección temporal hasta que tengamos un dashboard para el admin */}
      <Route exact path="/"><Redirect to="/reports" /></Route>
      <AppRoute exact path="/profile" component={ProfileTab} />
      <AppRoute exact path="/availableResources" component={AvailableResources} />
      <AppRoute exact path="/resource" component={ResourcesTab} />
      <AppRoute exact path="/user" component={UsersTab} />
      <AppRoute exact path="/floor/designer" component={Designer} />
      <AppRoute exact path="/company" component={CompanyTab} />
      <AppRoute exact path="/config" component={ConfigTab} />
      <AppRoute exact path="/rules" component={Rules} />
      <AppRoute exact path="/reports" component={Reports} />
      <AppRoute exact path="/bookings" component={BookingsTab} />
      <Route exact path="*"><Redirect to="/reports" /></Route>
    </>
  )
}

import { FC, useState, useRef } from "react";
import moment from "moment";
import { Switch, FormControlLabel, Typography } from "@material-ui/core";
import { TimeRange } from "../../weeklySchedule/time.range.model";
import { useLaborable } from "../../commons/laborableContext/LaborableHook";

type Props = {
  begin: moment.Moment;
  end: moment.Moment;
  onChange: (begin: moment.Moment, end: moment.Moment) => void;
}

const AllDaySelector: FC<Props> = ({ begin, end, onChange }) => {
  const { weeklySchedule } = useLaborable();
  const beginRef = useRef<moment.Moment>(begin);
  const endRef = useRef<moment.Moment>(end);
  const [isChecked, setIsChecked] = useState<boolean>(checkAllDayRange(begin, end, weeklySchedule.week));

  const handleToggle = () => {
    if (isChecked) {
      onChange(beginRef.current, endRef.current);
      setIsChecked(false);
    } else {
      beginRef.current = begin;
      endRef.current = end;
      const range = weeklySchedule.week.find(day => day.weekDay === begin.day());

      const _rangeBegin = moment(range.begin)
      const _rangeEnd = moment(range.end)
      onChange(begin.clone().hours(_rangeBegin.hour()).minutes(_rangeBegin.minutes()),
        end.clone().hours(_rangeEnd.hour()).minutes(_rangeEnd.minutes()));
      setIsChecked(true);
    }
  }

  return (<div>
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={isChecked}
          onClick={handleToggle}
        />
      }
      label={<Typography style={{ fontSize: "14px" }}>Todo el día</Typography>}
    />
  </div>);
}

function checkAllDayRange(begin: moment.Moment, end: moment.Moment, week: TimeRange[]): boolean {
  const { begin: rangeBegin, end: rangeEnd } = week.find(day => day.weekDay === begin.weekday());
  if (begin.isSame(moment(), "day")) {
    return begin.isSame(moment()) && end.isSame(rangeEnd);
  }
  return begin.isSame(rangeBegin) && end.isSame(rangeEnd);
}

export default AllDaySelector;
import baseAPI from './api';
import { ResourceType } from '../components/resource/resourceType.model'
import { Success } from '../components/commons/Success';

const create = async (resourceTypes: ResourceType) => {
  const body = resourceTypes
  const response = await baseAPI.post(`availableResourceTypes`, body)
  return response.data as Success<string>
}

const deleteAvailability = async (resToDelete: ResourceType) => {
  const id = resToDelete.id
  const response = await baseAPI.delete(`availableResourceTypes/${id}`)
  return response.data as Success<string>
}

export const availableResourceTypesService = {
  create,
  deleteAvailability
};

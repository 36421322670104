import moment from 'moment-timezone';
import React from 'react';
import { Chip, Grid, Tooltip } from '@material-ui/core';
import { Booking } from '../booking.model';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ComputerIcon from '@material-ui/icons/Computer';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoopIcon from '@material-ui/icons/Loop';
import { Resource } from '../../resource/resource.model';
import { getGMTString } from '../../commons/utils/timezoneUtils';
import { useLaborable } from '../../commons/laborableContext/LaborableHook';
import { FULL_WEEK_DAYS } from '../../../_helpers/constants';

type Props = {
  booking: Booking;
}

/** Componente para mostrar todos los campos de una reserva */
const BookingView: React.FC<Props> = ({ booking }) => {
  const { weeklySchedule } = useLaborable()
  return (
    <Grid container alignItems='center' spacing={1} >
      <Grid item xs={1}>
        <Tooltip title="Fecha reservada"><EventIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{moment.tz(booking?.bookingStart, weeklySchedule.timezone as string).format("dddd, D [de] MMMM")}</Grid>
      <Grid item xs={1}>
        <Tooltip title="Rango horario"><AccessTimeIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{`${moment.tz(booking?.bookingStart, weeklySchedule.timezone as string).format("HH:mm")} - ${moment.tz(booking?.bookingEnd, weeklySchedule.timezone as string).format("HH:mm")}  ${getGMTString(weeklySchedule.timezone as string)}`}</Grid>
      {booking?.isRecurrent &&
        <>
          <Grid item xs={1}>
            <Tooltip title="Días de la semana en que se repite">
              <LoopIcon color="primary" />
            </Tooltip>
          </Grid>
          <Grid item xs={11}>
            {booking.recurrenceWeekdays.map((day, index) => day ? FULL_WEEK_DAYS[index] + "  " : "")}
          </Grid>
        </>
      }
      <Grid item xs={1}>
        <Tooltip title="Usuario"><PersonIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{booking?.userInfo?.firstname + ' ' + booking?.userInfo?.lastname}</Grid>
      <Grid item xs={1}>
        <Tooltip title="Lugar"><LocationOnIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.branch.name + ' - ' + (booking?.resource as Resource)?.floor.name}</Grid>
      <Grid item xs={1}>
        <Tooltip title="Nombre de recurso"><ComputerIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.name}</Grid>
      <Grid item xs={1}>
        <Tooltip title="Capacidad"><AccessibilityIcon color='primary' /></Tooltip>
      </Grid>
      <Grid item xs={11}>{(booking?.resource as Resource)?.capacity}</Grid>
      <Grid item xs={1}>
        <Tooltip title="Recursos adicionales"><b><AttachFileIcon color='primary' /></b></Tooltip>
      </Grid>
      <Grid item xs={11}>
        {(booking?.resource as Resource)?.amenities?.map((value, index) => (
          <Chip key={`${value.id}-${index}`} label={value.name} />
        ))}
        {(!(booking?.resource as Resource)?.amenities || (booking?.resource as Resource)?.amenities.length === 0) &&
          'No posee extras'
        }
      </Grid>
    </Grid>
  )
}

export default BookingView;

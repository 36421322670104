import React, { useEffect, useCallback } from 'react';
import { Box } from '@material-ui/core';
import FilterInput from '../../components/commons/filterInput/FilterInput';
import { joinService } from '../../_services/join.service';
import { User } from '../../components/users/user.model';
import ListuserRequest from '../../components/user-request/ListuserRequest';
import { Paginated } from '../../components/commons/Paginated';
import useSnackBars from '../../components/commons/snackbar/SnackbarHook';
import EmptyResults from '../../components/commons/empty-results/EmptyResults';
import { useErrorHandler } from '../../components/errors/ErrorBoundary';
import useCancelToken from '../../hooks/useCancelToken';

const UserRequests: React.FC = () => {
  const [users, setUsers] = React.useState<Paginated<User>>(null);
  const [query, setQuery] = React.useState<string>("")
  const { getCancelToken, isCancel } = useCancelToken()
  const { showSuccess, showError } = useSnackBars();
  const handleError = useErrorHandler()

  const handleApiError = (errors: any) => {
    errors.api?.msg ? showError(errors.api.msg) : console.error(errors)
  }

  const fetchUsers = useCallback(
    async (query: string, limit: number, offset: number) => {
      try {
        const cancelToken = getCancelToken()
        const users = await joinService.list(query, limit, offset, cancelToken)
        setUsers(users)
      } catch (error) {
        if(!isCancel(error)) {
          error instanceof Error ?
            handleError(error) :
            handleApiError(error.errors)
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => { fetchUsers(query, 10, 0) }, [query, fetchUsers])

  const handleSearch = (query: string) => {
    setQuery(query)
  }

  const reloadRequests = async (limit: number, offset: number) => {
    fetchUsers('', limit, offset)
    setQuery('')
  }
  const handleChangePage = (page: number) => {
    reloadRequests(users.limit, (page) * users.limit)
  }
  const handleChangeRowsPerPage = (rows: number) => {
    reloadRequests(rows, 0)
  }

  const handleAccept = async (user: User) => {
    const res = await joinService.approved(user)
    showSuccess(res.msg)
    reloadRequests(users.limit, users.offset)
  }

  const handleReject = async (user: User) => {
    const res = await joinService.reject(user)
    showSuccess(res.msg)
    reloadRequests(users.limit, users.offset)
  }

  return (
    <>
      <Box display="flex" marginBottom={2}>
        <FilterInput value={query} onSearch={handleSearch} />
      </Box>
      {!users || users.totalDocs === 0 ? <EmptyResults /> :
        <ListuserRequest
          onAccept={handleAccept}
          onReject={handleReject}
          users={users}
          onChangePage={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      }
    </>
  );
}

export default UserRequests;
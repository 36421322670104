import React from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import useTableStyles from '../../hooks/styles/tableStyles';
import { Amenity } from './amenity.model';

type Props = {
  amenities: Amenity[];
  onUpdate: (amenity: Amenity) => void;
  onDelete: (amenity: Amenity) => void;
}

const AmenityList: React.FC<Props> = ({ amenities, onUpdate, onDelete }) => {
  const classes = useTableStyles();

  const handleUpdate = (amenity: Amenity) => { onUpdate(amenity) }
  const handleDelete = (amenity: Amenity) => { onDelete(amenity) }

  return (
    <TableContainer component={Paper} elevation={2} className={classes.tableContainer}>
      <Table
        size="small"
        aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell align='center'>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {amenities?.map((amenity: Amenity) => (
            <TableRow key={amenity.id}>
              <TableCell>{amenity.name}</TableCell>
              <TableCell align='center'>
                <Tooltip title="Editar">
                  <IconButton aria-label="edit" onClick={() => handleUpdate(amenity)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar">
                  <IconButton aria-label="delete" onClick={() => handleDelete(amenity)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AmenityList;
import React from "react"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Grid, IconButton } from "@material-ui/core";
import moment, { Moment } from 'moment'
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";

type Props = {
  currentDate: Date,
  minDate: Date,
  maxDate: Date,
  onChange: (date: Date) => void;
}

const DateSlider: React.FC<Props> = ({ currentDate, minDate, maxDate, onChange }) => {

  const handleDateChange = (date: Moment | null) => {
    if (!!date) {
      onChange(date.toDate())
    }
  }

  const increment = () => {
    onChange(moment(currentDate).add(1, 'days').toDate())
  }

  const decrement = () => {
    onChange(moment(currentDate).subtract(1, 'days').toDate())
  }

  const isDisabled = (limitDate: Date) => {
    const limitDateFormatted = moment(limitDate).format('YYYY-MM-DD');
    const dateFormatted = moment(currentDate).format('YYYY-MM-DD')
    return limitDateFormatted === dateFormatted;
  }

  return (
    <Grid container>
      <Grid item xs={3} >
        <IconButton aria-label="decrement" onClick={decrement} disabled={isDisabled(minDate)}>
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item xs={6} >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            format='DD/MM/YYYY'
            value={currentDate}
            onChange={handleDateChange}
            margin="dense"
            minDate={minDate}
            maxDate={maxDate}
            autoOk
            disableToolbar
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={3}>
        <IconButton aria-label="incement" onClick={increment} disabled={isDisabled(maxDate)}>
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default DateSlider
import React, { FC, useRef, useEffect } from "react";
import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography, Menu, MenuItem, MenuProps, ListItemText } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { BranchReference } from '../branches/branch.reference.model';
import { useCurrentBranch } from "../commons/currentbranch/CurrentBranchHook";
import { useBranches } from '../commons/branches/BranchesHook';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  arrowIcon: {
    color: theme.palette.primary.contrastText
  }
}));

const StyledMenu = withStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    '& .Mui-selected': {
      borderLeft: `solid 5px ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))((props: MenuProps) => (
  <Menu
    elevation={3}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(MenuItem);

const BranchSelector: FC = () => {
  const classes = useStyles();
  const { branches } = useBranches()
  const { currentBranch, onUpdateBranch } = useCurrentBranch()
  const [currentBranchName, setCurrentBranchName] = React.useState<string>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setCurrentBranchName(branches.find((branch: BranchReference) => branch.id === currentBranch)?.name)
  }, [currentBranch, branches]);

  const handleSelect = (event: any, branch: BranchReference) => {
    onUpdateBranch(branch.id);
    setCurrentBranchName(branch.name);
    handleClose();
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(containerRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {
        branches && branches.length > 0 ?
          <>
            <div ref={containerRef} className={classes.root}>
              <Typography>
                {currentBranchName}
              </Typography>
              <IconButton
                aria-controls="branch-selector"
                aria-haspopup="true"
                className={classes.arrowIcon}
                onClick={handleOpen}
              >
                <ArrowDropDown />
              </IconButton>
            </div>
            <StyledMenu
              id="branch-selector"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {branches.map((branch: BranchReference) => {
                return <StyledMenuItem
                  key={"branch-selector-" + branch.id}
                  selected={currentBranch === branch.id}
                  onClick={e => handleSelect(e, branch)}>
                  <ListItemText primary={branch.name} />
                </StyledMenuItem>
              })}
            </StyledMenu>
          </>
          : ''
      }
    </>
  );
}

export default BranchSelector;
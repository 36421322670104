import { BehaviorSubject } from 'rxjs';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import baseAPI from './api';
import { configService } from './config.service';

/**
 * The authentication service is used to login and logout of the application, to login it posts the
 * user's credentials to the /users/authenticate route on the api, if authentication is successful 
 * the user details including the token are added to local storage, and the current user is set in the
 * application by calling currentUserSubject.next(user);.
 */

export interface Credentials {
  email: string,
  password: string,
  token?: string
}

export interface UserSession {
  id: string
  firstname: string
  lastname: string
  email: string
  token?: string
  company?: string
  role: string
  expiration: Date
  active: boolean
  verified: boolean
  approved: boolean
  image?: string
}

const _buildSession = (accessToken: string) => {
  let decoded = jwt_decode<UserSession>(accessToken);
  const expiration = moment((decoded as any).exp * 1000).toDate();
  return { ...decoded, expiration, token: accessToken } as UserSession;
}

const _localRefresh = (accessToken: string) => {
  // store jwt token in local storage to keep user logged in between page refreshes
  localStorage.setItem('currentUser', accessToken);
  const session = _buildSession(accessToken);
  currentUserSubject.next(session);
}

const refresh = async () => {
  const response = await baseAPI.post(`auth/refresh`);
  const data = response.data;
  _localRefresh(data.token);
}

const login = async (credentials: Credentials) => {
  const response = await baseAPI.post(`auth/login`, credentials);
  const user = response.data;
  if (response.status === 200) {
    _localRefresh(user.accessToken);
  }
}

const loginWithToken = (token: string) => {
  _localRefresh(token);
}

const logout = () => {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
  configService.refreshCurrentBranch(null, true);
}

const isSessionExpired = () => {
  return !currentUserSubject.value || moment(currentUserSubject.value.expiration).isBefore(moment());
}

let accessToken = localStorage.getItem('currentUser')
let currentUserSubject = new BehaviorSubject<UserSession | null>(!!accessToken ? _buildSession(accessToken) : null)

export const authenticationService = {
  login,
  logout,
  loginWithToken,
  refresh,
  isSessionExpired,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
};

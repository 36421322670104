import { FC } from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import MobilePrompt from "./MobilePrompt";

/**
 * Componente de alta jerarquía que se encarga de la detección del dispositivo del usuario.
 * Momentáneamente se encarga de detectar el ingreso por dispositivo móvil y su respectivo SO,
 * pero se puede extender para detectar otras características (navegador, versión del navegador,
 * otros tipos de dispositivos como TVs, tablets, etc...).
 * Para extenderlo, chechear la documentación: https://www.npmjs.com/package/react-device-detect
 */
const DeviceDetector: FC = ({ children }) => {

  return(
    <>
      { children }
      { isMobile && (isAndroid || isIOS) &&
        <MobilePrompt isAndroid={isAndroid} isIOS={isIOS} />
      }
    </>
  );
}

export default DeviceDetector;
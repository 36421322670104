import moment from "moment-timezone"

const getTimezoneOffset = (timezone: string): number => {
    return moment().tz(timezone).utcOffset() / 60
}

const getDefaultTimezoneOffset = (): number => {
    return getTimezoneOffset(moment().zoneName())
}

const getGMTString = (timezone: string): string => {
    const offset = moment().tz(timezone).utcOffset()
    return `GMT${(offset > 0 ? '+' : '')}${offset / 60}`
}

const getDefaultGMTString = (): string => {
    return getGMTString(moment.tz.guess())
}


export { getTimezoneOffset, getDefaultTimezoneOffset, getGMTString, getDefaultGMTString }